import { ThemeProvider } from '@material-ui/core/styles';
import { Utils } from '@sigmail/common';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, RouteComponentProps, Switch } from 'react-router-dom';
import { store } from '../app-state';
import * as RouteId from '../constants/route-identifiers';
import { UITheme } from '../ui-theme';
import { Layout } from './layout/layout.component';
import { LoginForm } from './login-form/login-form.component';
import { PublicHome } from './public-home/public-home.component';
import { ROUTES } from './routes';
import { Route } from './shared/route';

const AsyncInviteInstituteForm = React.lazy(() =>
  import(
    /* webpackChunkName: "invitation" */
    './registration/invite-institution-form/invite-institution-form.component'
  ).then(({ InviteInstitutionForm }) => ({ default: InviteInstitutionForm }))
);

const AsyncForgotPasswordForm = React.lazy(() =>
  import('./account/forgot-password/forgot-password.component').then(({ ForgotPassword }) => ({
    default: ForgotPassword
  }))
);

const AsyncRegistrationRoot = React.lazy(() =>
  import(
    /* webpackChunkName: "registration" */
    './registration/registration-root.component'
  ).then(({ RegistrationRoot }) => ({ default: RegistrationRoot }))
);

const AsyncFaq = React.lazy(() =>
  import(/* webpackChunkName: "faq" */ './faq/faq.component').then(({ FAQ }) => ({ default: FAQ }))
);

const AsyncTermsAndConditions = React.lazy(() =>
  import(
    /* webpackChunkName: "conditions" */ './terms-and-conditions/terms-and-conditions.component'
  ).then(({ TermsAndConditions }) => ({ default: TermsAndConditions }))
);

const AsyncPrivacyPolicy = React.lazy(() =>
  import(/* webpackChunkName: "privacy" */ './privacy-policy/privacy-policy.component').then(({ PrivacyPolicy }) => ({
    default: PrivacyPolicy
  }))
);

const AsyncContactUsForm = React.lazy(() =>
  import(
    /* webpackChunkName: "contact-us" */ './contact-us-form/contact-us-form.component'
  ).then(({ ContactUsForm }) => ({ default: ContactUsForm }))
);

/** TODO document */
const AsyncMessagingRoot = React.lazy(() =>
  import(
    /* webpackChunkName: "messaging" */
    './messaging/messaging-root.component'
  ).then(({ MessagingRoot }) => ({ default: MessagingRoot }))
);

const AsyncContactListRoot = React.lazy(() =>
  import(
    /* webpackChunkName: "contact-list" */
    './contact-list/root.component'
  ).then(({ ContactListRoot }) => ({ default: ContactListRoot }))
);

const AsyncAccountRoot = React.lazy(() =>
  import(
    /* webpackChunkName: "account" */
    './account/account-root.component'
  ).then(({ AccountRoot }) => ({ default: AccountRoot }))
);

const ROUTE_COMPONENT_PROPS: ReadonlyArray<keyof RouteComponentProps> = [
  'history',
  'location',
  'match',
  'staticContext'
];

const withSuspense = (Component: React.ComponentType<any>) => (props: any): React.ReactNode => {
  const passDownProps = Utils.omit(props, ROUTE_COMPONENT_PROPS);

  return (
    <React.Suspense fallback={null}>
      <Component {...passDownProps} />
    </React.Suspense>
  );
};

const ROUTE_ID_TO_RENDER_MAP: Readonly<{ [routeId: string]: (props: any) => React.ReactNode }> = {
  [RouteId.ROUTE_APP_ROOT]: (props) => <PublicHome {...Utils.omit(props, ROUTE_COMPONENT_PROPS)} />,
  [RouteId.ROUTE_SIGN_IN]: (props) => <LoginForm {...Utils.omit(props, ROUTE_COMPONENT_PROPS)} />,
  [RouteId.ROUTE_INVITE_INSTITUTE]: withSuspense(AsyncInviteInstituteForm),
  [RouteId.ROUTE_REGISTER]: withSuspense(AsyncRegistrationRoot),
  [RouteId.ROUTE_FORGOT_PASSWORD]: withSuspense(AsyncForgotPasswordForm),
  [RouteId.ROUTE_FAQ]: withSuspense(AsyncFaq),
  [RouteId.ROUTE_TERMS_AND_CONDITIONS]: withSuspense(AsyncTermsAndConditions),
  [RouteId.ROUTE_PRIVACY_POLICY]: withSuspense(AsyncPrivacyPolicy),
  [RouteId.ROUTE_CONTACT_US]: withSuspense(AsyncContactUsForm),
  [RouteId.ROUTE_MAIL]: withSuspense(AsyncMessagingRoot),
  [RouteId.ROUTE_CONTACT_LIST]: withSuspense(AsyncContactListRoot),
  [RouteId.ROUTE_ACCOUNT]: withSuspense(AsyncAccountRoot)
};

const ROUTE_LIST = ROUTES.map((route) => {
  const { routeId, actionId, childRoutes, ...routeProps } = route;

  if (ROUTE_ID_TO_RENDER_MAP[routeId]) {
    routeProps.render = ROUTE_ID_TO_RENDER_MAP[routeId];
  }

  return <Route key={routeId} routeId={routeId} {...routeProps} />;
});

export interface Props extends RouteComponentProps {}

class AppComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <ThemeProvider theme={UITheme}>
        <Provider store={store}>
          <Router>
            <Layout>
              <Switch>{ROUTE_LIST}</Switch>
            </Layout>
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export const App = AppComponent;
