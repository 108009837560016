import { AlgorithmCode, EncryptWithParametersAlgorithmCode, Utils } from '@sigmail/common';
import {
  Algorithm,
  Constants,
  EncryptWithParametersAlgorithmParams,
  getAlgorithm,
  SigmailCryptoException
} from '@sigmail/crypto';
import { CryptographicKey } from '.';

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER;

export class CryptographicKeyMaster extends CryptographicKey<JsonWebKey> {
  /** @override */
  protected static get DEFAULT_CODE(): EncryptWithParametersAlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS;
  }

  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  public static async create(
    id: number,
    code: AlgorithmCode | undefined,
    version: number,
    value: JsonWebKey,
    encryptedFor: number,
    params: EncryptWithParametersAlgorithmParams,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  ): Promise<CryptographicKeyMaster> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    if (!Algorithm.isValidEncryptWithParametersCode(keyCode)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }

    const algorithm = getAlgorithm(keyCode);
    const encapsulatedKey = await algorithm.generateKey(params);
    const encryptedValue = await algorithm.encrypt(encapsulatedKey, value, version);
    const args = [id, keyCode, version, encryptedValue, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args);
  }

  public async decryptedValue(params: EncryptWithParametersAlgorithmParams): Promise<JsonWebKey> {
    if (!Algorithm.isValidEncryptWithParametersCode(this.code)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }
    const algorithm = getAlgorithm(this.code);
    const encapsulatedKey = await algorithm.generateKey(params);
    return algorithm.decrypt(encapsulatedKey, this.value, this.version);
  }
}
