/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

export interface RouterNavLinkProps extends NavLinkProps {
  image?: React.ImgHTMLAttributes<HTMLImageElement>;
}

export class RouterNavLink extends React.PureComponent<RouterNavLinkProps, any> {
  public render(): React.ReactNode {
    const {
      href,
      to,
      replace,
      innerRef,
      activeClassName,
      activeStyle,
      exact,
      strict,
      isActive,
      location,
      children,
      image: imageProps,
      ...anchorPros
    } = this.props;

    if (typeof href === 'string') {
      return (
        <a href={href} {...anchorPros}>
          {imageProps != null && <img {...imageProps} />}
          {children}
        </a>
      );
    }

    return (
      <NavLink
        to={to}
        replace={replace}
        innerRef={innerRef}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        exact={exact}
        strict={strict}
        isActive={isActive}
        location={location}
        {...anchorPros}
      >
        {imageProps != null && <img {...imageProps} />}
        {children}
      </NavLink>
    );
  }
}
