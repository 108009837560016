import { SEVERITY_ERROR, SEVERITY_SUCCESS } from '../constants/error/severity';

/**
 * @param severity Severity. Must be either 0 (success) or 1 (error).
 * @param facility Source of the error. Must bet between 0 and 2047.
 * @param code Error code. Must be between 0 and 65535.
 */
export function MAKE_ERROR_CODE(severity: number, facility: number, code: number) {
  if (severity !== SEVERITY_SUCCESS && severity !== SEVERITY_ERROR) {
    throw new Error(`Severity must be either ${SEVERITY_SUCCESS} or ${SEVERITY_ERROR}.`);
  }

  const f = facility & 0x000007ff;
  const c = code & 0x0000ffff;
  return (severity << 31) | (f << 16) | c;
}
