import { Utils } from '@sigmail/common';
import { ServerParamsEmailToken, SharedParamsEmailTokenUserRegistration } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import * as EmailTemplateParams from '../../../constants/email-template-params';
import { HealthPlanNumber as HealthPlanNumberMask } from '../../../constants/form-input-mask';
import { generateRandomAccessCode } from '../../../utils/generate-access-code';
import { generateCredentialHash } from '../../../utils/generate-credential-hash';
import { generateEmailToken } from '../../../utils/generate-email-token';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { DEFAULT_ACCESS_CODE_MEMBER_REGISTRATION } from '../constants';
import { sendTemplatedEmailMessageAction } from '../email/send-templated-email-message-action';

export interface SendMemberInvitationActionState extends AuthenticatedActionState {
  dtServer: Date;
  sharedParameters: SharedParamsEmailTokenUserRegistration;
  token: string;
  accessCode: string;
  credentialHash: string;
  serverParameters: ServerParamsEmailToken;
}

export abstract class BaseSendMemberInvitationAction<P, S extends SendMemberInvitationActionState, R = string> extends AuthenticatedAction<
  P,
  S,
  R
> {
  protected generateSharedParameters(roleId: string): void {
    this.logger.info('Generating shared parameters.');

    const isNonGuestRole = Utils.MedicalInstitute.isNonGuestRole(roleId);

    this.state.sharedParameters = {
      response: 'userRegistration',
      salt: Utils.generateSalt('hex'),
      nvac: isNonGuestRole,
      nphi: isNonGuestRole
    };

    this.logger.debug('sharedParameters =', this.state.sharedParameters);
  }

  protected generateEmailToken(): void {
    this.logger.info('Generating email token.');

    this.state.token = generateEmailToken(this.state.sharedParameters);

    this.logger.debug('token =', this.state.token);
  }

  protected generateAccessCode(roleId: string, healthPlanJurisdiction?: string, healthPlanNumber?: string): void {
    this.logger.info('Generating access code.');

    let accessCode = DEFAULT_ACCESS_CODE_MEMBER_REGISTRATION;
    if (Utils.MedicalInstitute.isGuestRole(roleId)) {
      accessCode = generateRandomAccessCode().join('');

      if (Utils.isString(healthPlanJurisdiction) && /^[A-Z][A-Z][A-Z]\$[A-Z]+$/.test(healthPlanJurisdiction)) {
        const [countryCode, stateCode] = healthPlanJurisdiction.split('$', 2);

        if (Utils.isString(healthPlanNumber) && Utils.isArray(HealthPlanNumberMask[countryCode]?.[stateCode])) {
          const planNumberDigits = healthPlanNumber.replaceAll(/[^0-9]/gu, '');
          if (planNumberDigits.length >= 6) {
            accessCode = planNumberDigits.slice(-6);
          }
        }
      }
    }

    this.logger.debug('accessCode =', accessCode);
    this.state.accessCode = accessCode;
  }

  protected async generateServerParameters(): Promise<void> {
    this.logger.info('Generating server parameters.');

    const {
      sharedParameters: { salt: hexSalt },
      accessCode
    } = this.state;

    this.state.credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token: this.state.token });
    this.logger.debug('credentialHash =', this.state.credentialHash);

    const { passwordHash } = await Utils.generatePasswordHash(accessCode, hexSalt);
    this.logger.debug('passwordHash =', passwordHash);

    const verifier = Utils.srpGenerateVerifier(hexSalt, this.state.credentialHash, passwordHash);
    this.state.serverParameters = { verifier };

    this.logger.debug('serverParameters =', this.state.serverParameters);
  }

  protected sendInvitationEmail(
    roleId: string,
    clinicName: string,
    firstName: string,
    lastName: string,
    emailAddress: string
  ): Promise<void> {
    this.logger.info('Sending an account setup invitation email.');

    try {
      const message: Api.TemplatedEmailMessage = {
        template_id: Utils.MedicalInstitute.isGuestRole(roleId)
          ? 'd-cfa7296ed3b24559adbb4aa1eee494ae'
          : 'd-89ad01b26530462eac8bb344179abc47',
        from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
        personalizations: [
          {
            to: [{ name: emailAddress, email: emailAddress }],
            dynamicTemplateData: {
              [EmailTemplateParams.ClinicName]: clinicName,
              [EmailTemplateParams.FirstName]: firstName,
              [EmailTemplateParams.LastName]: lastName,
              [EmailTemplateParams.RegistrationLink]: `${process.env.HOST_URL}?token=${this.state.token}`
            }
          }
        ]
      };

      return this.dispatch(sendTemplatedEmailMessageAction(message));
    } catch (error) {
      this.logger.warn('Error sending an account setup invitation email:', error);
      return Promise.resolve();
    }
  }

  protected get credentialExpiry(): Date {
    const dtExpiry = new Date();
    dtExpiry.setDate(this.state.dtServer.getDate() + 30); // 30 days
    return dtExpiry;
  }
}
