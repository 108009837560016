/** The default facility code. */
export const FACILITY_NULL = 0;

/** The source of error code is the application's HTTP service. */
export const FACILITY_HTTP_SERVICE = 1;

/** The source of error code is the application's API service. */
export const FACILITY_API_SERVICE = 2;

/** The source of error code is the Authentication related modules. */
export const FACILITY_AUTH = 3;

/** The source of error code is the Messaging module. */
export const FACILITY_MESSAGING = 4;

/** The source of error code is the Crypto module. */
export const FACILITY_CRYPTO = 5;
