import { ContactListI18n } from '@sigmail/i18n';
import { gettext } from '.';
import { getRoutePath } from '../app/routes';
import IconClientContactListPrimary from '../assets/images/icon-client-contact-list-primary.min.svg';
import IconClientContactListSecondary from '../assets/images/icon-client-contact-list-secondary.min.svg';
import IconGlobalGroupContactListPrimary from '../assets/images/icon-global-group-contact-list-primary.min.svg';
import IconGlobalGroupContactListSecondary from '../assets/images/icon-global-group-contact-list-secondary.min.svg';
import IconGlobalUserContactListPrimary from '../assets/images/icon-global-user-contact-list-primary.min.svg';
import IconGlobalUserContactListSecondary from '../assets/images/icon-global-user-contact-list-secondary.min.svg';
import IconGuestContactListPrimary from '../assets/images/icon-guest-contact-list-primary.min.svg';
import IconGuestContactListSecondary from '../assets/images/icon-guest-contact-list-secondary.min.svg';
import * as ActionId from '../constants/action-ids';
import { EnglishCanada, FrenchCanada } from '../constants/language-codes';
import * as RouteId from '../constants/route-identifiers';

const i18n: ContactListI18n = {
  navAction: {
    [ActionId.ContactListGlobalUsers]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalUserContactListPrimary,
          [FrenchCanada]: IconGlobalUserContactListPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalUserContactListSecondary,
          [FrenchCanada]: IconGlobalUserContactListSecondary
        }
      },
      label: gettext('contactList', 'contactList', 'actionLabelGlobalUsers'),
      to: getRoutePath(RouteId.ROUTE_CONTACT_LIST_GLOBAL_USERS)
    },
    [ActionId.ContactListGlobalGroups]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalGroupContactListPrimary,
          [FrenchCanada]: IconGlobalGroupContactListPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGlobalGroupContactListSecondary,
          [FrenchCanada]: IconGlobalGroupContactListSecondary
        }
      },
      label: gettext('contactList', 'contactList', 'actionLabelGlobalGroups'),
      to: getRoutePath(RouteId.ROUTE_CONTACT_LIST_GLOBAL_GROUPS)
    },
    [ActionId.ContactListClient]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: IconClientContactListPrimary,
          [FrenchCanada]: IconClientContactListPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: IconClientContactListSecondary,
          [FrenchCanada]: IconClientContactListSecondary
        }
      },
      label: gettext('contactList', 'contactList', 'actionLabelClient'),
      to: getRoutePath(RouteId.ROUTE_CONTACT_LIST_CLIENT)
    },
    [ActionId.ContactListGuest]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGuestContactListPrimary,
          [FrenchCanada]: IconGuestContactListPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: IconGuestContactListSecondary,
          [FrenchCanada]: IconGuestContactListSecondary
        }
      },
      label: gettext('contactList', 'contactList', 'actionLabelGuest'),
      to: getRoutePath(RouteId.ROUTE_CONTACT_LIST_GUEST)
    }
  }
};

export default i18n;
