import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import { URL_HRM_SEND_REPORT } from '../constants';
import { MalformedResponseException } from '../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../service-exception';

const SUCCESS_RESPONSE_PROPS: ReadonlyArray<keyof Api.HrmSendReportResponseData> = [
  'statusCode',
  'resourceType',
  'id',
  'type'
];

const REG_EX_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

function isValidResponseData(responseJson?: any): responseJson is Api.HrmSendReportResponseData {
  return (
    Utils.isNonArrayObjectLike(responseJson) &&
    Utils.every(SUCCESS_RESPONSE_PROPS, Utils.partial(Utils.has, responseJson)) &&
    responseJson.statusCode === 200 &&
    Utils.isString(responseJson.resourceType) &&
    Utils.isString(responseJson.id) &&
    REG_EX_UUID.test(responseJson.id) &&
    Utils.isString(responseJson.type)
  );
}

export async function apiHrmSendReport(this: Api.Service, accessToken: string, query: Api.HrmSendReportRequestData) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiHrmSendReport:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(URL_HRM_SEND_REPORT);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.HrmSendReportResponseData>(requestUrl, JSON.stringify(query), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<Api.HrmSendReportResponseData>(response);
          if (isValidResponseData(responseJson)) return responseJson;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        if (response.status === 400) {
          throw new ApiServiceException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
