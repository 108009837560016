import { AppException, Utils } from '@sigmail/common';
import { E_FAIL } from './constants/error';

export class ServiceException extends AppException {
  public readonly response: Response;

  public constructor(response: Response, errorCode?: number) {
    super(Utils.isFinite(errorCode) ? errorCode : E_FAIL, `HTTP ${response.status} ${response.statusText}`);

    this.name = 'HttpServiceException';
    this.response = response.clone();
  }
}
