// @ts-nocheck
import { ActionPayloadBatchQueryDataSuccess } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  IUserObject,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue,
  UserObjectProfileProtected,
  UserObjectProfileProtectedValue,
  UserObjectProfileProtectedValue_v1
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';

const PROPS_TO_MOVE: ReadonlyArray<keyof UserObjectProfileProtectedValue_v1> = [
  'addressLine1',
  'addressLine2',
  'addressLevel1',
  'addressLevel2',
  'postalCode',
  'officeNumber',
  'officeNumberExt',
  'cellNumber',
  'homeNumber',
  'faxNumber'
];

interface Payload {
  accessToken: string;
  userId: number;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

interface State {
  basicProfileObject: IUserObject<UserObjectProfileBasicValue>;
  basicProfile: UserObjectProfileBasicValue;
  protectedProfileObject: IUserObject<UserObjectProfileProtectedValue>;
  protectedProfile: UserObjectProfileProtectedValue;
  requestBody: BatchUpdateRequestBuilder;
}

class MigrationBumpBasicProfileValueToVersionFour extends BaseAction<Payload, State> {
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchBasicAndProtectedProfile();

        const { $$formatver } = this.state.basicProfile;
        if (!Utils.isNil($$formatver) && $$formatver >= 4) {
          this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
          return;
        }

        await this.generateRequestBody();

        const { accessToken, successPayload } = this.payload;
        const { requestBody, basicProfileObject, protectedProfileObject } = this.state;
        await this.batchUpdateData(accessToken, requestBody.build());

        // manually patch successPayload's data with updated objects
        do {
          const userObjectList = [basicProfileObject, protectedProfileObject];
          for (const obj of userObjectList) {
            const index = this.findUserObjectIndex(successPayload.response.userObjectsByType!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              successPayload.response.userObjectsByType![index] = obj.toApiFormatted();
            }
          }
        } while (false);

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchBasicAndProtectedProfile(): Promise<void> {
    this.logger.info('Fetching latest basic and protected profile objects.');

    const { userId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC },
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED }
      ]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const basicProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId });
    if (Utils.isNil(basicProfileJson)) {
      throw new Api.MalformedResponseException('Basic profile object could not be fetched.');
    } else {
      this.state.basicProfileObject = new UserObjectProfileBasic(basicProfileJson);
      this.state.basicProfile = await this.state.basicProfileObject.decryptedValue();
    }

    const protectedProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED, userId });
    if (Utils.isNil(protectedProfileJson)) {
      throw new Api.MalformedResponseException('Protected profile object could not be fetched.');
    } else {
      this.state.protectedProfileObject = new UserObjectProfileProtected(protectedProfileJson);
      this.state.protectedProfile = await this.state.protectedProfileObject.decryptedValue();
    }
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    await this.addUpdateOperationForBasicProfile();
    await this.addUpdateOperationForProtectedProfile();
  }

  private async addUpdateOperationForBasicProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for basic profile.');

    const { protectedProfile, basicProfile, basicProfileObject, requestBody } = this.state;
    const { officeNumber, ...protectedProfileData } = (protectedProfile as unknown) as UserObjectProfileProtectedValue_v1;

    const updatedValue: UserObjectProfileBasicValue = {
      ...basicProfile,
      ...Utils.pick(protectedProfileData, PROPS_TO_MOVE),
      $$formatver: 4,
      officeNumber: officeNumber.length === 0 ? undefined : officeNumber
    };

    const updatedObject = await basicProfileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.basicProfileObject = updatedObject;
  }

  private async addUpdateOperationForProtectedProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for protected profile.');

    const { protectedProfile, protectedProfileObject, requestBody } = this.state;

    const updatedValue: UserObjectProfileProtectedValue = {
      ...Utils.omit(protectedProfile, PROPS_TO_MOVE),
      $$formatver: 2
    };

    const updatedObject = await protectedProfileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.protectedProfileObject = updatedObject;
  }
}

export const migration_bumpBasicProfileValueToVersionFour = (payload: Payload): AppThunk<Promise<void>> => {
  return async (dispatch, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'bumpBasicProfileValueToVersionFour:');

    const migration = new MigrationBumpBasicProfileValueToVersionFour({ payload, dispatch, apiService, logger: Logger });
    return await migration.execute();
  };
};
