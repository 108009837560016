export const USER_OBJECT_TYPE_CODE_LIST: ReadonlyArray<number> = [
  process.env.USER_OBJECT_TYPE_PROFILE_BASIC,
  process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED,
  process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE,
  process.env.USER_OBJECT_TYPE_FOLDER_LIST,
  process.env.USER_OBJECT_TYPE_CONTACT_INFO,
  process.env.USER_OBJECT_TYPE_CONTACT_LIST,
  process.env.USER_OBJECT_TYPE_GUEST_DATA,
  process.env.USER_OBJECT_TYPE_PREFERENCES,
  process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE,
  process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS,
  process.env.USER_OBJECT_TYPE_SERVER_RIGHTS,
  process.env.USER_OBJECT_TYPE_REGISTRATION_DETAILS
];

export const CLIENT_OBJECT_TYPE_CODE_LIST: ReadonlyArray<number> = [
  process.env.CLIENT_OBJECT_TYPE_PROFILE,
  process.env.CLIENT_OBJECT_TYPE_CONFIGURATION,
  process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST,
  process.env.CLIENT_OBJECT_TYPE_USER_LIST
];

export const GROUP_OBJECT_TYPE_CODE_LIST: ReadonlyArray<number> = [
  process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC,
  process.env.GROUP_OBJECT_TYPE_FOLDER_LIST,
  process.env.GROUP_OBJECT_TYPE_CONTACT_INFO,
  process.env.GROUP_OBJECT_TYPE_PREFERENCES,
  process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS,
  process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS,
  process.env.GROUP_OBJECT_TYPE_GUEST_LIST
];

export const DATA_OBJECT_TYPE_CODE_LIST: ReadonlyArray<number> = [
  process.env.DATA_OBJECT_TYPE_MSG_METADATA,
  process.env.DATA_OBJECT_TYPE_MSG_BODY,
  process.env.DATA_OBJECT_TYPE_MSG_READ_RECEIPT,
  process.env.DATA_OBJECT_TYPE_MSG_FOLDER,
  process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT,
  process.env.DATA_OBJECT_TYPE_DOC_METADATA,
  process.env.DATA_OBJECT_TYPE_DOC_BODY,
  process.env.DATA_OBJECT_TYPE_DOC_FOLDER,
  process.env.DATA_OBJECT_TYPE_HRM_USER_LIST,
  process.env.DATA_OBJECT_TYPE_SIGMAIL_GLOBAL_CONTACT_LIST,
  process.env.DATA_OBJECT_TYPE_CLIENT_GLOBAL_CONTACT_LIST
];

export const USER_CREDENTIALS_TYPE_CODE_LIST: ReadonlyArray<number> = [
  process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN,
  process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN,
  process.env.USER_CREDENTIALS_TYPE_SECURITY_QUESTION,
  process.env.USER_CREDENTIALS_TYPE_PASSWORD_RESET,
  process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN
];

export const NOTIFICATION_OBJECT_TYPE_CODE_LIST: ReadonlyArray<number> = [
  process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE,
  process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE,
  process.env.NOTIFICATION_OBJECT_TYPE_READ_RECEIPT,
  process.env.NOTIFICATION_OBJECT_TYPE_CLIENT_REGISTRATION
];

export const CRYPTOGRAPHIC_KEY_TYPE_CODE_LIST: ReadonlyArray<number> = [
  process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC,
  process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE,
  process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER,
  process.env.CRYPTOGRAPHIC_KEY_TYPE_RECOVERY,
  process.env.CRYPTOGRAPHIC_KEY_TYPE_ENCAPSULATED,
  process.env.CRYPTOGRAPHIC_KEY_TYPE_AUDIT
];
