import { Utils } from '@sigmail/common';
import { UserObjectProfileProtectedValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED;

const OPTIONAL_STRING_PROP_LIST: ReadonlyArray<
  keyof Pick<
    DV,
    'birthDate' | 'groupName' | 'healthCardNumber' | 'healthPlanJurisdiction' | 'licenseNumber' | 'ohipBillingNumber'
  >
> = ['birthDate', 'groupName', 'healthCardNumber', 'healthPlanJurisdiction', 'licenseNumber', 'ohipBillingNumber'];

export class UserObjectProfileProtected extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    if (!OPTIONAL_STRING_PROP_LIST.every((prop) => Utils.isUndefined(value[prop]) || Utils.isString(value[prop]))) {
      return false;
    }

    if (Utils.isUndefined(value.$$formatver) || value.$$formatver === 1) {
      return Utils.isString(value.cellNumber) && Utils.isString(value.officeNumber);
    }

    return value.$$formatver >= 2;
  }

  public updateValue(newValue: DV): Promise<UserObjectProfileProtected> {
    const Class = this.constructor as typeof UserObjectProfileProtected;
    return Class.updateValue(this, newValue);
  }
}
