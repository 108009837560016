export * from './app-exception';
export * from './app-user';
export * from './app-user-group';
export * from './app-user-or-group';
export * from './not-implemented-exception';
import * as CommonConstants from './constants';
import * as ErrorCode from './constants/error';
import * as ErrorFacility from './constants/error/facility';
import * as ErrorSeverity from './constants/error/severity';
import * as Gender from './constants/gender';
import * as MI_LicenseTypeIdentifier from './constants/medical-institute/license-type-identifier';
import * as MI_RoleIdentifier from './constants/medical-institute/role-identifier';
import * as CommonUtil from './utils';
import { bcryptHash } from './utils/bcrypt-hash';
import { createMessageSignature } from './utils/create-message-signature';
import { decodeIdToken } from './utils/decode-id-token';
import { formatBytes } from './utils/format-bytes';
import {
  DATE_FORMAT_FULL,
  DATE_FORMAT_FULL_NO_TIME,
  DATE_FORMAT_SAME_WEEK,
  DATE_FORMAT_SAME_YEAR,
  DATE_FORMAT_SAME_YEAR_NO_TIME,
  DATE_FORMAT_TODAY,
  formatTimestamp
} from './utils/format-timestamp';
import { generatePasswordHash } from './utils/generate-password-hash';
import { generateSalt } from './utils/generate-salt';
import { isValidJwtToken } from './utils/is-valid-jwt-token';
import { joinPersonName } from './utils/join-person-name';
import { joinPostalAddress } from './utils/join-postal-address';
import { jsonStringifyErrorCode } from './utils/json-stringify-error-code';
import { MAKE_ERROR_CODE } from './utils/make-error-code';
import * as MI_Util from './utils/medical-institute';
import { srpDerivePrivateKey } from './utils/srp-derive-private-key';
import { srpDeriveSession } from './utils/srp-derive-session';
import { srpGenerateEphemeral } from './utils/srp-generate-ephemeral';
import { srpGenerateVerifier } from './utils/srp-generate-verifier';
import { srpVerifySession } from './utils/srp-verify-session';
import { stringToHslColor } from './utils/stringToHslColor';
import { tryGetResponseJson } from './utils/try-get-response-json';
import { uint8ArrayFromHex } from './utils/uint8-array-from-hex';
import { uint8ArrayToHex } from './utils/uint8-array-to-hex';

export const Constants = {
  ...CommonConstants,
  Gender,
  Error: {
    ...ErrorFacility,
    ...ErrorSeverity,
    ...ErrorCode
  },
  MedicalInstitute: {
    ...MI_RoleIdentifier,
    ...MI_LicenseTypeIdentifier
  }
};

export const Utils = {
  ...CommonUtil,

  MAKE_ERROR_CODE,
  DATE_FORMAT_TODAY,
  DATE_FORMAT_SAME_WEEK,
  DATE_FORMAT_SAME_YEAR,
  DATE_FORMAT_SAME_YEAR_NO_TIME,
  DATE_FORMAT_FULL,
  DATE_FORMAT_FULL_NO_TIME,

  bcryptHash,
  createMessageSignature,
  decodeIdToken,
  formatBytes,
  formatTimestamp,
  generatePasswordHash,
  generateSalt,
  isValidJwtToken,
  joinPersonName,
  joinPostalAddress,
  jsonStringifyErrorCode,

  // ==
  // will be removed in a future release - use maskHealthPlanNumber instead
  maskHealthCardNumber: MI_Util.maskHealthPlanNumber,
  // ==

  srpDerivePrivateKey,
  srpDeriveSession,
  srpGenerateEphemeral,
  srpGenerateVerifier,
  srpVerifySession,
  stringToHslColor,
  tryGetResponseJson,
  uint8ArrayFromHex,
  uint8ArrayToHex,

  MedicalInstitute: {
    ...MI_Util
  }
};
