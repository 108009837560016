import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ActionPayloadBatchQueryData } from '@sigmail/app-state';
import { AppThunk } from '..';
import { batchQuerySuccessAction } from './batch-query-success-action';

export const batchQueryDataAction = (payload: ActionPayloadBatchQueryData): AppThunk<Promise<Api.BatchQueryResponseData>> => {
  return async (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'batchQueryDataAction:');

    Logger.info('== BEGIN ==');
    let responseJson = {} as Api.BatchQueryResponseData;
    let success = false;
    try {
      const apiAccessToken = Utils.isString(payload.accessToken) ? payload.accessToken : getState().auth.accessToken;
      responseJson = await apiService.batchQueryData(apiAccessToken, payload.query);
      success = true;
    } finally {
      if (success && payload.cache !== null) {
        await dispatch(batchQuerySuccessAction({ request: payload.query, response: responseJson, cache: payload.cache }));
      }
      Logger.info('== END ==');
    }
    return responseJson;
  };
};
