import { Utils } from '@sigmail/common';
import { UserObjectFolderListValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_FOLDER_LIST;

export class UserObjectFolderList extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    if (Utils.isUndefined(value.$$formatver) || value.$$formatver === 1) {
      return Utils.isPlainObject(value.inbox) && Utils.isPlainObject(value.sent) && Utils.isPlainObject(value.drafts);
    }

    return value.$$formatver >= 2 && Utils.isPlainObject(value.msg) && Utils.isPlainObject(value.doc);
  }

  public updateValue(newValue: DV): Promise<UserObjectFolderList> {
    const Class = this.constructor as typeof UserObjectFolderList;
    return Class.updateValue(this, newValue);
  }
}
