import { Utils } from '@sigmail/common';
import { UserObjectRegistrationDetailsValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_REGISTRATION_DETAILS;

export class UserObjectRegistrationDetails extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      Utils.isString(value.name) &&
      Utils.isPlainObject(value.adminContact) &&
      Utils.isArray(value.documentList)
    );
  }

  public updateValue(newValue: DV): Promise<UserObjectRegistrationDetails> {
    const Class = this.constructor as typeof UserObjectRegistrationDetails;
    return Class.updateValue(this, newValue);
  }
}
