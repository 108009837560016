import { DocumentTitleI18n } from '@sigmail/i18n';
import { gettext } from '.';
import * as RouteId from '../constants/route-identifiers';

const i18n: DocumentTitleI18n = {
  // Translators: Text to be prefixed (if any) to the document title
  prefix: gettext('documentTitle', 'documentTitle', 'prefix'),
  // Translators: Text to be suffixed (if any) to the document title
  suffix: gettext('documentTitle', 'documentTitle', 'suffix'),

  // Translators: Browser window title for Sign In page
  [RouteId.ROUTE_SIGN_IN]: gettext('documentTitle', 'documentTitle', 'signInPage'),
  // Translators: Browser window title for Forgot Password page
  [RouteId.ROUTE_FORGOT_PASSWORD]: gettext('documentTitle', 'documentTitle', 'forgotPasswordPage'),
  // Translators: Browser window title for Invite Institute page
  [RouteId.ROUTE_INVITE_INSTITUTE]: gettext('documentTitle', 'documentTitle', 'inviteInstitutePage'),
  // Translators: Browser window title for Register Institute page
  [RouteId.ROUTE_REGISTER_INSTITUTE]: gettext('documentTitle', 'documentTitle', 'registerInstitutePage'),

  // Translators: Browser window title for My Account page
  [RouteId.ROUTE_ACCOUNT_MANAGE_SELF]: gettext('documentTitle', 'documentTitle', 'myAccountPage'),
  // Translators: Browser window title for Manage Members page
  [RouteId.ROUTE_ACCOUNT_MANAGE_MEMBERS]: gettext('documentTitle', 'documentTitle', 'manageMembersPage'),

  // Translators: Browser window title for Credentials Setup page
  [RouteId.ROUTE_ACCOUNT_SETUP_CREDENTIALS]: gettext('documentTitle', 'documentTitle', 'accountSetupCredentialsPage'),
  // Translators: Browser window title for Password Recovery Setup page
  [RouteId.ROUTE_ACCOUNT_SETUP_RECOVERY]: gettext('documentTitle', 'documentTitle', 'accountSetupRecoveryPage'),
  // Translators: Browser window title for Profile Info Setup page
  [RouteId.ROUTE_ACCOUNT_SETUP_PROFILE_INFO]: gettext('documentTitle', 'documentTitle', 'accountSetupProfileInfoPage'),
  // Translators: Browser window title for Account Setup Success page
  [RouteId.ROUTE_ACCOUNT_SETUP_SUCCESS]: gettext('documentTitle', 'documentTitle', 'accountSetupSuccessPage'),
  // Translators: Browser window title for Account Security Setup page
  [RouteId.ROUTE_ACCOUNT_SETUP_SECURITY]: gettext('documentTitle', 'documentTitle', 'accountSetupSecurityPage'),

  // Translators: Browser window title for Mailbox page
  [RouteId.ROUTE_MAIL]: gettext('documentTitle', 'documentTitle', 'mailboxPage'),
  // Translators: Browser window title for Compose HRM Message page
  [RouteId.ROUTE_MAIL_COMPOSE_HRM_MESSAGE]: gettext('documentTitle', 'documentTitle', 'composeHrmMessagePage'),
  // Translators: Browser window title for Compose Message page
  [RouteId.ROUTE_MAIL_COMPOSE_MESSAGE]: gettext('documentTitle', 'documentTitle', 'composeMessagePage'),
  // Translators: Browser window title for Compose Referral page
  [RouteId.ROUTE_MAIL_COMPOSE_REFERRAL]: gettext('documentTitle', 'documentTitle', 'composeReferralPage'),
  // Translators: Browser window title for Received Messages page
  [RouteId.ROUTE_MAIL_INBOX]: gettext('documentTitle', 'documentTitle', 'receivedMessagesPage'),
  // Translators: Browser window title for Shared Received Messages page
  [RouteId.ROUTE_MAIL_INBOX_SHARED]: gettext('documentTitle', 'documentTitle', 'sharedReceivedMessagesPage'),
  // Translators: Browser window title for Reminder Messages page
  [RouteId.ROUTE_MAIL_REMINDER]: gettext('documentTitle', 'documentTitle', 'reminderMessagesPage'),
  // Translators: Browser window title for Important Messages page
  [RouteId.ROUTE_MAIL_IMPORTANT]: gettext('documentTitle', 'documentTitle', 'importantMessagesPage'),
  // Translators: Browser window title for Shared Important Messages page
  [RouteId.ROUTE_MAIL_IMPORTANT_SHARED]: gettext('documentTitle', 'documentTitle', 'sharedImportantMessagesPage'),
  // Translators: Browser window title for Archived Messages page
  [RouteId.ROUTE_MAIL_ARCHIVED]: gettext('documentTitle', 'documentTitle', 'archivedMessagesPage'),
  // Translators: Browser window title for Shared Archived Messages page
  [RouteId.ROUTE_MAIL_ARCHIVED_SHARED]: gettext('documentTitle', 'documentTitle', 'sharedArchivedMessagesPage'),
  // Translators: Browser window title for Deleted Messages page
  [RouteId.ROUTE_MAIL_TRASH]: gettext('documentTitle', 'documentTitle', 'deletedMessagesPage'),
  // Translators: Browser window title for Shared Deleted Messages page
  [RouteId.ROUTE_MAIL_TRASH_SHARED]: gettext('documentTitle', 'documentTitle', 'sharedDeletedMessagesPage'),
  // Translators: Browser window title for Sent Messages page
  [RouteId.ROUTE_MAIL_SENT]: gettext('documentTitle', 'documentTitle', 'sentMessagesPage'),
  // Translators: Browser window title for Draft Messages page
  [RouteId.ROUTE_MAIL_DRAFTS]: gettext('documentTitle', 'documentTitle', 'draftMessagesPage'),

  // Translators: Browser window title for Contact List page
  [RouteId.ROUTE_CONTACT_LIST]: gettext('documentTitle', 'documentTitle', 'contactListPage'),
  // Translators: Browser window title for Global User Contact List page
  [RouteId.ROUTE_CONTACT_LIST_GLOBAL_USERS]: gettext('documentTitle', 'documentTitle', 'globalUserContactListPage'),
  // Translators: Browser window title for Global Group Contact List page
  [RouteId.ROUTE_CONTACT_LIST_GLOBAL_GROUPS]: gettext('documentTitle', 'documentTitle', 'globalGroupContactListPage'),
  // Translators: Browser window title for Client Contact List page
  [RouteId.ROUTE_CONTACT_LIST_CLIENT]: gettext('documentTitle', 'documentTitle', 'clientContactListPage'),
  // Translators: Browser window title for Guest Contact List page
  [RouteId.ROUTE_CONTACT_LIST_GUEST]: gettext('documentTitle', 'documentTitle', 'guestContactListPage'),

  // Translators: Browser window title for Document List page
  [RouteId.ROUTE_DOCUMENT_LIST]: gettext('documentTitle', 'documentTitle', 'documentListPage'),

  // Translators: Browser window title for FAQ page
  [RouteId.ROUTE_FAQ]: gettext('documentTitle', 'documentTitle', 'faqPage'),
  // Translators: Browser window title for Terms And Conditions page
  [RouteId.ROUTE_TERMS_AND_CONDITIONS]: gettext('documentTitle', 'documentTitle', 'termsAndConditionsPage'),
  // Translators: Browser window title for Privacy Policy page
  [RouteId.ROUTE_PRIVACY_POLICY]: gettext('documentTitle', 'documentTitle', 'privacyPolicyPage'),
  // Translators: Browser window title for Contact Us page
  [RouteId.ROUTE_CONTACT_US]: gettext('documentTitle', 'documentTitle', 'contactUsPage')
};

export default i18n;
