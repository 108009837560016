import { ActionPayloadSendMfaCode } from '@sigmail/app-state';
import { AppException, Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '../..';
import { BaseAction } from '../base-action';

const REGEX_PHONE_NUMBER_1 = /^\(\d{3}\) \d{3}-\d{4}$/;
const REGEX_PHONE_NUMBER_2 = /^\d{10}$/;
const REGEX_EMAIL_ADDRESS = /^\s*[\w-][\w.-]*@[a-zA-Z0-9][a-zA-Z0-9-]*(\.[a-zA-Z0-9]+)+\s*$/;

class SendMfaCodeAction extends BaseAction<ActionPayloadSendMfaCode, {}, number> {
  protected async onExecute() {
    if ('id' in this.payload.account) {
      await this.authySendMfaCode(this.payload.account.id, this.payload.mfaMethod);
      return this.payload.account.id;
    }

    const { emailAddress, phoneNumber } = this.payload.account;
    if (!Utils.isString(emailAddress) || !Utils.isString(phoneNumber)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Authy registration data is either missing or invalid.');
    }

    const trimmedEmailAddress = emailAddress.trim();
    if (!REGEX_EMAIL_ADDRESS.test(trimmedEmailAddress)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Email address does not match the expected data format.');
    }

    const trimmedPhoneNumber = phoneNumber.trim();
    if (!REGEX_PHONE_NUMBER_1.test(trimmedPhoneNumber) && !REGEX_PHONE_NUMBER_2.test(trimmedPhoneNumber)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Phone number does not match the expected data format.');
    }

    const accountId = await this.apiService.authyRegisterAccount(trimmedEmailAddress, trimmedPhoneNumber.replace(/[^\d]/g, ''), 1);
    this.logger.info('Authy registration succeeded. Account ID =', accountId);
    await this.apiService.authyUpdateEmail(accountId, trimmedEmailAddress);
    await this.authySendMfaCode(accountId, this.payload.mfaMethod);
    return accountId;
  }

  private async authySendMfaCode(accountId: number, mfaMethod: ActionPayloadSendMfaCode['mfaMethod']): Promise<void> {
    this.logger.info(`Sending an OTP verification code to account ID ${accountId} via ${mfaMethod}.`);

    if (!Utils.isFinite(accountId) || accountId <= 0) {
      this.logger.warn('Authy account ID is invalid.');
      return;
    }

    try {
      await this.apiService.authySendMfaCode(accountId, mfaMethod);
    } catch (error) {
      this.logger.warn('Call to ApiService::authySendOTP failed with an error', error);
      /* ignore */
    }
  }
}

export const sendMfaCodeAction = (payload: ActionPayloadSendMfaCode): AppThunk<Promise<number>> => {
  return (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'sendMfaCodeAction:');

    const action = new SendMfaCodeAction({ payload, apiService, logger: Logger });
    return action.execute();
  };
};
