import { AlgorithmCode, EncapsulatedKey, Utils } from '@sigmail/common';
import {
  Algorithm,
  Constants,
  EncryptWithParametersAlgorithmParams,
  getAlgorithm,
  SigmailCryptoException
} from '@sigmail/crypto';
import { ICryptographicKeyAudit } from '@sigmail/objects';
import { CryptographicKey } from '.';

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_AUDIT;

export class CryptographicKeyAudit extends CryptographicKey<EncapsulatedKey> implements ICryptographicKeyAudit {
  /** @override */
  protected static get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY;
  }

  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  public static async createForCredential(
    credentialId: number,
    params: EncryptWithParametersAlgorithmParams,
    encryptedFor: number,
    createdAtUtc: Date
  ): Promise<CryptographicKeyAudit> {
    const algorithm = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS);
    const key = await algorithm.generateKey(params);
    return this.create(credentialId, undefined, 0, key, encryptedFor, createdAtUtc);
  }

  public static async create(
    id: number,
    code: AlgorithmCode | undefined,
    version: number,
    key: EncapsulatedKey,
    encryptedFor: number,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  ): Promise<CryptographicKeyAudit> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    const value = await this.encryptEncapsulatedKey(key, { encryptedFor, keyCode, keyVersion: version });
    const args = [id, keyCode, version, value, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args);
  }

  public static async encryptFor(key: null, id: number): Promise<null>;
  public static async encryptFor(key: ICryptographicKeyAudit, id: number): Promise<ICryptographicKeyAudit>;
  public static async encryptFor(key: any, id: number): Promise<ICryptographicKeyAudit | null> {
    if (key === null) return null;

    const { id: keyId, createdAtUtc } = key;
    const encapsulatedKey = await key.decryptedValue();
    return this.create(keyId, undefined, 0, encapsulatedKey, id, createdAtUtc);
  }

  public encryptFor(id: number): Promise<ICryptographicKeyAudit> {
    const Class = this.constructor as typeof CryptographicKeyAudit;
    return Class.encryptFor(this, id);
  }

  /** @override */
  public decryptedValue(): Promise<EncapsulatedKey> {
    if (!Algorithm.isValidEncryptEncapsulatedKeyCode(this.code)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }
    const algorithm = getAlgorithm(this.code);
    const Class = this.constructor as typeof CryptographicKeyAudit;
    const cryptoKey = Class.getPrivateKey(this.encryptedForId);
    return algorithm.decrypt({ privateKey: cryptoKey }, this.value, this.version);
  }
}
