import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';
import { DataObjectId, DataObjectVersion, StoreStateDataObjects } from '@sigmail/app-state';
import { signOutAction } from '../actions';
import { setStateReducer } from './set-state-reducer';

export const INITIAL_STATE: StoreStateDataObjects = Immutable.Map<DataObjectId, DataObjectVersion>();

const dataObjectsSlice = createSlice({
  name: 'dataObjects',
  initialState: INITIAL_STATE,
  reducers: {
    setState: setStateReducer
  },
  extraReducers: (builder) => {
    builder.addCase(signOutAction, () => INITIAL_STATE);
  }
});

export const { setState } = dataObjectsSlice.actions;
export const { reducer: dataObjectsReducer } = dataObjectsSlice;
