import { Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_SRP_EXCHANGE_CREDENTIALS } from './constants';
import { MalformedResponseException } from './malformed-response-exception';
import { ServiceException as ApiServiceException } from './service-exception';

const PROPS: ReadonlyArray<keyof Api.SrpExchangeCredentialResponseData> = ['shared', 'counterpartB', 'nonce'];

function isValidApiResponse(json: any): json is Api.SrpExchangeCredentialResponseData {
  // make sure response JSON is an object and has at least all of the expected properties
  if (Utils.isNonArrayObjectLike(json) && Utils.every(PROPS, Utils.partial(Utils.has, json))) {
    // make sure all of the properties are of type string
    return PROPS.every((p) => Utils.isString(json[p]));
  }
  return false;
}

export async function apiSrpExchangeCredential(this: Api.Service, credentialHash: string, ephemeralPublic: string) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiSrpExchangeCredential:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(URL_SRP_EXCHANGE_CREDENTIALS);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }
    const requestBody: Api.SrpExchangeCredentialRequestData = {
      userHash: credentialHash,
      counterpartA: ephemeralPublic
    };

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.SrpExchangeCredentialResponseData>(requestUrl, JSON.stringify(requestBody), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<Api.SrpExchangeCredentialResponseData>(response);
          if (isValidApiResponse(responseJson)) return responseJson;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status === 400) {
          throw new ApiServiceException(response, Constants.Error.E_AUTH_FAIL);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
