import { ActionPayloadBatchQueryDataSuccess, MedicalInstitute, Messaging } from '@sigmail/app-state';
import {
  AppException,
  AppUser,
  AppUserGroup,
  Constants,
  GenderIdentity,
  IAppUser,
  IAppUserGroup,
  MessageContent,
  Utils
} from '@sigmail/common';
import { EncryptWithParametersAlgorithmParams, getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ClientObjectContactList,
  ClientObjectContactListValue,
  ClientObjectUserList,
  ClientObjectUserListValue,
  ContactListItem,
  CryptographicKey,
  CryptographicKeyAudit,
  CryptographicKeyMaster,
  CryptographicKeyPrivate,
  GroupObjectGuestList,
  GroupObjectGuestListValue,
  GroupObjectProfileBasic,
  GroupObjectProfileBasicValue,
  IUserObject,
  UserContactListItem,
  UserCredentialsEmailToken,
  UserObjectAccessRights,
  UserObjectAccessRightsValue,
  UserObjectCircleOfCare,
  UserObjectCircleOfCareValue,
  UserObjectContactInfo,
  UserObjectContactInfoValue,
  UserObjectContactList,
  UserObjectContactListValue,
  UserObjectPreferences,
  UserObjectPreferencesValue,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue,
  UserObjectProfilePrivate,
  UserObjectProfilePrivateValue,
  UserObjectProfileProtected,
  UserObjectProfileProtectedValue,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { DEFAULT_CLIENT_RIGHTS_BY_ROLE } from '../../../constants/medical-institute-default-client-rights';
import { CIRCLE_OF_CARE } from '../../../constants/medical-institute-user-group-type-identifier';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import * as AuthSelectors from '../../selectors/auth';
import * as ClientObjectSelectors from '../../selectors/client-object';
import * as GroupObjectSelectors from '../../selectors/group-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { UserObjectCache } from '../../user-objects-slice/cache';
import { FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_CREATE_AS_ROLE } from '../constants/auth-state-identifier';
import { sendMessageAction } from '../messaging/send-message-action';
import { BaseSendMemberInvitationAction, SendMemberInvitationActionState } from './base-send-member-invitation-action';
import { createCircleOfCareGroupAction } from './create-circle-of-care-group';

interface Payload extends MedicalInstitute.ActionPayloadSendMemberInvitation {}

const NAME_EMAIL_SPECIALTY_ROLE: ReadonlyArray<keyof Pick<Payload, 'firstName' | 'lastName' | 'emailAddress' | 'specialty' | 'role'>> = [
  'firstName',
  'lastName',
  'emailAddress',
  'specialty',
  'role'
];

const PHONE_OFFICE_CELL_AND_HOME: ReadonlyArray<keyof Pick<Payload, 'officeNumber' | 'officeNumberExt' | 'cellNumber' | 'homeNumber'>> = [
  'officeNumber',
  'officeNumberExt',
  'cellNumber',
  'homeNumber'
];

const GROUP_GUEST_LIST_ITEM_USER_DATA: ReadonlyArray<
  keyof Pick<
    UserContactListItem['userData'],
    'firstName' | 'lastName' | 'birthDate' | 'cellNumber' | 'homeNumber' | 'emailAddress' | 'role'
  >
> = ['firstName', 'lastName', 'birthDate', 'cellNumber', 'homeNumber', 'emailAddress', 'role'];

const CONTACT_LIST_ITEM_USER_DATA: ReadonlyArray<
  keyof Pick<
    UserContactListItem['userData'],
    'firstName' | 'lastName' | 'emailAddress' | 'officeNumber' | 'officeNumberExt' | 'cellNumber' | 'role' | 'specialty'
  >
> = ['firstName', 'lastName', 'emailAddress', 'officeNumber', 'officeNumberExt', 'cellNumber', 'role', 'specialty'];

const CONTACT_INFO_DATA_BASIC: {
  [key: string]: ReadonlyArray<
    keyof Pick<
      UserObjectContactInfoValue,
      | 'prefix'
      | 'firstName'
      | 'middleName'
      | 'lastName'
      | 'suffix'
      | 'emailAddress'
      | 'specialty'
      | 'role'
      | 'officeNumber'
      | 'officeNumberExt'
      | 'cellNumber'
    >
  >;
} = {
  all: ['prefix', 'firstName', 'middleName', 'lastName', 'suffix', 'emailAddress', 'role', 'cellNumber'],
  nonGuest: ['specialty', 'officeNumber', 'officeNumberExt']
};

const CONTACT_INFO_DATA_PROTECTED: {
  [key: string]: ReadonlyArray<keyof Pick<UserObjectContactInfoValue, 'gender' | 'healthPlanJurisdiction' | 'groupName'>>;
} = {
  all: [],
  guest: ['gender', 'healthPlanJurisdiction'],
  nonGuest: ['groupName']
};

type UserObjectDataUpdater = Api.DataUpdater<IUserObject<any>>;

interface State extends SendMemberInvitationActionState {
  adminUser: IAppUser;
  privateProfileAdmin: UserObjectProfilePrivateValue;
  circleOfCareGroupName: string;
  circleOfCareGroupExists: boolean;
  circleOfCareGroup: IAppUserGroup;
  circleOfCareGroupClaim: string;
  idRecord: Api.GetIdsResponseData['ids'];
  batchUpdateAuthState: string;
  userClaim: string;
  idsClaim: string;
  masterKeyId: number;
  credentialId: number;
  inviteeId: number;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ActionPayloadBatchQueryDataSuccess;
  basicProfileInvitee: UserObjectProfileBasicValue;
  protectedProfileInvitee: UserObjectProfileProtectedValue;
}

const assertGender = (value?: any): value is Exclude<GenderIdentity, typeof Constants.Gender.Unknown> =>
  [Constants.Gender.Female, Constants.Gender.Male, Constants.Gender.Other].indexOf(value) !== -1;

class SendMemberInvitationAction extends BaseSendMemberInvitationAction<Payload, State, string> {
  private readonly asymmetricKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);

  protected async onExecute() {
    this.state.adminUser = this.state.currentUser;
    await this.fetchClientAdminData();

    const { role: inviteeRoleId, groupName, healthPlanJurisdiction, healthCardNumber } = this.payload;
    const { roleAuthClaim, currentUser } = this.state;

    if (Utils.MedicalInstitute.isGuestRole(inviteeRoleId)) {
      const circleOfCareGroup = UserObjectSelectors.circleOfCareGroupSelector(this.getRootState());
      if (Utils.isNotNil(circleOfCareGroup)) {
        const [, groupProfile] = await this.extractGroupProfileBasic(circleOfCareGroup.id);
        this.state.circleOfCareGroupName = groupProfile.name;
      }
    } else if (Utils.MedicalInstitute.isNonGuestRole(inviteeRoleId)) {
      this.state.circleOfCareGroupName = groupName.trim();
    } else {
      throw new AppException(Constants.Error.S_ERROR, 'Invalid/unknown role ID.');
    }

    await this.findCircleOfCareGroup();

    if (!this.state.circleOfCareGroupExists && Utils.MedicalInstitute.isNonGuestRole(inviteeRoleId)) {
      const apiAccessToken = AuthSelectors.accessTokenSelector(this.getRootState());

      const groupClaim = await this.dispatch(
        createCircleOfCareGroupAction({
          roleAuthClaim,
          apiAccessToken,
          currentUserId: currentUser.id,
          groupName
        })
      );

      const { groupId } = Utils.decodeIdToken(groupClaim);
      if (!AppUserGroup.isValidId(groupId)) {
        throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Group ID could not be extracted from group claim.');
      }

      this.state.circleOfCareGroupExists = true;
      this.state.circleOfCareGroup = new AppUserGroup(groupId, CIRCLE_OF_CARE);
      this.state.circleOfCareGroupClaim = groupClaim;
    }

    this.generateSharedParameters(inviteeRoleId);
    this.generateEmailToken();
    this.generateAccessCode(inviteeRoleId, healthPlanJurisdiction, healthCardNumber);
    await this.generateServerParameters();

    await this.generateIdSequence();
    await this.generateRequestBody();

    const { batchUpdateAuthState: authState, idsClaim, requestBody, successPayload } = this.state;
    await this.dispatchBatchUpdateData({ authState, claims: [idsClaim], ...requestBody.build() });

    try {
      await this.dispatchBatchQueryDataSuccess(successPayload);
    } catch (error) {
      this.logger.warn('Error manually updating app state:', error);
      /* ignore */
    }

    await this.dispatch(sendMessageAction({ messageContent: this.generateWelcomeMessage() }));

    await this.sendInvitationEmail();

    CryptographicKey.clearPrivateKey(this.state.masterKeyId);
    CryptographicKey.clearPublicKey(this.state.masterKeyId);

    return this.state.accessCode;
  }

  private async fetchClientAdminData(): Promise<void> {
    this.logger.info('Fetching latest client admin data.');

    const { roleAuthClaim: authState, adminUser } = this.state;

    const { serverDateTime } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [
        { userId: adminUser.id, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC },
        { userId: adminUser.id, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE }
      ]
    });

    const privateProfileObject = UserObjectSelectors.privateProfileObjectSelector(this.getRootState())();
    const privateProfile = UserObjectCache.getValue(privateProfileObject);
    if (Utils.isNil(privateProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Private profile data is either missing or invalid.');
    }

    const { clientId } = privateProfile;
    await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_USER_LIST },
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST },
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONFIGURATION },
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_PROFILE }
      ]
    });

    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.privateProfileAdmin = privateProfile;
  }

  private async extractGroupProfileBasic(
    groupId: number
  ): Promise<[IUserObject<GroupObjectProfileBasicValue>, GroupObjectProfileBasicValue]> {
    this.logger.info('Extracting circle of care group profile data.');

    const { roleAuthClaim: authState, circleOfCareGroupClaim } = this.state;

    await this.dispatchFetchObjects({
      authState,
      claims: [circleOfCareGroupClaim].filter(Utils.isString),
      userObjectsByType: [{ userId: groupId, type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC }],
      expectedCount: { userObjectsByType: null }
    });

    const groupProfileObject = GroupObjectSelectors.basicProfileObjectSelector(this.getRootState())(groupId);
    const groupProfile = UserObjectCache.getValue(groupProfileObject);
    if (Utils.isNil(groupProfileObject) || Utils.isNil(groupProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Group profile data is either missing or invalid.');
    }

    return [groupProfileObject, groupProfile];
  }

  private async findCircleOfCareGroup(): Promise<void> {
    const { circleOfCareGroupName: groupName, roleAuthClaim: authState } = this.state;

    if (!Utils.isString(groupName) || groupName.length === 0) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Circle of care group name is either missing or invalid.');
    }

    const contactListObject = ClientObjectSelectors.contactListObjectSelector(this.getRootState())();
    const { list } = UserObjectCache.getValue(contactListObject, {} as ClientObjectContactListValue);
    if (!Utils.isArray(list)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Client contact list is either missing or invalid.');
    }

    this.logger.info(`Looking for a circle of care group named <${groupName}> in client contacts.`);
    const group = list.find((item) => item.type === 'group' && item.groupType === CIRCLE_OF_CARE && item.groupData.groupName === groupName);
    if (Utils.isNotNil(group)) {
      this.state.circleOfCareGroupExists = true;
      this.state.circleOfCareGroup = new AppUserGroup(group.id, CIRCLE_OF_CARE);
      this.logger.info('Found.', 'ID =', group.id, 'Name =', groupName);

      await this.dispatchFetchObjects({
        authState,
        userObjectsByType: [{ userId: group.id, type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS }]
      });

      const serverRightsObject = GroupObjectSelectors.serverRightsObjectSelector(this.getRootState())(group.id);
      const serverRights = UserObjectCache.getValue(serverRightsObject);
      if (Utils.isNil(serverRights)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Group server rights data is either missing or invalid.');
      }

      this.state.circleOfCareGroupClaim = serverRights.groupClaim;
    } else {
      this.state.circleOfCareGroupExists = false;
      this.logger.info('Not found.', 'Name =', groupName);
    }
  }

  private async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const { role: inviteeRoleId } = this.payload;
    const { roleAuthClaim: authState, circleOfCareGroupClaim, circleOfCareGroup: group } = this.state;

    const state = AUTH_STATE_CREATE_AS_ROLE[inviteeRoleId];
    if (!Utils.isString(state)) {
      throw new AppException(Constants.Error.S_ERROR, '<createAs> state could not be determined from role.');
    }

    const roleBasedIdList: Array<Required<Api.GetIdsRequestData['ids']>['ids'][0]> = [];
    const { queryIds, updateIds, expireIds } = Utils.decodeIdToken(authState) as { [prop: string]: number[] };
    if (!Utils.isArray(queryIds) || !Utils.isArray(updateIds) || !Utils.isArray(expireIds)) {
      throw new AppException(Constants.Error.S_ERROR);
    }

    if (Utils.MedicalInstitute.isGuestRole(inviteeRoleId)) {
      queryIds.length = updateIds.length = expireIds.length = 0;

      roleBasedIdList.push({ type: process.env.USER_OBJECT_TYPE_CONTACT_LIST });
      roleBasedIdList.push({ type: process.env.USER_OBJECT_TYPE_GUEST_DATA });
      roleBasedIdList.push({ type: process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE });
    }

    const query: Api.GetIdsRequestData = {
      authState,
      claims: [circleOfCareGroupClaim],
      state,
      ids: {
        usages: [{ usage: 'userId' }],
        ids: [
          { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER },

          { type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN },

          { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC },
          { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE },
          { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED },
          { type: process.env.USER_OBJECT_TYPE_CONTACT_INFO },
          { type: process.env.USER_OBJECT_TYPE_PREFERENCES },
          { type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS },
          { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS },

          ...roleBasedIdList
        ]
      },
      groupIdList: [group.id],
      queryIds,
      updateIds,
      expireIds
    };

    const responseJson = await this.dispatchFetchIdsByUsage(query);

    let userClaim: string | undefined = undefined;
    for (const claim of responseJson.claims) {
      const { name } = Utils.decodeIdToken(claim);
      if (name === 'user') {
        userClaim = claim;
        break;
      }
    }

    if (!Utils.isValidJwtToken(userClaim, 'id')) {
      throw new Api.MalformedResponseException('User claim is either missing or invalid.');
    }

    const idRecord = (this.state.idRecord = responseJson.ids);
    this.state.batchUpdateAuthState = responseJson.authState;
    this.state.userClaim = userClaim;
    this.state.idsClaim = responseJson.idsClaim;

    [this.state.masterKeyId, this.state.credentialId, this.state.inviteeId] = [
      idRecord[process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER][0],
      idRecord[process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN][0],
      idRecord['userId'][0]
    ];

    this.logger.debug({
      masterKeyId: this.state.masterKeyId,
      credentialId: this.state.credentialId,
      inviteeId: this.state.inviteeId
    });
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    await this.addInsertOperationForTemporaryUserKey(); // 112
    await this.addInsertOperationForClientPrivateKey(); // 111 (Client)

    await this.addInsertOperationForUserProfileBasic(); // 401
    await this.addInsertOperationForUserProfileProtected(); // 402
    await this.addInsertOperationForUserProfilePrivate(); // 403
    await this.addInsertOperationForUserContactInfo(); // 405
    await this.addInsertOperationForUserPreferences(); // 408
    await this.addInsertOperationForUserAccessRights(); // 410
    await this.addInsertOperationForUserServerRights(); // 411

    if (Utils.MedicalInstitute.isGuestRole(this.payload.role)) {
      await this.addInsertOperationForUserContactList(); // 406
      await this.addInsertOperationForCircleOfCare(); // 420
      await this.addUpdateOperationForGroupGuestList(); // 468
    } else {
      await this.addUpdateOperationForGroupProfileBasic(); // 431
      await this.addUpdateOperationForClientContactList(); // 466
    }

    await this.addUpdateOperationForClientUserList(); // 467

    await this.addInsertOperationForEmailTokenAndAuditKey(); // 871
  }

  private async addInsertOperationForTemporaryUserKey(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for a temporary user key.');

    const { token, accessCode, sharedParameters, masterKeyId: keyId, credentialId, dtServer, requestBody } = this.state;

    const { privateKey, exportedPrivateKey, publicKey, exportedPublicKey } = await this.asymmetricKeyAlgo.generateKey();
    this.logger.debug('User private JWK', JSON.stringify(exportedPrivateKey));
    this.logger.debug('User public JWK', JSON.stringify(exportedPublicKey));
    CryptographicKey.setPrivateKey(keyId, privateKey!);
    CryptographicKey.setPublicKey(keyId, publicKey!);

    const params: EncryptWithParametersAlgorithmParams = {
      type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
      parameter1: token,
      parameter2: accessCode,
      hexSalt: sharedParameters.salt
    };

    const masterKey = await CryptographicKeyMaster.create(keyId, undefined, 0, exportedPrivateKey!, credentialId, params, dtServer);
    requestBody.insert(masterKey);
  }

  private async addInsertOperationForClientPrivateKey(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for client private key.');

    const { roleAuthClaim: authState, masterKeyId, requestBody } = this.state;
    const { clientId } = this.state.privateProfileAdmin;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [{ id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE }]
    };

    const { keyList } = await this.dispatchFetchObjects(query);

    const clientPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: clientId });
    if (Utils.isNil(clientPrivateKeyJson)) {
      throw new Api.MalformedResponseException('Client private key is either missing or invalid.');
    } else {
      let clientPrivateKey = new CryptographicKeyPrivate(clientPrivateKeyJson);
      clientPrivateKey = await CryptographicKeyPrivate.encryptFor(clientPrivateKey, masterKeyId);
      requestBody.insert(clientPrivateKey);
    }
  }

  private async addInsertOperationForUserProfileBasic(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for basic profile.');

    const { idRecord, circleOfCareGroup, inviteeId, masterKeyId, dtServer, requestBody } = this.state;
    const { clientId, auditId } = this.state.privateProfileAdmin;

    const isNonGuestRole = Utils.MedicalInstitute.isNonGuestRole(this.payload.role);
    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PROFILE_BASIC];
    const value: UserObjectProfileBasicValue = {
      $$formatver: 4,
      ...Utils.mapValues(Utils.pick(this.payload, NAME_EMAIL_SPECIALTY_ROLE), (value) => value.trim()),
      ...Utils.mapValues(Utils.pick(this.payload, PHONE_OFFICE_CELL_AND_HOME), (value) => value.trim() || undefined),
      memberOf: isNonGuestRole ? [circleOfCareGroup] : undefined,
      specialty: isNonGuestRole ? this.payload.specialty.trim() : undefined
    };
    this.logger.debug({ id, ...value });

    const basicProfileObject = await UserObjectProfileBasic.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await basicProfileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(basicProfileObject[Constants.$$CryptographicKey]);
    requestBody.insert(basicProfileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.basicProfileInvitee = value;
  }

  private async addInsertOperationForUserProfileProtected(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for protected profile.');

    const { birthDate, gender, healthPlanJurisdiction, healthCardNumber, groupName } = this.payload;
    const { idRecord, inviteeId, masterKeyId, dtServer, requestBody } = this.state;

    const { clientId, auditId } = this.state.privateProfileAdmin;
    const isNonGuestRole = Utils.MedicalInstitute.isNonGuestRole(this.payload.role);

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED];
    const value: UserObjectProfileProtectedValue = {
      $$formatver: 2,
      birthDate: isNonGuestRole ? undefined : birthDate.trim(),
      gender: isNonGuestRole ? undefined : assertGender(gender) ? gender : undefined,
      healthPlanJurisdiction: isNonGuestRole ? undefined : healthPlanJurisdiction,
      healthCardNumber: isNonGuestRole ? undefined : healthCardNumber.trim(),
      groupName: isNonGuestRole ? groupName.trim() : undefined
    };
    this.logger.debug({ id, ...value });

    const protectedProfileObject = await UserObjectProfileProtected.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await protectedProfileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(protectedProfileObject[Constants.$$CryptographicKey]);
    requestBody.insert(protectedProfileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.protectedProfileInvitee = value;
  }

  private async addInsertOperationForUserProfilePrivate(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for private profile.');

    const { idRecord, adminUser, credentialId, accessCode, inviteeId, masterKeyId, dtServer, requestBody } = this.state;
    const { clientId, auditId, ownerId, globalContactListId } = this.state.privateProfileAdmin;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE];
    const value: UserObjectProfilePrivateValue = {
      $$formatver: 1,
      adminId: adminUser.id,
      clientId,
      auditId,
      ownerId,
      globalContactListId,
      credentialId,
      accessCode
    };
    this.logger.debug({ id, ...value });

    const privateProfileObject = await UserObjectProfilePrivate.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await privateProfileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(privateProfileObject[Constants.$$CryptographicKey]);
    requestBody.insert(privateProfileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForUserContactInfo(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for contact info.');

    const { idRecord, basicProfileInvitee, protectedProfileInvitee, inviteeId, masterKeyId, dtServer, requestBody } = this.state;
    const { clientId, auditId } = this.state.privateProfileAdmin;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_CONTACT_INFO];
    let value: UserObjectContactInfoValue = {
      $$formatver: 2,
      ...Utils.pick(basicProfileInvitee, CONTACT_INFO_DATA_BASIC.all),
      ...Utils.pick(protectedProfileInvitee, CONTACT_INFO_DATA_PROTECTED.all)
    };

    if (Utils.MedicalInstitute.isNonGuestRole(basicProfileInvitee.role)) {
      value = {
        ...value,
        ...Utils.pick(basicProfileInvitee, CONTACT_INFO_DATA_BASIC.nonGuest),
        ...Utils.pick(protectedProfileInvitee, CONTACT_INFO_DATA_PROTECTED.nonGuest)
      };
    } else {
      value = {
        ...value,
        ...Utils.pick(protectedProfileInvitee, CONTACT_INFO_DATA_PROTECTED.guest),
        birthDate: this.payload.maskedBirthDate,
        healthCardNumber: Utils.MedicalInstitute.maskHealthPlanNumber(protectedProfileInvitee.healthCardNumber!)
      };
    }

    this.logger.debug({ id, ...value });

    const contactInfoObject = await UserObjectContactInfo.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await contactInfoObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(contactInfoObject[Constants.$$CryptographicKey]);
    requestBody.insert(contactInfoObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForUserPreferences(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user preferences.');

    const { idRecord, inviteeId, masterKeyId, dtServer, requestBody } = this.state;
    const { clientId, auditId } = this.state.privateProfileAdmin;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_PREFERENCES];
    const value: UserObjectPreferencesValue = { $$formatver: 1 };
    this.logger.debug({ id, ...value });

    const preferencesObject = await UserObjectPreferences.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await preferencesObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(preferencesObject[Constants.$$CryptographicKey]);
    requestBody.insert(preferencesObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForUserAccessRights(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user access rights.');

    const { idRecord, inviteeId, masterKeyId, dtServer, requestBody } = this.state;
    const { clientId, auditId } = this.state.privateProfileAdmin;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS];
    const value: UserObjectAccessRightsValue = DEFAULT_CLIENT_RIGHTS_BY_ROLE[this.payload.role];
    if (!Utils.isPlainObject(value)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Unable to determine initial access rights to assign.');
    }
    this.logger.debug({ id, ...value });

    const accessRightsObject = await UserObjectAccessRights.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await accessRightsObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(accessRightsObject[Constants.$$CryptographicKey]);
    requestBody.insert(accessRightsObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForUserServerRights(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for user server rights.');

    const { idRecord, userClaim, inviteeId, masterKeyId, dtServer, requestBody } = this.state;
    const { clientId, auditId } = this.state.privateProfileAdmin;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_SERVER_RIGHTS];
    const value: UserObjectServerRightsValue = { $$formatver: 1, userClaim };
    this.logger.debug({ id, ...value });

    const serverRightsObject = await UserObjectServerRights.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await serverRightsObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(serverRightsObject[Constants.$$CryptographicKey]);
    requestBody.insert(serverRightsObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForUserContactList(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for contact list.');

    const { idRecord, circleOfCareGroup, circleOfCareGroupName: groupName, inviteeId, masterKeyId, dtServer, requestBody } = this.state;
    const { clientId, auditId } = this.state.privateProfileAdmin;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_CONTACT_LIST];
    const circleOfCare: ContactListItem = { ...circleOfCareGroup, groupData: { groupName } };
    const value: UserObjectContactListValue = { $$formatver: 1, recent: [circleOfCare], contacts: [circleOfCare] };
    this.logger.debug({ id, ...value });

    const contactListObject = await UserObjectContactList.create(id, undefined, 1, value, inviteeId, masterKeyId, dtServer);
    const keyList = await contactListObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(contactListObject[Constants.$$CryptographicKey]);
    requestBody.insert(contactListObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addInsertOperationForCircleOfCare(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for circle of care.');

    const { idRecord, circleOfCareGroup: group, circleOfCareGroupName: groupName, inviteeId, dtServer, requestBody } = this.state;
    const { auditId } = this.state.privateProfileAdmin;

    const [id] = idRecord[process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE];
    const value: UserObjectCircleOfCareValue = {
      $$formatver: 1,
      list: [{ ...group, groupData: { groupName }, sendAllowed: true, replyAllowed: true }]
    };
    this.logger.debug({ id, ...value });

    const circleOfCareObject = await UserObjectCircleOfCare.create(id, undefined, 1, value, inviteeId, group.id, dtServer);
    const keyList = await circleOfCareObject.generateKeysEncryptedFor(this.state.masterKeyId, auditId);
    keyList.push(circleOfCareObject[Constants.$$CryptographicKey]);
    requestBody.insert(circleOfCareObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));
  }

  private async addUpdateOperationForGroupGuestList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group guest list.');

    const { circleOfCareGroup: group, roleAuthClaim: authState, inviteeId, requestBody, successPayload } = this.state;

    let guestListObject = GroupObjectSelectors.guestListObjectSelector(this.getRootState())(group.id);
    if (Utils.isNil(guestListObject)) {
      await this.dispatchFetchObjects({
        authState,
        userObjectsByType: [{ userId: group.id, type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST }],
        expectedCount: { userObjectsByType: null }
      });

      guestListObject = GroupObjectSelectors.guestListObjectSelector(this.getRootState())(group.id);
      if (Utils.isNil(guestListObject)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Group guest list is either missing or invalid.');
      }
    }

    const applyUpdate = async (guestListObject: IUserObject<GroupObjectGuestListValue>) => {
      const guestList = await guestListObject.decryptedValue();

      const updatedValue: GroupObjectGuestListValue = {
        ...guestList,
        list: guestList.list.concat({
          ...new AppUser(inviteeId),
          userData: {
            ...Utils.pick(this.payload, GROUP_GUEST_LIST_ITEM_USER_DATA),
            gender: assertGender(this.payload.gender) ? this.payload.gender : undefined,
            healthCardNumber: Utils.MedicalInstitute.maskHealthPlanNumber(this.payload.healthCardNumber)
          }
        })
      };

      return guestListObject.updateValue(updatedValue);
    };

    const guestListObjectKey = guestListObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (guestListJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === guestListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; group guest list could not be found in request body.');
      }

      const key = guestListObjectKey === null ? null : guestListObjectKey.toApiFormatted();
      const guestListObject = new GroupObjectGuestList({ ...guestListJson, key });
      const updatedObject = await applyUpdate(guestListObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === guestListJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(guestListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(guestListObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForGroupProfileBasic(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group profile.');

    const { circleOfCareGroup: group, inviteeId, requestBody, successPayload } = this.state;
    const [profileObject] = await this.extractGroupProfileBasic(group.id);

    const applyUpdate = async (profileObject: IUserObject<GroupObjectProfileBasicValue>) => {
      const profile = await profileObject.decryptedValue();

      const updatedValue: GroupObjectProfileBasicValue = {
        ...profile,
        memberList: profile.memberList.concat(new AppUser(inviteeId))
      };

      return profileObject.updateValue(updatedValue);
    };

    const profileObjectKey = profileObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (profileJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === profileJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; group profile could not be found in request body.');
      }

      const key = profileObjectKey === null ? null : profileObjectKey.toApiFormatted();
      const profileObject = new GroupObjectProfileBasic({ ...profileJson, key });
      const updatedObject = await applyUpdate(profileObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === profileJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(profileObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(profileObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForClientContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for client contact list.');

    const { inviteeId, requestBody, successPayload } = this.state;

    const contactListObject = ClientObjectSelectors.contactListObjectSelector(this.getRootState())();
    if (Utils.isNil(contactListObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Client contact list is either missing or invalid.');
    }

    const applyUpdate = async (contactListObject: IUserObject<ClientObjectContactListValue>) => {
      const contactList = await contactListObject.decryptedValue();

      const updatedValue: ClientObjectContactListValue = {
        ...contactList,
        list: contactList.list.concat({
          ...new AppUser(inviteeId),
          userData: Utils.pick(this.payload, CONTACT_LIST_ITEM_USER_DATA)
        })
      };

      return contactListObject.updateValue(updatedValue);
    };

    const contactListObjectKey = contactListObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (contactListJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === contactListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; client contact list could not be found in request body.');
      }

      const key = contactListObjectKey === null ? null : contactListObjectKey.toApiFormatted();
      const contactListObject = new ClientObjectContactList({ ...contactListJson, key });
      const updatedObject = await applyUpdate(contactListObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === contactListJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(contactListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(contactListObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForClientUserList(): Promise<void> {
    const { role: inviteeRoleId } = this.payload;
    const { inviteeId, accessCode, dtServer, adminUser, credentialId, requestBody, successPayload } = this.state;

    const userListObject = ClientObjectSelectors.userListObjectSelector(this.getRootState())();
    if (Utils.isNil(userListObject)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Client user list is either missing or invalid.');
    }

    const applyUpdate = async (userListObject: IUserObject<ClientObjectUserListValue>) => {
      const userList = await userListObject.decryptedValue();

      const updatedValue: ClientObjectUserListValue = {
        ...userList,
        pending: userList.pending.concat({
          id: inviteeId,
          role: inviteeRoleId,
          accessCode,
          timestamp: { start: dtServer.getTime(), end: null },

          invitedBy: adminUser.id,
          credentialId,
          credentialExpiry: this.credentialExpiry.getTime()
        })
      };

      return userListObject.updateValue(updatedValue);
    };

    const userListObjectKey = userListObject[Constants.$$CryptographicKey];
    const dataUpdater: UserObjectDataUpdater = async (userListJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === userListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; client user list could not be found in request body.');
      }

      const key = userListObjectKey === null ? null : userListObjectKey.toApiFormatted();
      const userListObject = new ClientObjectUserList({ ...userListJson, key });
      const updatedObject = await applyUpdate(userListObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === userListJson.id);
      if (index !== -1) {
        successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
      }
    };

    const updatedObject = await applyUpdate(userListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(userListObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addInsertOperationForEmailTokenAndAuditKey(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for email token credentials and its audit key.');

    const {
      credentialId,
      inviteeId,
      masterKeyId,
      credentialHash,
      sharedParameters,
      serverParameters,
      requestBody,
      dtServer,
      token,
      accessCode,
      privateProfileAdmin: { auditId }
    } = this.state;

    const emailTokenCredentials = await UserCredentialsEmailToken.UserRegistration.create(
      credentialId,
      undefined,
      inviteeId,
      masterKeyId,
      credentialHash,
      sharedParameters,
      serverParameters,
      0, // encryptedFor
      dtServer,
      this.credentialExpiry
    );
    requestBody.insert(emailTokenCredentials);

    const params: EncryptWithParametersAlgorithmParams = {
      type: process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN,
      parameter1: token,
      parameter2: accessCode,
      hexSalt: sharedParameters.salt
    };
    const auditKey = await CryptographicKeyAudit.createForCredential(credentialId, params, auditId, dtServer);
    requestBody.insert(auditKey);
  }

  private generateWelcomeMessage(): Messaging.ActionPayloadSendMessage['messageContent'] {
    const basicProfileObject = UserObjectSelectors.basicProfileObjectSelector(this.getRootState())();
    const basicProfile = UserObjectCache.getValue(basicProfileObject);
    if (Utils.isNil(basicProfile)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Basic profile data is either missing or invalid.');
    }

    const { firstName: firstNameAdmin, lastName: lastNameAdmin } = basicProfile;
    const { role: inviteeRoleId } = this.payload;
    const { circleOfCareGroup, circleOfCareGroupName, adminUser, basicProfileInvitee, dtServer, inviteeId, masterKeyId } = this.state;

    let sender: MessageContent['header']['sender'];
    if (Utils.MedicalInstitute.isGuestRole(inviteeRoleId)) {
      sender = { ...circleOfCareGroup, groupName: circleOfCareGroupName };
    } else {
      sender = { ...adminUser, firstName: firstNameAdmin, lastName: lastNameAdmin };
    }

    const { firstName, lastName } = basicProfileInvitee;
    const greeting = ['Hi', firstName, lastName].filter(Boolean).join(' ');

    return {
      header: {
        sender,
        sentAtUtc: dtServer.getTime(),
        subjectLine: 'Welcome',
        recipientList: [
          {
            entity: {
              entityType: 'primary',
              ...new AppUser(inviteeId),
              firstName,
              lastName,
              keyId: masterKeyId
            }
          }
        ],
        readReceiptId: 0, // sendMessageAction will take care of assigning a valid ID
        importance: 'normal'
      },
      body: {
        data: [
          `<p>${greeting},</p>`,
          '<p><br/></p>',
          '<p>Welcome to Sigmail!</p>',
          '<p><br/></p>',
          '<p>You are now using the next generation secure messaging platform. All your information is protected when',
          'in-transit, at-rest and on the network. We respect customer privacy and follow guidelines of HIPAA, PHIPA',
          'and Canadian privacy law.</p>',
          '<p><br/></p>',
          '<p>Feel free to communicate with your healthcare provider and the circle of care with your healthcare',
          'questions.</p>',
          '<p><br/></p>',
          '<p><br/></p>',
          `<p>Thank you<br/><br/>${Utils.joinPersonName({ firstName: firstNameAdmin, lastName: lastNameAdmin })}</p>`
        ].join('\n')
      }
    };
  }

  protected sendInvitationEmail(): Promise<void> {
    this.logger.info('Sending an account setup invitation email.');

    const clientProfileObject = ClientObjectSelectors.profileObjectSelector(this.getRootState())();
    const clientProfile = UserObjectCache.getValue(clientProfileObject);
    if (Utils.isNil(clientProfile)) {
      this.logger.warn('Client profile is either missing or invalid.');
      return Promise.resolve();
    }

    const { firstName, lastName, emailAddress, role: roleId } = this.state.basicProfileInvitee;

    return super.sendInvitationEmail(roleId, clientProfile.name, firstName, lastName, emailAddress);
  }
}

export const sendMemberInvitationAction = (payload: Payload): AppThunk<Promise<string>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'sendMemberInvitationAction:');

    const action = new SendMemberInvitationAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
