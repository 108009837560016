export const ROLE_ID_ADMIN_PHYSICIAN: string = 'admin';
export const ROLE_ID_ADMIN_STAFF: string = 'adminStaff';
export const ROLE_ID_SENIOR_PHYSICIAN: string = 'physician+';
export const ROLE_ID_JUNIOR_PHYSICIAN: string = 'physician';
export const ROLE_ID_STAFF: string = 'staff';
export const ROLE_ID_GUEST: string = 'patient';

export const ROLE_ID_LIST: ReadonlyArray<string> = [
  ROLE_ID_ADMIN_PHYSICIAN,
  ROLE_ID_ADMIN_STAFF,
  ROLE_ID_SENIOR_PHYSICIAN,
  ROLE_ID_JUNIOR_PHYSICIAN,
  ROLE_ID_STAFF,
  ROLE_ID_GUEST
];
