import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Utils } from '@sigmail/common';
import React from 'react';
import { DEFAULT_HEALTH_PLAN_JURISDICTION } from '../../../app-state/actions/constants';
import { BaseFormComponentState } from '../../../app/shared/base-form-pure-component';
import i18n from '../../../i18n/medical-institute/invite-guest-form';

export interface FormData {
  firstName: string;
  lastName: string;
  birthDate: Date | null;
  gender: string;
  cellNumber: string;
  homeNumber: string;
  emailAddress: string;
  healthPlanJurisdiction: string;
  healthPlanNumber: string;
  accessCode: string | null;
}

export interface ContextValue extends BaseFormComponentState<keyof FormData>, FormData {
  dispatch(action: PayloadAction<any>): void;
}

type CaseReducer<TPayload = void> = (state: ContextValue, action: PayloadAction<TPayload>) => ContextValue;

const FORM_DATA_KEY_LIST: ReadonlyArray<keyof FormData> = [
  'firstName',
  'lastName',
  'birthDate',
  'gender',
  'cellNumber',
  'homeNumber',
  'emailAddress',
  'healthPlanJurisdiction',
  'healthPlanNumber'
];

const inputValidityStateReducer: CaseReducer<Partial<ContextValue['inputErrorMessage']>> = (state, action) => {
  const payload = Utils.pick(action.payload, FORM_DATA_KEY_LIST);

  const inputErrorMessage = Utils.transform(
    payload,
    (obj, newErrorMessage, inputId) => {
      const key = inputId as keyof FormData;
      const prevErrorMessage = state.inputErrorMessage[key];
      if (prevErrorMessage !== newErrorMessage) {
        obj[key] = newErrorMessage;
      }
    },
    {} as ContextValue['inputErrorMessage']
  );

  return { ...state, inputErrorMessage: Utils.defaults(inputErrorMessage, state.inputErrorMessage) };
};

const formDataStateReducer: CaseReducer<Partial<FormData>> = (state, action) => {
  const payload = Utils.pick(action.payload, FORM_DATA_KEY_LIST);

  let nextState = { ...state, ...payload };

  if ('healthPlanJurisdiction' in payload) {
    nextState = { ...nextState, healthPlanNumber: '' };
  }

  if ('birthDate' in payload) {
    const { error: errorMessage } = i18n.fieldset.default.field.birthDate;
    nextState = inputValidityStateReducer(
      nextState,
      updateInputErrorMessage({
        birthDate: Utils.isValidDate(payload.birthDate) ? null : errorMessage?.badInput || ''
      })
    );
  }

  return nextState;
};

/** Default value of the institute registration context defined below. */
export const getDefaultValue = (): ContextValue => ({
  firstName: '',
  lastName: '',
  birthDate: null,
  gender: 'unknown',
  cellNumber: '',
  homeNumber: '',
  emailAddress: '',
  healthPlanJurisdiction: DEFAULT_HEALTH_PLAN_JURISDICTION,
  healthPlanNumber: '',
  accessCode: null,

  inputErrorMessage: {} as ContextValue['inputErrorMessage'],

  submitStatus: 'NotSubmitted',

  dispatch() {}
});

const inviteGuestFormSlice = createSlice({
  name: 'inviteGuestForm',
  initialState: getDefaultValue(),
  reducers: {
    updateInputErrorMessage: inputValidityStateReducer,
    updateFormData: formDataStateReducer,
    submitFormData: (state) => {
      if (state.submitStatus === 'InProgress') return state;

      const trimmedFormData = Utils.mapValues(Utils.pick(state, FORM_DATA_KEY_LIST), (value) =>
        Utils.isString(value) ? value.trim() : value
      ) as FormData;
      return { ...state, ...trimmedFormData, submitStatus: 'InProgress' };
    }
  }
});

export const {
  reducer: rootReducer,
  actions: { updateInputErrorMessage, updateFormData, submitFormData }
} = inviteGuestFormSlice;

export const Context = React.createContext(getDefaultValue());
