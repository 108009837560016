export function stringToHslColor(value: any, saturation: number = 30, lightness: number = 30): string {
  // ===========================================================================
  // IMPORTANT: the reason to choose 30 as a default for lightness is that the
  //            generated color is generally seen to meet WCAG contrast ratio
  //            requirement for large and normal text. Having said that, it just
  //            wasn't possible for us to test it extensively hence there might
  //            still be cases where a color wouldn't satisfy that requirement.
  // ===========================================================================
  let trimmedString = JSON.stringify(value).trim();
  if (trimmedString.length === 0) {
    trimmedString = Math.random().toString(36).substring(7);
  }
  let hash = 0;
  for (let i = 0; i < trimmedString.length; i++) {
    hash = trimmedString.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
