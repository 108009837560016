import { createSelector } from '@reduxjs/toolkit';
import { StoreStateDataObjects } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { DataObject, IDataObject, ValueFormatVersion } from '@sigmail/objects';
import { DataObjectCache } from '../data-objects-slice/cache';
import { RootState } from '../root-reducer';

/** Selector to extract the top-level data objects state from the store. */
export const dataObjectsSelector: Reselect.Selector<RootState, StoreStateDataObjects> = (state) => state.dataObjects;

/** Selector to extract a data object identified by a given ID. */
export const dataObjectByIdSelector = createSelector(dataObjectsSelector, (dataObjectMap) => {
  const memoized = Utils.memoize(<DV extends ValueFormatVersion>(objectId: number) => {
    if (!DataObject.isValidId(objectId)) return undefined;

    const objectIdKey = objectId.toString(10);
    return dataObjectMap.has(objectIdKey) ? DataObjectCache.find<IDataObject<DV>>(objectId)?.[0] : undefined;
  });
  return memoized;
});
