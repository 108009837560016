import { Menu, MenuProps, StyledComponentProps } from '@material-ui/core';
import { Utils } from '@sigmail/common';
import React, { AriaAttributes } from 'react';

export type MenuButtonClassKey = 'anchor' | 'paper' | 'list';

export interface Props
  extends Omit<MenuProps, 'anchorEl' | 'classes' | 'onClick'>,
    StyledComponentProps<MenuButtonClassKey> {
  anchorId?: string;
  anchorChildren?: React.ButtonHTMLAttributes<HTMLButtonElement>['children'];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

class MenuButtonComponent extends React.PureComponent<Props> {
  private readonly anchorRef = React.createRef<HTMLButtonElement>();

  public constructor(props: Props) {
    super(props);

    this.onClose = this.onClose.bind(this);
  }

  public render(): React.ReactNode {
    let {
      getContentAnchorEl,
      anchorOrigin,
      transformOrigin,
      anchorChildren: anchorLabel,
      anchorId,
      onClick,
      classes,
      ...menuProps
    } = this.props;
    const { anchor: anchorClassName, ...menuClasses } = classes || {};
    let ariaHasPopup: AriaAttributes['aria-haspopup'] = undefined;

    if (!Utils.isUndefined(menuProps.id)) {
      ariaHasPopup = 'menu';
    }

    if (Utils.isUndefined(getContentAnchorEl)) {
      getContentAnchorEl = null;
    }

    if (Utils.isUndefined(anchorOrigin)) {
      anchorOrigin = { horizontal: 'right', vertical: 'bottom' };
    }

    if (Utils.isUndefined(transformOrigin)) {
      transformOrigin = { horizontal: 'right', vertical: 'top' };
    }

    return (
      <React.Fragment>
        <button
          aria-controls={this.props.id}
          aria-haspopup={ariaHasPopup}
          className={anchorClassName}
          id={anchorId}
          onClick={onClick}
          ref={this.anchorRef}
          type="button"
        >
          {anchorLabel}
        </button>
        <Menu
          anchorEl={this.anchorRef.current}
          anchorOrigin={anchorOrigin}
          classes={menuClasses}
          getContentAnchorEl={getContentAnchorEl}
          onClose={this.onClose}
          transformOrigin={transformOrigin}
          {...menuProps}
        />
      </React.Fragment>
    );
  }

  private onClose(...args: Parameters<NonNullable<MenuProps['onClose']>>): void {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose(...args);
    }
  }
}

export const MenuButton = MenuButtonComponent;
