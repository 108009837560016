import { ActionPayloadBatchQueryDataSuccess, Messaging } from '@sigmail/app-state';
import { AppException, Constants, MessageHeader, PersonName, Utils } from '@sigmail/common';
import { getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  CryptographicKey,
  CryptographicKeyPublic,
  DataObject,
  DataObjectDocBody,
  DataObjectDocMetadata,
  DataObjectDocMetadataValue,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgFolderItem,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  DataObjectMsgReadReceipt,
  DataObjectMsgReadReceiptValue,
  MessageFolderItemCount,
  NotificationObjectIncomingMessage,
  NotificationObjectIncomingMessageValue,
  UserMessageFolderKey
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import sanitizeHtml from 'sanitize-html';
import { AppThunk } from '../..';
import * as EmailTemplateParams from '../../../constants/email-template-params';
import { GUEST_CONTACT_GROUP } from '../../../constants/medical-institute-user-group-type-identifier';
import { MessagingException } from '../../../core/messaging-exception';
import { MessageFlags } from '../../../utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { contactListItemToMessageRecipient } from '../../../utils/contact-list-item-to-message-recipient-entity';
import * as AuthSelectors from '../../selectors/auth';
import * as DataObjectSelectors from '../../selectors/data-object';
import * as GroupObjectSelectors from '../../selectors/group-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { UserObjectCache } from '../../user-objects-slice/cache';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { SANITIZER_OPTIONS } from '../constants';
import { AUTH_STATE_SEND_MESSAGE } from '../constants/auth-state-identifier';
import { sendTemplatedEmailMessageAction } from '../email/send-templated-email-message-action';
import { createMessageFolderExtensionAction } from './create-message-folder-extension-action';
import { ApplyMessageFolderUpdateMeta, ApplyMessageFolderUpdateResult, updateMessageFolderAction } from './update-msg-folder-action';

const MESSAGE_FOLDER_KEY_SENT: Extract<UserMessageFolderKey, 'sent'> = 'sent';
const MESSAGE_FOLDER_KEY_DRAFTS: Extract<UserMessageFolderKey, 'drafts'> = 'drafts';

interface Payload extends Messaging.ActionPayloadSendMessage {}

interface State extends AuthenticatedActionState {
  isDraftMessage: boolean;
  isGuestContactGroupMsg: boolean;
  recipientList: MessageHeader['recipientList'];
  dtServer: Date;
  ownerId: number;
  auditId: number;
  batchUpdateAuthState: string;
  idsClaim: string;
  idRecord: Api.GetIdsResponseData['ids'];
  requestBody: BatchUpdateRequestBuilder;
  msgReadReceiptId: number;
  msgMetadataId: number;
  msgBodyId: number;
  documentList: Array<Required<DataObjectMsgFolderItem>['documentList'][0]>;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

class SendMessageAction extends AuthenticatedAction<Payload, State> {
  protected async preExecute() {
    const result = await super.preExecute();

    const { draftMsgMetadataId, draftMsgBodyId } = this.payload;
    this.state.isDraftMessage = DataObjectMsgMetadata.isValidId(draftMsgMetadataId) && DataObjectMsgBody.isValidId(draftMsgBodyId);

    const { recipientList, sender } = this.payload.messageContent.header;

    this.state.recipientList = recipientList;
    if (recipientList.some(({ entity: recipient }) => recipient.type === 'group' && recipient.groupType === GUEST_CONTACT_GROUP)) {
      if (recipientList.length !== 1) {
        throw new MessagingException(`Expected recipient list length to be <1>; was <${recipientList.length}>.`);
      }

      this.state.isGuestContactGroupMsg = true;

      const circleOfCareGroup = UserObjectSelectors.circleOfCareGroupSelector(this.getRootState());
      if (Utils.isNil(circleOfCareGroup)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Circle of care group is either missing or invalid.');
      }

      const { id: circleOfCareGroupId } = circleOfCareGroup;
      const guestContactGroupId = recipientList[0].entity.id;

      const query: FetchObjectsRequestData = {
        authState: this.state.roleAuthClaim,
        userObjectsByType: [
          { userId: guestContactGroupId, type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC },
          { userId: circleOfCareGroupId, type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST }
        ]
      };

      await this.dispatchFetchObjects(query);

      const groupProfileBasicObject = GroupObjectSelectors.basicProfileObjectSelector(this.getRootState())(guestContactGroupId);
      const groupProfileBasic = UserObjectCache.getValue(groupProfileBasicObject);
      if (Utils.isNil(groupProfileBasic)) {
        throw new Api.MalformedResponseException('Guest contact group profile object could not be fetched.');
      }

      const groupGuestListObject = GroupObjectSelectors.guestListObjectSelector(this.getRootState())(circleOfCareGroupId);
      const groupGuestList = UserObjectCache.getValue(groupGuestListObject);
      if (Utils.isNil(groupGuestList)) {
        throw new Api.MalformedResponseException('Group guest list object could not be fetched.');
      }

      this.state.recipientList = groupProfileBasic.memberList.map(({ id }) => {
        const entry = groupGuestList.list.find((item) => item.type === 'user' && item.id === id);
        if (Utils.isNil(entry)) {
          throw new AppException(
            Constants.Error.E_DATA_MISSING_OR_INVALID,
            `User entry with ID <${id}> could not be found in group guest list.`
          );
        }
        return { entity: contactListItemToMessageRecipient(entry) };
      });
    }

    if (this.state.recipientList.length === 0) {
      this.logger.warn('Call ignored; recipient list is empty.');
      return false;
    }

    if (this.state.isDraftMessage && sender.id !== this.state.currentUser.id) {
      throw new MessagingException('Expected sender to be the current user.');
    }

    return result;
  }

  protected async onExecute(...args: any[]) {
    if (args.length > 0 && args[0] === false) return;

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchUserProfilePrivate();

        await this.generateIdSequence();

        this.initializeRequestBodyAndSuccessPayload();
        await this.fetchRecipientPublicKeys();
        await this.generateRequestBody();

        const { batchUpdateAuthState: authState, idsClaim, requestBody } = this.state;
        await this.dispatchBatchUpdateData({ authState, claims: [idsClaim], ...requestBody.build() });

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }

    await this.sendEmailNotifications();

    try {
      await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
    } catch (error) {
      this.logger.warn('Error manually updating app state:', error);
      /* ignore */
    }

    try {
      await this.dispatch(createMessageFolderExtensionAction({ folderKey: MESSAGE_FOLDER_KEY_SENT }));
    } catch (error) {
      this.logger.warn('Error creating message folder extension:', error);
      /* ignore */
    }
  }

  private async fetchUserProfilePrivate(): Promise<void> {
    this.logger.info("Fetching current user's latest private profile data.");

    const { currentUser, roleAuthClaim: authState } = this.state;

    const { serverDateTime } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [{ userId: currentUser.id, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE }]
    });

    const ownerId = UserObjectSelectors.ownerIdSelector(this.getRootState());
    const auditId = UserObjectSelectors.auditIdSelector(this.getRootState());
    if (!DataObject.isValidId(ownerId) || !DataObject.isValidId(auditId)) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Owner and/or audit ID is either missing or invalid.');
    }

    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.ownerId = ownerId;
    this.state.auditId = auditId;
  }

  private async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const { attachedDocumentList: documentList } = this.payload;

    let documentIdList: Api.GetIdsRequestData['ids']['ids'] = [];
    if (Utils.isNonEmptyArray(documentList)) {
      documentIdList = [
        { type: process.env.DATA_OBJECT_TYPE_DOC_METADATA, count: documentList.length },
        { type: process.env.DATA_OBJECT_TYPE_DOC_BODY, count: documentList.length }
      ];
    }

    const query: Api.GetIdsRequestData = {
      authState: this.state.roleAuthClaim,
      state: AUTH_STATE_SEND_MESSAGE,
      ids: {
        ids: [
          { type: process.env.DATA_OBJECT_TYPE_MSG_METADATA },
          { type: process.env.DATA_OBJECT_TYPE_MSG_BODY },
          { type: process.env.DATA_OBJECT_TYPE_MSG_READ_RECEIPT },
          {
            type: process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE,
            count: this.state.recipientList.length
          },
          ...documentIdList
        ]
      }
    };

    const { authState, idsClaim, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);

    this.state.batchUpdateAuthState = authState;
    this.state.idsClaim = idsClaim;
    this.state.idRecord = idRecord;
  }

  private initializeRequestBodyAndSuccessPayload(): void {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] } },
      response: { dataObjects: [], serverDateTime: '' }
    };
  }

  private async fetchRecipientPublicKeys(): Promise<void> {
    const { sender } = this.payload.messageContent.header;
    const { recipientList } = this.state;

    const query: FetchObjectsRequestData = {
      authState: this.state.roleAuthClaim,
      keysByType: recipientList
        .filter(({ entity: { keyId, id } }) => Utils.isUndefined(keyId) && id !== sender.id)
        .map(({ entity: { id } }) => ({ id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }))
    };
    if (query.keysByType!.length === 0) return;

    this.logger.info('Fetching public keys of all of the recipients.');
    const { keyList } = await this.dispatchFetchObjects(query);

    const publicKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC);
    for (const keyObjectJson of keyList) {
      const publicKeyObject = new CryptographicKeyPublic(keyObjectJson);
      const jsonWebKey = await publicKeyObject.decryptedValue();
      this.logger.debug(`JWK (key.id: ${keyObjectJson.id})`, JSON.stringify(jsonWebKey));
      const cryptoKey = await publicKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPublicKey(keyObjectJson.id, cryptoKey);
    }
  }

  private async generateRequestBody(): Promise<void> {
    await this.addInsertOperationsForAttachedDocumentList();

    await this.addInsertOperationForMessageReadReceipt();
    await this.addInsertOperationForMessageMetadata();
    await this.addInsertOperationForMessageBody();

    await this.addInsertOperationsForRecipientNotification();

    await this.addUpdateOperationForSentMessagesFolder();

    if (this.state.isDraftMessage) {
      await this.markCurrentDraftMessageObjectsExpired();
      await this.addUpdateOperationToDiscardCurrentDraftMessage();
    }
  }

  private async addInsertOperationsForAttachedDocumentList(): Promise<void> {
    // prettier-ignore
    const {
      attachedDocumentList,
      messageContent: { header: { sender: { id: senderId } } }
    } = this.payload;
    const { recipientList } = this.state;

    if (!Utils.isNonEmptyArray(attachedDocumentList)) {
      return;
    }

    this.logger.info("Adding an insert operation each to request body for every attached document's metadata, body, and associated keys.");

    const { ownerId, dtServer, auditId, requestBody, roleAuthClaim: authState } = this.state;
    const idSeqDocMetadata = Utils.makeSequence(this.state.idRecord[process.env.DATA_OBJECT_TYPE_DOC_METADATA]);
    const idSeqDocBody = Utils.makeSequence(this.state.idRecord[process.env.DATA_OBJECT_TYPE_DOC_BODY]);

    const apiAccessToken = AuthSelectors.accessTokenSelector(this.getRootState());
    this.state.documentList = [];
    for (let index = 0; index < attachedDocumentList.length; index++) {
      const doc = attachedDocumentList[index];

      const recipientKeyIdList: ReadonlyArray<number> = recipientList
        .filter(({ entity: { id } }) => id !== senderId)
        .map(({ entity: { keyId, id } }) => (Utils.isUndefined(keyId) ? id : keyId));

      if (doc instanceof File) {
        const docMetadata: DataObjectDocMetadataValue = {
          $$formatver: 1,
          name: doc.name,
          size: doc.size,
          docType: `attachment${index + 1}`
        };
        const { value: docMetadataId } = idSeqDocMetadata.next();
        const docMetadataObject = await DataObjectDocMetadata.create(docMetadataId, undefined, 0, docMetadata, ownerId, senderId, dtServer);

        const docContents = await readFileAsDataUrl(doc);
        const docBody: DataObjectMsgBodyValue = { $$formatver: 1, data: docContents };
        const { value: docBodyId } = idSeqDocBody.next();
        const docBodyObject = await DataObjectDocBody.create(docBodyId, undefined, 0, docBody, ownerId, senderId, dtServer);

        const objectList = [docMetadataObject, docBodyObject];
        const docMetadataKeyList = await docMetadataObject.generateKeysEncryptedFor(auditId, ...recipientKeyIdList);
        docMetadataKeyList.push(docMetadataObject[Constants.$$CryptographicKey]);
        const docBodyKeyList = await docBodyObject.generateKeysEncryptedFor(auditId, ...recipientKeyIdList);
        docBodyKeyList.push(docBodyObject[Constants.$$CryptographicKey]);
        requestBody.insert(objectList);
        requestBody.insert(docMetadataKeyList.filter(Utils.isNotNil));
        requestBody.insert(docBodyKeyList.filter(Utils.isNotNil));

        this.state.documentList!.push({ metadata: docMetadataId, body: docBodyId, name: doc.name, size: doc.size });
      } else if (!!!doc.deleted) {
        const { metadata: docMetadataId, body: docBodyId } = doc;

        const { dataObjectList } = await this.fetchObjects(apiAccessToken, {
          authState,
          dataObjects: { ids: [docMetadataId, docBodyId] },
          expectedCount: { dataObjects: null }
        });

        const docMetadataJson = this.findDataObject(dataObjectList, { type: process.env.DATA_OBJECT_TYPE_DOC_METADATA, id: docMetadataId });
        if (Utils.isNil(docMetadataJson)) {
          throw new Api.MalformedResponseException("Attached document's metadata object could not be fetched.");
        }

        const docBodyJson = this.findDataObject(dataObjectList, { type: process.env.DATA_OBJECT_TYPE_DOC_BODY, id: docBodyId });
        if (Utils.isNil(docBodyJson)) {
          throw new Api.MalformedResponseException("Attached document's body object could not be fetched.");
        }

        const docMetadataObject = new DataObjectDocMetadata(docMetadataJson);
        const docBodyObject = new DataObjectDocBody(docBodyJson);

        const docMetadataKeyList = await docMetadataObject.generateKeysEncryptedFor(auditId, ...recipientKeyIdList);
        const docBodyKeyList = await docBodyObject.generateKeysEncryptedFor(auditId, ...recipientKeyIdList);
        requestBody.insert(docMetadataKeyList.filter(Utils.isNotNil));
        requestBody.insert(docBodyKeyList.filter(Utils.isNotNil));

        this.state.documentList!.push(Utils.omit(doc, 'deleted'));
      }
    }
  }

  private async addInsertOperationForMessageReadReceipt(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message read receipt and associated keys.');

    const { ownerId, dtServer, recipientList, auditId, requestBody } = this.state;
    const { sender } = this.payload.messageContent.header;

    const [id] = this.state.idRecord[process.env.DATA_OBJECT_TYPE_MSG_READ_RECEIPT];
    const data: DataObjectMsgReadReceiptValue = {
      $$formatver: 1,
      data: recipientList.map(({ entity: { id: recipientId } }) => ({
        recipientId,
        receivedAtUtc: dtServer.getTime(),
        readAtUtc: null
      }))
    };
    this.logger.debug({ id, ...data });

    const readReceiptObject = await DataObjectMsgReadReceipt.create(id, undefined, 1, data, ownerId, sender.id, dtServer);
    const keyIdList = recipientList
      .filter(({ entity: { id } }) => id !== sender.id)
      .map(({ entity: { keyId, id } }) => (Utils.isUndefined(keyId) ? id : keyId));
    const keyList = await readReceiptObject.generateKeysEncryptedFor(auditId, ...keyIdList);
    keyList.push(readReceiptObject[Constants.$$CryptographicKey]);
    requestBody.insert(readReceiptObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.msgReadReceiptId = id;
  }

  private async addInsertOperationForMessageMetadata(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message metadata and associated keys.');

    const { dtServer, documentList, msgReadReceiptId, ownerId, auditId, requestBody } = this.state;
    const { header } = this.payload.messageContent;

    let recipientList = this.state.recipientList.slice();
    if (this.state.isGuestContactGroupMsg) recipientList.length = 0;

    const [id] = this.state.idRecord[process.env.DATA_OBJECT_TYPE_MSG_METADATA];
    const data: DataObjectMsgMetadataValue = {
      ...header,
      $$formatver: 1,
      recipientList,
      sentAtUtc: dtServer.getTime(),
      documentList: documentList!,
      readReceiptId: msgReadReceiptId
    };
    this.logger.debug({ id, ...data });

    const msgMetadataObject = await DataObjectMsgMetadata.create(id, undefined, 0, data, ownerId, header.sender.id, dtServer);
    const keyIdList = this.state.recipientList
      .filter(({ entity: { id } }) => id !== header.sender.id)
      .map(({ entity: { keyId, id } }) => (Utils.isUndefined(keyId) ? id : keyId));
    const keyList = await msgMetadataObject.generateKeysEncryptedFor(auditId, ...keyIdList);
    keyList.push(msgMetadataObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgMetadataObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.msgMetadataId = id;
  }

  private async addInsertOperationForMessageBody(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for message body and associated keys.');

    const { ownerId, dtServer, auditId, requestBody } = this.state;
    const { sender } = this.payload.messageContent.header;
    const { recipientList } = this.state;

    const [id] = this.state.idRecord[process.env.DATA_OBJECT_TYPE_MSG_BODY];
    const data: DataObjectMsgBodyValue = {
      $$formatver: 1,
      data: sanitizeHtml(this.payload.messageContent.body.data, SANITIZER_OPTIONS)
    };
    this.logger.debug({ id, data });

    const msgBodyObject = await DataObjectMsgBody.create(id, undefined, 0, data, ownerId, sender.id, dtServer);
    const keyIdList = recipientList
      .filter(({ entity: { id } }) => id !== sender.id)
      .map(({ entity: { keyId, id } }) => (Utils.isUndefined(keyId) ? id : keyId));
    const keyList = await msgBodyObject.generateKeysEncryptedFor(auditId, ...keyIdList);
    keyList.push(msgBodyObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgBodyObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.msgBodyId = id;
  }

  private async addInsertOperationsForRecipientNotification(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for recipient notification.');

    const { msgMetadataId: header, msgBodyId: body, recipientList, requestBody, dtServer } = this.state;
    const { sender } = this.payload.messageContent.header;

    const idSequence = Utils.makeSequence(this.state.idRecord[process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE]);

    const data: NotificationObjectIncomingMessageValue = { $$formatver: 1, header, body };
    for (const { entity } of recipientList) {
      const { value: id } = idSequence.next();
      const notificationObject = await NotificationObjectIncomingMessage.create(id, undefined, 0, data, entity.id, sender.id, 0, dtServer);
      requestBody.insert(notificationObject);
    }
  }

  private async addUpdateOperationForSentMessagesFolder(): Promise<void> {
    this.logger.info('Adding an update operation to request body for sent message folder.');

    const { requestBody, successPayload } = this.state;

    await this.dispatch(
      updateMessageFolderAction({
        folderKey: MESSAGE_FOLDER_KEY_SENT,
        requestBody,
        successPayload,
        applyUpdate: this.applySentMessagesFolderUpdate.bind(this)
      })
    );
  }

  private applySentMessagesFolderUpdate(
    folderData: Array<DataObjectMsgFolderItem>,
    itemCount: MessageFolderItemCount
  ): ApplyMessageFolderUpdateResult {
    const { msgMetadataId, msgBodyId, dtServer } = this.state;
    const { recipientList, subjectLine: subject, importance, billable } = this.payload.messageContent.header;

    const sentMessage: DataObjectMsgFolderItem = {
      header: msgMetadataId,
      body: msgBodyId,
      entity: recipientList.map(({ entity: recipient }) =>
        recipient.type === 'group' ? recipient.groupName : Utils.joinPersonName(recipient)
      ),
      subject,
      snippet: null,
      documentList: this.state.documentList,
      timestamp: dtServer.getTime(),
      flags: {
        markedAsRead: true,
        readAtTimestamp: dtServer.getTime(),
        importance,
        billable
      },
      extraData: null
    };

    folderData.unshift(sentMessage);

    ++itemCount.all.total;
    itemCount.important.total += +(importance === 'high');
    itemCount.billable.total += +!!billable;

    return { updated: true, done: true };
  }

  private async markCurrentDraftMessageObjectsExpired(): Promise<void> {
    this.logger.info('Adding an update operation to request body to mark current draft message objects expired.');

    const { roleAuthClaim: authState, requestBody, dtServer, successPayload } = this.state;

    const draftMsgMetadataId = this.payload.draftMsgMetadataId!;
    const draftMsgBodyId = this.payload.draftMsgBodyId!;

    await this.dispatchFetchObjects({
      authState,
      dataObjects: { ids: [draftMsgMetadataId, draftMsgBodyId] },
      expectedCount: { dataObjects: null }
    });

    const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
    const draftMsgHeaderObject = dataObjectByIdSelector<DataObjectMsgMetadataValue>(draftMsgMetadataId);
    const draftMsgBodyObject = dataObjectByIdSelector<DataObjectMsgBodyValue>(draftMsgBodyId);
    if (Utils.isNil(draftMsgHeaderObject) || Utils.isNil(draftMsgBodyObject)) {
      throw new MessagingException("Either current draft's header object or it's body object could not be fetched.");
    }

    requestBody.expire([draftMsgHeaderObject, draftMsgBodyObject], dtServer);

    successPayload.request.dataObjects!.ids.push(draftMsgMetadataId, draftMsgBodyId);
    // XXX: because we are not pushing the corresponding data objects in
    // successPayload's response, they will be removed from app state if present
  }

  private async addUpdateOperationToDiscardCurrentDraftMessage(): Promise<void> {
    this.logger.info('Adding an update operation to request body to discard the draft message.');

    const { requestBody, successPayload } = this.state;

    await this.dispatch(
      updateMessageFolderAction({
        folderKey: MESSAGE_FOLDER_KEY_DRAFTS,
        requestBody,
        successPayload,
        applyUpdate: this.applyDraftMessagesFolderUpdate.bind(this)
      })
    );
  }

  private applyDraftMessagesFolderUpdate(
    folderData: Array<DataObjectMsgFolderItem>,
    itemCount: MessageFolderItemCount,
    meta: ApplyMessageFolderUpdateMeta
  ): ApplyMessageFolderUpdateResult {
    const { draftMsgMetadataId, draftMsgBodyId } = this.payload;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: ApplyMessageFolderUpdateResult = { updated: false, done: false };

    this.logger.info(
      `Locating item with metadata ID <${draftMsgMetadataId}> and body ID <${draftMsgBodyId}> in ${folderOrExt} <${meta.folderOrExtId}>.`
    );

    const index = folderData.findIndex(({ header, body }) => header === draftMsgMetadataId && body === draftMsgBodyId);
    if (index !== -1) {
      const message = folderData[index];
      const { isMarkedAsRead, isImportant, hasReminder, isBillable } = MessageFlags(message);

      const { all, important, reminder, billable } = itemCount;
      --all.total;
      all.unread -= +!isMarkedAsRead;
      important.total -= +isImportant;
      important.unread -= +(isImportant && !isMarkedAsRead);
      reminder.total -= +hasReminder;
      reminder.unread -= +(hasReminder && !isMarkedAsRead);
      billable.total -= +isBillable;
      billable.unread -= +(isBillable && !isMarkedAsRead);

      folderData.splice(index, 1);
      result.updated = result.done = true;
    }

    return result;
  }

  private async sendEmailNotifications(): Promise<Array<Promise<void>>> {
    this.logger.info('Sending email notifications to all recipients who do not have it disabled.');

    const { recipientList: messageRecipientList } = this.payload.messageContent.header;

    const promiseList: Array<Promise<void>> = [];
    try {
      // email notification to users
      do {
        const recipientList = messageRecipientList.filter(
          ({ entity: { type, emailAddress } }) => type === 'user' && Utils.isString(emailAddress)
        );

        if (recipientList.length === 0) break;

        const personalizationList: Array<Api.TemplatedEmailMessage['personalizations'][0]> = [];
        for (const { entity: recipient } of recipientList) {
          personalizationList.push({
            to: [{ name: recipient.emailAddress!, email: recipient.emailAddress! }],
            dynamicTemplateData: {
              [EmailTemplateParams.FirstName]: (recipient as PersonName).firstName,
              [EmailTemplateParams.LastName]: (recipient as PersonName).lastName,
              [EmailTemplateParams.LoginLink]: process.env.HOST_URL
            }
          });
        }

        promiseList.push(
          ...personalizationList.map((personalization) =>
            this.dispatch(
              sendTemplatedEmailMessageAction({
                template_id: 'd-b20ca48db02d433eb825a22a88cec85d',
                from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
                personalizations: [personalization]
              })
            )
          )
        );
      } while (false);

      // email notification to group users
      do {
        const groupIdList = messageRecipientList.filter(({ entity: { type } }) => type === 'group').map(({ entity: { id } }) => id);
        if (groupIdList.length === 0) break;

        await this.dispatchFetchObjects({
          authState: this.state.roleAuthClaim,
          userObjectsByType: [
            ...groupIdList.map<Required<Api.BatchQueryRequestData>['userObjectsByType'][0]>((groupId) => ({
              userId: groupId,
              type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO
            }))
          ],
          expectedCount: { userObjectsByType: null }
        });

        const personalizationList: Array<Api.TemplatedEmailMessage['personalizations'][0]> = [];
        for (const groupId of groupIdList) {
          const contactInfoObject = GroupObjectSelectors.contactInfoObjectSelector(this.getRootState())(groupId);
          const contactInfo = UserObjectCache.getValue(contactInfoObject);
          if (Utils.isArray(contactInfo?.newMessageEmailNotificationList)) {
            for (const user of contactInfo!.newMessageEmailNotificationList) {
              personalizationList.push({
                to: [{ name: user.emailAddress, email: user.emailAddress }],
                dynamicTemplateData: {
                  [EmailTemplateParams.FirstName]: user.firstName,
                  [EmailTemplateParams.LastName]: user.lastName,
                  [EmailTemplateParams.LoginLink]: process.env.HOST_URL
                }
              });
            }
          }
        }

        promiseList.push(
          ...personalizationList.map((personalization) =>
            this.dispatch(
              sendTemplatedEmailMessageAction({
                template_id: 'd-8475a63cd24d449db95c7020a5fcd350',
                from: { name: 'noreply@sigmail.ca', email: 'noreply@sigmail.ca' },
                personalizations: [personalization]
              })
            )
          )
        );
      } while (false);
    } catch (error) {
      this.logger.warn('Error sending email notifications:', error);
    }
    return promiseList;
  }
}

function readFileAsDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('error', () => reject(reader.error));
    reader.addEventListener('load', () => reader.readyState === FileReader.DONE && resolve(reader.result as string));
    reader.readAsDataURL(file);
  });
}

export const sendMessageAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'sendMessageAction:');

    const action = new SendMessageAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
