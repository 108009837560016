import { MfaMethod } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_AUTHY_SEND_MFA_CODE } from './constants';
import { ServiceException as ApiServiceException } from './service-exception';

export async function apiAuthySendMfaCode(this: Api.Service, accountId: number, mfaMethod: MfaMethod) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiAuthySendMfaCode:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.authyApi.concat(URL_AUTHY_SEND_MFA_CODE.replace(/:mfaMethod\/$/, mfaMethod));
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    if (this.authKey.authyApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.authyApi);
    }
    const requestBody = { id: accountId };

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<void>(requestUrl, JSON.stringify(requestBody), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          return;
        }

        if (response.status === 400) {
          throw new ApiServiceException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
