(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("date-fns"), require("bcryptjs"), require("jwt-decode"), require("secure-remote-password/client"));
	else if(typeof define === 'function' && define.amd)
		define("SigmailCommon", ["lodash", "date-fns", "bcryptjs", "jwt-decode", "secure-remote-password/client"], factory);
	else if(typeof exports === 'object')
		exports["SigmailCommon"] = factory(require("lodash"), require("date-fns"), require("bcryptjs"), require("jwt-decode"), require("secure-remote-password/client"));
	else
		root["SigmailCommon"] = factory(root["lodash"], root["date-fns"], root["bcryptjs"], root["jwt-decode"], root["secure-remote-password/client"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__804__, __WEBPACK_EXTERNAL_MODULE__879__, __WEBPACK_EXTERNAL_MODULE__773__, __WEBPACK_EXTERNAL_MODULE__420__, __WEBPACK_EXTERNAL_MODULE__785__) {
return 