import log from 'loglevel';
import logMsgPrefixer from 'loglevel-plugin-prefix';

logMsgPrefixer.reg(log);
logMsgPrefixer.apply(log, { template: '\n[%t|%l|%n]' });

const FIRST_INIT_DONE: { [key: string]: boolean } = {};
export function getLogger(name: string): log.Logger {
  const logger = log.getLogger(name);

  if (!FIRST_INIT_DONE[name]) {
    FIRST_INIT_DONE[name] = true;
    if (process.env.REACT_APP_ENV === 'production') {
      logger.disableAll();
    } else {
      logger.enableAll();
    }
  }

  return logger;
}

const PREFIXED_LOGGER_CACHE: { [key: string]: log.Logger } = {};
export function getLoggerWithPrefix(name: string, prefix: string): log.Logger {
  const prefixedLoggerName = name.concat('/', prefix);
  if (!PREFIXED_LOGGER_CACHE[prefixedLoggerName]) {
    const logger = getLogger(name);
    PREFIXED_LOGGER_CACHE[prefixedLoggerName] = Object.assign({} as log.Logger, logger, {
      trace: (...args: any[]): void => logger.trace(prefix, ...args, '\n\n'),
      debug: (...args: any[]): void => logger.debug(prefix, ...args, '\n\n'),
      info: (...args: any[]): void => logger.info(prefix, ...args, '\n\n'),
      warn: (...args: any[]): void => logger.warn(prefix, ...args, '\n\n'),
      error: (...args: any[]): void => logger.error(prefix, ...args, '\n\n')
    } as log.Logger);
  }
  return PREFIXED_LOGGER_CACHE[prefixedLoggerName];
}
