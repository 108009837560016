import { Utils } from '@sigmail/common';
import {
  DataObjectMsgFolder,
  DataObjectMsgFolderValue,
  GroupMessageFolderKey,
  GroupMessageSubFolderKey,
  UserMessageFolderKey,
  UserMessageSubFolderKey,
  UserObjectProfileBasicValue
} from '@sigmail/objects';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GROUP_MESSAGE_FOLDER_PREFIX, USER_DEFINED_MESSAGE_FOLDER_PREFIX } from '../../../app-state/actions/constants';
import { DataObjectCache } from '../../../app-state/data-objects-slice/cache';
import { RootState } from '../../../app-state/root-reducer';
import * as AuthSelectors from '../../../app-state/selectors/auth';
import * as DataObjectSelectors from '../../../app-state/selectors/data-object';
import * as GroupObjectSelectors from '../../../app-state/selectors/group-object';
import * as UserObjectSelectors from '../../../app-state/selectors/user-object';
import { UserObjectCache } from '../../../app-state/user-objects-slice/cache';
import FolderIconUserDefinedPrimary from '../../../assets/images/folder-icon-user-defined-primary.min.svg';
import FolderIconUserDefinedSecondary from '../../../assets/images/folder-icon-user-defined-secondary.min.svg';
import * as ActionId from '../../../constants/action-ids';
import * as ClientRightId from '../../../constants/client-rights-identifiers';
import { EnglishCanada, FrenchCanada } from '../../../constants/language-codes';
import * as RouteId from '../../../constants/route-identifiers';
import { withTranslation } from '../../../i18n';
import { I18N_NS_MESSAGING } from '../../../i18n/config/namespace-identifiers';
import i18n from '../../../i18n/messaging';
import { processImgProps } from '../../../utils/process-img-props';
import { resolveActionIcon } from '../../../utils/resolve-action-icon';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import { resolveActionPath } from '../../../utils/resolve-action-path';
import { getRoutePath } from '../../routes';
import { ActionNavList, NavAction } from '../../shared/action-nav-list';

type MessageFolderKey = UserMessageFolderKey | GroupMessageFolderKey;
type MessageSubFolderKey = UserMessageSubFolderKey | GroupMessageSubFolderKey;

interface MessageFolderChildItem {
  actionId: string;
  userDefinedFolder?: boolean; // `false` when omitted
  itemCount: number;
  folderId: number;
  parentFolderId: number;
}

interface MessageFolderItem extends Omit<MessageFolderChildItem, 'parentFolderId'> {
  children?: Array<MessageFolderChildItem>;
}

const ACTION_ID_LIST: [
  actionId: string,
  folderKey: MessageFolderKey,
  childrenIfAny: [string, MessageSubFolderKey][] | undefined
][] = [
  [
    ActionId.ReceivedMessagesShared,
    '$group_inbox',
    [
      [ActionId.ArchivedMessagesShared, 'archived'],
      [ActionId.DeletedMessagesShared, 'deleted']
    ]
  ],
  [
    ActionId.ReceivedMessages,
    'inbox',
    [
      [ActionId.ArchivedMessages, 'archived'],
      [ActionId.DeletedMessages, 'deleted']
    ]
  ],
  [ActionId.SentMessages, 'sent', undefined],
  [ActionId.DraftMessages, 'drafts', undefined]
];

interface OwnProps {
  classes?: {
    root?: string;
    navItem?: string;
    navLink?: string;
    folderName?: string;
    folderItemCount?: string;
  };
  onNavItemClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

/**
 * Retrieves and returns data needed from the store which will be merged into
 * the MessageFolderList component's properties.
 */
const mapStateToProps = (state: RootState) => {
  const clientRights = AuthSelectors.clientRightsSelector(state);
  const basicProfileObject = UserObjectSelectors.basicProfileObjectSelector(state)();
  const { role: roleId } = UserObjectCache.getValue(basicProfileObject, {} as UserObjectProfileBasicValue);
  const userMsgFolderMap = UserObjectSelectors.messageFolderMapSelector(state)();
  const groupMsgFolderMap = GroupObjectSelectors.messageFolderMapSelector(state)();
  const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(state);

  const getFolderItemCount = (folderId: any): DataObjectMsgFolderValue['itemCount'] => {
    const defaultItemCount: DataObjectMsgFolderValue['itemCount'] = {
      all: { total: 0, unread: 0 },
      reminder: { total: 0, unread: 0 },
      important: { total: 0, unread: 0 },
      billable: { total: 0, unread: 0 },
      referral: { total: 0, unread: 0 }
    };

    if (DataObjectMsgFolder.isValidId(folderId)) {
      const folderObject = dataObjectByIdSelector<DataObjectMsgFolderValue>(folderId);
      const folderContents = DataObjectCache.getValue(folderObject);
      if (!Utils.isNil(folderContents) && !Utils.isNil(folderContents.$$formatver) && folderContents.$$formatver >= 2) {
        // @ts-expect-error - false warning: condition will always return false
        if (folderContents.$$formatver === 2) {
          return { ...folderContents.itemCount, referral: defaultItemCount.referral };
        }
        return folderContents.itemCount;
      }
    }

    return defaultItemCount;
  };

  const msgFolderItemList: MessageFolderItem[] = [];
  if (clientRights.get(ClientRightId.CAN_ACCESS_MAILBOX) === true) {
    Utils.transform(
      ACTION_ID_LIST,
      (folderItem, [actionId, folderKey, children]) => {
        switch (actionId) {
          case ActionId.SentMessages:
            if (clientRights.get(ClientRightId.CAN_COMPOSE_MESSAGE) !== true) {
              return;
            }
            break;
          case ActionId.DraftMessages:
            if (clientRights.get(ClientRightId.CAN_SAVE_MESSAGE_DRAFT) !== true) {
              return;
            }
            break;
          default:
            break;
        }

        const folder = folderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX)
          ? groupMsgFolderMap[folderKey]
          : userMsgFolderMap[folderKey];
        const folderId = folder?.id;
        if (!DataObjectMsgFolder.isValidId(folderId)) {
          return;
        }

        const { all, reminder, important, referral } = getFolderItemCount(folderId);
        const itemCount = actionId === ActionId.DraftMessages ? all.total : all.unread;

        const index = folderItem.push({ actionId, itemCount, folderId }) - 1;
        if (actionId === ActionId.ReceivedMessages) {
          // INBOX has three virtual folders: REMINDER, IMPORTANT, and
          // REFERRALS; the following code block handles that special case
          folderItem[index].children = [
            // { actionId: ActionId.ReminderMessages, itemCount: reminder.unread, folderId, parentFolderId: folderId },
            { actionId: ActionId.ImportantMessages, itemCount: important.unread, folderId, parentFolderId: folderId }
          ];

          // REFERRALS is available to users of non-GUEST role only
          if (Utils.MedicalInstitute.isNonGuestRole(roleId)) {
            folderItem[index].children!.push({
              actionId: ActionId.ReferralMessages,
              itemCount: referral.unread,
              folderId,
              parentFolderId: folderId
            });
          }
        } else if (actionId === ActionId.ReceivedMessagesShared) {
          // GROUP INBOX has two virtual folders - IMPORTANT and REFERRALS
          folderItem[index].children = [
            {
              actionId: ActionId.ImportantMessagesShared,
              itemCount: important.unread,
              folderId,
              parentFolderId: folderId
            }
          ];

          // REFERRALS is available to users of non-GUEST role only
          if (Utils.MedicalInstitute.isNonGuestRole(roleId)) {
            folderItem[index].children!.push({
              actionId: ActionId.ReferralMessagesShared,
              itemCount: referral.unread,
              folderId,
              parentFolderId: folderId
            });
          }
        }

        const folderChildMap = folder!.children;
        if (!Utils.isNil(folderChildMap)) {
          let parentFolderId = folderId;

          // any user-defined folders are placed above the known sub-folders,
          // and are arranged in an ascending order by name
          const userDefinedFolderList: Required<MessageFolderItem>['children'] = [];
          Object.keys(folderChildMap)
            .filter((folderKey) => folderKey.startsWith(USER_DEFINED_MESSAGE_FOLDER_PREFIX))
            .forEach((folderKey) => {
              const { id: folderId, name: folderName } = folderChildMap[folderKey] || {};
              if (
                !DataObjectMsgFolder.isValidId(folderId) ||
                !Utils.isString(folderName) ||
                folderName.trim().length === 0
              ) {
                return;
              }
              const itemCount = getFolderItemCount(folderId).all.unread;
              userDefinedFolderList.push({
                actionId: folderName,
                itemCount,
                userDefinedFolder: true,
                folderId,
                parentFolderId
              });
            });
          userDefinedFolderList.sort(({ actionId: name1 }, { actionId: name2 }) => name1.localeCompare(name2));
          (folderItem[index].children || (folderItem[index].children = [])).push(...userDefinedFolderList);

          if (Utils.isArray(children)) {
            children.forEach(([actionId, folderKey]) => {
              switch (actionId) {
                case ActionId.ArchivedMessages:
                case ActionId.ArchivedMessagesShared:
                  if (clientRights.get(ClientRightId.CAN_ARCHIVE_MESSAGE) !== true) {
                    return;
                  }
                  break;
                case ActionId.DeletedMessages:
                case ActionId.DeletedMessagesShared:
                  if (clientRights.get(ClientRightId.CAN_DELETE_MESSAGE) !== true) {
                    return;
                  }
                  break;
                default:
                  break;
              }
              const folderId = folderChildMap[folderKey]?.id;
              if (!DataObjectMsgFolder.isValidId(folderId)) {
                return;
              }
              const itemCount = getFolderItemCount(folderId).all.unread;
              (folderItem[index].children || (folderItem[index].children = [])).push({
                actionId,
                itemCount,
                folderId,
                parentFolderId
              });
            });
          }
        }
      },
      msgFolderItemList
    );
  }

  return { msgFolderItemList };
};

/**
 * HOC to create a MessageFolderListComponent which is connected to the store.
 */
const withConnect = connect(mapStateToProps, {});
type ConnectedProps = Omit<ReduxConnectedProps<typeof withConnect>, keyof OwnProps>;

/** MessageFolderList component properties. */
export interface Props extends OwnProps, RouteComponentProps, WithTranslation, ConnectedProps {}

class MessageFolderListComponent extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const { msgFolderItemList, t } = this.props;
    if (msgFolderItemList.length === 0) return null;

    const action = Utils.transform(
      msgFolderItemList,
      (navActionMap, folderItem) => {
        const navAction = this.createNavAction(folderItem);
        if (Utils.isNotNil(navAction)) {
          navActionMap[folderItem.actionId] = navAction;
          if (Utils.isArray(folderItem.children)) {
            folderItem.children.forEach((folderItem) => {
              const childNavAction = this.createNavAction(folderItem);
              if (Utils.isNotNil(childNavAction)) {
                navActionMap[folderItem.actionId] = childNavAction;
              }
            });
          }
        }
      },
      {} as { [actionId: string]: NavAction }
    );

    return <ActionNavList t={t} className={this.props.classes?.root} action={action} />;
  }

  private createNavAction(folderItem: MessageFolderItem): NavAction | undefined {
    const { t, location, classes, onNavItemClick: onClick } = this.props;

    const { actionId, userDefinedFolder, itemCount } = folderItem;
    let action = i18n.navAction[actionId];
    if (userDefinedFolder === true) {
      const { folderId, parentFolderId } = folderItem as MessageFolderChildItem;
      const path = getRoutePath(RouteId.ROUTE_MAIL_USER_DEFINED_FOLDER);
      action = {
        iconPrimary: {
          alt: '',
          src: { [EnglishCanada]: FolderIconUserDefinedPrimary, [FrenchCanada]: FolderIconUserDefinedPrimary }
        },
        iconSecondary: {
          alt: '',
          src: { [EnglishCanada]: FolderIconUserDefinedSecondary, [FrenchCanada]: FolderIconUserDefinedSecondary }
        },
        label: actionId,
        to: path.replace(':folderId', parentFolderId.toString(10)).replace(':subFolderId', folderId.toString(10))
      };
    }

    if (Utils.isNil(action)) {
      return undefined;
    }

    const actionLabel = t(resolveActionLabel(action, actionId));
    const iconPrimary = resolveActionIcon({ icon: action.iconPrimary }, actionId);
    const iconSecondary = resolveActionIcon({ icon: action.iconSecondary }, actionId);
    const pathname = t(resolveActionPath(action, actionId));
    const actionIcon = location.pathname === pathname ? iconPrimary : iconSecondary;

    return {
      ...Utils.omit(action, ['iconPrimary', 'iconSecondary']),
      renderLabel: () => (
        <React.Fragment>
          <span className={classes?.folderName} title={actionLabel}>
            {actionLabel}
          </span>
          {itemCount > 0 && <span className={classes?.folderItemCount}>{itemCount}</span>}
        </React.Fragment>
      ),
      exact: true,
      image: processImgProps({ ...actionIcon, src: actionIcon.src[this.props.i18n.language] }),
      navItemClassName: classes?.navItem,
      navLinkClassName: [classes?.navLink, Utils.has(folderItem, 'parentFolderId') && 'indented']
        .filter(Boolean)
        .join(' '),
      onClick
    };
  }
}

const ns = [I18N_NS_MESSAGING];
export const MessageFolderList = withConnect(withRouter(withTranslation(ns)(MessageFolderListComponent)));
MessageFolderList.displayName = 'MessageFolderList';
