import { ActionPayloadBatchQueryDataSuccess, MedicalInstitute } from '@sigmail/app-state';
import { AppException, AppUserGroup, Constants, IAppUserGroup, InstituteInfo, Utils } from '@sigmail/common';
import { getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ClientObjectContactList,
  ClientObjectContactListValue,
  ClientObjectProfile,
  ClientObjectProfileValue,
  CryptographicKey,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  DataObjectMsgFolder,
  DataObjectMsgFolderValue,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue,
  GroupObjectContactInfo,
  GroupObjectContactInfoValue,
  GroupObjectFolderList,
  GroupObjectFolderListValue,
  GroupObjectGuestList,
  GroupObjectGuestListValue,
  GroupObjectPreferences,
  GroupObjectPreferencesValue,
  GroupObjectProfileBasic,
  GroupObjectProfileBasicValue,
  GroupObjectServerRights,
  GroupObjectServerRightsValue,
  IDataObject,
  IUserObject,
  UserObjectProfilePrivate
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { CIRCLE_OF_CARE } from '../../../constants/medical-institute-user-group-type-identifier';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_CREATE_CIRCLE_OF_CARE_GROUP } from '../constants/auth-state-identifier';

const INSTITUTE_INFO_KEY_LIST: ReadonlyArray<keyof InstituteInfo> = [
  'name',
  'emailAddress',
  'phoneNumber',
  'faxNumber',
  'addressLine1',
  'addressLine2',
  'addressLevel2',
  'addressLevel1',
  'postalCode'
];

interface Payload extends MedicalInstitute.ActionPayloadCreateCircleOfCareGroup {}

interface State {
  dtServer: Date;
  clientId: number;
  ownerId: number;
  auditId: number;
  globalContactListId: number;
  clientContactListObject: IUserObject<ClientObjectContactListValue>;
  clientProfile: ClientObjectProfileValue;
  idRecord: Api.GetIdsResponseData['ids'];
  batchUpdateAuthState: string;
  groupClaim: string;
  idsClaim: string;
  group: IAppUserGroup;
  inboxFolderId: number;
  archivedFolderId: number;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

class CreateCircleOfCareGroupAction extends BaseAction<Payload, State, string> {
  private readonly asymmetricKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);

  protected async onExecute(): Promise<string> {
    await this.fetchUserProfilePrivate();
    await this.fetchClientContactListAndProfile();

    await this.generateIdSequence();
    await this.generateRequestBody();

    const { batchUpdateAuthState: authState, idsClaim, requestBody, successPayload, group } = this.state;
    await this.dispatchBatchUpdateData({ authState, claims: [idsClaim], ...requestBody.build() });

    try {
      await this.dispatchBatchQueryDataSuccess(successPayload);
    } catch (error) {
      this.logger.warn('Error manually updating app state:', error);
      /* ignore */
    }

    CryptographicKeyPrivate.clearPrivateKey(group.id);
    CryptographicKeyPublic.clearPrivateKey(group.id);

    return this.state.groupClaim;
  }

  private async fetchUserProfilePrivate(): Promise<void> {
    this.logger.info('Fetching the latest private profile object.');

    const { roleAuthClaim: authState, currentUserId: userId, apiAccessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE }]
    };

    const { serverDateTime, userObjectList } = await this.fetchObjects(apiAccessToken, query);

    const privateProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId });
    if (Utils.isNil(privateProfileJson)) throw new Api.MalformedResponseException('Private profile could not be fetched.');

    const privateProfileObject = new UserObjectProfilePrivate(privateProfileJson);
    const { clientId, ownerId, auditId, globalContactListId } = await privateProfileObject.decryptedValue();

    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.clientId = clientId;
    this.state.ownerId = ownerId;
    this.state.auditId = auditId;
    this.state.globalContactListId = globalContactListId;
  }

  private async fetchClientContactListAndProfile(): Promise<void> {
    this.logger.info('Fetching the latest client contact list and profile data.');

    const { roleAuthClaim: authState, apiAccessToken } = this.payload;
    const { clientId } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST },
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_PROFILE }
      ]
    };

    const { userObjectList } = await this.fetchObjects(apiAccessToken, query);

    const contactListJson = this.findUserObject(userObjectList, { type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST, userId: clientId });
    if (Utils.isNil(contactListJson)) throw new Api.MalformedResponseException('Client contact list could not be fetched.');

    const profileJson = this.findUserObject(userObjectList, { type: process.env.CLIENT_OBJECT_TYPE_PROFILE, userId: clientId });
    if (Utils.isNil(profileJson)) throw new Api.MalformedResponseException('Client profile could not be fetched.');

    const profileObject = new ClientObjectProfile(profileJson);
    const profile = await profileObject.decryptedValue();

    this.state.clientContactListObject = new ClientObjectContactList(contactListJson);
    this.state.clientProfile = profile;
  }

  private async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const { updateIds } = Utils.decodeIdToken(this.payload.roleAuthClaim);
    if (!Utils.isArray<number>(updateIds)) {
      throw new AppException(Constants.Error.S_ERROR, '<updateIds> is either missing or invalid in decoded authState.');
    }

    const query: Api.GetIdsRequestData = {
      authState: this.payload.roleAuthClaim,
      state: AUTH_STATE_CREATE_CIRCLE_OF_CARE_GROUP,
      ids: {
        usages: [{ usage: 'groupId' }],
        ids: [
          { type: process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC },
          { type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST },
          { type: process.env.GROUP_OBJECT_TYPE_CONTACT_INFO },
          { type: process.env.GROUP_OBJECT_TYPE_PREFERENCES },
          // { type: process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS },
          { type: process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS },
          { type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST },

          { type: process.env.DATA_OBJECT_TYPE_MSG_FOLDER, count: 2 /* Inbox, Archived */ }
        ]
      },
      updateIds
    };

    const { ids: idRecord, claims, authState, idsClaim } = await this.fetchIdsByUsage(this.payload.apiAccessToken, query);
    const [groupId] = idRecord['groupId'];
    const [inboxFolderId, archivedFolderId] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_FOLDER];

    let groupClaim: string | undefined = undefined;
    for (const claim of claims) {
      const { name } = Utils.decodeIdToken(claim);
      if (name === 'group') {
        groupClaim = claim;
        break;
      }
    }

    if (!Utils.isValidJwtToken(groupClaim, 'id')) {
      throw new Api.MalformedResponseException('Group claim is either missing or invalid.');
    }

    this.state.idRecord = idRecord;
    this.state.batchUpdateAuthState = authState;
    this.state.groupClaim = groupClaim;
    this.state.idsClaim = idsClaim;
    this.state.group = new AppUserGroup(groupId, CIRCLE_OF_CARE);
    this.state.inboxFolderId = inboxFolderId;
    this.state.archivedFolderId = archivedFolderId;

    this.logger.debug({ groupId, inboxFolderId, archivedFolderId });
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] }, userObjects: { ids: [] } },
      response: { dataObjects: [], userObjects: [], serverDateTime: '' }
    };

    await this.addInsertOperationForKeyPair(); // 111, 101

    await this.addInsertOperationForInboxFolder(); // 251 (Inbox)
    await this.addInsertOperationForArchivedFolder(); // 251 (Archived)

    await this.addInsertOperationForProfileBasic(); // 431
    await this.addInsertOperationForFolderList(); // 434
    await this.addInsertOperationForContactInfo(); // 435
    await this.addInsertOperationForPreferences(); // 438
    // await this.addInsertOperationForAccessRights(); // 440
    await this.addInsertOperationForServerRights(); // 441
    await this.addInsertOperationForGuestList(); // 468

    await this.addUpdateOperationForClientContactList(); // 466

    await this.addUpdateOperationForGlobalContactList(); // 360
  }

  private async addInsertOperationForKeyPair(): Promise<void> {
    this.logger.info('Adding an insert operation each to request body for a group key pair.');

    const { group, clientId, dtServer, globalContactListId, requestBody } = this.state;

    const { privateKey, exportedPrivateKey, publicKey, exportedPublicKey } = await this.asymmetricKeyAlgo.generateKey();
    this.logger.debug('Group private JWK', JSON.stringify(exportedPrivateKey));
    this.logger.debug('Group public JWK', JSON.stringify(exportedPublicKey));
    CryptographicKey.setPrivateKey(group.id, privateKey!);
    CryptographicKey.setPublicKey(group.id, publicKey!);

    const groupPrivateKey = await CryptographicKeyPrivate.create(group.id, undefined, 0, exportedPrivateKey!, clientId, dtServer);
    const groupPublicKey = await CryptographicKeyPublic.create(group.id, undefined, 0, exportedPublicKey!, group.id, dtServer);
    const groupPublicKeyForClient = await CryptographicKeyPublic.encryptFor(groupPublicKey, clientId);
    const groupPublicKeyForGlobalContactList = await CryptographicKeyPublic.encryptFor(groupPublicKey, globalContactListId);
    requestBody.insert([groupPrivateKey, groupPublicKey, groupPublicKeyForClient, groupPublicKeyForGlobalContactList]);
  }

  private async addInsertOperationForInboxFolder(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group inbox folder.');

    const { inboxFolderId: id, ownerId, group, dtServer, auditId, requestBody, successPayload } = this.state;

    const value: DataObjectMsgFolderValue = {
      $$formatver: 3,
      data: [],
      next: null,
      itemCount: {
        all: { total: 0, unread: 0 },
        important: { total: 0, unread: 0 },
        reminder: { total: 0, unread: 0 },
        billable: { total: 0, unread: 0 },
        referral: { total: 0, unread: 0 }
      }
    };
    this.logger.debug({ id, ...value });

    const msgFolderObject = await DataObjectMsgFolder.create(id, undefined, 1, value, ownerId, group.id, dtServer);
    const keyList = await msgFolderObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgFolderObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgFolderObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.dataObjects!.ids.push(msgFolderObject.id);
    successPayload.response.dataObjects!.push(msgFolderObject.toApiFormatted());
  }

  private async addInsertOperationForArchivedFolder(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group archived folder.');

    const { archivedFolderId: id, ownerId, group, dtServer, auditId, requestBody, successPayload } = this.state;

    const value: DataObjectMsgFolderValue = {
      $$formatver: 3,
      data: [],
      next: null,
      itemCount: {
        all: { total: 0, unread: 0 },
        important: { total: 0, unread: 0 },
        reminder: { total: 0, unread: 0 },
        billable: { total: 0, unread: 0 },
        referral: { total: 0, unread: 0 }
      }
    };
    this.logger.debug({ id, ...value });

    const msgFolderObject = await DataObjectMsgFolder.create(id, undefined, 1, value, ownerId, group.id, dtServer);
    const keyList = await msgFolderObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgFolderObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgFolderObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.dataObjects!.ids.push(msgFolderObject.id);
    successPayload.response.dataObjects!.push(msgFolderObject.toApiFormatted());
  }

  private async addInsertOperationForProfileBasic(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group profile.');

    const { idRecord, group, dtServer, clientId, auditId, requestBody, successPayload } = this.state;

    const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC];
    const value: GroupObjectProfileBasicValue = { $$formatver: 1, name: this.payload.groupName, memberList: [] };
    this.logger.debug({ id, groupId: group.id, ...value });

    const profileObject = await GroupObjectProfileBasic.create(id, undefined, 1, value, group.id, group.id, dtServer);
    const keyList = await profileObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(profileObject[Constants.$$CryptographicKey]);
    requestBody.insert(profileObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjects!.ids.push(profileObject.id);
    successPayload.response.userObjects!.push(profileObject.toApiFormatted());
  }

  private async addInsertOperationForFolderList(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group folder list.');

    const { idRecord, inboxFolderId, archivedFolderId, group, dtServer, clientId, auditId, requestBody, successPayload } = this.state;

    const TYPE_MSG_FOLDER = process.env.DATA_OBJECT_TYPE_MSG_FOLDER;
    const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_FOLDER_LIST];
    const value: GroupObjectFolderListValue = {
      $$formatver: 2,
      msg: {
        $group_inbox: {
          id: inboxFolderId,
          type: TYPE_MSG_FOLDER,
          children: {
            archived: { id: archivedFolderId, type: TYPE_MSG_FOLDER },
            deleted: undefined
          }
        }
      },
      doc: {}
    };
    this.logger.debug({ id, groupId: group.id, ...value });

    const folderListObject = await GroupObjectFolderList.create(id, undefined, 1, value, group.id, group.id, dtServer);
    const keyList = await folderListObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(folderListObject[Constants.$$CryptographicKey]);
    requestBody.insert(folderListObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjects!.ids.push(folderListObject.id);
    successPayload.response.userObjects!.push(folderListObject.toApiFormatted());
  }

  private async addInsertOperationForContactInfo(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group contact info.');

    const { groupName } = this.payload;
    const { idRecord, group, dtServer, clientId, auditId, requestBody, successPayload } = this.state;

    const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_CONTACT_INFO];
    const value: GroupObjectContactInfoValue = { $$formatver: 1, groupId: group.id, groupName };
    this.logger.debug({ id, ...value });

    const contactInfoObject = await GroupObjectContactInfo.create(id, undefined, 1, value, group.id, group.id, dtServer);
    const keyList = await contactInfoObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(contactInfoObject[Constants.$$CryptographicKey]);
    requestBody.insert(contactInfoObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjects!.ids.push(contactInfoObject.id);
    successPayload.response.userObjects!.push(contactInfoObject.toApiFormatted());
  }

  private async addInsertOperationForPreferences(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group preferences.');

    const { idRecord, group, dtServer, clientId, auditId, requestBody, successPayload } = this.state;

    const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_PREFERENCES];
    const value: GroupObjectPreferencesValue = { $$formatver: 1 };
    this.logger.debug({ id, ...value });

    const preferencesObject = await GroupObjectPreferences.create(id, undefined, 1, value, group.id, group.id, dtServer);
    const keyList = await preferencesObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(preferencesObject[Constants.$$CryptographicKey]);
    requestBody.insert(preferencesObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjects!.ids.push(preferencesObject.id);
    successPayload.response.userObjects!.push(preferencesObject.toApiFormatted());
  }

  // private async addInsertOperationForAccessRights(): Promise<void> {
  //   this.logger.info('Adding an insert operation to request body for group access rights.');

  //   const { idRecord, group, dtServer, clientId, auditId, requestBody, successPayload } = this.state;

  //   const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS];
  //   const value: GroupObjectAccessRightsValue = { $$formatver: 1 };
  //   this.logger.debug({ id, groupId: group.id, ...value });

  //   const accessRightsObject = await GroupObjectAccessRights.create(id, undefined, 1, value, group.id, group.id, dtServer);
  //   const keyList = await accessRightsObject.generateKeysEncryptedFor(clientId, auditId);
  //   keyList.push(accessRightsObject[Constants.$$CryptographicKey]);
  //   requestBody.insert(accessRightsObject);
  //   requestBody.insert(keyList.filter(Utils.isNotNil));

  //   successPayload.request.userObjects!.ids.push(accessRightsObject.id);
  //   successPayload.response.userObjects!.push(accessRightsObject.toApiFormatted());
  // }

  private async addInsertOperationForServerRights(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group server rights.');

    const { idRecord, groupClaim, group, dtServer, clientId, auditId, requestBody, successPayload } = this.state;

    const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS];
    const value: GroupObjectServerRightsValue = { $$formatver: 1, groupClaim };
    this.logger.debug({ id, groupId: group.id, ...value });

    const serverRightsObject = await GroupObjectServerRights.create(id, undefined, 1, value, group.id, group.id, dtServer);
    const keyList = await serverRightsObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(serverRightsObject[Constants.$$CryptographicKey]);
    requestBody.insert(serverRightsObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjects!.ids.push(serverRightsObject.id);
    successPayload.response.userObjects!.push(serverRightsObject.toApiFormatted());
  }

  private async addInsertOperationForGuestList(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group guest list.');

    const { idRecord, group, dtServer, auditId, requestBody, successPayload } = this.state;

    const [id] = idRecord[process.env.GROUP_OBJECT_TYPE_GUEST_LIST];
    const value: GroupObjectGuestListValue = { $$formatver: 2, list: [] };
    this.logger.debug({ id, groupId: group.id, ...value });

    const guestListObject = await GroupObjectGuestList.create(id, undefined, 1, value, group.id, group.id, dtServer);
    const keyList = await guestListObject.generateKeysEncryptedFor(auditId);
    keyList.push(guestListObject[Constants.$$CryptographicKey]);
    requestBody.insert(guestListObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjects!.ids.push(guestListObject.id);
    successPayload.response.userObjects!.push(guestListObject.toApiFormatted());
  }

  private async addUpdateOperationForClientContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for client contact list.');

    const { groupName } = this.payload;
    const { group, clientProfile, clientContactListObject: contactListObject, requestBody, successPayload } = this.state;

    const applyUpdate = async (contactListObject: IUserObject<ClientObjectContactListValue>) => {
      const contactList = await contactListObject.decryptedValue();
      const institute = Utils.pick(clientProfile, INSTITUTE_INFO_KEY_LIST);

      const groupExists = contactList.list.some(
        (item) => item.type === 'group' && item.groupType === CIRCLE_OF_CARE && item.groupData.groupName === groupName
      );
      if (groupExists) {
        throw new AppException(Constants.Error.S_ERROR, 'Circle of care group with the same name already exists.');
      }

      const updatedValue: ClientObjectContactListValue = {
        ...contactList,
        list: contactList.list.concat({ ...group, groupData: { groupName, institute } })
      };

      return contactListObject.updateValue(updatedValue);
    };

    const contactListObjectKey = contactListObject[Constants.$$CryptographicKey];
    const dataUpdater: Api.DataUpdater<IUserObject<any>> = async (contactListJson, { userObjects }) => {
      let index = userObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === contactListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; client contact list could not be found in request body.');
      }

      const key = contactListObjectKey === null ? null : contactListObjectKey.toApiFormatted();
      const contactListObject = new ClientObjectContactList({ ...contactListJson, key });
      const updatedObject = await applyUpdate(contactListObject);
      userObjects![index].data = updatedObject;

      index = successPayload.response.userObjects!.findIndex(({ id }) => id === contactListJson.id);
      if (index !== -1) successPayload.response.userObjects![index] = updatedObject.toApiFormatted();
    };

    const updatedObject = await applyUpdate(contactListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.userObjects!.ids.push(contactListObject.id);
    successPayload.response.userObjects!.push(updatedObject.toApiFormatted());
  }

  private async addUpdateOperationForGlobalContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for global contact list.');

    const { roleAuthClaim: authState, apiAccessToken, groupName } = this.payload;
    const { globalContactListId, clientProfile, group, successPayload, requestBody } = this.state;

    const query: FetchObjectsRequestData = { authState, dataObjects: { ids: [globalContactListId] } };
    const { dataObjectList } = await this.fetchObjects(apiAccessToken, query);

    const contactListJson = this.findDataObject(dataObjectList, { id: globalContactListId });
    if (Utils.isNil(contactListJson)) throw new Api.MalformedResponseException('Global contact list could not be fetched.');
    const contactListObject = new DataObjectSigmailGlobalContactList(contactListJson);

    const applyUpdate = async (contactListObject: IDataObject<DataObjectSigmailGlobalContactListValue>) => {
      const contactList = await contactListObject.decryptedValue();
      const institute = Utils.pick(clientProfile, INSTITUTE_INFO_KEY_LIST);

      const updatedValue: DataObjectSigmailGlobalContactListValue = {
        ...contactList,
        list: contactList.list.concat({ ...group, groupData: { groupName, institute } })
      };

      return contactListObject.updateValue(updatedValue);
    };

    const contactListObjectKey = contactListObject[Constants.$$CryptographicKey];
    const dataUpdater: Api.DataUpdater<IDataObject<any>> = async (contactListJson, { dataObjects }) => {
      let index = dataObjects!.findIndex((entry) => entry.operation === 'update' && entry.data.id === contactListJson.id);
      if (index === -1) {
        throw new AppException(Constants.Error.S_ERROR, 'Unexpected error; global contact list could not be found in request body.');
      }

      const key = contactListObjectKey === null ? null : contactListObjectKey.toApiFormatted();
      const contactListObject = new DataObjectSigmailGlobalContactList({ ...contactListJson, key });

      const updatedObject = await applyUpdate(contactListObject);
      dataObjects![index].data = updatedObject;

      index = successPayload.response.dataObjects!.findIndex(({ id }) => id === contactListJson.id);
      if (index !== -1) successPayload.response.dataObjects![index] = updatedObject.toApiFormatted();
    };

    const updatedObject = await applyUpdate(contactListObject);
    requestBody.update(updatedObject, dataUpdater);

    successPayload.request.dataObjects!.ids.push(globalContactListId);
    successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
  }
}

export const createCircleOfCareGroupAction = (payload: Payload): AppThunk<Promise<string>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'createCircleOfCareGroupAction:');

    const action = new CreateCircleOfCareGroupAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
