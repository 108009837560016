import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { createAction, PayloadAction } from '@reduxjs/toolkit';
import React from 'react';

export interface LayoutEventHandler<TEventArgs = any> {
  (event: TEventArgs): any;
}

export interface BreakpointChangeEvent {
  prevValue: Breakpoint;
  newValue: Breakpoint;
}

export interface BreakpointChangeEventHandler extends LayoutEventHandler<BreakpointChangeEvent> {}

export interface ContextValue {
  /** Determines if Invite Guest dialog is open. */
  isInviteGuestModalOpen: boolean;
  breakpoint?: Breakpoint;
  readonly isScreenSmallAndUp: boolean;
  readonly isScreenMediumAndUp: boolean;

  dispatch(action: PayloadAction<any>): void;

  on(eventType: 'breakpointchange', handler: BreakpointChangeEventHandler): void;
  on(eventType: string, handler: LayoutEventHandler): void;

  off(eventType: 'breakpointchange', handler: BreakpointChangeEventHandler): void;
  off(eventType: string, handler: LayoutEventHandler): void;
}

export const openInviteGuestModal = createAction('layout/openInviteGuestModal');
export const closeInviteGuestModal = createAction('layout/closeInviteGuestModal');

export const Context = React.createContext<ContextValue>({
  isInviteGuestModalOpen: false,
  breakpoint: undefined,
  isScreenSmallAndUp: false,
  isScreenMediumAndUp: false,
  dispatch() {},
  on() {},
  off() {}
});
