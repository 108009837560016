import { MessageFormName, Utils } from '@sigmail/common';
import { DataObjectMsgBodyValue as DV } from '@sigmail/objects';
import { DataObject } from '.';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_BODY;

export class DataObjectMsgBody extends DataObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    let messageForm: MessageFormName | null = null;
    if (Utils.isString(value.data)) {
      if (Utils.isUndefined(value.messageForm)) {
        messageForm = 'default';
      }
    } else if (Utils.isUndefined(value.data)) {
      if (Utils.isNonArrayObjectLike(value.messageForm)) {
        messageForm = value.messageForm.name;
      }
    }

    return messageForm === 'default' || messageForm === 'referral';
  }

  /** @override */
  public static isValidVersion(value: any): value is number {
    return value === 0;
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgBody> {
    const Class = this.constructor as typeof DataObjectMsgBody;
    return Class.updateValue(this, newValue);
  }
}
