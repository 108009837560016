import sanitizeHtml, { IOptions } from 'sanitize-html';

export const DEFAULT_ACCESS_CODE_CLIENT_REGISTRATION = 'WELCOME';
export const DEFAULT_ACCESS_CODE_MEMBER_REGISTRATION = 'WELCOME';
export const DEFAULT_ACCESS_CODE_PASSWORD_RESET = 'PWD_RESET';
export const DEFAULT_ACCESS_CODE_PASSWORD_RESET_TOKEN = 'RESET_PWD';
export const DEFAULT_PASSWORD_MFA_LOGIN_CREDENTIAL = 'MFA_LOGIN_AUTH';
export const GROUP_MESSAGE_FOLDER_PREFIX = '$group_';
export const USER_DEFINED_MESSAGE_FOLDER_PREFIX = '$u_';
export const DEFAULT_HEALTH_PLAN_JURISDICTION = 'CAN$ON';

export const DEFAULT_GROUP_NAME = 'Clinic';
export const MAX_MESSAGE_FOLDER_ITEM_COUNT = process.env.REACT_APP_ENV === 'local' ? 10 : 1000;

export const SANITIZER_OPTIONS: IOptions = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat('u', 'sub', 'sup', 'span', 'hr'),
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    '*': ['style'],
    a: ['href', 'rel', 'name', 'target']
  },
  allowedClasses: {
    p: [
      'ql-align-center',
      'ql-align-right',
      'ql-align-justify',
      'ql-indent-1',
      'ql-indent-2',
      'ql-indent-3',
      'ql-indent-4',
      'ql-indent-5',
      'ql-indent-6',
      'ql-indent-7',
      'ql-indent-8'
    ]
  },
  allowedStyles: {
    '*': {
      // Match HEX and RGB
      'background-color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
      // Match HEX and RGB
      color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
      'text-align': [/^left$/, /^right$/, /^center$/],
      // Match any number with px, em, or %
      'font-size': [/^\d+(?:px|em|%)$/]
    }
  }
};
