import bcrypt from 'bcryptjs';
import { uint8ArrayToHex } from './uint8-array-to-hex';

const ROUNDS = 10; // IMPORTANT must be between 10 and 31
const PREFIX = ['$2a$', ROUNDS < 10 ? '0' : '', ROUNDS, '$'].join('');

/**
 * Generates a salt value.
 *
 * @param encoder Valid values are "hex" and "base64". Default is "hex".
 */
export function generateSalt(encoder: 'hex' | 'base64' = 'hex'): string {
  const enc = encoder || 'hex';
  if (enc !== 'hex' && enc !== 'base64') {
    throw new TypeError("Argument <encoder> must be either 'hex' or 'base64'.");
  }

  const b64Salt = bcrypt.genSaltSync(ROUNDS); // generates a 128-bit salt value
  const nonPrefixedB64Salt = b64Salt.substring(PREFIX.length);
  if (enc === 'base64') {
    return nonPrefixedB64Salt;
  }

  const byteArray = bcrypt.decodeBase64(nonPrefixedB64Salt, 16);
  return uint8ArrayToHex(new Uint8Array(byteArray));
}
