import { Utils } from '@sigmail/common';
import { WithT } from 'i18next';
import React from 'react';
import { Action, ActionLabel, RouterAction } from 'sigmail';
import navStyle from './nav.module.css';
import { RouterNavLink, RouterNavLinkProps } from './router-nav-link.component';

export interface NavAction extends ActionLabel, Omit<RouterNavLinkProps, 'to' | 'href'> {
  to?: RouterAction['to'];
  href?: Action['href'];
  render?: (props: { action: NavAction }) => React.ReactChild;
  renderLabel?: (actionId: string, label: ActionLabel['label']) => React.ReactChild;
  navItemClassName?: string;
  navLinkClassName?: string;
  image?: React.ImgHTMLAttributes<HTMLImageElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export interface Props extends WithT {
  action: { [key: string]: NavAction };
  className?: string;
  children?: void;
}

export const ActionNavList: React.FC<Props> = (props) => {
  const actionList = Object.keys(props.action).map((actionId) => {
    let {
      to,
      href,
      label,
      render,
      renderLabel,
      navItemClassName,
      navLinkClassName,
      children,
      ...action
    } = props.action[actionId];

    if (typeof render === 'function') {
      return render({ action: props.action[actionId] });
    }

    navItemClassName = [navItemClassName, navStyle.item].find(Boolean);
    navLinkClassName = [navLinkClassName, navStyle.link].find(Boolean);

    const linkProps: RouterNavLinkProps = {
      ...action,
      className: navLinkClassName,
      to: props.t(Utils.isString(to) ? to : typeof to === 'function' ? to(actionId) : ''),
      href: Utils.isString(href) ? props.t(href) : typeof href === 'function' ? props.t(href(actionId)) : undefined
    };

    return (
      <li key={actionId} className={navItemClassName}>
        <RouterNavLink {...linkProps} data-action-id={actionId}>
          {typeof renderLabel === 'function'
            ? renderLabel(actionId, label)
            : props.t(Utils.isString(label) ? label : label(actionId))}
        </RouterNavLink>
        {children}
      </li>
    );
  });

  const className = [props.className, navStyle.nav].find(Boolean);
  return <ul className={className}>{actionList}</ul>;
};
