import {
  AlgorithmCode,
  EncryptAsymmetricKeyAlgorithmCode,
  EncryptEncapsulatedKeyAlgorithmCode,
  EncryptObjectAlgorithmCode,
  EncryptWithParametersAlgorithmCode,
  NonEncryptedObjectAlgorithmCode
} from '@sigmail/common';
import {
  AlgorithmLike,
  EncryptAsymmetricKeyAlgorithm,
  EncryptEncapsulatedKeyAlgorithm,
  EncryptObjectAlgorithm,
  EncryptWithParametersAlgorithm
} from '@sigmail/crypto';
import { E_UNEXPECTED_ALGORITHM, E_UNKNOWN_ALGORITHM_CODE } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';
import { EncryptAsymmetricKeyAlgorithmImpl } from './encrypt-asymmetric-key';
import { EncryptEncapsulatedKeyAlgorithmImpl } from './encrypt-encapsulated-key';
import { EncryptObjectAlgorithmImpl } from './encrypt-object-algorithm';
import { EncryptWithParametersAlgorithmImpl } from './encrypt-with-parameters';
import { NonEncryptedObjectAlgorithmImpl } from './non-encrypted-object-algorithm';

type AlgorithmClassImplTuple = [new (...args: any) => AlgorithmLike, AlgorithmLike];

/** A map of known algorithm codes to their base class and implementation. */
const AlgorithmMap: ReadonlyMap<AlgorithmCode, AlgorithmClassImplTuple> = new Map<
  AlgorithmCode,
  AlgorithmClassImplTuple
>([
  [
    process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT,
    [NonEncryptedObjectAlgorithmImpl, new NonEncryptedObjectAlgorithmImpl()]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC,
    [EncryptAsymmetricKeyAlgorithmImpl, new EncryptAsymmetricKeyAlgorithmImpl()]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS,
    [EncryptWithParametersAlgorithmImpl, new EncryptWithParametersAlgorithmImpl('EncryptWithParametersAlgorithmImpl')]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE,
    [EncryptAsymmetricKeyAlgorithmImpl, new EncryptAsymmetricKeyAlgorithmImpl()]
  ],
  [
    process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY,
    [EncryptEncapsulatedKeyAlgorithmImpl, new EncryptEncapsulatedKeyAlgorithmImpl()]
  ],
  [process.env.ALGORITHM_CODE_ENCRYPT_OBJECT, [EncryptObjectAlgorithmImpl, new EncryptObjectAlgorithmImpl()]]
]);

export function getAlgorithm(code: EncryptAsymmetricKeyAlgorithmCode): EncryptAsymmetricKeyAlgorithm;

export function getAlgorithm(code: EncryptWithParametersAlgorithmCode): EncryptWithParametersAlgorithm;

export function getAlgorithm(code: EncryptEncapsulatedKeyAlgorithmCode): EncryptEncapsulatedKeyAlgorithm;

export function getAlgorithm(
  code: NonEncryptedObjectAlgorithmCode | EncryptObjectAlgorithmCode
): EncryptObjectAlgorithm;

export function getAlgorithm(code: AlgorithmCode) {
  if (!AlgorithmMap.has(code)) {
    throw new SigmailCryptoException(E_UNKNOWN_ALGORITHM_CODE);
  }

  const [AlgorithmClass, algorithm] = AlgorithmMap.get(code)!;
  if (!(algorithm instanceof AlgorithmClass)) {
    throw new SigmailCryptoException(E_UNEXPECTED_ALGORITHM);
  }

  return algorithm;
}
