import { Action, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkExtraArg } from '@sigmail/app-state';
import { Api } from '@sigmail/services';
import { ThunkAction as ReduxThunkAction } from 'redux-thunk';
import { INITIAL_STATE as INITIAL_STORE_STATE_AUTH } from './auth-slice';
import { INITIAL_STATE as INITIAL_STORE_STATE_DATA_OBJECTS } from './data-objects-slice';
import { rootReducer, RootState } from './root-reducer';
import { INITIAL_STATE as INITIAL_STORE_STATE_USER_OBJECTS } from './user-objects-slice';

declare const module: any;

const apiService = new Api.ApiService(
  {
    coreApi: process.env.API_BASE_URL,
    emailApi: process.env.EMAIL_API_BASE_URL,
    authyApi: process.env.EMAIL_API_BASE_URL
  },
  {
    emailApi: process.env.EMAIL_API_KEY,
    authyApi: process.env.EMAIL_API_KEY
  }
);

export const getInitialRootState = (): RootState => ({
  auth: INITIAL_STORE_STATE_AUTH,
  dataObjects: INITIAL_STORE_STATE_DATA_OBJECTS,
  userObjects: INITIAL_STORE_STATE_USER_OBJECTS
});

export const store = configureStore({
  devTools: process.env.REACT_APP_ENV !== 'production',
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    thunk: {
      extraArgument: {
        apiService
      }
    },
    serializableCheck: false,
    immutableCheck: false
  })
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./root-reducer', () => {
    const newRootReducer = require('./root-reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk<R> = ReduxThunkAction<R, RootState, ThunkExtraArg, Action<string>>;
