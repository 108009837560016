import { Http } from '@sigmail/services';
import { BaseHttpService, logHttpError, logHttpRequest } from './base-http-service';
import { ServiceException as HttpServiceException } from './service-exception';

export class HttpService extends BaseHttpService {
  public get(url: string): Promise<Response>;
  public get(url: string, init: Http.RequestInit): Promise<Response>;
  public get<T = any>(url: string, init: Http.RequestInitWithResponseParser<T>): Promise<T>;
  public async get(url: string, init?: Http.RequestInit) {
    const { responseParser, ...requestInit } = init as Http.RequestInitWithResponseParser;
    const initParams: RequestInit = { ...requestInit, method: 'GET' };
    logHttpRequest('get:', url, initParams);

    let response: Response | undefined = undefined;
    try {
      response = await fetch(url, initParams);
    } catch (error) {
      logHttpError(error);
      throw new HttpServiceException(Response.error());
    }

    if (typeof responseParser === 'function') {
      return await responseParser(response);
    }

    return response;
  }

  public post(url: string, body: string): Promise<Response>;
  public post(url: string, body: string, init: Http.RequestInit): Promise<Response>;
  public post<T = any>(url: string, body: string, init: Http.RequestInitWithResponseParser<T>): Promise<T>;
  public async post(url: string, body: string, init?: Http.RequestInit) {
    const { responseParser, ...requestInit } = init as Http.RequestInitWithResponseParser;
    const initParams: RequestInit = { ...requestInit, method: 'POST', body };
    logHttpRequest('post:', url, initParams);

    let response: Response | undefined = undefined;
    try {
      response = await fetch(url, initParams);
    } catch (error) {
      logHttpError(error);
      throw new HttpServiceException(Response.error());
    }

    if (typeof responseParser === 'function') {
      return await responseParser(response);
    }

    return response;
  }

  public async delete(url: string): Promise<Response>;
  public async delete(url: string, init: Http.RequestInit): Promise<Response>;
  public async delete<T = any>(url: string, init: Http.RequestInitWithResponseParser<T>): Promise<T>;
  public async delete(url: string, init?: Http.RequestInit) {
    const { responseParser, ...requestInit } = init as Http.RequestInitWithResponseParser;
    const initParams: RequestInit = { ...requestInit, method: 'DELETE' };
    logHttpRequest('delete:', url, initParams);

    let response: Response | undefined = undefined;
    try {
      response = await fetch(url, initParams);
    } catch (error) {
      logHttpError(error);
      throw new HttpServiceException(Response.error());
    }

    if (typeof responseParser === 'function') {
      return await responseParser(response);
    }

    return response;
  }
}
