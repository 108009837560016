const A_TO_Z = /[A-Za-z]/;
const O_TO_9 = /[0-9]/;

// prettier-ignore
export const OhipBillingNumber: Array<string | RegExp> = [
  /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/, /[A-Za-z0-9]/,
  '-',
  O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9,
  '-',
  O_TO_9, O_TO_9
];

export const HealthPlanNumber: Readonly<Record<string, Readonly<Record<string, Array<string | RegExp> | false>>>> = {
  CAN: {
    AB: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9],
    BC: [/9/, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    MB: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    NB: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    NL: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    NS: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    NT: [/[DdMmNnTt]/, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    NU: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    ON: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', A_TO_Z, A_TO_Z],
    PE: [O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    QC: [A_TO_Z, A_TO_Z, A_TO_Z, A_TO_Z, '-', O_TO_9, O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, O_TO_9],
    SK: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9],
    YT: [O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9]
  }
};

export const PostalCode: Array<string | RegExp> = [A_TO_Z, O_TO_9, A_TO_Z, ' ', O_TO_9, A_TO_Z, O_TO_9];

// prettier-ignore
export const PhoneNumber: Array<string | RegExp> = [
  '(', /[1-9]/, O_TO_9, O_TO_9, ')', ' ', O_TO_9, O_TO_9, O_TO_9, '-', O_TO_9, O_TO_9, O_TO_9, O_TO_9
];

export const FaxNumber = PhoneNumber;
