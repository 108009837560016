import { AppException, Constants, Utils } from '@sigmail/common';

export class MessagingException extends AppException {
  public constructor();
  public constructor(errorCode: number);
  public constructor(message: string);
  public constructor(errorCode: number, message: string);

  public constructor(...args: any[]) {
    const errorCode = (args.length >= 1 && Utils.isFinite(args[0]) && args[0]) || Constants.Error.E_MESSAGING_FAIL;
    const message = args.length >= 1 && ((args.length === 1 && args[0]) || args[1]);

    super(errorCode, Utils.isString(message) ? message : undefined);

    this.name = 'MessagingException';
  }
}
