import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { CryptographicKey } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_BATCH_QUERY_DATA } from './constants';
import { MalformedResponseException } from './malformed-response-exception';

export async function apiBatchQueryData(this: Api.Service, accessToken: string, query: Api.BatchQueryRequestData) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiBatchQueryData:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(URL_BATCH_QUERY_DATA);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    let { encryptedFor } = query;
    if (Utils.isUndefined(encryptedFor)) {
      const ids = Array.from(CryptographicKey.encryptedForIds());
      if (ids.length > 0) {
        encryptedFor = { ids };
      }
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.BatchQueryResponseData>(
      requestUrl,
      JSON.stringify({ ...query, encryptedFor }),
      {
        headers: requestHeaders,
        cache: 'no-store',

        async responseParser(response) {
          if (response.status === 200) {
            const responseJson = await Utils.tryGetResponseJson<Api.BatchQueryResponseData>(response, undefined);
            if (Utils.isNotNil(responseJson) && Utils.isPlainObject(responseJson)) {
              const { serverAtUtc, ...responseBody } = responseJson as any;
              return { ...responseBody, serverDateTime: serverAtUtc };
            }
            Logger.warn('Operation failed. (Malformed/unexpected response data)');
            throw new MalformedResponseException(response);
          }

          Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
          throw new HttpServiceException(response);
        }
      }
    );
  } finally {
    Logger.info('== END ==');
  }
}
