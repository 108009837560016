(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@sigmail/common"), require("@sigmail/crypto"));
	else if(typeof define === 'function' && define.amd)
		define("SigmailObjects", ["@sigmail/common", "@sigmail/crypto"], factory);
	else if(typeof exports === 'object')
		exports["SigmailObjects"] = factory(require("@sigmail/common"), require("@sigmail/crypto"));
	else
		root["SigmailObjects"] = factory(root["@sigmail/common"], root["@sigmail/crypto"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__774__, __WEBPACK_EXTERNAL_MODULE__359__) {
return 