import { Utils } from '@sigmail/common';
import { DataObjectSigmailGlobalContactListValue as DV } from '@sigmail/objects';
import { DataObject } from '.';

const TYPE = process.env.DATA_OBJECT_TYPE_SIGMAIL_GLOBAL_CONTACT_LIST;

export class DataObjectSigmailGlobalContactList extends DataObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isArray(value.list);
  }

  public updateValue(newValue: DV): Promise<DataObjectSigmailGlobalContactList> {
    const Class = this.constructor as typeof DataObjectSigmailGlobalContactList;
    return Class.updateValue(this, newValue);
  }
}
