export * from './algorithm';
export * from './algorithm/get-algorithm';
export * as Encoder from './encoder';
export * from './encryptor';
export * as Hash from './hash';
export * as RNG from './rng';
export * from './SigmailCryptoException';
import * as ErrorCode from './constants';

export const Constants = {
  Error: {
    ...ErrorCode
  }
};
