import { Utils } from '@sigmail/common';
import { ServerParamsSecurityQuestion, SharedParamsSecurityQuestion } from '@sigmail/objects';
import { UserCredentials } from '.';

const TYPE = process.env.USER_CREDENTIALS_TYPE_SECURITY_QUESTION;

const SHARED_PARAMS_PROPS: ReadonlyArray<keyof SharedParamsSecurityQuestion> = ['salt'];
const SERVER_PARAMS_PROPS: ReadonlyArray<keyof ServerParamsSecurityQuestion> = ['verifier'];

export class UserCredentialsSecurityQuestion extends UserCredentials<
  SharedParamsSecurityQuestion,
  ServerParamsSecurityQuestion
> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  public static isValidSharedParameters(value: any): value is SharedParamsSecurityQuestion {
    if (!super.isValidSharedParameters(value)) return false;

    return Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, value)) && Utils.isString(value.salt);
  }

  /** @override */
  public static isValidServerParameters(value: any): value is ServerParamsSecurityQuestion {
    if (!super.isValidServerParameters(value)) return false;

    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, value)) && Utils.isString(value.verifier);
  }
}
