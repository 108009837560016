import { SrpSession } from '@sigmail/common';
import SRP from 'secure-remote-password/client';

export function srpVerifySession(
  clientPublicEphemeral: string,
  clientSession: SrpSession,
  serverSessionProof: string
): void {
  return SRP.verifySession(clientPublicEphemeral, clientSession, serverSessionProof);
}
