import { Utils } from '@sigmail/common';
import { ServerParamsPrimaryUserLogin, SharedParamsPrimaryUserLogin } from '@sigmail/objects';
import { UserCredentials } from '.';

const TYPE = process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN;

const SHARED_PARAMS_PROPS: ReadonlyArray<keyof SharedParamsPrimaryUserLogin> = ['salt'];
const SERVER_PARAMS_PROPS: ReadonlyArray<keyof ServerParamsPrimaryUserLogin> = ['verifier'];

export class UserCredentialsPrimaryUserLogin extends UserCredentials<
  SharedParamsPrimaryUserLogin,
  ServerParamsPrimaryUserLogin
> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  public static isValidSharedParameters(value: any): value is SharedParamsPrimaryUserLogin {
    if (!super.isValidSharedParameters(value)) return false;

    return Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, value)) && Utils.isString(value.salt);
  }

  /** @override */
  public static isValidServerParameters(value: any): value is ServerParamsPrimaryUserLogin {
    if (!super.isValidServerParameters(value)) return false;

    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, value)) && Utils.isString(value.verifier);
  }
}
