import { Alert } from '@material-ui/lab';
import { Utils } from '@sigmail/common';
import { getLogger } from '@sigmail/logging';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppDispatch } from '../../app-state';
import { srpExchangeCredentialAction } from '../../app-state/actions/SRP/srp-exchange-credential-action';
import { RootState } from '../../app-state/root-reducer';
import * as AuthSelectors from '../../app-state/selectors/auth';
import {
  ROUTE_ACCOUNT_SETUP,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_REGISTER,
  ROUTE_SIGN_IN
} from '../../constants/route-identifiers';
import { withTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import { generateCredentialHash } from '../../utils/generate-credential-hash';
import { getRoutePath } from '../routes';
import { CircularProgress } from '../shared/circular-progress.component';
import style from './public-home.module.css';

/** An instance of the logging module. */
const Logger = getLogger('PublicHomeComponent');

/**
 * Retrieves and returns data needed from the store which will be merged into
 * the PublicHome component's properties.
 */
const mapStateToProps = (state: RootState) => ({
  isUserLoggedIn: AuthSelectors.isUserLoggedInSelector(state)
});

/**
 * Returns a list of action dispatcher(s) to be injected in PublicHome
 * component's properties.
 */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  async dispatchSrpExchangeCredential(credentialHash: string) {
    let shared: string = '{}';
    try {
      shared = (await dispatch(srpExchangeCredentialAction({ credentialHash }))).shared;
      try {
        const sharedParameters = JSON.parse(shared);
        if (Utils.isPlainObject(sharedParameters) && Utils.isString(sharedParameters.response)) {
          Logger.debug('sharedParameters.response =', sharedParameters.response);
          return sharedParameters.response as string;
        }
      } catch (error) {
        Logger.warn('Error parsing shared parameters:', error);
        /* ignore */
      }
    } catch (error) {
      Logger.warn('SRP exchange failed:', error);
      /* ignore */
    }
    return '';
  }
});

/** HOC to create a PublicHomeComponent which is connected to the store. */
const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ConnectedProps = ReduxConnectedProps<typeof withConnect>;

/** PublicHome component properties. */
export interface Props extends RouteComponentProps, WithTranslation, ConnectedProps {}

/** Type definition for the {@link PublicHomeComponent#state} object. */
interface State {
  alertTitle?: string;
  alertBody?: string;
}

class PublicHomeComponent extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public async componentDidMount(): Promise<void> {
    let token = '';
    if (window.location.search.length > 7 /* '?token='.length */ && window.location.search.startsWith('?')) {
      const params = window.location.search.slice(1).split('&');
      for (const param of params) {
        const pair = param.split('=');
        if (pair.length === 2 && pair[0].toLowerCase() === 'token') {
          token = pair[1];
          break;
        }
      }
    }

    if (token.length > 0) {
      Logger.debug('token =', token);
      const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN, { token });
      const response = await this.props.dispatchSrpExchangeCredential(credentialHash);
      if (response === 'clientInvitation') {
        this.props.history.replace(getRoutePath(ROUTE_REGISTER), { token });
      } else if (response === 'clientRegistration' || response === 'userRegistration') {
        this.props.history.replace(getRoutePath(ROUTE_ACCOUNT_SETUP), { token });
      } else if (response === 'passwordReset') {
        this.props.history.replace(getRoutePath(ROUTE_FORGOT_PASSWORD), { token });
      } else {
        this.setState({
          alertTitle: this.props.t(globalI18n.invalidEmailLinkAlert.title),
          alertBody: this.props.t(globalI18n.invalidEmailLinkAlert.body)
        });
      }
      return;
    }

    if (!this.props.isUserLoggedIn) {
      this.props.history.replace(getRoutePath(ROUTE_SIGN_IN));
    }
  }

  public render(): React.ReactNode {
    if (Utils.isString(this.state.alertTitle) && Utils.isString(this.state.alertBody)) {
      return (
        <div className={style.page}>
          <div className="container-fluid">
            <div className="row">
              <div styleName="alert">
                <h1 styleName="style.title">{this.state.alertTitle}</h1>
                <Alert styleName="style.body" severity="error">
                  <div dangerouslySetInnerHTML={{ __html: this.state.alertBody }} />
                </Alert>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return <CircularProgress />;
  }
}

export const PublicHome = withConnect(withTranslation(I18N_NS_GLOBAL)(withRouter(PublicHomeComponent)));
PublicHome.displayName = 'PublicHome';
