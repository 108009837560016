// @ts-nocheck
import { ActionPayloadBatchQueryDataSuccess } from '@sigmail/app-state';
import { Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ClientObjectContactList,
  ClientObjectContactListValue,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue,
  IDataObject,
  IUserObject,
  UserContactListItem,
  UserObjectPreferences,
  UserObjectPreferencesValue,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';

export interface Payload {
  accessToken: string;
  userId: number;
  clientId: number;
  auditId: number;
  globalContactListId: number;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

interface State {
  dtServer: Date;
  basicProfileObject: IUserObject<UserObjectProfileBasicValue>;
  basicProfile: UserObjectProfileBasicValue;
  requestBody: BatchUpdateRequestBuilder;
  clientContactListObject: IUserObject<ClientObjectContactListValue>;
  globalContactListObject: IDataObject<DataObjectSigmailGlobalContactListValue>;
}

class MigrationCreatePreferencesObject extends BaseAction<Payload, State> {
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchBasicProfile();

        const { $$formatver } = this.state.basicProfile;
        if (!Utils.isNil($$formatver) && $$formatver > 2) {
          this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
          return;
        }

        await this.generateRequestBody();

        const { accessToken, successPayload } = this.payload;
        const { requestBody, basicProfileObject, clientContactListObject, globalContactListObject } = this.state;
        await this.batchUpdateData(accessToken, requestBody.build());

        // manually patch successPayload's data with updated objects
        do {
          const userObjectList = [basicProfileObject, clientContactListObject];
          for (const obj of userObjectList) {
            const index = this.findUserObjectIndex(successPayload.response.userObjectsByType!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              successPayload.response.userObjectsByType![index] = obj.toApiFormatted();
            }
          }

          const dataObjectList = [globalContactListObject];
          for (const obj of dataObjectList) {
            const index = this.findDataObjectIndex(successPayload.response.dataObjects!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              successPayload.response.dataObjects![index] = obj.toApiFormatted();
            }
          }
        } while (false);

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchBasicProfile(): Promise<void> {
    this.logger.info('Fetching the latest basic profile object.');

    const { userId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [{ userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC }]
    };

    const { userObjectList, serverDateTime } = await this.fetchObjects(accessToken, query);

    const basicProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId });
    if (Utils.isNil(basicProfileJson)) {
      throw new Api.MalformedResponseException('Basic profile object could not be fetched.');
    }

    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.basicProfileObject = new UserObjectProfileBasic(basicProfileJson);
    this.state.basicProfile = await this.state.basicProfileObject.decryptedValue();
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    await this.addInsertOperationForPreferencesObject(); // 408
    await this.addUpdateOperationForBasicProfile(); // 401

    const { role: roleId, noNotifyOnNewMessage } = this.state.basicProfile as any;
    if (typeof noNotifyOnNewMessage === 'boolean') {
      if (Utils.MedicalInstitute.isNonGuestRole(roleId)) {
        await this.fetchClientContactList();
        await this.addUpdateOperationForClientContactList(); // 466

        if (Utils.MedicalInstitute.isPhysicianRole(roleId)) {
          await this.fetchGlobalContactList();
          await this.addUpdateOperationForGlobalContactList(); // 360
        }
      } else {
        // XXX
        // the team decided to update the group guest list as a separate
        // migration sometime later when a well thought out solution is
        // available
        // XXX
      }
    }
  }

  private async addInsertOperationForPreferencesObject(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for preferences object.');

    const { accessToken, userId, clientId, auditId, successPayload } = this.payload;
    const { basicProfile, dtServer, requestBody } = this.state;

    const [id] = await this.fetchIds(accessToken, /* count := */ 1);
    let data: UserObjectPreferencesValue = { $$formatver: 1 };
    const { noNotifyOnNewMessage } = basicProfile as any;
    if (typeof noNotifyOnNewMessage === 'boolean') {
      data = { ...data, noNotifyOnNewMessage };
    }
    this.logger.debug({ id, ...data });

    const preferencesObject = await UserObjectPreferences.create(id, undefined, 1, data, userId, userId, dtServer);
    const keyList = await preferencesObject.generateKeysEncryptedFor(clientId, auditId);
    keyList.push(preferencesObject[Constants.$$CryptographicKey]);
    requestBody.insert(preferencesObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.userObjectsByType!.push({ userId, type: process.env.USER_OBJECT_TYPE_PREFERENCES });
    successPayload.response.userObjectsByType!.push(preferencesObject.toApiFormatted());
  }

  private async addUpdateOperationForBasicProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for basic profile.');

    const { basicProfile, basicProfileObject, requestBody } = this.state;

    const updatedValue = { ...basicProfile, $$formatver: 3, noNotifyOnNewMessage: undefined };
    const updatedObject = await basicProfileObject.updateValue(updatedValue as UserObjectProfileBasicValue);
    requestBody.update(updatedObject);

    this.state.basicProfileObject = updatedObject;
  }

  private async fetchClientContactList(): Promise<void> {
    this.logger.info('Fetching the latest client contact list object.');

    const { clientId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [{ userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST }]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const contactListJson = this.findUserObject(userObjectList, { type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST, userId: clientId });
    if (Utils.isNil(contactListJson)) throw new Api.MalformedResponseException('Client contact list object could not be fetched.');

    const contactListObject = new ClientObjectContactList(contactListJson);
    this.state.clientContactListObject = contactListObject;
  }

  private async addUpdateOperationForClientContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for client contact list.');

    const { basicProfile, clientContactListObject: contactListObject, requestBody } = this.state;

    const contactList = await contactListObject.decryptedValue();
    const index = contactList.list.findIndex(({ type, id }) => type === 'user' && id === this.payload.userId);
    if (index === -1) {
      this.logger.warn('Client contact list update skipped; entry matching the current user ID could not be found.');
      return;
    }

    const updatedList: Array<ClientObjectContactListValue['list'][0]> = [...contactList.list];
    const listItem = contactList.list[index] as UserContactListItem;
    updatedList[index] = {
      ...listItem,
      userData: {
        ...listItem.userData,
        noNotifyOnNewMessage: (basicProfile as any).noNotifyOnNewMessage
      }
    };
    const updatedValue: ClientObjectContactListValue = { ...contactList, list: updatedList };

    const updatedObject = await contactListObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.clientContactListObject = updatedObject;
  }

  private async fetchGlobalContactList(): Promise<void> {
    this.logger.info('Fetching the latest global contact list object.');

    const { globalContactListId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      dataObjects: { ids: [globalContactListId] },
      expectedCount: { dataObjects: null }
    };

    const { dataObjectList } = await this.fetchObjects(accessToken, query);

    const contactListJson = this.findDataObject(dataObjectList, { id: globalContactListId });
    if (Utils.isNil(contactListJson)) throw new Api.MalformedResponseException('Global contact list object could not be fetched.');

    const contactListObject = new DataObjectSigmailGlobalContactList(contactListJson);
    this.state.globalContactListObject = contactListObject;
  }

  private async addUpdateOperationForGlobalContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for global contact list.');

    const { globalContactListObject: contactListObject, basicProfile, requestBody } = this.state;

    const contactList = await contactListObject.decryptedValue();
    const index = contactList.list.findIndex(({ type, id }) => type === 'user' && id === this.payload.userId);
    if (index === -1) {
      this.logger.warn('Global contact list update skipped; entry matching the current user ID could not be found.');
      return;
    }

    const updatedList: Array<DataObjectSigmailGlobalContactListValue['list'][0]> = [...contactList.list];
    const listItem = contactList.list[index] as UserContactListItem;
    updatedList[index] = {
      ...listItem,
      userData: {
        ...listItem.userData,
        noNotifyOnNewMessage: (basicProfile as any).noNotifyOnNewMessage
      }
    };
    const updatedValue: DataObjectSigmailGlobalContactListValue = { ...contactList, list: updatedList };

    const updatedObject = await contactListObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.globalContactListObject = updatedObject;
  }
}

export const migration_createPreferencesObject = (payload: Payload): AppThunk<Promise<void>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'createPreferencesObject');

    const migration = new MigrationCreatePreferencesObject({ payload, apiService, logger: Logger });
    return await migration.execute();
  };
};
