import { Messaging } from '@sigmail/app-state';
import { AppException, AppUserGroup, Constants, Utils } from '@sigmail/common';
import { DataObjectMsgFolder, FolderItem, GroupMessageFolderKey, UserMessageFolderKey } from '@sigmail/objects';
import { MessagingException } from '../../../core/messaging-exception';
import * as GroupObjectSelectors from '../../selectors/group-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { GROUP_MESSAGE_FOLDER_PREFIX } from '../constants';

const MESSAGE_FOLDER_KEY_INBOX: Extract<Messaging.ParentMessageFolderKey, 'inbox'> = 'inbox';
const MESSAGE_FOLDER_KEY_GROUP_INBOX: Extract<Messaging.ParentMessageFolderKey, '$group_inbox'> = '$group_inbox';

// Unique collection of all top-level message folder keys
const MESSAGE_FOLDER_KEY_SET: ReadonlySet<string> = new Set<UserMessageFolderKey | GroupMessageFolderKey>([
  MESSAGE_FOLDER_KEY_INBOX,
  MESSAGE_FOLDER_KEY_GROUP_INBOX,
  'sent',
  'drafts'
]);

export abstract class BaseMessagingAction<
  P,
  S extends AuthenticatedActionState = AuthenticatedActionState,
  R = void
> extends AuthenticatedAction<P, S, R> {
  protected async findMessageFolder(folderKey: string, parentFolderKey?: Messaging.ParentMessageFolderKey): Promise<FolderItem> {
    this.logger.info('Locating message folder.', `(folderKey=${folderKey}, parentFolderKey=${String(parentFolderKey)})`);

    const isGroupFolderKey =
      folderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX) ||
      (Utils.isString(parentFolderKey) && parentFolderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX));

    const msgFolderMap = isGroupFolderKey
      ? GroupObjectSelectors.messageFolderMapSelector(this.getRootState())()
      : UserObjectSelectors.messageFolderMapSelector(this.getRootState())();

    let msgFolder: FolderItem | undefined;
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      msgFolder = undefined;

      if (MESSAGE_FOLDER_KEY_SET.has(folderKey)) {
        msgFolder = msgFolderMap[folderKey];
      } else if (parentFolderKey === MESSAGE_FOLDER_KEY_INBOX || parentFolderKey === MESSAGE_FOLDER_KEY_GROUP_INBOX) {
        const msgFolderChildMap = msgFolderMap[parentFolderKey]?.children;
        msgFolder = msgFolderChildMap?.[folderKey];
      }

      if (DataObjectMsgFolder.isValidId(msgFolder?.id)) {
        break;
      } else if (attempt === MAX_ATTEMPTS) {
        throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_ID);
      }

      await this.fetchMessageFolderList({ user: true, group: isGroupFolderKey });
    }

    return msgFolder!;
  }

  protected async fetchMessageFolderList(options?: { user?: boolean; group?: boolean }): Promise<void> {
    this.logger.info('Fetching the latest message folder list.');

    const {
      roleAuthClaim: authState,
      currentUser: { id: userId }
    } = this.state;

    const opts = Utils.defaults({}, options, { user: true, group: true });
    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [
        opts.group === true ? { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC } : undefined,
        opts.user === true ? { userId, type: process.env.USER_OBJECT_TYPE_FOLDER_LIST } : undefined
      ].filter(Utils.isNotNil)
    };

    if (query.userObjectsByType!.length === 0) {
      return Promise.resolve();
    }

    await this.dispatchFetchObjects(query);

    if (opts.group === true) {
      const circleOfCareGroup = UserObjectSelectors.circleOfCareGroupSelector(this.getRootState());
      if (!AppUserGroup.isValidId(circleOfCareGroup?.id)) {
        throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Circle of care group ID is either missing or invalid.');
      }

      await this.dispatchFetchObjects({
        authState,
        userObjectsByType: [{ userId: circleOfCareGroup!.id, type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST }]
      });
    }
  }
}
