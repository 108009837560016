import { Utils } from '@sigmail/common';
import { DataObjectMsgFolderItem } from '@sigmail/objects';

export function MessageFlags(msg: any) {
  let flags: Required<DataObjectMsgFolderItem>['flags'] = {};
  let isReferral = false;
  let hasAttachments = false;

  if (Utils.isNonArrayObjectLike(msg)) {
    const { messageForm, documentList, flags: msgFlags } = msg;

    isReferral = Utils.isNonArrayObjectLike(messageForm) && messageForm.name === 'referral';
    hasAttachments = Utils.isArray(documentList) && documentList.length > 0;

    if (Utils.isNonArrayObjectLike(msgFlags)) {
      flags = msg.flags;
    }
  }

  return {
    isReferral,
    hasAttachments,
    isMarkedAsRead: flags.markedAsRead === true,
    isRecalled: Utils.isFinite(flags.recalled),
    isImportant: flags.importance === 'high',
    isBillable: flags.billable === true,
    hasReminder: Utils.isFinite(flags.reminder),
    isLastSentToOscar: Utils.isFinite(flags.lastSentToOscar)
  };
}
