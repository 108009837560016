import { Utils } from '@sigmail/common';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import i18n from '../../i18n/global';
import { ActionNavList, NavAction } from '../shared/action-nav-list';
import style from './site-footer.module.css';

/** SiteFooter component properties. */
export interface Props extends WithTranslation {
  navAction?: { [key: string]: NavAction };
  navClassName?: string;
}

/**
 * A component that renders a copyright notice and a list of links including
 * links providing access to the legal documents such as Terms and Conditions,
 * Privacy Policy, etc.
 */
class SiteFooterComponent extends React.PureComponent<Props> {
  public render() {
    const { t, navAction, navClassName } = this.props;

    return (
      <footer className={style.footer}>
        <div className="container-fluid">
          <div className="row">
            {!Utils.isNil(navAction) && (
              <div styleName="style.col style.nav">
                <ActionNavList t={t} className={navClassName} action={navAction} />
              </div>
            )}
            <div styleName="style.col style.notice" dangerouslySetInnerHTML={{ __html: t(i18n.copyrightNotice) }} />
          </div>
        </div>
      </footer>
    );
  }
}

export const SiteFooter = withTranslation([I18N_NS_GLOBAL])(SiteFooterComponent);
SiteFooter.displayName = 'SiteFooter';
