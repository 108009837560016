import {
  ActionPayloadAuthSuccess,
  ActionPayloadSendMfaCode,
  ActionPayloadSignIn,
  ActionPayloadVerifyMfaCode
} from '@sigmail/app-state';
import { Constants, Utils } from '@sigmail/common';
import { getLogger } from '@sigmail/logging';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ActionLabel } from 'sigmail';
import { AppDispatch } from '../../app-state';
import { sendMfaCodeAction } from '../../app-state/actions/MFA/send-mfa-code-action';
import { verifyMfaCodeAction } from '../../app-state/actions/MFA/verify-mfa-code-action';
import { signInAction } from '../../app-state/auth-slice';
import { RootState } from '../../app-state/root-reducer';
import * as RootSelectors from '../../app-state/selectors';
import * as AuthSelectors from '../../app-state/selectors/auth';
import * as ActionContext from '../../constants/action-context';
import * as ActionId from '../../constants/action-ids';
import { ROUTE_FORGOT_PASSWORD } from '../../constants/route-identifiers';
import { withTranslation } from '../../i18n';
import { I18N_NS_LOGIN_FORM, I18N_NS_VERIFY_MFA_CODE } from '../../i18n/config/namespace-identifiers';
import globalI18n from '../../i18n/global';
import i18n from '../../i18n/login-form';
import verifyMfaCodeI18n from '../../i18n/verify-mfa-code';
import { focusFirstInvalidInputElement } from '../../utils/focus-first-invalid-input-element';
import { generateCredentialHash } from '../../utils/generate-credential-hash';
import { resolveActionLabel } from '../../utils/resolve-action-label';
import { getRoutePath } from '../routes';
import { ErrorMessageSnackbar } from '../shared/error-message-snackbar.component';
import sharedFormStyle from '../shared/forms.module.css';
import { RouterNavLink, RouterNavLinkProps } from '../shared/router-nav-link.component';
import { SigninCredentialsInput } from '../shared/signin-credentials-input/signin-credentials-input.component';
import * as Context from '../shared/signin-credentials-input/signin-credentials-input.context';
import { SubmitButton } from '../shared/submit-button.component';
import { VerifyMfaCode } from '../shared/verify-mfa-code/verify-mfa-code.component';
import * as VerifyMfaCodeContext from '../shared/verify-mfa-code/verify-mfa-code.context';
import style from './login-form.module.css';

/** An instance of the logging module. */
const Logger = getLogger('LoginFormComponent');

/**
 * Retrieves and returns data needed from the store which will be merged into
 * the LoginForm component's properties.
 */
const mapStateToProps = (state: RootState) => ({
  isUserLoggedIn: AuthSelectors.isUserLoggedInSelector(state),
  lastAuthErrorCode: AuthSelectors.lastAuthErrorCodeSelector(state),
  homeRoutePath: RootSelectors.homeRoutePathSelector(state)
});

/**
 * Returns a list of action dispatcher(s) to be injected in LoginForm
 * component's properties.
 */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  /** Dispatches the {@link signInAction}. */
  dispatchSignInAction(payload: FormData, beforeInitializeSession?: ActionPayloadSignIn['beforeInitializeSession']) {
    const username = payload.username.trim().toLowerCase();
    const credentialHash = generateCredentialHash(process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN, { username });
    return dispatch(signInAction({ username, password: payload.password, credentialHash, beforeInitializeSession }));
  },

  /** Dispatches the {@link sendMfaCodeAction}. */
  dispatchSendMfaCode(payload: ActionPayloadSendMfaCode) {
    return dispatch(sendMfaCodeAction(payload));
  },

  /** Dispatches the {@link verifyMfaCodeAction}. */
  dispatchVerifyMfaCode(payload: ActionPayloadVerifyMfaCode) {
    return dispatch(verifyMfaCodeAction(payload));
  }
});

/** HOC to create a LoginFormComponent which is connected to the store. */
const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ConnectedProps = ReduxConnectedProps<typeof withConnect>;

/** LoginForm component properties. */
export interface Props extends WithTranslation, ConnectedProps {}

/** Data user needs to provide to submit a login request. */
interface FormData {
  username: string;
  password: string;
}

/** Type definition for the {@link LoginFormComponent#state} object. */
interface State
  extends Context.ContextValue,
    Pick<VerifyMfaCodeContext.ContextValue, 'mfaAccountId' | 'mfaMethod' | 'mfaContact' | 'verificationCode'> {
  activeStep: 'signInCredentials' | 'verifyMfaCode';
  continueSignIn: () => void;
}

/**
 * A component to renders a form which allows the user to enter their
 * sign-in credentials to log into the application.
 */
class LoginFormComponent extends React.PureComponent<Props, State> {
  private readonly _formRef = React.createRef<HTMLFormElement>();

  public constructor(props: Props) {
    super(props);

    // initial state
    this.state = {
      ...Context.getDefaultValue(),
      ...VerifyMfaCodeContext.getDefaultValue(),
      dispatch: (action) =>
        this.setState((prevState) => {
          if (
            action.type === VerifyMfaCodeContext.updateFormData.type ||
            action.type === VerifyMfaCodeContext.updateInputErrorMessage.type ||
            action.type === VerifyMfaCodeContext.resetSubmitStatus.type
          ) {
            return { ...prevState, ...VerifyMfaCodeContext.rootReducer(prevState, action) };
          }
          return { ...prevState, ...Context.rootReducer(prevState, action) };
        }),
      activeStep: 'signInCredentials',
      continueSignIn: () => undefined
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
    this.resetSubmitStatus = this.resetSubmitStatus.bind(this);
    this.beforeInitializeSession = this.beforeInitializeSession.bind(this);
    this.sendMfaCode = this.sendMfaCode.bind(this);
  }

  /**
   * Handles the form submission by processing user input and then, dispatching
   * a sign-in action to the store. If sign-in succeeds, a change in the store
   * state will trigger a re-render which, in turn, will navigate the user to
   * the appropriate target URL. In case of a sign-in failure, this method
   * will update the state and set submitStatus to `SubmitFailure`.
   */
  public componentDidUpdate(_: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.submitInProgress) {
      if (prevState.submitStatus !== 'InProgress') {
        // Sign-in form submit
        if (this.state.activeStep === 'signInCredentials') {
          Logger.info('Form submitted; dispatching a sign-in action.');
          this.props
            .dispatchSignInAction(Utils.pick(this.state, ['username', 'password']), this.beforeInitializeSession)
            .then(
              () => {
                if (this.props.lastAuthErrorCode !== Constants.Error.S_OK) {
                  Logger.info('Form submission failed.', 'lastAuthErrorCode =', this.props.lastAuthErrorCode);
                  this.setState({ submitStatus: 'SubmitFailure' });
                } else {
                  Logger.info('Form submission succeeded.');
                }
              },
              (error) => {
                Logger.info('Form submission failed with an error.', error);
                this.setState({ submitStatus: 'SubmitFailure' });
              }
            );
        }

        // Verify MFA Code form submit
        if (this.state.activeStep === 'verifyMfaCode') {
          this.props
            .dispatchVerifyMfaCode({
              accountId: this.state.mfaAccountId,
              verificationCode: this.state.verificationCode
            })
            .then(this.state.continueSignIn)
            .catch(() => {
              this.setState((prevState) =>
                VerifyMfaCodeContext.rootReducer(
                  { ...prevState, submitStatus: 'ValidationErrors' },
                  VerifyMfaCodeContext.updateInputErrorMessage({
                    verificationCode: verifyMfaCodeI18n.formField.verificationCode.error?.badInput || ''
                  })
                )
              );
            });
        }
      }
      return;
    }

    if (prevState.activeStep === 'signInCredentials' && this.state.activeStep === 'verifyMfaCode') {
      this.sendMfaCode();
    }
  }

  /**
   * Renders the login form and associated actions. When `submitStatus ===
   * SubmitFailure`, an error alert to notify user of the failure is rendered
   * as well.
   */
  public render(): React.ReactNode {
    if (this.props.isUserLoggedIn) {
      Logger.info('User is logged-in; redirecting to ', this.props.homeRoutePath);
      return <Redirect to={this.props.homeRoutePath} />;
    }

    if (this.state.activeStep === 'signInCredentials') {
      return this.renderSignInForm();
    } else if (this.state.activeStep === 'verifyMfaCode') {
      return this.renderVerifyMfaCodeForm();
    }
  }

  private renderSignInForm(): React.ReactNode {
    const classNameForm = [style.form, this.formHasErrors && 'was-validated'].filter(Boolean).join(' ');
    const { submitAction } = i18n.formField;

    return (
      <form ref={this._formRef} className={classNameForm}>
        <div className="container-fluid">
          <div className="row">
            <ErrorMessageSnackbar
              message={this.props.t(i18n.errorMessageSubmitFailed)}
              open={this.state.submitStatus === 'SubmitFailure'}
              onClose={this.resetSubmitStatus}
              closeText={this.props.t(globalI18n.ariaLabelClosePopup)}
            />

            <div styleName="style.heading1" dangerouslySetInnerHTML={{ __html: this.props.t(i18n.heading1) }} />
            <div styleName="style.leadText" dangerouslySetInnerHTML={{ __html: this.props.t(i18n.leadText) }} />

            <Context.Context.Provider value={this.state}>
              <SigninCredentialsInput
                I18N={{ ...i18n, leadText: undefined }}
                styles={{ formInput: sharedFormStyle['form-input-group'] }}
              />
            </Context.Context.Provider>

            {this.renderSubmitAction(submitAction, ActionId.FormSubmit, ActionContext.AccountSignIn)}

            {this.renderForgotPasswordAction()}
          </div>
        </div>
      </form>
    );
  }

  private renderVerifyMfaCodeForm(): React.ReactNode {
    const classNameForm = [style.form, this.formHasErrors && 'was-validated'].filter(Boolean).join(' ');
    const { submitAction } = i18n.verifyMfaCode;

    return (
      <div className={classNameForm}>
        <div className="container-fluid">
          <div className="row">
            <div
              styleName="style.heading1"
              dangerouslySetInnerHTML={{ __html: this.props.t(i18n.verifyMfaCode.heading1) }}
            />
            <VerifyMfaCodeContext.Context.Provider value={this.state}>
              <VerifyMfaCode
                I18N={verifyMfaCodeI18n}
                styles={{
                  leadText: style.leadText,
                  formInput: sharedFormStyle['form-input-group'],
                  notReceivedCodeQuestion: style.notReceivedCodeQuestion,
                  resentCodeAction: style.resentCodeAction
                }}
                onResendCodeActionClick={this.sendMfaCode}
                onFormSubmit={this.onFormSubmit}
              />
            </VerifyMfaCodeContext.Context.Provider>

            {this.renderSubmitAction(submitAction, ActionId.FormSubmitVerifyMfaCode, ActionContext.AccountSignIn)}
          </div>
        </div>
      </div>
    );
  }

  /** Render a Form Submit action. */
  private renderSubmitAction(actionLabel: ActionLabel, actionId?: string, context?: any): React.ReactNode {
    return (
      <SubmitButton
        className={style['btn-submit']}
        disabled={this.submitInProgress}
        progress={this.submitInProgress}
        onClick={this.onFormSubmit}
      >
        {this.props.t(resolveActionLabel(actionLabel, actionId, context))}
      </SubmitButton>
    );
  }

  /** Renders a Forgot Password action. */
  private renderForgotPasswordAction(): React.ReactNode {
    const className = [style['action-forgot-password'], this.submitInProgress && 'disabled'];

    const { label } = i18n.action.forgotPassword;
    const actionLabel = this.props.t(Utils.isString(label) ? label : label('forgotPassword'));
    const actionProps: RouterNavLinkProps = {
      className: className.filter(Boolean).join(' '),
      to: getRoutePath(ROUTE_FORGOT_PASSWORD),
      onClick: this.onForgotPasswordClick
    };

    return <RouterNavLink {...actionProps}>{actionLabel}</RouterNavLink>;
  }

  /** Event handler invoked when Form Submit action is clicked. */
  private onFormSubmit(event: React.SyntheticEvent<HTMLElement>): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.submitInProgress) {
      Logger.info('onFormSubmit:', 'Call ignored; form submission in progress.');
      return;
    }

    if (focusFirstInvalidInputElement(this._formRef.current, /* scrollIntoView := */ true) !== null) {
      this.setState({ submitStatus: 'ValidationErrors' });
      return;
    }

    this.setState({ submitStatus: 'InProgress' });
  }

  /** Event handler invoked when Forgot Password action is clicked. */
  private onForgotPasswordClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    if (this.submitInProgress) {
      event.preventDefault();
      event.stopPropagation();
      Logger.info('onForgotPasswordClick:', 'Call ignored; form submission in progress.');
      return;
    }
  }

  private resetSubmitStatus(): void {
    this.setState({ submitStatus: 'NotSubmitted' });
  }

  private beforeInitializeSession(authData: ActionPayloadAuthSuccess): Promise<void> {
    const { mfaAccountId, mfaMethod, mfaContact } = authData;
    if (!Utils.isNil(mfaAccountId) && Utils.isString(mfaMethod) && Utils.isString(mfaContact)) {
      return new Promise((resolve) => {
        this.setState({
          activeStep: 'verifyMfaCode',
          continueSignIn: resolve,
          mfaAccountId: mfaAccountId!,
          mfaMethod: mfaMethod!,
          mfaContact: mfaContact!,
          submitStatus: 'NotSubmitted'
        });
      });
    }
    return Promise.resolve();
  }

  private async sendMfaCode(): Promise<void> {
    const { mfaAccountId, mfaMethod } = this.state;
    if (Utils.isNil(mfaAccountId) || Utils.isNil(mfaMethod)) {
      return;
    }
    await this.props.dispatchSendMfaCode({ account: { id: mfaAccountId }, mfaMethod });
  }

  /** Convenience getter to determine if form has validation errors. */
  private get formHasErrors(): boolean {
    return this.state.submitStatus === 'ValidationErrors';
  }

  /** Convenience getter to determine if form submission is in progress. */
  private get submitInProgress(): boolean {
    return this.state.submitStatus === 'InProgress';
  }
}

export const LoginForm = withConnect(
  withTranslation([I18N_NS_LOGIN_FORM, I18N_NS_VERIFY_MFA_CODE])(LoginFormComponent)
);

LoginForm.displayName = 'LoginForm';
