import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ApiFormattedDataObject, DataObjectHrmUserList } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { MalformedResponseException } from '../malformed-response-exception';

const OBJECT_ID_HRM_USER_LIST = 3201;
const OBJECT_TYPE_HRM_USER_LIST = process.env.DATA_OBJECT_TYPE_HRM_USER_LIST;

export async function apiHrmGetUserList(this: Api.Service, accessToken: string, authState: string) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiHrmGetUserList:');

  Logger.info('== BEGIN ==');
  try {
    Logger.info('Initiating HTTP request.');
    const { dataObjects } = await this.batchQueryData(accessToken, {
      authState,
      dataObjects: { ids: [OBJECT_ID_HRM_USER_LIST] }
    });

    let json: ApiFormattedDataObject | undefined = undefined;
    if (Utils.isNonEmptyArray(dataObjects)) {
      json = dataObjects.find(({ type, id }) => type === OBJECT_TYPE_HRM_USER_LIST && id === OBJECT_ID_HRM_USER_LIST);
    }

    if (Utils.isNil(json)) {
      Logger.warn('Operation failed. (Malformed/unexpected response data)');
      throw new MalformedResponseException('Failed to fetch HRM user list data object.');
    }

    const hrmUserListObject = new DataObjectHrmUserList(json);
    const { list: hrmUserList } = await hrmUserListObject.decryptedValue();
    return hrmUserList;
  } finally {
    Logger.info('== END ==');
  }
}
