import { Utils } from '@sigmail/common';
import { UserObjectCircleOfCareValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE;

export class UserObjectCircleOfCare extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isArray(value.list);
  }

  public updateValue(newValue: DV): Promise<UserObjectCircleOfCare> {
    const Class = this.constructor as typeof UserObjectCircleOfCare;
    return Class.updateValue(this, newValue);
  }
}
