import { createSlice } from '@reduxjs/toolkit';
import { StoreStateAuthorization } from '@sigmail/app-state';
import { CryptographicKey } from '@sigmail/objects';
import { signOutAction } from '../actions';
import { DataObjectCache } from '../data-objects-slice/cache';
import { UserObjectCache } from '../user-objects-slice/cache';
import { authFailureReducer } from './auth-failure-reducer';
import { authSuccessReducer } from './auth-success-reducer';
import { mfaEnableSuccessReducer } from './mfa-enable-success-reducer';
import { signInAction } from './sign-in-action';

export const INITIAL_STATE: StoreStateAuthorization = {
  scope: '',
  tokenType: '',
  accessToken: '',
  expiresIn: 0,
  refreshToken: '',
  idToken: '',
  mfaAccountId: null,
  mfaMethod: null,
  mfaContact: null,
  authClaim: ''
};

const authorizationSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    authSuccess: authSuccessReducer,
    authFailure: authFailureReducer,
    mfaEnableSuccess: mfaEnableSuccessReducer,
    mfaDisableSuccess: (state) => ({ ...state, mfaAccountId: null, mfaMethod: null, mfaContact: null })
  },
  extraReducers: {
    [signOutAction.type]: () => {
      DataObjectCache.clear();
      UserObjectCache.clear();

      CryptographicKey.clearAllPrivateKeys();
      CryptographicKey.clearAllPublicKeys();

      return INITIAL_STATE;
    }
  }
});

export { signInAction };
export const { authSuccess, authFailure, mfaEnableSuccess, mfaDisableSuccess } = authorizationSlice.actions;
export const { reducer: authStateReducer } = authorizationSlice;
