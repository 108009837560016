import { Utils } from '@sigmail/common';
import { GroupObjectPreferencesValue as DV } from '@sigmail/objects';
import { GroupObject } from '.';

const TYPE = process.env.GROUP_OBJECT_TYPE_PREFERENCES;

export class GroupObjectPreferences extends GroupObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      (Utils.isUndefined(value.integrations) || Utils.isNonArrayObjectLike(value.integrations))
    );
  }

  public updateValue(newValue: DV): Promise<GroupObjectPreferences> {
    const Class = this.constructor as typeof GroupObjectPreferences;
    return Class.updateValue(this, newValue);
  }
}
