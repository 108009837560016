import { Utils } from '@sigmail/common';
import { DataObjectDocMetadataValue as DV } from '@sigmail/objects';
import { DataObject } from '.';

const TYPE = process.env.DATA_OBJECT_TYPE_DOC_METADATA;

export class DataObjectDocMetadata extends DataObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      Utils.isString(value.docType) &&
      Utils.isString(value.name) &&
      Utils.isInteger(value.size)
    );
  }

  /** @override */
  public static isValidVersion(value: any): value is number {
    return value === 0;
  }

  public updateValue(newValue: DV): Promise<DataObjectDocMetadata> {
    const Class = this.constructor as typeof DataObjectDocMetadata;
    return Class.updateValue(this, newValue);
  }
}
