import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_ENTER_STATE } from './constants';
import { MalformedResponseException } from './malformed-response-exception';
import { ServiceException as ApiServiceException } from './service-exception';

const ERROR_RESPONSE_BAD_REQUEST = new Response(JSON.stringify({ message: 'Bad request.' }), {
  status: 400,
  statusText: 'Bad Request',
  headers: { 'Content-Type': 'application/json' }
});

const REGEX_VALID_ID_TOKEN = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

function isValidIdToken(value?: any): value is string {
  return Utils.isString(value) && REGEX_VALID_ID_TOKEN.test(value);
}

function isValidQueryData(query: any): query is Api.GetIdsRequestData {
  const isValidUsagesEntry = (entry: any) => Utils.isNonArrayObjectLike(entry) && Utils.isString(entry.usage);

  const isValidIdsEntry = (entry: any) =>
    Utils.isNonArrayObjectLike(entry) &&
    Utils.isNumber(entry.type) &&
    (Utils.isUndefined(entry.count) ||
      (Utils.isNumber(entry.count) && Utils.isInteger(entry.count) && entry.count > 0));

  let isValid =
    Utils.isNonArrayObjectLike(query) &&
    Utils.isValidJwtToken(query.authState, 'id') &&
    (Utils.isUndefined(query.claims) || (Utils.isArray(query.claims) && query.claims.every(isValidIdToken))) &&
    Utils.isString(query.state) &&
    query.state.trim().length > 0 &&
    Utils.isNonArrayObjectLike(query.ids);

  if (isValid) {
    const hasUsages = !Utils.isUndefined(query.ids.usages);
    const hasIds = !Utils.isUndefined(query.ids.ids);
    isValid = hasUsages || hasIds;
    if (isValid) {
      isValid = !hasUsages || (Utils.isNonEmptyArray(query.ids.usages) && query.ids.usages.every(isValidUsagesEntry));
      isValid = isValid && (!hasIds || (Utils.isNonEmptyArray(query.ids.ids) && query.ids.ids.every(isValidIdsEntry)));
    }
  }

  return isValid;
}

function isValidSuccessResponseJson(responseJson: any): responseJson is Api.EnterStateResponseData {
  return (
    Utils.isPlainObject(responseJson) &&
    Utils.every(['authState', 'claims'], Utils.partial(Utils.has, responseJson)) &&
    Utils.isValidJwtToken(responseJson.authState, 'id') &&
    Utils.isNonEmptyArray(responseJson.claims) &&
    responseJson.claims.every(isValidIdToken)
  );
}

export async function apiGetIDsByUsage(this: Api.Service, accessToken: string, query: Api.GetIdsRequestData) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiGetIDsByUsage:');

  Logger.info('== BEGIN ==');
  try {
    if (!isValidQueryData(query)) {
      throw new ApiServiceException(ERROR_RESPONSE_BAD_REQUEST);
    }

    const requestUrl = this.baseUrl.coreApi.concat(URL_ENTER_STATE);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.GetIdsResponseData>(requestUrl, JSON.stringify(query), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<Api.EnterStateResponseData>(response, undefined);
          if (isValidSuccessResponseJson(responseJson)) {
            let idsClaimIndex = -1;
            const idsClaimDataList = responseJson.claims
              .map((claim, index) => {
                const claimData = Utils.decodeIdToken(claim);

                const responseHasUsagesList = Utils.isNonEmptyArray(claimData.usages);
                const responseHasIdsList = Utils.isNonEmptyArray(claimData.ids);
                if (responseHasUsagesList || responseHasIdsList) {
                  idsClaimIndex = index;

                  const requestHasUsagesList = Utils.isNonEmptyArray(query.ids.usages);
                  const requestHasIdsList = Utils.isNonEmptyArray(query.ids.ids);
                  if ((requestHasUsagesList && !responseHasUsagesList) || (requestHasIdsList && !responseHasIdsList)) {
                    throw new MalformedResponseException(response);
                  }

                  let ids: Record<string | number, Array<number>> = {};
                  if (requestHasUsagesList) {
                    const isValidEntryData = (entry: any): entry is { usage: string; id: number } =>
                      Utils.isPlainObject(entry) && Utils.isString(entry.usage) && Utils.isNumber(entry.id);

                    Utils.transform(
                      claimData.usages as any[],
                      (obj, entry) => {
                        if (
                          isValidEntryData(entry) &&
                          query.ids.usages!.some((item) => Utils.isPlainObject(item) && item.usage === entry.usage) &&
                          !Utils.isArray(obj[entry.usage])
                        ) {
                          obj[entry.usage] = [entry.id];
                          return;
                        }

                        throw new MalformedResponseException(response);
                      },
                      ids
                    );
                  }

                  if (requestHasIdsList) {
                    const isValidEntryData = (entry: any): entry is { type: number; id: number[] } =>
                      Utils.isPlainObject(entry) && Utils.isNumber(entry.type) && Utils.isArray(entry.id);

                    Utils.transform(
                      claimData.ids as any[],
                      (obj, entry) => {
                        if (isValidEntryData(entry)) {
                          // prettier-ignore
                          const item = query.ids.ids!.find((item) => Utils.isPlainObject(item) && item.type === entry.type);
                          if (!Utils.isUndefined(item)) {
                            const count = Utils.isUndefined(item.count) || item.count === 0 ? 1 : item.count!;
                            if (entry.id.length === count && !Utils.isArray(obj[entry.type])) {
                              obj[entry.type] = entry.id;
                              return;
                            }
                          }
                        }

                        throw new MalformedResponseException(response);
                      },
                      ids
                    );
                  }

                  return ids as Api.GetIdsResponseData['ids'];
                }

                return undefined;
              })
              .filter(Utils.isNotNil);

            if (idsClaimDataList.length === 1 && idsClaimIndex >= 0 && idsClaimIndex <= responseJson.claims.length) {
              const response: Api.GetIdsResponseData = {
                ...responseJson,
                idsClaim: responseJson.claims[idsClaimIndex],
                ids: idsClaimDataList[0]
              };
              return response;
            }
          }
          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
