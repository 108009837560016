import { createSelector } from '@reduxjs/toolkit';
import { StoreStateUserObjects } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import {
  ClientObject,
  ClientObjectConfigValue,
  ClientObjectContactListValue,
  ClientObjectProfileValue,
  ClientObjectUserListValue,
  IUserObject,
  ValueFormatVersion
} from '@sigmail/objects';
import { CLIENT_OBJECT_TYPE_CODE_LIST } from '../constants';
import { RootState } from '../root-reducer';
import { UserObjectCache } from '../user-objects-slice/cache';
import { clientIdSelector } from './user-object';

const TYPE_CODE_LIST: ReadonlyArray<string> = CLIENT_OBJECT_TYPE_CODE_LIST.map(String);

/** Selector to extract all available client objects from the store. */
export const clientObjectsSelector: Reselect.Selector<RootState, StoreStateUserObjects> = (state) =>
  state.userObjects.filter((_, key) => TYPE_CODE_LIST.includes(key));

function createFirstObjectByTypeSelector<DV extends ValueFormatVersion>(objectType: number) {
  return createSelector(clientIdSelector, clientObjectsSelector, (currentClientId, clientObjectsMap) =>
    Utils.memoize((clientId?: number) => {
      const clientIdKey = Utils.isNumber(clientId) ? clientId : currentClientId;
      if (!ClientObject.isValidId(clientIdKey)) return undefined;

      const clientObjectByTypeMap = clientObjectsMap.get(objectType.toString(10));
      const clientObjectId = clientObjectByTypeMap?.get(clientIdKey.toString(10))?.keySeq().first();
      return UserObjectCache.find<IUserObject<DV>>(Number(clientObjectId))?.[0];
    })
  );
}

export const profileObjectSelector = createFirstObjectByTypeSelector<ClientObjectProfileValue>(
  process.env.CLIENT_OBJECT_TYPE_PROFILE
);

export const configurationObjectSelector = createFirstObjectByTypeSelector<ClientObjectConfigValue>(
  process.env.CLIENT_OBJECT_TYPE_CONFIGURATION
);

export const contactListObjectSelector = createFirstObjectByTypeSelector<ClientObjectContactListValue>(
  process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST
);

export const userListObjectSelector = createFirstObjectByTypeSelector<ClientObjectUserListValue>(
  process.env.CLIENT_OBJECT_TYPE_USER_LIST
);

/**
 * Selector to extract the global contact list ID associated with the client
 * the currently logged-in user is a member of.
 */
export const globalContactListIdSelector = createSelector(configurationObjectSelector, (configurationObjectSelector):
  | number
  | undefined => {
  const clientConfigObject = configurationObjectSelector();
  return UserObjectCache.getValue(clientConfigObject)?.globalContactListId;
});

type ClientLicenseCounts = {
  pro: { max: number; active: number; pending: number; inactive: number };
  standard: { max: number; active: number; pending: number; inactive: number };
  guest: { max: number; active: number; pending: number; inactive: number };
};

export const licenseCountsSelector = createSelector(
  configurationObjectSelector,
  userListObjectSelector,
  (clientConfigSelector, clientUserListSelector): ClientLicenseCounts => {
    const licenseCounts: ClientLicenseCounts = {
      pro: { max: NaN, active: 0, pending: 0, inactive: 0 },
      standard: { max: NaN, active: 0, pending: 0, inactive: 0 },
      guest: { max: NaN, active: 0, pending: 0, inactive: 0 }
    };

    const clientConfigObject = clientConfigSelector();
    const clientConfig = UserObjectCache.getValue(clientConfigObject, {} as ClientObjectConfigValue);

    if (Utils.isPlainObject(clientConfig.licenses)) {
      if (Utils.isFinite(clientConfig.licenses.pro)) {
        licenseCounts.pro.max = clientConfig.licenses.pro;
      }
      if (Utils.isFinite(clientConfig.licenses.standard)) {
        licenseCounts.standard.max = clientConfig.licenses.standard;
      }
      if (Utils.isFinite(clientConfig.licenses.guest)) {
        licenseCounts.guest.max = clientConfig.licenses.guest;
      }
    }

    const clientUserListObject = clientUserListSelector();
    const { active, pending, inactive } = UserObjectCache.getValue(
      clientUserListObject,
      {} as ClientObjectUserListValue
    );

    Utils.transform(
      { active, pending, inactive },
      (counts, list, key: keyof ClientLicenseCounts['pro']) => {
        if (Utils.isArray(list)) {
          list.forEach(({ role: roleId }) => {
            const licenseType = Utils.MedicalInstitute.getLicenseTypeFromRole(roleId);
            if (Utils.MedicalInstitute.isProLicense(licenseType)) {
              ++counts.pro[key];
            } else if (Utils.MedicalInstitute.isStandardLicense(licenseType)) {
              ++counts.standard[key];
            } else if (Utils.MedicalInstitute.isGuestLicense(licenseType)) {
              ++counts.guest[key];
            }
          });
        }
      },
      licenseCounts
    );

    return licenseCounts;
  }
);
