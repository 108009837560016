import { ActionPayloadSrpExchangeCredential } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';

export const srpExchangeCredentialAction = (
  payload: ActionPayloadSrpExchangeCredential
): AppThunk<Promise<Api.SrpExchangeCredentialResponseData>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'srpExchangeCredentialsAction:');

    Logger.info('== BEGIN ==');
    try {
      const clientEphemeral = Utils.srpGenerateEphemeral();
      Logger.debug('clientEphemeral =', JSON.stringify(clientEphemeral));

      const responseJson = await apiService.srpExchangeCredential(payload.credentialHash, clientEphemeral.public);
      return { ...responseJson, clientEphemeral };
    } finally {
      Logger.info('== END ==');
    }
  };
};
