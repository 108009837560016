import { getLoggerWithPrefix } from '@sigmail/logging';
import { ActionPayloadVerifyMfaCode } from '@sigmail/app-state';
import { AppThunk } from '../..';

export const verifyMfaCodeAction = (payload: ActionPayloadVerifyMfaCode): AppThunk<Promise<void>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'verifyMfaCodeAction:');

    Logger.info('== BEGIN ==');
    try {
      return await apiService.authyVerifyMfaCode(payload.accountId, payload.verificationCode);
    } finally {
      Logger.info('== END ==');
    }
  };
};
