import { Utils } from '@sigmail/common';
import React from 'react';
import sharedFormStyle from './forms.module.css';

type HtmlButtonElementProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export interface Props extends Omit<HtmlButtonElementProps, 'ref' | 'type'> {
  innerRef?: HtmlButtonElementProps['ref'];
  progress?: boolean;
}

const SubmitButtonComponent = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  let { className, progress, ...otherProps } = props;

  className = [
    Utils.isString(className) ? className : sharedFormStyle['btn-submit'],
    !!otherProps.disabled && 'disabled',
    !!progress && 'lds-dual-ring'
  ]
    .filter(Boolean)
    .join(' ');

  return <button className={className} ref={ref || props.innerRef} type="submit" {...otherProps} />;
});

export const SubmitButton = React.memo(SubmitButtonComponent);
SubmitButton.displayName = 'SubmitButton';
