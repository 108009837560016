import { createSelector } from '@reduxjs/toolkit';
import { StoreStateAuthorization } from '@sigmail/app-state';
import { AppUser, Constants, Utils } from '@sigmail/common';
import { UserObjectAccessRightsValue } from '@sigmail/objects';
import Immutable from 'immutable';
import * as ClientRightId from '../../constants/client-rights-identifiers';
import { RootState } from '../root-reducer';
import { UserObjectCache } from '../user-objects-slice/cache';

function clamp(num: number, min: number, max: number) {
  return Math.max(min, Math.min(num, max));
}

const MIN_VALUE_ATTACHMENT_COUNT = 0;
const MAX_VALUE_ATTACHMENT_COUNT = 128;
const MIN_VALUE_RECALL_MESSAGE = 0;
const MAX_VALUE_RECALL_MESSAGE = 365;

const EIGHT_MB = 8388608;
const TWENTY_FIVE_MB = 26214400;

const MFA_DATA_PROPS: ReadonlyArray<
  keyof Pick<StoreStateAuthorization, 'mfaAccountId' | 'mfaMethod' | 'mfaContact'>
> = ['mfaAccountId', 'mfaMethod', 'mfaContact'];

/** Selector to get the current authentication state from the store. */
const authStateSelector: Reselect.Selector<RootState, StoreStateAuthorization> = (state) => state.auth;

/** Selector to extract the Bearer access token from auth state. */
export const accessTokenSelector = createSelector(authStateSelector, (state) => state.accessToken);

/** Selector to extract the salt value from auth state. */
export const saltValueSelector = createSelector(authStateSelector, (state) => state.salt);

/**
 * Selector to extract the AppUser class' instance from auth state. If no user
 * is logged-in, `undefined` is returned.
 */
export const currentUserSelector = createSelector(authStateSelector, (state) => state.user);

/**
 * Selector to extract the ID of the currently logged-in user. If no user is
 * logged-in, `undefined` is returned.
 */
export const currentUserIdSelector = createSelector(currentUserSelector, (user) => user?.id);

/** Selector to determine whether a user is currently logged-in or not. */
export const isUserLoggedInSelector = createSelector(currentUserIdSelector, (userId) => AppUser.isValidId(userId));

/**
 * Selector to extract access rights object of the logged-in user. If no user is
 * logged-in, `undefined` is returned.
 */
export const accessRightsSelector = createSelector(authStateSelector, (state) => state.accessRights);

/** Selector to extract MFA data of the logged-in user. */
export const mfaDataSelector = createSelector(authStateSelector, (state) => Utils.pick(state, MFA_DATA_PROPS));

/** Selector to extract the role authorization claim of the logged-in user. */
export const authClaimSelector = createSelector(authStateSelector, (state) => state.authClaim);

/** Selector to extract the result code of the last authentication attempt. */
export const lastAuthErrorCodeSelector = createSelector(authStateSelector, (state) => state.lastAuthErrorCode);

export const clientRightsSelector = createSelector(
  accessRightsSelector,
  (accessRightsObject): Immutable.Map<Symbol, boolean | number | string | readonly string[]> => {
    const { clientRights: rights } = Utils.defaults(
      {} as UserObjectAccessRightsValue,
      UserObjectCache.getValue(accessRightsObject, {} as UserObjectAccessRightsValue),
      { clientRights: {} } as Required<UserObjectAccessRightsValue>
    );

    const canInviteMemberOfRole = (roleId: string) =>
      rights.inviteMember === true ||
      (Utils.isNonEmptyArray(rights.inviteMember) && rights.inviteMember.indexOf(roleId) >= 0);
    const canInviteMember = Constants.MedicalInstitute.ROLE_ID_LIST.filter(canInviteMemberOfRole);

    const canActivateMemberOfRole = (roleId: string) =>
      rights.activateMember === true ||
      (Utils.isNonEmptyArray(rights.activateMember) && rights.activateMember.indexOf(roleId) >= 0);
    const canActivateMember = Constants.MedicalInstitute.ROLE_ID_LIST.filter(canActivateMemberOfRole);

    const canDeactivateMemberOfRole = (roleId: string) =>
      rights.deactivateMember === true ||
      (Utils.isNonEmptyArray(rights.deactivateMember) && rights.deactivateMember.indexOf(roleId) >= 0);
    const canDeactivateMember = Constants.MedicalInstitute.ROLE_ID_LIST.filter(canDeactivateMemberOfRole);

    let maxAttachmentCount: number = 0;
    if (rights.attachDocumentsToMessage === true) {
      maxAttachmentCount = Infinity;
    } else {
      maxAttachmentCount = Number(rights.attachDocumentsToMessage);
    }
    maxAttachmentCount = Math.floor(
      clamp(maxAttachmentCount || 0, MIN_VALUE_ATTACHMENT_COUNT, MAX_VALUE_ATTACHMENT_COUNT)
    );

    let maxTotalMsgAttachmentSize = Number(rights.maxTotalMsgAttachmentSize);
    let maxPerFileMsgAttachmentSize = Number(rights.maxPerFileMsgAttachmentSize);
    if (maxAttachmentCount > 0) {
      maxTotalMsgAttachmentSize = Math.floor(clamp(maxTotalMsgAttachmentSize || 0, 0, TWENTY_FIVE_MB));
      maxPerFileMsgAttachmentSize = Math.floor(clamp(maxPerFileMsgAttachmentSize || 0, 0, EIGHT_MB));
    }

    let recallMessageWindow = Number(rights.recallMessageWindow);
    recallMessageWindow = Math.floor(
      clamp(recallMessageWindow || 0, MIN_VALUE_RECALL_MESSAGE, MAX_VALUE_RECALL_MESSAGE)
    );

    return Immutable.Map<Symbol, boolean | number | string | string[]>([
      [ClientRightId.CAN_INVITE_INSTITUTE, !!rights.inviteInstitute],

      [ClientRightId.CAN_INVITE_MEMBER, canInviteMember],
      [ClientRightId.CAN_ACTIVATE_MEMBER, canActivateMember],
      [ClientRightId.CAN_DEACTIVATE_MEMBER, canDeactivateMember],

      [ClientRightId.CAN_RESEND_INVITATION, rights.resendInvitation || false],
      [ClientRightId.CAN_REVOKE_INVITATION, rights.revokeInvitation || false],
      [ClientRightId.CAN_REMOVE_INVITATION, rights.removeInvitation || false],

      [ClientRightId.CAN_ACCESS_MAILBOX, !!rights.accessMailbox],
      [ClientRightId.CAN_ACCESS_GLOBAL_CONTACTS, !!rights.accessGlobalContacts],
      [ClientRightId.CAN_ACCESS_MEMBER_MANAGEMENT, !!rights.accessMemberManagement],
      [ClientRightId.CAN_ACCESS_OWN_ACCOUNT, !!rights.accessOwnAccount],
      [ClientRightId.CAN_ACCESS_CLIENT_CONTACTS, !!rights.accessClientContacts],
      [ClientRightId.CAN_ACCESS_CIRCLE_OF_CARE, !!rights.accessCircleOfCare],

      [ClientRightId.CAN_COMPOSE_MESSAGE, !!rights.composeMessage],
      [ClientRightId.CAN_COMPOSE_REFERRAL, !!rights.composeReferral],
      [ClientRightId.CAN_SEND_MESSAGE, !!rights.sendMessage],
      [ClientRightId.CAN_SEND_MESSAGE_TO_OSCAR_EMR, !!rights.sendMessageToOscarEmr],
      [ClientRightId.CAN_REPLY_TO_MESSAGE, !!rights.replyToMessage],
      [ClientRightId.CAN_FORWARD_MESSAGE, !!rights.forwardMessage],
      [ClientRightId.CAN_ARCHIVE_MESSAGE, !!rights.archiveMessage],
      [ClientRightId.CAN_DELETE_MESSAGE, !!rights.deleteMessage],
      [ClientRightId.CAN_SAVE_MESSAGE_DRAFT, !!rights.saveMessageDraft],
      [ClientRightId.ATTACH_DOCUMENTS_TO_MESSAGE, maxAttachmentCount],
      [ClientRightId.CAN_MARK_MESSAGE_IMPORTANT, !!rights.markMessageImportant],
      [ClientRightId.CAN_MARK_MESSAGE_BILLABLE, !!rights.markMessageBillable],
      [ClientRightId.CAN_SET_MESSAGE_REMINDER, !!rights.setMessageReminder],
      [ClientRightId.RECALL_MESSAGE_WINDOW, recallMessageWindow],

      [ClientRightId.MAX_TOTAL_MSG_ATTACHMENT_SIZE, maxTotalMsgAttachmentSize],
      [ClientRightId.MAX_PER_FILE_MSG_ATTACHMENT_SIZE, maxPerFileMsgAttachmentSize],
      [ClientRightId.CAN_UPDATE_GROUP_PREFS, !!rights.updateGroupPrefs]
    ]);
  }
);
