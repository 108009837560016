import { combineReducers } from '@reduxjs/toolkit';
import { authStateReducer } from './auth-slice';
import { dataObjectsReducer } from './data-objects-slice';
import { userObjectReducer } from './user-objects-slice';

export const rootReducer = combineReducers({
  auth: authStateReducer,
  userObjects: userObjectReducer,
  dataObjects: dataObjectsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
