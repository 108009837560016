import { NotificationObjectIncomingMessageValue as DV } from '@sigmail/objects';
import { NotificationObject } from '.';
import { DataObjectMsgBody } from '../data-object/msg-body';
import { DataObjectMsgMetadata } from '../data-object/msg-metadata';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE;

export class NotificationObjectIncomingMessage extends NotificationObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      DataObjectMsgMetadata.isValidId(value.header) &&
      DataObjectMsgBody.isValidId(value.body)
    );
  }
}
