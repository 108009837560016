import { createSelector } from '@reduxjs/toolkit';
import { StoreStateUserObjects } from '@sigmail/app-state';
import { AppUser, AppUserGroup, IAppUserGroup, Utils } from '@sigmail/common';
import {
  DataObjectMsgFolder,
  IUserObject,
  UserMessageFolderItem,
  UserObjectAccessRightsValue,
  UserObjectCircleOfCareValue,
  UserObjectContactInfoValue,
  UserObjectContactListValue,
  UserObjectFolderListValue,
  UserObjectFolderListValue_v2,
  UserObjectPreferencesValue,
  UserObjectProfileBasicValue,
  UserObjectProfilePrivateValue,
  UserObjectProfileProtectedValue,
  UserObjectServerRightsValue,
  ValueFormatVersion
} from '@sigmail/objects';
import {
  DEFAULT_MESSAGE_SIGNATURE,
  DEFAULT_PREFERRED_LANGUAGE_CODE,
  DEFAULT_REFERRAL_EXPIRY_IN_DAYS
} from '../../constants';
import { CIRCLE_OF_CARE as CIRCLE_OF_CARE_GROUP } from '../../constants/medical-institute-user-group-type-identifier';
import { USER_OBJECT_TYPE_CODE_LIST } from '../constants';
import { RootState } from '../root-reducer';
import { UserObjectCache } from '../user-objects-slice/cache';
import * as AuthSelectors from './auth';

const TYPE_CODE_LIST: ReadonlyArray<string> = USER_OBJECT_TYPE_CODE_LIST.map(String);
const TYPE_MSG_FOLDER = process.env.DATA_OBJECT_TYPE_MSG_FOLDER;

/** Selector to extract all available user objects from the store. */
export const userObjectsSelector: Reselect.Selector<RootState, StoreStateUserObjects> = (state) =>
  state.userObjects.filter((_, key) => TYPE_CODE_LIST.includes(key));

export function createFirstObjectIdByTypeSelector(objectType: number) {
  return createSelector(AuthSelectors.currentUserIdSelector, userObjectsSelector, (currentUserId, userObjectsMap) =>
    Utils.memoize((userId?: number) => {
      const userIdKey = Utils.isNumber(userId) ? userId : currentUserId;
      if (!AppUser.isValidId(userIdKey)) return undefined;

      const userObjectByTypeMap = userObjectsMap.get(objectType.toString(10));
      const userObjectId: string | undefined = userObjectByTypeMap?.get(userIdKey.toString(10))?.keySeq().first();
      return Number(userObjectId);
    })
  );
}

export function createFirstObjectByTypeSelector<DV extends ValueFormatVersion>(objectType: number) {
  return createSelector(createFirstObjectIdByTypeSelector(objectType), (userObjectByIdSelector) =>
    Utils.memoize((userId?: number) => UserObjectCache.find<IUserObject<DV>>(userObjectByIdSelector(userId))?.[0])
  );
}

/**
 * Selector to extract the basic profile object of the currently logged-in
 * user.
 */
export const basicProfileObjectSelector = createFirstObjectByTypeSelector<UserObjectProfileBasicValue>(
  process.env.USER_OBJECT_TYPE_PROFILE_BASIC
);

/**
 * Selector to extract the protected profile object of the currently logged-in
 * user.
 */
export const protectedProfileObjectSelector = createFirstObjectByTypeSelector<UserObjectProfileProtectedValue>(
  process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED
);

/**
 * Selector to extract the private profile object of the currently logged-in
 * user.
 */
export const privateProfileObjectSelector = createFirstObjectByTypeSelector<UserObjectProfilePrivateValue>(
  process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE
);

/**
 * Selector to extract the folder list object of the currently logged-in
 * user.
 */
export const folderListObjectSelector = createFirstObjectByTypeSelector<UserObjectFolderListValue>(
  process.env.USER_OBJECT_TYPE_FOLDER_LIST
);

/**
 * Selector to extract the contact info object of the currently logged-in
 * user.
 */
export const contactInfoObjectSelector = createFirstObjectByTypeSelector<UserObjectContactInfoValue>(
  process.env.USER_OBJECT_TYPE_CONTACT_INFO
);

/**
 * Selector to extract the contact list object of the currently logged-in
 * user.
 */
export const contactListObjectSelector = createFirstObjectByTypeSelector<UserObjectContactListValue>(
  process.env.USER_OBJECT_TYPE_CONTACT_LIST
);

/**
 * Selector to extract the guest data object of the currently logged-in
 * user.
 */
// export const guestDataObjectSelector = createFirstObjectByTypeSelector<ValueFormatVersion>(
//   process.env.USER_OBJECT_TYPE_GUEST_DATA
// );

/**
 * Selector to extract the preferences object of the currently logged-in
 * user.
 */
export const preferencesObjectSelector = createFirstObjectByTypeSelector<UserObjectPreferencesValue>(
  process.env.USER_OBJECT_TYPE_PREFERENCES
);

/**
 * Selector to extract the access rights object of the currently logged-in
 * user.
 */
export const accessRightsObjectSelector = createFirstObjectByTypeSelector<UserObjectAccessRightsValue>(
  process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS
);

/**
 * Selector to extract the server rights object of the currently logged-in
 * user.
 */
export const serverRightsObjectSelector = createFirstObjectByTypeSelector<UserObjectServerRightsValue>(
  process.env.USER_OBJECT_TYPE_SERVER_RIGHTS
);

/**
 * Selector to extract the circle of care object of the currently logged-in
 * user.
 */
export const circleOfCareObjectSelector = createFirstObjectByTypeSelector<UserObjectCircleOfCareValue>(
  process.env.USER_OBJECT_TYPE_CIRCLE_OF_CARE
);

export const registrationDetailsObjectSelector = createFirstObjectByTypeSelector<ValueFormatVersion>(
  process.env.USER_OBJECT_TYPE_REGISTRATION_DETAILS
);

/**
 * Selector to extract the audit ID associated with the currently logged-in
 * user.
 */
export const auditIdSelector = createSelector(privateProfileObjectSelector, (privateProfileObjectSelector):
  | number
  | undefined => {
  const privateProfileObject = privateProfileObjectSelector();
  return UserObjectCache.getValue(privateProfileObject)?.auditId;
});

/**
 * Selector to extract the owner ID associated with the currently logged-in
 * user.
 */
export const ownerIdSelector = createSelector(privateProfileObjectSelector, (privateProfileObjectSelector):
  | number
  | undefined => {
  const privateProfileObject = privateProfileObjectSelector();
  return UserObjectCache.getValue(privateProfileObject)?.ownerId;
});

/**
 * Selector to extract the client ID associated with the currently logged-in
 * user.
 */
export const clientIdSelector = createSelector(privateProfileObjectSelector, (privateProfileObjectSelector):
  | number
  | undefined => {
  const privateProfileObject = privateProfileObjectSelector();
  return UserObjectCache.getValue(privateProfileObject)?.clientId;
});

/**
 * Selector to extract a list of all groups of a specific type the current user
 * is a member of.
 */
export const membershipByGroupTypeSelector = createSelector(basicProfileObjectSelector, (basicProfileSelector) => {
  const basicProfileObject = basicProfileSelector();

  let { memberOf: groupList } = UserObjectCache.getValue(basicProfileObject, {} as UserObjectProfileBasicValue);
  if (!Utils.isArray(groupList)) {
    groupList = [];
  }

  return Utils.memoize((groupType: string): IAppUserGroup[] =>
    groupList!.filter((group) => group.groupType === groupType).map((obj) => new AppUserGroup(obj))
  );
});

/**
 * Selector to extract an AppUserGroup class' instance of the CircleOfCare group
 * the currently logged-in user is a member of.
 */
export const circleOfCareGroupSelector = createSelector(membershipByGroupTypeSelector, (groupByTypeSelector):
  | IAppUserGroup
  | undefined => {
  const [circleOfCareGroup] = groupByTypeSelector(CIRCLE_OF_CARE_GROUP);
  return circleOfCareGroup;
});

/**
 * Selector to extract a map of all message folders associated with the
 * currently logged-in user's account.
 */
export const messageFolderMapSelector = createSelector(folderListObjectSelector, (folderListObjectSelector) => (): {
  [folderKey: string]: UserMessageFolderItem | undefined;
} => {
  const folderListObject = folderListObjectSelector();
  const { $$formatver, ...folderList } = UserObjectCache.getValue(folderListObject, {} as UserObjectFolderListValue);

  return Utils.transform(
    (folderList as UserObjectFolderListValue_v2).msg,
    (folderItemMap, folder, folderKey) => {
      if (
        Utils.isNonArrayObjectLike(folder) &&
        DataObjectMsgFolder.isValidId(folder.id) &&
        folder.type === TYPE_MSG_FOLDER
      ) {
        folderItemMap[folderKey] = folder;
      }
    },
    {} as { [folderKey: string]: UserMessageFolderItem | undefined }
  );
});

/** Selector to extract a map of currently logged-in user's preferences. */
export const preferencesSelector = createSelector(preferencesObjectSelector, (preferencesObjectSelector) => {
  const preferencesObject = preferencesObjectSelector(/***/);
  const preferences = UserObjectCache.getValue(preferencesObject, {} as UserObjectPreferencesValue);

  let { messageSignature, languagePreference, referralExpiry } = preferences;
  if (!Utils.isString(messageSignature)) {
    messageSignature = DEFAULT_MESSAGE_SIGNATURE;
  }

  if (!Utils.isString(languagePreference)) {
    languagePreference = DEFAULT_PREFERRED_LANGUAGE_CODE;
  }

  if (!Utils.isInteger(referralExpiry)) {
    referralExpiry = DEFAULT_REFERRAL_EXPIRY_IN_DAYS;
  }

  referralExpiry = Math.max(0, Math.min(referralExpiry!, 366));

  return {
    isNotifyOnNewMessageEnabled: preferences.noNotifyOnNewMessage !== true,
    messageSignature,
    languagePreference,
    referralExpiry
  };
});
