import { Utils } from '@sigmail/common';
import { UserObjectContactListValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_CONTACT_LIST;

export class UserObjectContactList extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isArray(value.recent) && Utils.isArray(value.contacts);
  }

  public updateValue(newValue: DV): Promise<UserObjectContactList> {
    const Class = this.constructor as typeof UserObjectContactList;
    return Class.updateValue(this, newValue);
  }
}
