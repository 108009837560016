import { Utils } from '@sigmail/common';
import { ServerParamsPasswordReset, SharedParamsPasswordReset } from '@sigmail/objects';
import { UserCredentials } from '.';

const TYPE = process.env.USER_CREDENTIALS_TYPE_PASSWORD_RESET;

const SHARED_PARAMS_PROPS: ReadonlyArray<keyof SharedParamsPasswordReset> = [
  'salt',
  'question',
  'firstName',
  'lastName',
  'emailAddress'
];

const SERVER_PARAMS_PROPS: ReadonlyArray<keyof ServerParamsPasswordReset> = ['verifier'];

export class UserCredentialsPasswordReset extends UserCredentials<
  SharedParamsPasswordReset,
  ServerParamsPasswordReset
> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  public static isValidSharedParameters(value: any): value is SharedParamsPasswordReset {
    if (!super.isValidSharedParameters(value)) return false;

    return (
      Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, value)) &&
      Utils.isString(value.salt) &&
      Utils.isArray(value.question) &&
      Utils.isString(value.firstName) &&
      Utils.isString(value.lastName) &&
      Utils.isString(value.emailAddress)
    );
  }

  /** @override */
  public static isValidServerParameters(value: any): value is ServerParamsPasswordReset {
    if (!super.isValidServerParameters(value)) return false;

    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, value)) && Utils.isString(value.verifier);
  }
}
