import { createSelector } from '@reduxjs/toolkit';
import { StoreStateUserObjects } from '@sigmail/app-state';
import { AppUserGroup, Utils } from '@sigmail/common';
import {
  DataObjectMsgFolder,
  GroupMessageFolderItem,
  GroupObjectAccessRightsValue,
  GroupObjectContactInfoValue,
  GroupObjectFolderListValue,
  GroupObjectFolderListValue_v2,
  GroupObjectGuestListValue,
  GroupObjectPreferencesValue,
  GroupObjectProfileBasicValue,
  GroupObjectServerRightsValue,
  IUserObject,
  ValueFormatVersion
} from '@sigmail/objects';
import { GROUP_OBJECT_TYPE_CODE_LIST } from '../constants';
import { RootState } from '../root-reducer';
import { UserObjectCache } from '../user-objects-slice/cache';
import { circleOfCareGroupSelector } from './user-object';

const TYPE_CODE_LIST: ReadonlyArray<string> = GROUP_OBJECT_TYPE_CODE_LIST.map(String);
const TYPE_MSG_FOLDER = process.env.DATA_OBJECT_TYPE_MSG_FOLDER;

/** Selector to extract all available group objects from the store. */
export const groupObjectsSelector: Reselect.Selector<RootState, StoreStateUserObjects> = (state) =>
  state.userObjects.filter((_, key) => TYPE_CODE_LIST.includes(key));

function createFirstObjectByTypeSelector<DV extends ValueFormatVersion>(objectType: number) {
  return createSelector(groupObjectsSelector, circleOfCareGroupSelector, (groupObjectsMap, circleOfCareGroup) => {
    const circleOfCareGroupId: number | undefined = Utils.isNotNil(circleOfCareGroup)
      ? circleOfCareGroup.id
      : undefined;
    return Utils.memoize((groupId?: number) => {
      const groupIdKey = Utils.isNumber(groupId) ? groupId : circleOfCareGroupId;
      if (!AppUserGroup.isValidId(groupIdKey)) return undefined;

      const groupObjectByTypeMap = groupObjectsMap.get(objectType.toString(10));
      const groupObjectId = groupObjectByTypeMap?.get(groupIdKey.toString(10))?.keySeq().first();
      return UserObjectCache.find<IUserObject<DV>>(Number(groupObjectId))?.[0];
    });
  });
}

/**
 * Selector to extract the basic profile object associated with the circle of
 * care group the current user is a member of.
 */
export const basicProfileObjectSelector = createFirstObjectByTypeSelector<GroupObjectProfileBasicValue>(
  process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC
);

/**
 * Selector to extract the folder list object associated with the circle of
 * care group the current user is a member of.
 */
export const folderListObjectSelector = createFirstObjectByTypeSelector<GroupObjectFolderListValue>(
  process.env.GROUP_OBJECT_TYPE_FOLDER_LIST
);

/**
 * Selector to extract the contact info object associated with the circle of
 * care group the current user is a member of.
 */
export const contactInfoObjectSelector = createFirstObjectByTypeSelector<GroupObjectContactInfoValue>(
  process.env.GROUP_OBJECT_TYPE_CONTACT_INFO
);

/**
 * Selector to extract the preferences object associated with the circle of
 * care group the current user is a member of.
 */
export const preferencesObjectSelector = createFirstObjectByTypeSelector<GroupObjectPreferencesValue>(
  process.env.GROUP_OBJECT_TYPE_PREFERENCES
);

/**
 * Selector to extract the access rights object associated with the circle of
 * care group the current user is a member of.
 */
export const accessRightsObjectSelector = createFirstObjectByTypeSelector<GroupObjectAccessRightsValue>(
  process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS
);

/**
 * Selector to extract the server rights object associated with the circle of
 * care group the current user is a member of.
 */
export const serverRightsObjectSelector = createFirstObjectByTypeSelector<GroupObjectServerRightsValue>(
  process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS
);

/**
 * Selector to extract the guest list object associated with the circle of
 * care group the current user is a member of.
 */
export const guestListObjectSelector = createFirstObjectByTypeSelector<GroupObjectGuestListValue>(
  process.env.GROUP_OBJECT_TYPE_GUEST_LIST
);

/**
 * Selector to extract a map of all message folders associated with the circle
 * of care group the current user is a member of.
 */
export const messageFolderMapSelector = createSelector(folderListObjectSelector, (folderListObjectSelector) =>
  Utils.memoize((groupId?: number): { [folderKey: string]: GroupMessageFolderItem | undefined } => {
    const folderListObject = folderListObjectSelector(groupId);
    const { $$formatver, ...folderList } = UserObjectCache.getValue(folderListObject, {} as GroupObjectFolderListValue);

    return Utils.transform(
      (folderList as GroupObjectFolderListValue_v2).msg,
      (folderItemMap, folder, folderKey) => {
        if (
          Utils.isNonArrayObjectLike(folder) &&
          DataObjectMsgFolder.isValidId(folder.id) &&
          folder.type === TYPE_MSG_FOLDER
        ) {
          folderItemMap[folderKey] = folder;
        }
      },
      {} as { [folderKey: string]: GroupMessageFolderItem | undefined }
    );
  })
);

export const preferencesSelector = createSelector(preferencesObjectSelector, (preferencesObjectSelector) => {
  const preferencesObject = preferencesObjectSelector();
  const preferences = UserObjectCache.getValue(preferencesObject, {} as GroupObjectPreferencesValue);

  let oscarEmrOAuthParams: NonNullable<
    NonNullable<GroupObjectPreferencesValue['integrations']>['oscar']
  >['oauthParams'] = undefined;

  const { integrations } = preferences;
  if (Utils.isNonArrayObjectLike(integrations)) {
    const { oscar } = integrations;
    if (Utils.isNonArrayObjectLike(oscar)) {
      oscarEmrOAuthParams = oscar.oauthParams;
    }
  }

  return { oscarEmrOAuthParams };
});
