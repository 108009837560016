import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import { URL_OSCAR_CHECK_AUTH_STATUS } from '../constants';
import { MalformedResponseException } from '../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../service-exception';

export async function apiOscarCheckAuthStatus(
  this: Api.Service,
  accessToken: string,
  userId: number,
  oscarOAuthParams: Api.OscarOAuthParams
) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiOscarCheckAuthStatus:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(URL_OSCAR_CHECK_AUTH_STATUS);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<boolean>(requestUrl, JSON.stringify({ userId, oscar: oscarOAuthParams }), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<{ authenticated: boolean }>(response);
          if (Utils.isNonArrayObjectLike(responseJson)) return responseJson.authenticated === true;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        if (response.status === 400) {
          throw new ApiServiceException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
