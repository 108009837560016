import { Utils } from '@sigmail/common';
import {
  EmailTokenResponseType,
  ServerParamsEmailToken,
  SharedParamsEmailTokenPasswordReset as DVShared
} from '@sigmail/objects';
import { UserCredentials } from '..';

const TYPE = process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN;
const RESPONSE_TYPE: EmailTokenResponseType = 'passwordReset';

const SHARED_PARAMS_PROPS: ReadonlyArray<keyof DVShared> = ['response', 'salt', 'questionId'];
const SERVER_PARAMS_PROPS: ReadonlyArray<keyof ServerParamsEmailToken> = ['verifier'];

export class PasswordReset extends UserCredentials<DVShared, ServerParamsEmailToken> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  public static isValidSharedParameters(value: any): value is DVShared {
    if (!super.isValidSharedParameters(value)) return false;

    return (
      Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, value)) &&
      value.response === RESPONSE_TYPE &&
      Utils.isString(value.salt) &&
      Utils.isString(value.questionId)
    );
  }

  /** @override */
  public static isValidServerParameters(value: any): value is ServerParamsEmailToken {
    if (!super.isValidServerParameters(value)) return false;

    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, value)) && Utils.isString(value.verifier);
  }
}
