import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_SEND_TEMPLATED_EMAIL_MESSAGE } from './constants';

export async function apiSendTemplatedEmailMessage(this: Api.Service, message: Api.TemplatedEmailMessage) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiSendTemplatedEmailMessage:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.emailApi.concat(URL_SEND_TEMPLATED_EMAIL_MESSAGE);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    if (this.authKey.emailApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.emailApi);
    }

    Logger.info('Initiating HTTP request.');
    const response = await this.httpService.post(requestUrl, JSON.stringify(message), {
      headers: requestHeaders,
      cache: 'no-store'
    });

    if (response.status !== 202) {
      Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
      throw new HttpServiceException(response);
    }
  } finally {
    Logger.info('== END ==');
  }
}
