// @ts-nocheck
import { ActionPayloadBatchQueryDataSuccess } from '@sigmail/app-state';
import { InstituteInfo, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ClientObjectContactList,
  ClientObjectContactListValue,
  ClientObjectProfile,
  ClientObjectProfileValue,
  ContactListItem,
  CryptographicKeyPublic,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue,
  GroupContactListItem,
  IDataObject,
  IUserObject
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';

const INSTITUTE_INFO_KEY_LIST: ReadonlyArray<keyof InstituteInfo> = [
  'name',
  'emailAddress',
  'phoneNumber',
  'faxNumber',
  'addressLine1',
  'addressLine2',
  'addressLevel2',
  'addressLevel1',
  'postalCode'
];

interface Payload {
  accessToken: string;
  clientId: number;
  globalContactListId: number;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

interface State {
  clientContactList: ClientObjectContactListValue['list'];
  clientProfileObject: IUserObject<ClientObjectProfileValue>;
  clientProfile: ClientObjectProfileValue;
  globalContactListObject: IDataObject<DataObjectSigmailGlobalContactListValue>;
  requestBody: BatchUpdateRequestBuilder;
  groupContactList: GroupContactListItem[];
}

class MigrationAddGroupsAndInstituteInfoToGlobalContactList extends BaseAction<Payload, State> {
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchClientContactListAndProfile();

        const { $$formatver } = this.state.clientProfile;
        if (!Utils.isNil($$formatver) && $$formatver > 1) {
          this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
          return;
        }

        await this.fetchGlobalContactList();

        await this.generateRequestBody();

        const { accessToken, successPayload } = this.payload;
        const { requestBody, globalContactListObject, clientProfileObject } = this.state;
        await this.batchUpdateData(accessToken, requestBody.build());

        // manually patch successPayload's data with updated objects
        do {
          const userObjectList = [clientProfileObject];
          for (const obj of userObjectList) {
            const index = this.findUserObjectIndex(successPayload.response.userObjectsByType!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              successPayload.response.userObjectsByType![index] = obj.toApiFormatted();
            }
          }

          const dataObjectList = [globalContactListObject];
          for (const obj of dataObjectList) {
            const index = this.findDataObjectIndex(successPayload.response.dataObjects!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              successPayload.response.dataObjects![index] = obj.toApiFormatted();
            }
          }
        } while (false);

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchClientContactListAndProfile(): Promise<void> {
    this.logger.info('Fetching the latest client contact list, and client profile object.');

    const { clientId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST },
        { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_PROFILE }
      ]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const contactListJson = this.findUserObject(userObjectList, { type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST, id: clientId });
    if (Utils.isNil(contactListJson)) {
      throw new Api.MalformedResponseException('Client contact list object could not be fetched.');
    } else {
      const contactListObject = new ClientObjectContactList(contactListJson);
      const { list: contactList } = await contactListObject.decryptedValue();
      this.state.clientContactList = contactList;
    }

    const profileJson = this.findUserObject(userObjectList, { type: process.env.CLIENT_OBJECT_TYPE_PROFILE, id: clientId });
    if (Utils.isNil(profileJson)) {
      throw new Api.MalformedResponseException('Client registration details object could not be fetched.');
    } else {
      const profileObject = new ClientObjectProfile(profileJson);
      this.state.clientProfileObject = profileObject;
      this.state.clientProfile = await profileObject.decryptedValue();
    }
  }

  private async fetchGlobalContactList(): Promise<void> {
    this.logger.info('Fetching the latest global contact list object.');

    const { globalContactListId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      dataObjects: { ids: [globalContactListId] },
      expectedCount: { dataObjects: null }
    };

    const { dataObjectList } = await this.fetchObjects(accessToken, query);

    const contactListJson = this.findDataObject(dataObjectList, { id: globalContactListId });
    if (Utils.isNil(contactListJson)) {
      throw new Api.MalformedResponseException('Global contact list object could not be fetched.');
    }

    const contactListObject = new DataObjectSigmailGlobalContactList(contactListJson);
    this.state.globalContactListObject = contactListObject;
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    await this.addUpdateOperationForGlobalContactList();
    await this.encryptGroupPublicKeysForGlobalContactList();
    await this.addUpdateOperationForClientProfile();
  }

  private async addUpdateOperationForGlobalContactList(): Promise<void> {
    const { globalContactListObject, clientProfileObject, clientContactList, requestBody } = this.state;

    const globalContactList = await globalContactListObject.decryptedValue();
    const clientProfile = await clientProfileObject.decryptedValue();
    const institute = Utils.pick(clientProfile, INSTITUTE_INFO_KEY_LIST);

    const groupContactList = clientContactList.filter((item): item is GroupContactListItem => item.type === 'group');
    this.state.groupContactList = groupContactList;

    let listChanged = groupContactList.length > 0;
    const updatedList = globalContactList.list.concat(groupContactList).map((item) => {
      if (!clientContactList.some(({ type, id }) => type === item.type && id === item.id)) {
        return item;
      }

      listChanged = true;

      const updatedItem: ContactListItem =
        item.type === 'group'
          ? { ...item, groupData: { ...item.groupData, institute } }
          : { ...item, userData: { ...item.userData, institute } };

      if (updatedItem.type === 'user') {
        (updatedItem.userData as any).$$formatver = undefined;
        (updatedItem.userData as any).license = undefined;
      }

      return updatedItem;
    });

    if (!listChanged) {
      return;
    }

    this.logger.info('Adding an update operation to request body for global contact list.');

    const updatedValue: DataObjectSigmailGlobalContactListValue = { ...globalContactList, list: updatedList };
    const updatedObject = await globalContactListObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.globalContactListObject = updatedObject;
  }

  private async encryptGroupPublicKeysForGlobalContactList(): Promise<void> {
    this.logger.info("Adding an insert operation to request body for each group's public key encrypted for global contact list.");

    const { accessToken, globalContactListId } = this.payload;
    const { groupContactList, requestBody } = this.state;

    const query: FetchObjectsRequestData = {
      keysByType: groupContactList.map(({ id }): Required<FetchObjectsRequestData>['keysByType'][0] => ({
        id,
        type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC
      }))
    };

    const { keyList } = await this.fetchObjects(accessToken, query);

    for (const { id: groupId } of groupContactList) {
      const groupKeyJsonPublic = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: groupId });
      if (Utils.isNil(groupKeyJsonPublic)) {
        throw new Api.MalformedResponseException(`Group public key could not be fetched. (Group ID = ${groupId})`);
      } else {
        const groupKeyPublic = new CryptographicKeyPublic(groupKeyJsonPublic);
        const key = await groupKeyPublic.encryptFor(globalContactListId);
        requestBody.insert(key);
      }
    }
  }

  private async addUpdateOperationForClientProfile(): Promise<void> {
    this.logger.info('Adding an update operation to request body for client profile.');

    const { clientProfileObject, clientProfile, requestBody } = this.state;

    const updatedValue: ClientObjectProfileValue = { ...clientProfile, $$formatver: 2 };
    const updatedObject = await clientProfileObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.clientProfileObject = updatedObject;
  }
}

export const migration_addGroupsAndInstituteInfoToGlobalContactList = (payload: Payload): AppThunk<Promise<void>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'addGroupsAndInstituteInfoToGlobalContactList');

    const migration = new MigrationAddGroupsAndInstituteInfoToGlobalContactList({ payload, apiService, logger: Logger });
    return await migration.execute();
  };
};
