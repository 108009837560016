import { GroupObjectAccessRightsValue as DV } from '@sigmail/objects';
import { GroupObject } from '.';

const TYPE = process.env.GROUP_OBJECT_TYPE_ACCESS_RIGHTS;

export class GroupObjectAccessRights extends GroupObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value);
  }

  public updateValue(newValue: DV): Promise<GroupObjectAccessRights> {
    const Class = this.constructor as typeof GroupObjectAccessRights;
    return Class.updateValue(this, newValue);
  }
}
