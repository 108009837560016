import { Utils } from '@sigmail/common';
import { DataObjectMsgFolderExtValue as DV } from '@sigmail/objects';
import { DataObject } from '.';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT;

export class DataObjectMsgFolderExt extends DataObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      Utils.isArray(value.data) &&
      (value.next === null || this.isValidId(value.next))
    );
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgFolderExt> {
    const Class = this.constructor as typeof DataObjectMsgFolderExt;
    return Class.updateValue(this, newValue);
  }
}
