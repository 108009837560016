import { Utils } from '@sigmail/common';
import { DataObjectMsgFolderValue as DV } from '@sigmail/objects';
import { DataObject } from '.';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_FOLDER;

export class DataObjectMsgFolder extends DataObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    let result = Utils.isArray(value.data) && (value.next === null || this.isValidId(value.next));
    if (result) {
      if (Utils.isUndefined(value.$$formatver) || value.$$formatver === 1) {
        return Utils.isInteger(value.totalItemCount);
      }
      return Utils.isPlainObject(value.itemCount);
    }

    return false;
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgFolder> {
    const Class = this.constructor as typeof DataObjectMsgFolder;
    return Class.updateValue(this, newValue);
  }
}
