const REGEX_VALID_BEARER_TOKEN = /^[A-Za-z0-9\-._~+/]+=*/;
const REGEX_VALID_ID_TOKEN = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

export function isValidJwtToken(value: any, tokenType: 'bearer' | 'id' = 'bearer'): boolean {
  const jwtTokenType: typeof tokenType = arguments.length >= 2 ? arguments[1] : 'bearer';
  switch (jwtTokenType) {
    case 'bearer':
      return REGEX_VALID_BEARER_TOKEN.test(value);
    case 'id':
      return REGEX_VALID_ID_TOKEN.test(value);
    default:
      return false;
  }
}
