import { Nullable, PersonName } from '@sigmail/common';
import { isNil, isString, transform } from '.';

const KEYS: Array<keyof PersonName> = ['prefix', 'firstName', 'middleName', 'lastName', 'suffix'];

export function joinPersonName(props: Nullable<PersonName> | null | undefined): string {
  if (isNil(props)) {
    return '';
  }

  const name = transform(
    KEYS,
    (result, prop, index) => {
      result[index] = isString(props[prop]) && props[prop]!.trim();
    },
    Array<string | false>(KEYS.length)
  );

  return name.filter(Boolean).join(' ');
}
