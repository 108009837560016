// @ts-nocheck
import { ActionPayloadBatchQueryDataSuccess } from '@sigmail/app-state';
import { Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObjectMsgFolder,
  DataObjectMsgFolderValue,
  GroupObjectFolderList,
  GroupObjectFolderListValue,
  GroupObjectFolderListValue_v1,
  IUserObject,
  UserObjectFolderList,
  UserObjectFolderListValue,
  UserObjectFolderListValue_v1
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';

interface Payload {
  accessToken: string;
  userId: number;
  roleId: string;
  groupId: number;
  ownerId: number;
  auditId: number;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

interface State {
  userFolderListObject: IUserObject<UserObjectFolderListValue>;
  userFolderList: UserObjectFolderListValue;
  dtServer: Date;
  groupFolderListObject: IUserObject<GroupObjectFolderListValue>;
  groupFolderList: GroupObjectFolderListValue;
  requestBody: BatchUpdateRequestBuilder;
  groupArchivedFolderId: number;
}

class MigrationBumpUserAndGroupFolderListToVersionTwo extends BaseAction<Payload, State> {
  protected async onExecute() {
    await this.applyUserFolderListMigration();
    await this.applyGroupFolderListMigration();

    const { successPayload } = this.payload;
    const { userFolderListObject, groupFolderListObject } = this.state;

    // manually patch successPayload's data with updated objects
    do {
      const userObjectList = [userFolderListObject, groupFolderListObject];
      for (const obj of userObjectList) {
        const index = this.findUserObjectIndex(successPayload.response.userObjectsByType!, { type: obj.type, id: obj.id });
        if (index !== -1) {
          successPayload.response.userObjectsByType![index] = obj.toApiFormatted();
        }
      }
    } while (false);
  }

  private async applyUserFolderListMigration(): Promise<void> {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchUserMessageFolderList();

        const { $$formatver } = this.state.userFolderList;
        if (!Utils.isNil($$formatver) && $$formatver >= 2) {
          this.logger.info(`Call ignored; user folder list migration is not required. ($$formatver = ${$$formatver})`);
          break;
        }

        this.state.requestBody = new BatchUpdateRequestBuilder();
        await this.addUpdateOperationForUserMessageFolderList();

        await this.batchUpdateData(this.payload.accessToken, this.state.requestBody.build());

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchUserMessageFolderList(): Promise<void> {
    this.logger.info('Fetching the latest user message folder list.');

    const { userId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [{ userId, type: process.env.USER_OBJECT_TYPE_FOLDER_LIST }]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const folderListJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_FOLDER_LIST, userId });
    if (Utils.isNil(folderListJson)) throw new Api.MalformedResponseException('User folder list object could not be fetched.');

    this.state.userFolderListObject = new UserObjectFolderList(folderListJson);
    this.state.userFolderList = await this.state.userFolderListObject.decryptedValue();
  }

  private async addUpdateOperationForUserMessageFolderList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for user message folder list.');

    const { userFolderListObject, userFolderList, requestBody } = this.state;
    const userFolderList_v1 = (userFolderList as unknown) as UserObjectFolderListValue_v1;

    const updatedValue: UserObjectFolderListValue = {
      $$formatver: 2,
      msg: {
        inbox: {
          ...userFolderList_v1.inbox,
          children: {
            archived: userFolderList_v1.archived,
            deleted: userFolderList_v1.deleted
          }
        },
        sent: userFolderList_v1.sent,
        drafts: userFolderList_v1.drafts
      },
      doc: {}
    };

    const updatedFolderListObject = await userFolderListObject.updateValue(updatedValue);
    requestBody.update(updatedFolderListObject);

    this.state.userFolderListObject = updatedFolderListObject;
  }

  private async applyGroupFolderListMigration(): Promise<void> {
    if (Utils.MedicalInstitute.isGuestRole(this.payload.roleId)) {
      return;
    }

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchGroupMessageFolderList();

        const { $$formatver } = this.state.groupFolderList;
        if (!Utils.isNil($$formatver) && $$formatver >= 2) {
          this.logger.info(`Call ignored; group folder list migration is not required. ($$formatver = ${$$formatver})`);
          break;
        }

        this.state.requestBody = new BatchUpdateRequestBuilder();
        await this.addInsertOperationForGroupArchivedFolder();
        await this.addUpdateOperationForGroupMessageFolderList();

        await this.dispatchBatchUpdateData(this.payload.accessToken, this.state.requestBody.build());

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchGroupMessageFolderList(): Promise<void> {
    this.logger.info('Fetching the latest group message folder list.');

    const { groupId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [{ userId: groupId, type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST }]
    };

    const { serverDateTime, userObjectList } = await this.fetchObjects(accessToken, query);

    const folderListJson = this.findUserObject(userObjectList, { type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST, userId: groupId });
    if (Utils.isNil(folderListJson)) throw new Api.MalformedResponseException('Group folder list object could not be fetched.');

    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);
    this.state.groupFolderListObject = new GroupObjectFolderList(folderListJson);
    this.state.groupFolderList = await this.state.groupFolderListObject.decryptedValue();
  }

  private async addInsertOperationForGroupArchivedFolder(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for group archived folder.');

    const { accessToken, ownerId, groupId, auditId } = this.payload;
    const { dtServer, requestBody } = this.state;

    const [folderId] = await this.fetchIds(accessToken, 1);
    const data: DataObjectMsgFolderValue<2> = {
      $$formatver: 2,
      data: [],
      next: null,
      itemCount: {
        all: { total: 0, unread: 0 },
        important: { total: 0, unread: 0 },
        reminder: { total: 0, unread: 0 },
        billable: { total: 0, unread: 0 }
      }
    };

    const msgFolderObject = await DataObjectMsgFolder.create(
      folderId,
      undefined,
      1,
      (data as unknown) as DataObjectMsgFolderValue,
      ownerId,
      groupId,
      dtServer
    );
    const keyList = await msgFolderObject.generateKeysEncryptedFor(auditId);
    keyList.push(msgFolderObject[Constants.$$CryptographicKey]);
    requestBody.insert(msgFolderObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    this.state.groupArchivedFolderId = folderId;
  }

  private async addUpdateOperationForGroupMessageFolderList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for group message folder list.');

    const { groupArchivedFolderId, groupFolderListObject, groupFolderList, requestBody } = this.state;
    const groupFolderList_v1 = (groupFolderList as unknown) as GroupObjectFolderListValue_v1;

    const updatedValue: GroupObjectFolderListValue = {
      $$formatver: 2,
      msg: {
        $group_inbox: {
          ...groupFolderList_v1.$group_inbox,
          children: {
            archived: { id: groupArchivedFolderId, type: process.env.DATA_OBJECT_TYPE_MSG_FOLDER },
            deleted: undefined
          }
        }
      },
      doc: {}
    };

    const updatedFolderListObject = await groupFolderListObject.updateValue(updatedValue);
    requestBody.update(updatedFolderListObject);

    this.state.groupFolderListObject = updatedFolderListObject;
  }
}

export const migration_bumpUserAndGroupFolderListToVersionTwo = (payload: Payload): AppThunk<Promise<void>> => {
  return async (dispatch, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'bumpUserAndGroupFolderListToVersionTwo:');

    const migration = new MigrationBumpUserAndGroupFolderListToVersionTwo({ payload, dispatch, apiService, logger: Logger });
    return await migration.execute();
  };
};
