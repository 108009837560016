export const ROUTE_APP_ROOT: string = 'routeAppRoot';

export const ROUTE_SIGN_IN: string = 'routeSignIn';
export const ROUTE_SIGN_OUT: string = 'routeSignOut';

export const ROUTE_FORGOT_PASSWORD = 'routeForgotPassword';
export const ROUTE_INVITE_INSTITUTE = 'routeInviteInstitute';

export const ROUTE_REGISTER: string = 'routeRegister';
export const ROUTE_REGISTER_ROOT: string = 'routeRegister/root';
export const ROUTE_REGISTER_INSTITUTE: string = 'routeRegister/institute';

export const ROUTE_ACCOUNT: string = 'routeAccount';
export const ROUTE_ACCOUNT_ROOT: string = 'routeAccount/root';
export const ROUTE_ACCOUNT_MANAGE_SELF: string = 'routeAccount/myAccount';
export const ROUTE_ACCOUNT_MANAGE_MEMBERS: string = 'routeAccount/members';
export const ROUTE_ACCOUNT_BULK_INVITE_GUESTS: string = 'routeAccount/bulkInviteGuests';
export const ROUTE_ACCOUNT_SETUP: string = 'routeAccount/setup';
export const ROUTE_ACCOUNT_SETUP_ROOT: string = 'routeAccount/setup/root';
export const ROUTE_ACCOUNT_SETUP_CREDENTIALS: string = 'routeAccount/setup/credentials';
export const ROUTE_ACCOUNT_SETUP_RECOVERY: string = 'routeAccount/setup/recovery';
export const ROUTE_ACCOUNT_SETUP_PROFILE_INFO: string = 'routeAccount/setup/profileInfo';
export const ROUTE_ACCOUNT_SETUP_SUCCESS: string = 'routeAccount/setup/success';
export const ROUTE_ACCOUNT_SETUP_SECURITY: string = 'routeAccount/setup/security';

export const ROUTE_MAIL: string = 'routeMail';
export const ROUTE_MAIL_ROOT: string = 'routeMail/root';
export const ROUTE_MAIL_COMPOSE_HRM_MESSAGE: string = 'routeMail/composeHrmMessage';
export const ROUTE_MAIL_COMPOSE_MESSAGE: string = 'routeMail/composeMessage';
export const ROUTE_MAIL_COMPOSE_REFERRAL: string = 'routeMail/composeReferral';
export const ROUTE_MAIL_INBOX: string = 'routeMail/inbox';
export const ROUTE_MAIL_INBOX_SHARED: string = 'routeMail/sharedInbox';
export const ROUTE_MAIL_REMINDER: string = 'routeMail/reminder';
export const ROUTE_MAIL_IMPORTANT: string = 'routeMail/important';
export const ROUTE_MAIL_IMPORTANT_SHARED: string = 'routeMail/sharedImportant';
export const ROUTE_MAIL_REFERRALS: string = 'routeMail/referrals';
export const ROUTE_MAIL_REFERRALS_SHARED: string = 'routeMail/sharedReferrals';
export const ROUTE_MAIL_ARCHIVED: string = 'routeMail/archived';
export const ROUTE_MAIL_ARCHIVED_SHARED: string = 'routeMail/sharedArchived';
export const ROUTE_MAIL_TRASH: string = 'routeMail/trash';
export const ROUTE_MAIL_TRASH_SHARED: string = 'routeMail/sharedTrash';
export const ROUTE_MAIL_SENT: string = 'routeMail/sent';
export const ROUTE_MAIL_DRAFTS: string = 'routeMail/drafts';
export const ROUTE_MAIL_USER_DEFINED_FOLDER: string = 'routeMail/userDefinedFolder';

export const ROUTE_CONTACT_LIST: string = 'routeContactList';
export const ROUTE_CONTACT_LIST_ROOT: string = 'routeContactList/root';
export const ROUTE_CONTACT_LIST_GLOBAL_USERS = 'routeContactList/globalUsers';
export const ROUTE_CONTACT_LIST_GLOBAL_GROUPS = 'routeContactList/globalGroups';
export const ROUTE_CONTACT_LIST_CLIENT: string = 'routeContactList/client';
export const ROUTE_CONTACT_LIST_GUEST: string = 'routeContactList/guest';

export const ROUTE_DOCUMENT_LIST = 'routeDocumentList';

export const ROUTE_FAQ: string = 'routeFaq';
export const ROUTE_TERMS_AND_CONDITIONS: string = 'routeTermsAndConditions';
export const ROUTE_PRIVACY_POLICY: string = 'routePrivacyPolicy';
export const ROUTE_CONTACT_US: string = 'routeContactUs';
