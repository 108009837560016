import { AlgorithmCode, AppException, Constants, EncapsulatedKey, Utils } from '@sigmail/common';
import { Algorithm, Constants as CryptoConstants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import {
  ApiFormattedCryptographicKey,
  ApiFormattedVersionedSigmailObject,
  EncryptAsymmetricKeyParams,
  EncryptEncapsulatedKeyParams,
  ICryptographicKey,
  IVersionedSigmailObject
} from '@sigmail/objects';
import { VersionedSigmailObject } from '../versioned-sigmail-object';

const ErrorConstants = { ...Constants.Error, ...CryptoConstants.Error };

const PROPS: ReadonlyArray<
  keyof Omit<ICryptographicKey<any>, keyof IVersionedSigmailObject<any> | 'decryptedValue'>
> = ['value', 'encryptedForId', 'createdAtUtc', 'expiredAtUtc'];

const API_FORMATTED_PROPS: ReadonlyArray<
  keyof Omit<ApiFormattedCryptographicKey, keyof ApiFormattedVersionedSigmailObject>
> = ['encryptedForId', 'value', 'createdAtUtc', 'expiredAtUtc'];

/**
 * TODO document
 *
 * @template DV Type of decrypted value. `JsonWebKey` or `EncapsulatedKey`
 */
export abstract class CryptographicKey<DV extends JsonWebKey | EncapsulatedKey>
  extends VersionedSigmailObject<DV>
  implements ICryptographicKey<DV> {
  private static readonly KnownPrivateKeys = new Map<number, CryptoKey>();
  private static readonly KnownPublicKeys = new Map<number, CryptoKey>();

  public static encryptedForIds(): IterableIterator<number> {
    return this.KnownPrivateKeys.keys();
  }

  public static getPrivateKey(keyId: number): CryptoKey | undefined {
    if (!this.KnownPrivateKeys.has(keyId)) {
      throw new AppException(ErrorConstants.S_ERROR, `Key could not be found. (keyId: ${keyId})`);
    }
    return this.KnownPrivateKeys.get(keyId);
  }

  public static setPrivateKey(keyId: number, privateKey: CryptoKey): void {
    this.KnownPrivateKeys.set(keyId, privateKey);
  }

  public static clearPrivateKey(keyId: number): boolean {
    return this.KnownPrivateKeys.delete(keyId);
  }

  public static clearAllPrivateKeys(): void {
    this.KnownPrivateKeys.clear();
  }

  public static getPublicKey(keyId: number): CryptoKey | undefined {
    if (!this.KnownPublicKeys.has(keyId)) {
      throw new AppException(ErrorConstants.S_ERROR, `Key could not be found. (keyId: ${keyId})`);
    }
    return this.KnownPublicKeys.get(keyId);
  }

  public static setPublicKey(keyId: number, publicKey: CryptoKey): void {
    this.KnownPublicKeys.set(keyId, publicKey);
  }

  public static clearPublicKey(keyId: number): boolean {
    return this.KnownPublicKeys.delete(keyId);
  }

  public static clearAllPublicKeys(): void {
    this.KnownPublicKeys.clear();
  }

  /** Determines if the given value is a valid encrypted value. */
  public static isValidEncryptedValue(value: any): value is string {
    return Utils.isString(value);
  }

  /** Determines if the given value is a valid creation date. */
  public static isValidCreationDate(value: any): value is Date {
    return Utils.isValidDate(value);
  }

  /**
   * Determines if the given value is a valid expiry date. `null` is considered
   * a valid value.
   */
  public static isValidExpiryDate(value: any): value is Date | null {
    return value === null || Utils.isValidDate(value);
  }

  /** @override */
  public static isAssignableFrom(obj: any): obj is ICryptographicKey<any> {
    return (
      super.isAssignableFrom(obj) === true &&
      Utils.every(PROPS, Utils.partial(Utils.has, obj)) &&
      this.isValidEncryptedValue(obj.value) &&
      this.isValidId(obj.encryptedForId) &&
      this.isValidCreationDate(obj.createdAtUtc) &&
      this.isValidExpiryDate(obj.expiredAtUtc) &&
      typeof obj.decryptedValue === 'function'
    );
  }

  /** @override */
  public static isApiFormatted(obj: any): obj is ApiFormattedCryptographicKey {
    return (
      super.isApiFormatted(obj) === true &&
      Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, obj)) &&
      Utils.isString(obj.value) &&
      Utils.isNumber(obj.encryptedForId) &&
      Utils.isString(obj.createdAtUtc) &&
      (obj.expiredAtUtc === null || Utils.isString(obj.expiredAtUtc))
    );
  }

  protected static encryptAsymmetricKey(key: JsonWebKey, params: EncryptAsymmetricKeyParams): Promise<string> {
    if (!this.isValidId(params.encryptedFor)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_ID, 'Invalid ID. (Parameter: encryptedFor)');
    }

    if (!Algorithm.isValidEncryptAsymmetricKeyCode(params.keyCode)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }

    const version = Utils.isNil(params.keyVersion) ? 0 : params.keyVersion;
    if (!this.isValidVersion(version)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_VERSION);
    }

    const algorithm = getAlgorithm(params.keyCode);
    const publicKey = this.getPublicKey(params.encryptedFor);
    return algorithm.encrypt({ publicKey }, key, version);
  }

  protected static encryptEncapsulatedKey(key: EncapsulatedKey, params: EncryptEncapsulatedKeyParams): Promise<string> {
    if (!this.isValidId(params.encryptedFor)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_ID, 'Invalid ID. (Parameter: encryptedFor)');
    }

    if (!Algorithm.isValidEncryptEncapsulatedKeyCode(params.keyCode)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }

    const version = Utils.isNil(params.keyVersion) ? 0 : params.keyVersion;
    if (!this.isValidVersion(version)) {
      throw new AppException(ErrorConstants.E_INVALID_OBJECT_VERSION);
    }

    const algorithm = getAlgorithm(params.keyCode);
    const publicKey = this.getPublicKey(params.encryptedFor);
    return algorithm.encrypt({ publicKey }, key, version);
  }

  public readonly value: string;
  public readonly encryptedForId: number;
  public readonly createdAtUtc: Date;
  public readonly expiredAtUtc: Date | null;

  public constructor(
    id: number,
    code: AlgorithmCode,
    version: number,
    value: string,
    encryptedForId: number,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  );

  public constructor(obj: ApiFormattedCryptographicKey);

  public constructor(...args: any[]) {
    super(...(args.length === 1 ? args : [undefined, ...args]));

    const Class = this.constructor as typeof CryptographicKey;
    if (Class === CryptographicKey) throw new TypeError('Initialization error.');

    const value = args.length === 1 ? args[0].value : args[3];
    const encryptedForId = args.length === 1 ? args[0].encryptedForId : args[4];
    const createdAtUtc = args.length === 1 ? new Date(args[0].createdAtUtc) : args[5];
    const expiredAtUtc = args.length === 1 ? args[0].expiredAtUtc : args[6];
    const expiryDate = Utils.isNil(expiredAtUtc) ? null : new Date(expiredAtUtc);

    if (!Class.isValidEncryptedValue(value)) throw new AppException(ErrorConstants.E_INVALID_OBJECT_VALUE);
    if (!Class.isValidId(encryptedForId)) throw new AppException(ErrorConstants.E_INVALID_USER_OR_GROUP_ID);
    if (!Class.isValidCreationDate(createdAtUtc)) throw new AppException(ErrorConstants.E_INVALID_CREATION_DATE);
    if (!Class.isValidExpiryDate(expiryDate)) throw new AppException(ErrorConstants.E_INVALID_EXPIRY_DATE);

    this.value = value;
    this.encryptedForId = encryptedForId;
    this.createdAtUtc = createdAtUtc;
    this.expiredAtUtc = expiryDate;
  }

  /** @override */
  public equals(other: any): other is ICryptographicKey<any> {
    return super.equals(other) === true && this.encryptedForId === other.encryptedForId;
  }

  /** @override */
  public hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.encryptedForId)) | 0;
    return hashed;
  }

  public abstract decryptedValue(...args: any[]): Promise<DV>;

  /** @override */
  public toApiFormatted(): ApiFormattedCryptographicKey {
    const apiFormatted = super.toApiFormatted();
    return {
      ...apiFormatted,
      value: this.value,
      encryptedForId: this.encryptedForId,
      createdAtUtc: this.createdAtUtc.toISOString(),
      expiredAtUtc: this.expiredAtUtc === null ? null : this.expiredAtUtc.toISOString()
    };
  }
}
