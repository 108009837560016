import { isAfter, isBefore } from 'date-fns';
import React from 'react';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export interface FormInputProps
  extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'ref'> {
  inputRef?: Writeable<React.RefObject<HTMLInputElement>> | ((instance: HTMLInputElement | null) => void);
  noErrorMessageBlock?: boolean;
  customValidity?: string;
  validationError?: { [key: string]: string };
  errorMessageClassName?: string;
  onValidityStateChange?: (target: HTMLInputElement, validityState?: string[], errorMessage?: string) => void;
}

interface FormInputState {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  validityState?: string[];
  errorMessage?: string;
}

interface FormInputSnapshot {
  forceValidate: boolean;
}

function validateRequired(value: any) {
  switch (typeof value) {
    case 'string':
      return value.length > 0;
    case 'number':
      return Number.isFinite(value);
    case 'boolean':
      return value === true;
    default:
      return false;
  }
}

function validatePattern(value: string, pattern: string) {
  if (typeof pattern !== 'string') {
    throw new TypeError('Argument <pattern> must be of type string.');
  }
  return typeof value === 'string' && (value.length === 0 || RegExp(pattern, 'u').test(value));
}

function validateRange(value: number, rangeValue: number, rangeValueType: 'minValue' | 'maxValue'): boolean;
function validateRange(value: Date, rangeValue: Date, rangeValueType: 'minDate' | 'maxDate'): boolean;
function validateRange(
  value: any,
  rangeValue: any,
  rangeValueType: 'minValue' | 'maxValue' | 'minDate' | 'maxDate'
): boolean {
  if (rangeValueType === 'minValue' || rangeValueType === 'maxValue') {
    if (typeof rangeValue !== 'number' || !Number.isFinite(rangeValue)) {
      throw new TypeError('Argument <rangeValue> must be a finite number.');
    }
    if (typeof value === 'number' && Number.isFinite(value)) {
      return rangeValueType === 'minValue' ? value >= rangeValue : value <= rangeValue;
    }
  } else {
    if (!(rangeValue instanceof Date) || !Number.isFinite(rangeValue.getTime())) {
      throw new TypeError('Argument <rangeValue> must be a valid of type Date.');
    }
    if (value instanceof Date && Number.isFinite(value.getTime())) {
      return (
        // isEqual(value, rangeValue) ||
        value.getTime() === rangeValue.getTime() ||
        (rangeValueType === 'minDate' ? isAfter(value, rangeValue) : isBefore(value, rangeValue))
      );
    }
  }
  return false;
}

function validateLength(value: string, length: number, rangeValueType: 'min' | 'max') {
  if (!Number.isFinite(length) || length < 1 || length > Number.MAX_SAFE_INTEGER) {
    throw new TypeError(`Argument <length> must be a number between 1 and ${Number.MAX_SAFE_INTEGER}.`);
  }
  return (
    typeof value === 'string' && validateRange(value.length, length, rangeValueType === 'min' ? 'minValue' : 'maxValue')
  );
}

function getValidityState(
  value: any,
  constraints: any,
  validationError?: { [key: string]: string },
  customValidity?: string
): Pick<FormInputState, 'validityState' | 'errorMessage'> {
  const { required, minLength, maxLength, pattern } = constraints;
  const validityState: string[] = [];

  let tooShort = false;
  if (required) {
    if (!validateRequired(value)) {
      validityState.push('valueMissing');
      tooShort = true;
    } else if (minLength > 0 && !validateLength(value, minLength, 'min')) {
      validityState.push('tooShort');
      tooShort = true;
    }
  } else if (minLength >= 1 && value.length !== 0 && !validateLength(value, minLength, 'min')) {
    validityState.push('tooShort');
    tooShort = true;
  }

  if (!tooShort && maxLength >= 0 && !validateLength(value, maxLength, 'max')) {
    validityState.push('tooLong');
  }

  if (pattern && !validatePattern(value, pattern)) {
    validityState.push('patternMismatch');
  }

  if (typeof customValidity === 'string') {
    validityState.push('custom');
  }

  let errorMessage: string | undefined = undefined;
  if (validityState.length > 0) {
    errorMessage = ''; // signifies error message unavailable
    if (validityState[0] === 'custom') {
      errorMessage = typeof customValidity === 'string' ? customValidity : '';
    } else if (typeof validationError === 'object' && validationError !== null) {
      errorMessage = validationError[validityState[0]];
    }
  }

  return { validityState: validityState.length === 0 ? undefined : validityState, errorMessage };
}

class FormInputComponent extends React.PureComponent<FormInputProps, FormInputState, FormInputSnapshot> {
  private readonly _inputRef: Writeable<React.RefObject<HTMLInputElement>>;
  private readonly _listener: WeakMap<HTMLInputElement, (event: HTMLElementEventMap['input']) => any> = new WeakMap();

  static getDerivedStateFromProps(
    nextProps: Readonly<FormInputProps>,
    prevState: Readonly<FormInputState>
  ): Partial<FormInputState> | null {
    const { required, minLength, maxLength, pattern } = nextProps;

    const max =
      typeof maxLength === 'number' && Number.isSafeInteger(maxLength) && maxLength > 0
        ? maxLength
        : Number.MAX_SAFE_INTEGER;

    const min = Math.max(
      typeof minLength === 'number' && Number.isSafeInteger(minLength)
        ? Math.max(minLength > max ? 0 : minLength, 0)
        : 0,
      !!required ? 1 : 0
    );

    const oldState = ((): FormInputState => {
      const { required, minLength, maxLength, pattern } = prevState;
      return { required, minLength, maxLength, pattern };
    })();

    const nextState: FormInputState = {
      required: !!required,
      minLength: min,
      maxLength: max,
      pattern: typeof pattern === 'string' ? pattern : undefined,
      validityState: undefined,
      errorMessage: undefined
    };

    const isEqual =
      oldState.required === nextState.required &&
      oldState.minLength === nextState.minLength &&
      oldState.maxLength === nextState.maxLength &&
      oldState.pattern === nextState.pattern;

    return isEqual ? null : nextState;
  }

  constructor(props: FormInputProps) {
    super(props);

    this.state = {};

    this._inputRef = { current: null };

    this.onInputRef = this.onInputRef.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
  }

  getSnapshotBeforeUpdate(prevProps: Readonly<FormInputProps>): FormInputSnapshot | null {
    if (
      prevProps.value !== this.props.value ||
      prevProps.required !== this.props.required ||
      prevProps.minLength !== this.props.minLength ||
      prevProps.maxLength !== this.props.maxLength ||
      prevProps.pattern !== this.props.pattern ||
      prevProps.customValidity !== this.props.customValidity
    ) {
      return { forceValidate: true };
    }
    return null;
  }

  componentDidMount(): void {
    this.onInputChange();
  }

  componentDidUpdate(_P: Readonly<FormInputProps>, _S: Readonly<FormInputState>, snapshot?: FormInputSnapshot) {
    if (typeof snapshot === 'object' && snapshot !== null && snapshot.forceValidate === true) {
      this.onInputChange();
    }
  }

  componentWillUnmount(): void {
    const { current: elInput } = this._inputRef;
    if (elInput !== null) {
      const listener = this._listener.get(elInput);
      if (listener) {
        elInput.removeEventListener('input', this.onInputChange);
        this._listener.delete(elInput);
      }
    }
  }

  render(): React.ReactNode {
    const { required, minLength, maxLength, pattern, inputRef, errorMessageClassName, ...otherProps } = this.props;

    delete otherProps.noErrorMessageBlock;
    delete otherProps.customValidity;
    delete otherProps.validationError;
    delete otherProps.onValidityStateChange;

    const { id, name } = otherProps;
    otherProps['aria-disabled'] = otherProps.disabled;
    otherProps['aria-invalid'] = !!this.state.validityState && this.state.validityState.length > 0;
    otherProps['aria-required'] = this.state.required;

    let errorControlId = '';
    if (this.props.noErrorMessageBlock !== true) {
      errorControlId =
        typeof id === 'string' ? `${id.trim()}_errorMsg` : typeof name === 'string' ? `${name.trim()}_errorMsg` : '';
      if (errorControlId.length > 0) {
        otherProps['aria-describedby'] = errorControlId;
        otherProps['aria-errormessage'] = errorControlId;
      }
    }

    return (
      <React.Fragment>
        <input ref={this.onInputRef} {...otherProps} />
        {errorControlId.length > 0 && (
          <div id={errorControlId} className={errorMessageClassName}>
            {typeof this.state.errorMessage === 'string' && this.state.errorMessage}
          </div>
        )}
      </React.Fragment>
    );
  }

  onInputRef(el: HTMLInputElement): void {
    const prevRef = this._inputRef.current;
    this._inputRef.current = el;

    if (el === null && prevRef !== null) {
      const listener = this._listener.get(prevRef);
      if (listener) {
        prevRef.removeEventListener('input', listener);
        this._listener.delete(prevRef);
      }
    }

    try {
      if (typeof this.props.inputRef === 'function') {
        this.props.inputRef(el);
      } else if (
        typeof this.props.inputRef === 'object' &&
        this.props.inputRef !== null &&
        'current' in this.props.inputRef
      ) {
        this.props.inputRef.current = el;
      }
    } catch {
      /* ignore */
    }

    if (el !== null) {
      this.onInputChange();

      let timeout: number | null = null;
      const listener = () => {
        if (timeout) {
          window.cancelAnimationFrame(timeout);
        }
        timeout = window.requestAnimationFrame(this.onInputChange);
      };
      el.addEventListener('input', listener);
      this._listener.set(el, listener);
    }
  }

  onInputChange(): void {
    if (this._inputRef.current === null) {
      return;
    }

    this.setState(
      getValidityState(this._inputRef.current.value, this.state, this.props.validationError, this.props.customValidity)
    );

    setTimeout(() =>
      this.setState((state, props) => {
        if (this._inputRef.current !== null && typeof props.onValidityStateChange === 'function') {
          props.onValidityStateChange(this._inputRef.current, state.validityState, state.errorMessage);
        }
        return null;
      })
    );
  }
}

export const FormInput = FormInputComponent;
