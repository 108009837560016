// @ts-nocheck
import { ActionPayloadBatchQueryDataSuccess } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ClientObjectContactList,
  ClientObjectContactListValue,
  DataObjectSigmailGlobalContactList,
  DataObjectSigmailGlobalContactListValue,
  IDataObject,
  IUserObject,
  UserContactListItem,
  UserObjectContactInfo,
  UserObjectContactInfoValue,
  UserObjectProfileBasic,
  UserObjectProfileProtected
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';

const CONTACT_INFO_DATA_BASIC: {
  [key: string]: ReadonlyArray<
    keyof Pick<
      UserObjectContactInfoValue,
      | 'prefix'
      | 'firstName'
      | 'middleName'
      | 'lastName'
      | 'suffix'
      | 'emailAddress'
      | 'specialty'
      | 'otherSpecialties'
      | 'academicDegrees'
      | 'role'
    >
  >;
} = {
  all: ['prefix', 'firstName', 'middleName', 'lastName', 'suffix', 'emailAddress', 'role'],
  nonGuest: ['academicDegrees', 'specialty', 'otherSpecialties']
};

const CONTACT_INFO_DATA_PROTECTED: {
  [key: string]: ReadonlyArray<
    keyof Pick<
      UserObjectContactInfoValue,
      | 'addressLine1'
      | 'addressLine2'
      | 'addressLevel2'
      | 'addressLevel1'
      | 'postalCode'
      | 'cellNumber'
      | 'homeNumber'
      | 'officeNumber'
      | 'officeNumberExt'
      | 'faxNumber'
      | 'healthCardNumber'
      | 'groupName'
    >
  >;
} = {
  all: ['addressLine1', 'addressLine2', 'addressLevel2', 'addressLevel1', 'postalCode', 'cellNumber'],
  guest: ['homeNumber', 'healthCardNumber'],
  nonGuest: ['officeNumber', 'officeNumberExt', 'faxNumber', 'groupName']
};

export interface Payload {
  accessToken: string;
  userId: number;
  clientId: number;
  globalContactListId: number;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

interface State {
  contactInfoObject: IUserObject<UserObjectContactInfoValue>;
  contactInfo: UserObjectContactInfoValue;
  roleId: string;
  requestBody: BatchUpdateRequestBuilder;
  clientContactListObject: IUserObject<ClientObjectContactListValue>;
  globalContactListObject: IDataObject<DataObjectSigmailGlobalContactListValue>;
}

class MigrationBumpContactInfoToVersionTwo extends BaseAction<Payload, State> {
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchContactInfo();

        const { $$formatver } = this.state.contactInfo;
        if (!Utils.isNil($$formatver) && $$formatver >= 2) {
          this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
          return;
        }

        await this.generateRequestBody();

        const { accessToken, successPayload } = this.payload;
        const { requestBody, contactInfoObject, clientContactListObject, globalContactListObject } = this.state;
        await this.batchUpdateData(accessToken, requestBody.build());

        // manually patch successPayload's data with updated objects
        do {
          const userObjectList = [contactInfoObject, clientContactListObject];
          for (const obj of userObjectList) {
            const index = this.findUserObjectIndex(successPayload.response.userObjectsByType!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              successPayload.response.userObjectsByType![index] = obj.toApiFormatted();
            }
          }

          const dataObjectList = [globalContactListObject];
          for (const obj of dataObjectList) {
            const index = this.findDataObjectIndex(successPayload.response.dataObjects!, { type: obj.type, id: obj.id });
            if (index !== -1) {
              successPayload.response.dataObjects![index] = obj.toApiFormatted();
            }
          }
        } while (false);

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchContactInfo(): Promise<void> {
    this.logger.info('Fetching the latest contact info object.');

    const { userId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [{ userId, type: process.env.USER_OBJECT_TYPE_CONTACT_INFO }]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const contactInfoJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_CONTACT_INFO, userId });
    if (Utils.isNil(contactInfoJson)) {
      throw new Api.MalformedResponseException('Contact info object could not be fetched.');
    }

    this.state.contactInfoObject = new UserObjectContactInfo(contactInfoJson);
    this.state.contactInfo = await this.state.contactInfoObject.decryptedValue();
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    await this.addUpdateOperationForContactInfo();

    const { roleId } = this.state;
    if (Utils.MedicalInstitute.isNonGuestRole(roleId)) {
      await this.fetchClientContactList();
      await this.addUpdateOperationForClientContactList();

      if (Utils.MedicalInstitute.isPhysicianRole(roleId)) {
        await this.fetchGlobalContactList();
        await this.addUpdateOperationForGlobalContactList();
      }
    }
  }

  private async addUpdateOperationForContactInfo(): Promise<void> {
    this.logger.info('Adding an update operation to request body for contact info object.');

    const { userId, accessToken } = this.payload;
    const { contactInfoObject, requestBody } = this.state;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC },
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED }
      ]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const basicProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId });
    if (Utils.isNil(basicProfileJson)) throw new Api.MalformedResponseException('Basic profile object could not be fetched.');
    const protectedProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED, userId });
    if (Utils.isNil(protectedProfileJson)) throw new Api.MalformedResponseException('Protected profile object could not be fetched.');

    const basicProfileObject = new UserObjectProfileBasic(basicProfileJson);
    const protectedProfileObject = new UserObjectProfileProtected(protectedProfileJson);
    const basicProfile = await basicProfileObject.decryptedValue();
    const protectedProfile = await protectedProfileObject.decryptedValue();

    let updatedValue: UserObjectContactInfoValue = {
      $$formatver: 2,
      ...Utils.pick(basicProfile, CONTACT_INFO_DATA_BASIC.all),
      ...Utils.pick(protectedProfile, CONTACT_INFO_DATA_PROTECTED.all)
    };

    if (Utils.MedicalInstitute.isNonGuestRole(basicProfile.role)) {
      updatedValue = {
        ...updatedValue,
        ...Utils.pick(basicProfile, CONTACT_INFO_DATA_BASIC.nonGuest),
        ...Utils.pick(protectedProfile, CONTACT_INFO_DATA_PROTECTED.nonGuest)
      };
    } else {
      updatedValue = {
        ...updatedValue,
        ...Utils.pick(protectedProfile, CONTACT_INFO_DATA_PROTECTED.guest)
      };
    }

    const updatedObject = await contactInfoObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.contactInfoObject = updatedObject;
    this.state.contactInfo = updatedValue;
    this.state.roleId = basicProfile.role;
  }

  private async fetchClientContactList(): Promise<void> {
    this.logger.info('Fetching the latest client contact list object.');

    const { clientId: userId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [{ userId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST }]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const contactListJson = this.findUserObject(userObjectList, { type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST, userId });
    if (Utils.isNil(contactListJson)) throw new Api.MalformedResponseException('Client contact list could not be fetched.');

    const contactListObject = new ClientObjectContactList(contactListJson);
    this.state.clientContactListObject = contactListObject;
  }

  private async addUpdateOperationForClientContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for client contact list object.');

    const {
      clientContactListObject: contactListObject,
      contactInfo: { $$formatver, ...contactInfo },
      requestBody
    } = this.state;

    const contactList = await contactListObject.decryptedValue();
    const index = contactList.list.findIndex(({ type, id }) => type === 'user' && id === this.payload.userId);
    if (index === -1) {
      this.logger.warn('Client contact list update skipped; entry matching the current user ID could not be found.');
      return;
    }

    const updatedList: Array<ClientObjectContactListValue['list'][0]> = [...contactList.list];
    updatedList[index] = { ...(updatedList[index] as UserContactListItem), userData: contactInfo };
    const updatedValue: ClientObjectContactListValue = { ...contactList, list: updatedList };

    const updatedObject = await contactListObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.clientContactListObject = updatedObject;
  }

  private async fetchGlobalContactList(): Promise<void> {
    this.logger.info('Fetching the latest global contact list object.');

    const { globalContactListId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      dataObjects: { ids: [globalContactListId] },
      expectedCount: { dataObjects: null }
    };

    const { dataObjectList } = await this.fetchObjects(accessToken, query);

    const contactListJson = this.findDataObject(dataObjectList, { id: globalContactListId });
    if (Utils.isNil(contactListJson)) throw new Api.MalformedResponseException('Global contact list object could not be fetched.');

    const contactListObject = new DataObjectSigmailGlobalContactList(contactListJson);
    this.state.globalContactListObject = contactListObject;
  }

  private async addUpdateOperationForGlobalContactList(): Promise<void> {
    this.logger.info('Adding an update operation to request body for global contact list object.');

    const {
      globalContactListObject: contactListObject,
      contactInfo: { $$formatver, ...contactInfo },
      requestBody
    } = this.state;

    const contactList = await contactListObject.decryptedValue();
    const index = contactList.list.findIndex(({ type, id }) => type === 'user' && id === this.payload.userId);
    if (index === -1) {
      this.logger.warn('Global contact list update skipped; entry matching the current user ID could not be found.');
      return;
    }

    const updatedList: Array<DataObjectSigmailGlobalContactListValue['list'][0]> = [...contactList.list];
    updatedList[index] = { ...(updatedList[index] as UserContactListItem), userData: contactInfo };
    const updatedValue: DataObjectSigmailGlobalContactListValue = { ...contactList, list: updatedList };

    const updatedObject = await contactListObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    this.state.globalContactListObject = updatedObject;
  }
}

export const migration_bumpContactInfoValueToVersionTwo = (payload: Payload): AppThunk<Promise<void>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'bumpContactInfoValueToVersionTwo');

    const migration = new MigrationBumpContactInfoToVersionTwo({ payload, apiService, logger: Logger });
    return await migration.execute();
  };
};
