import { Utils } from '@sigmail/common';
import i18next, { StringMap, TFunction, TOptions } from 'i18next';
import XhrBackend from 'i18next-xhr-backend';
import React from 'react';
import { initReactI18next, Namespace, withTranslation as i18nextWithTranslation, WithTranslationProps } from 'react-i18next';
import { Default as DefaultLanguageCode } from '../constants/language-codes';
import i18nConfig, { I18N_CONTEXT_SEPARATOR, I18N_NS_SEPARATOR } from './config';
import { I18N_NS_GLOBAL } from './config/namespace-identifiers';

const REGEX_VALID_DOMAIN_NAME = /^[a-z0-9_.-]+$/i;
const I18N_T_PREFIX = 'T$';

export function gettext(
  domain: string | undefined,
  context: string | undefined,
  singular_key: string,
  _flags?: string | undefined,
  _comment?: string | undefined
) {
  const ns = (typeof domain === 'string' && domain) || I18N_NS_GLOBAL;
  if (!REGEX_VALID_DOMAIN_NAME.test(ns)) {
    throw new Error('Invalid domain name.');
  }

  let keySuffix = '';
  if (typeof context === 'string' && typeof I18N_CONTEXT_SEPARATOR === 'string') {
    const trimmedContext = context.trim();
    if (trimmedContext.length > 0) {
      keySuffix = I18N_CONTEXT_SEPARATOR + context.trim();
    }
  }

  return I18N_T_PREFIX + ns + I18N_NS_SEPARATOR + singular_key + keySuffix;
}

function $t(t: TFunction, key: string | string[], options?: TOptions<StringMap> | string): string {
  const keys: string[] = [...(Utils.isArray(key) ? key : [key])].map((value) =>
    Utils.isString(value) && value.startsWith(I18N_T_PREFIX) ? value.substring(I18N_T_PREFIX.length) : value
  );
  return t(keys, options);
}

export function withTranslation(ns?: Namespace, options?: { withRef?: boolean }): ReturnType<typeof i18nextWithTranslation> {
  const withTranslationWrapper = i18nextWithTranslation(ns, options);
  // @ts-ignore
  return <C extends React.ComponentType<React.ComponentProps<C> & WithTranslationProps>>(WrappedComponent: C) => {
    const WithTranslationComponent = React.forwardRef<unknown, React.ComponentProps<any> & WithTranslationProps>((props, ref) => {
      const { t: i18nextT } = props;
      const passDownProps = { ...props, t: $t.bind(null, i18nextT) };
      if (options?.withRef === true) {
        passDownProps.ref = ref;
      }
      return React.createElement(WrappedComponent, passDownProps);
    });

    const wrappedComponentDisplayName = WrappedComponent.displayName || WrappedComponent.name || 'Unknown';
    WithTranslationComponent.displayName = `WithTranslationComponent(${wrappedComponentDisplayName})`;
    (WithTranslationComponent as any).WrappedComponent = WrappedComponent;

    // @ts-ignore
    return withTranslationWrapper(WithTranslationComponent);
  };
}

const i18nInstance = i18next
  .createInstance()
  .use({
    type: 'languageDetector',
    async: false,
    init: () => {},
    detect: () => DefaultLanguageCode, // TODO revise
    cacheUserLanguage: () => {}
  })
  .use(XhrBackend)
  .use(initReactI18next);

i18nInstance.init(i18nConfig);

export default i18nInstance;
