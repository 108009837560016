import { KeyDerivationFunction as SigmailCryptoKeyDerivationFunction } from '@sigmail/crypto';
import { E_FAIL } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';

export abstract class KeyDerivationFunction implements SigmailCryptoKeyDerivationFunction {
  public readonly NAME: string;

  protected constructor(name: string) {
    const trimmedName = (typeof name === 'string' && name.trim()) || '';
    if (trimmedName.length === 0) {
      throw new SigmailCryptoException(E_FAIL, 'Invalid name.');
    }

    this.NAME = name;
  }

  /**
   * @inheritdoc
   */
  public abstract derive(
    seed: string | Uint8Array,
    version?: number | Uint8Array | null | undefined
  ): Promise<Uint8Array>;
}
