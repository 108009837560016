import { Constants } from '@sigmail/common';
import { UserObjectAccessRightsValue } from '@sigmail/objects';

const EIGHT_MB = 8388608;
const TEN_MB = 10485760;
const TWENTY_FIVE_MB = 26214400;

export const DEFAULT_CLIENT_RIGHTS_BY_ROLE: Readonly<{ [roleId: string]: UserObjectAccessRightsValue }> = {
  sigmailAdmin: {
    $$formatver: 1,
    clientRights: {
      inviteInstitute: true,
      inviteMember: true,
      activateMember: true,
      deactivateMember: true,
      accessMailbox: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessClientContacts: true,
      composeMessage: true,
      sendMessage: true,
      replyToMessage: true,
      forwardMessage: true,
      archiveMessage: true,
      saveMessageDraft: true,
      attachDocumentsToMessage: true,
      markMessageImportant: true,
      setMessageReminder: true,
      recallMessageWindow: 7,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      maxPerFileMsgAttachmentSize: EIGHT_MB
    }
  },
  [Constants.MedicalInstitute.ROLE_ID_ADMIN_PHYSICIAN]: {
    $$formatver: 1,
    clientRights: {
      inviteMember: true,
      activateMember: true,
      deactivateMember: true,
      resendInvitation: true,
      revokeInvitation: true,
      removeInvitation: true,
      accessMailbox: true,
      accessGlobalContacts: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessClientContacts: true,
      accessCircleOfCare: true,
      composeMessage: true,
      composeReferral: true,
      sendMessage: true,
      sendMessageToOscarEmr: true,
      replyToMessage: true,
      forwardMessage: true,
      archiveMessage: true,
      saveMessageDraft: true,
      attachDocumentsToMessage: 8,
      markMessageImportant: true,
      markMessageBillable: true,
      setMessageReminder: true,
      recallMessageWindow: 7,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      updateGroupPrefs: true
    }
  },
  [Constants.MedicalInstitute.ROLE_ID_ADMIN_STAFF]: {
    $$formatver: 1,
    clientRights: {
      inviteMember: [
        Constants.MedicalInstitute.ROLE_ID_ADMIN_STAFF,
        Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_STAFF,
        Constants.MedicalInstitute.ROLE_ID_GUEST
      ],
      activateMember: [
        Constants.MedicalInstitute.ROLE_ID_ADMIN_STAFF,
        Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_STAFF,
        Constants.MedicalInstitute.ROLE_ID_GUEST
      ],
      deactivateMember: [
        Constants.MedicalInstitute.ROLE_ID_ADMIN_STAFF,
        Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_STAFF,
        Constants.MedicalInstitute.ROLE_ID_GUEST
      ],
      resendInvitation: 'invitedByMe',
      revokeInvitation: 'invitedByMe',
      removeInvitation: 'invitedByMe',
      accessMailbox: true,
      accessGlobalContacts: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessClientContacts: true,
      accessCircleOfCare: true,
      composeMessage: true,
      sendMessage: true,
      sendMessageToOscarEmr: true,
      replyToMessage: true,
      forwardMessage: true,
      archiveMessage: true,
      saveMessageDraft: true,
      attachDocumentsToMessage: 8,
      markMessageImportant: true,
      setMessageReminder: true,
      recallMessageWindow: 7,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      maxPerFileMsgAttachmentSize: EIGHT_MB,
      updateGroupPrefs: true
    }
  },
  [Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN]: {
    $$formatver: 1,
    clientRights: {
      inviteMember: [
        Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_ADMIN_STAFF,
        Constants.MedicalInstitute.ROLE_ID_STAFF,
        Constants.MedicalInstitute.ROLE_ID_GUEST
      ],
      resendInvitation: 'invitedByMe',
      revokeInvitation: 'invitedByMe',
      removeInvitation: 'invitedByMe',
      accessMailbox: true,
      accessGlobalContacts: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessClientContacts: true,
      accessCircleOfCare: true,
      composeMessage: true,
      composeReferral: true,
      sendMessage: true,
      sendMessageToOscarEmr: true,
      replyToMessage: true,
      forwardMessage: true,
      archiveMessage: true,
      saveMessageDraft: true,
      attachDocumentsToMessage: 8,
      markMessageImportant: true,
      markMessageBillable: true,
      setMessageReminder: true,
      recallMessageWindow: 7,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      maxPerFileMsgAttachmentSize: EIGHT_MB
    }
  },
  [Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN]: {
    $$formatver: 1,
    clientRights: {
      inviteMember: [
        Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_STAFF,
        Constants.MedicalInstitute.ROLE_ID_GUEST
      ],
      resendInvitation: 'invitedByMe',
      revokeInvitation: 'invitedByMe',
      removeInvitation: 'invitedByMe',
      accessMailbox: true,
      accessGlobalContacts: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessClientContacts: true,
      accessCircleOfCare: true,
      composeMessage: true,
      composeReferral: true,
      sendMessage: true,
      sendMessageToOscarEmr: true,
      replyToMessage: true,
      forwardMessage: true,
      archiveMessage: true,
      saveMessageDraft: true,
      attachDocumentsToMessage: 4,
      markMessageImportant: true,
      markMessageBillable: true,
      setMessageReminder: true,
      recallMessageWindow: 7,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      maxPerFileMsgAttachmentSize: EIGHT_MB
    }
  },
  [Constants.MedicalInstitute.ROLE_ID_STAFF]: {
    $$formatver: 1,
    clientRights: {
      inviteMember: [
        Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN,
        Constants.MedicalInstitute.ROLE_ID_STAFF,
        Constants.MedicalInstitute.ROLE_ID_GUEST
      ],
      resendInvitation: 'invitedByMe',
      revokeInvitation: 'invitedByMe',
      removeInvitation: 'invitedByMe',
      accessMailbox: true,
      accessGlobalContacts: true,
      accessMemberManagement: true,
      accessOwnAccount: true,
      accessClientContacts: true,
      accessCircleOfCare: true,
      composeMessage: true,
      sendMessage: true,
      replyToMessage: true,
      forwardMessage: true,
      archiveMessage: true,
      saveMessageDraft: true,
      attachDocumentsToMessage: 4,
      markMessageImportant: true,
      setMessageReminder: true,
      recallMessageWindow: 7,
      maxTotalMsgAttachmentSize: TWENTY_FIVE_MB,
      maxPerFileMsgAttachmentSize: EIGHT_MB
    }
  },
  [Constants.MedicalInstitute.ROLE_ID_GUEST]: {
    $$formatver: 1,
    clientRights: {
      accessMailbox: true,
      accessOwnAccount: true,
      composeMessage: true,
      sendMessage: true,
      replyToMessage: true,
      archiveMessage: true,
      attachDocumentsToMessage: 2,
      markMessageImportant: true,
      recallMessageWindow: 7,
      maxTotalMsgAttachmentSize: TEN_MB,
      maxPerFileMsgAttachmentSize: EIGHT_MB
    }
  }
};
