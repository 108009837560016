import { Avatar, AvatarProps } from '@material-ui/core';
import { Utils } from '@sigmail/common';
import React from 'react';

export interface Props {
  /** Value to be used as the first name. */
  firstName?: string | undefined;
  /** Value to be used as the last name. */
  lastName?: string | undefined;
  /** The shape of the avatar. Default is circular. */
  variant?: AvatarProps['variant'];
  /** The background color to use. */
  backgroundColor?: string;
}

const PersonNameAvatarComponent: React.FC<Props> = ({ firstName, lastName, variant, backgroundColor }) => {
  const letters = ['', ''];

  let trimmedFirstName = (Utils.isString(firstName) && firstName.trim()) || undefined;
  let trimmedLastName = (Utils.isString(lastName) && lastName.trim()) || undefined;

  if (trimmedFirstName?.length) {
    const match = trimmedFirstName.match(/[a-z]+/gi);
    if (match) {
      letters[0] = match[0][0].toUpperCase();
    }
  }

  if (trimmedLastName?.length) {
    const match = trimmedLastName.match(/[a-z]+$/gi);
    if (match) {
      letters[1] = match[0][0].toUpperCase();
    }
  }

  return (
    <Avatar
      alt=""
      role="presentation"
      aria-hidden={true}
      variant={variant}
      style={{
        backgroundColor: Utils.isString(backgroundColor) ? backgroundColor : Utils.stringToHslColor(letters)
      }}
    >
      {letters.filter(Boolean).join('') || null}
    </Avatar>
  );
};

PersonNameAvatarComponent.defaultProps = {
  variant: 'circular'
};

export const PersonNameAvatar = React.memo(PersonNameAvatarComponent);
PersonNameAvatar.displayName = 'PersonNameAvatar';
