import { InputAdornment, OutlinedInputProps, TextField } from '@material-ui/core';
import { Utils } from '@sigmail/common';
import { FormInputI18n } from '@sigmail/i18n';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { FormInput, FormInputProps } from './form-input.component';

/**
 * Mapping of an input ID to it's properties.
 *
 *
 * Properties consist of standard HTML input properties plus the input's I18N
 * definitions. In addition to that, there are some optional properties such as
 * format masking rules, adornments, etc.
 */
export type BaseFormInputDefinition<KInputId extends string | number | symbol = string> = Record<
  KInputId,
  Omit<FormInputProps, 'ref'> & {
    render?: (inputId: string) => React.ReactNode;

    /** I18N definition to use for this input. */
    i18n: FormInputI18n;

    /** Format masking rules (if any). */
    mask?: MaskedInputProps['mask'];

    maskGuide?: MaskedInputProps['guide'];

    /** End `InputAdornment` for this component (if any). */
    endAdornment?: React.ReactNode;
  }
>;

/** Mapping of an input ID to it's error message content (if any). */
export type BaseFormInputErrorMessage<K extends string | number | symbol = string> = Partial<
  Record<K, string | null | undefined>
>;

/** Type definition for {@link BaseFormPureComponent#state} object. */
export interface BaseFormComponentState<KInputId extends string | number | symbol = string> {
  /**
   * Current form submission status:
   * - **NotSubmitted** - Form has not been submitted.
   * - **ValidationInProgress** - Form validation is in progress.
   * - **ValidationErrors** - Form currently has client-side validation errors.
   * - **InProgress** - Form submission is in progress.
   * - **SubmitFailure** - Form submission failed with an error.
   */
  submitStatus: 'NotSubmitted' | 'ValidationInProgress' | 'ValidationErrors' | 'InProgress' | 'SubmitFailure';

  /** A mapping of input IDs to their error message. */
  inputErrorMessage: BaseFormInputErrorMessage<KInputId>;
}

/**
 * An abstract base class which can be extended by components displaying a
 * form view consisting of one or more text input elements. It provides a
 * {@link BaseFormPureComponent#renderFormInput} method which allows to render
 * text fields in a manner which is consistent throughout the application.
 *
 *
 * Because this class uses {@link FormInput} component to render the input
 * elements, validations such as a required input, minimum/maximum length, etc.
 * are automatically taken care of provided that you specify the validation
 * constraints in the form input definition object passed to the constructor.
 * When {@link BaseFormComponentState#submitStatus} is set to
 * `ValidationErrors`, text fields rendered by this class will automatically
 * display the error message while also making sure that any attributes required
 * for accessibility are correctly set.
 */
export abstract class BaseFormPureComponent<
  P extends WithTranslation = WithTranslation,
  S extends BaseFormComponentState<string | number | symbol> = BaseFormComponentState<string>,
  SS = any,
  KInputId extends string | number | symbol = S extends BaseFormComponentState<infer K> ? K : string
  // > extends React.PureComponent<P, S, SS> {
> extends React.Component<P, S, SS> {
  /**
   * @param props
   * @param formInputDef
   */
  public constructor(props: P, protected formInputDef: BaseFormInputDefinition<KInputId>) {
    super(props);

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputValidityStateChange = this.onInputValidityStateChange.bind(this);
  }

  /**
   * Returns the current state of this component.
   *
   *
   * Derived classes may override this method. For example, in case where state
   * may not be available locally but comes from a context.
   */
  protected getState(): S {
    return this.state;
  }

  /**
   * Renders a {@link TextField} component for the input specified by the
   * `inputId` parameter. If there is a mask defined for the input, or if
   * there is an endAdornment specified in the definition, they will be applied
   * as well. In all cases, a {@link FormInput} component is used to render the
   * underlying `input` element.
   *
   *
   * Default implementation notes:
   * - rendered control's disabled will be set to true if `submitStatus ===
   * InProgress`.
   * - error messages are rendered only if `submitStatus === ValidationErrors`.
   * - format masking guides are disabled by default.
   * - any necessary attributes required for accessibility are automatically
   * applied.
   */
  protected renderFormInput(inputId: string, formInputDefOverride?: BaseFormInputDefinition): React.ReactNode {
    const formInputDef = formInputDefOverride || this.formInputDef;
    if (!Utils.isPlainObject(formInputDef[inputId])) {
      return null;
    }

    const { render, i18n: inputI18n, mask, maskGuide, endAdornment, ...inputProps } = formInputDef[inputId];
    if (typeof render === 'function') {
      return render(inputId);
    }

    const inputValue = (this.getState()[inputId as keyof S] as unknown) as string;

    let errorMessage = this.getState().inputErrorMessage[inputId];
    if (Utils.isString(errorMessage)) {
      errorMessage = this.props.t(errorMessage);
    }

    inputProps.noErrorMessageBlock = true;
    inputProps.validationError = inputI18n.error as { [key: string]: string };
    inputProps.onValidityStateChange = this.onInputValidityStateChange;
    inputProps['aria-invalid'] = Utils.isString(errorMessage);

    const InputProps: Partial<OutlinedInputProps> = { inputComponent: FormInput, inputProps };

    if (React.isValidElement(endAdornment)) {
      InputProps.endAdornment = <InputAdornment position="end">{endAdornment}</InputAdornment>;
    }

    inputProps.value = inputValue;
    if (mask) {
      const {
        inputRef,
        noErrorMessageBlock,
        customValidity,
        validationError,
        errorMessageClassName,
        onValidityStateChange,
        required,
        minLength,
        maxLength,
        pattern,
        ...otherInputProps
      } = inputProps;

      const maskedInputProps: MaskedInputProps = {
        ...otherInputProps,
        mask,
        guide: typeof maskGuide === 'boolean' ? maskGuide : false,
        render: (ref, { defaultValue, ...props }) => {
          return (
            <FormInput
              {...props}
              value={inputValue}
              inputRef={(inputElement) => {
                ref(inputElement as HTMLInputElement);
                if (inputRef) {
                  typeof inputRef === 'function' ? inputRef(inputElement) : (inputRef.current = inputElement);
                }
              }}
              required={required}
              minLength={minLength}
              maxLength={maxLength}
              pattern={pattern}
              noErrorMessageBlock={noErrorMessageBlock}
              customValidity={customValidity}
              validationError={validationError}
              errorMessageClassName={errorMessageClassName}
              onValidityStateChange={onValidityStateChange}
            />
          );
        }
      };

      InputProps.inputComponent = MaskedInput;
      InputProps.inputProps = maskedInputProps;
    }

    const hasNonEmptyValue = Utils.isString(inputValue) && inputValue.length > 0;
    const hasNonEmptyPlaceholder =
      Utils.isString(InputProps.inputProps!.placeholder) && InputProps.inputProps!.placeholder.length > 0;
    const shrink = hasNonEmptyValue || hasNonEmptyPlaceholder ? true : undefined;
    const hasError = this.formHasErrors && Utils.isString(errorMessage);

    return (
      <TextField
        variant="outlined"
        fullWidth={true}
        id={inputId}
        label={this.props.t(inputI18n.label)}
        disabled={inputProps.disabled || this.submitInProgress}
        error={hasError}
        helperText={hasError ? errorMessage : undefined}
        value={inputValue}
        onChange={this.onInputChange}
        InputLabelProps={{ shrink }}
        InputProps={InputProps}
      />
    );
  }

  /**
   * This method is called by the default implementation of
   * {@link FormInputProps#onValidityStateChange} event to update the error
   * message state of the input specified by the `inputId` parameter.
   *
   *
   * Derived classes may override this method. For example, in case where error
   * message state is not stored in component's own state but externally such
   * as in a context.
   *
   * @param inputId ID of the input whose error message state needs to be updated.
   * @param errorMessage Error message, or `null`/`undefined` to indicate no errors.
   *
   * @see {@link BaseFormComponentState#onInputValidityStateChange}
   */
  protected updateInputErrorMessage(inputId: string, errorMessage: string | null | undefined): void {
    this.setState((prevState) => {
      if (prevState.inputErrorMessage[inputId] !== errorMessage) {
        return {
          inputErrorMessage: {
            ...prevState.inputErrorMessage,
            [inputId]: Utils.isString(errorMessage) ? errorMessage : undefined
          }
        };
      }
      return null;
    });
  }

  /**
   * Event handler for the {@link FormInputProps#onValidityStateChange} event.
   *
   *
   * Default implementation calls the
   * {@link BaseFormComponentState#updateInputErrorMessage} method to update the
   * error message state of the input for which this event was fired.
   */
  protected onInputValidityStateChange(input: HTMLInputElement, _?: string[], errorMessage?: string): void {
    if (this.submitInProgress) {
      return;
    }

    this.updateInputErrorMessage(input.id, errorMessage || null);
  }

  protected updateInputValue(inputId: string, value: string): void {
    this.setState({ [inputId]: value } as any);
  }

  protected onInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (this.submitInProgress) {
      return;
    }

    const { id: inputId, value } = event.currentTarget;
    this.updateInputValue(inputId, value);
  }

  protected focusFirstInvalidInputElement(parentNode: ParentNode): HTMLElement | null;
  protected focusFirstInvalidInputElement(scrollIntoView: boolean): HTMLElement | null;
  protected focusFirstInvalidInputElement(
    parentNode?: ParentNode | null | undefined,
    scrollIntoView?: boolean | undefined
  ): HTMLElement | null;

  protected focusFirstInvalidInputElement(...args: any[]): HTMLElement | null {
    const [arg0, arg1] = args;

    const parentNode: { querySelector: ParentNode['querySelector'] } =
      Utils.isObjectLike(arg0) && typeof arg0.querySelector === 'function' ? arg0 : document;

    const [elFirstInvalidInput] = Object.keys(this.getState().inputErrorMessage)
      .map(
        (inputId) =>
          (Utils.isString(this.getState().inputErrorMessage[inputId]) && parentNode.querySelector(`#${inputId}`)) ||
          null
      )
      .filter((el): el is HTMLElement => el instanceof HTMLElement);

    if (elFirstInvalidInput instanceof HTMLElement) {
      elFirstInvalidInput.focus();

      const scrollIntoView = (typeof arg0 === 'boolean' && arg0) || Boolean(arg1);
      if (scrollIntoView) {
        elFirstInvalidInput.scrollIntoView({ behavior: 'auto', block: 'start' });
      }

      return elFirstInvalidInput;
    }

    return null;
  }

  /** Convenience getter to determine if form has validation errors. */
  protected get formHasErrors(): boolean {
    return this.getState().submitStatus === 'ValidationErrors';
  }

  /** Convenience getter to determine if form submission is in progress. */
  protected get submitInProgress(): boolean {
    return this.getState().submitStatus === 'InProgress';
  }
}
