import { MessageRecipient, PersonName, Utils } from '@sigmail/common';
import { ContactListItem, GroupContactListItem, UserContactListItem } from '@sigmail/objects';

const PERSON_NAME_KEYS: ReadonlyArray<keyof PersonName> = ['prefix', 'firstName', 'middleName', 'lastName', 'suffix'];

const isValidEntityType = (value: any): value is MessageRecipient['entityType'] =>
  value === 'primary' || value === 'secondary';

const groupContactListItemToMessageRecipient = (
  listItem: GroupContactListItem,
  entityType?: MessageRecipient['entityType']
): MessageRecipient => ({
  entityType: isValidEntityType(entityType) ? entityType : 'primary',
  id: listItem.id,
  type: listItem.type,
  groupType: listItem.groupType,
  groupName: listItem.groupData.groupName
});

const userContactListItemToMessageRecipient = (
  listItem: UserContactListItem,
  entityType?: MessageRecipient['entityType']
): MessageRecipient => ({
  entityType: isValidEntityType(entityType) ? entityType : 'primary',
  id: listItem.id,
  type: listItem.type,
  emailAddress: listItem.userData.noNotifyOnNewMessage === true ? undefined : listItem.userData.emailAddress,
  languagePreference: listItem.userData.languagePreference,
  ...Utils.pick(listItem.userData, PERSON_NAME_KEYS)
});

export function contactListItemToMessageRecipient(
  listItem: ContactListItem,
  entityType?: MessageRecipient['entityType']
): MessageRecipient {
  return listItem.type === 'group'
    ? groupContactListItemToMessageRecipient(listItem as GroupContactListItem, entityType)
    : userContactListItemToMessageRecipient(listItem as UserContactListItem, entityType);
}
