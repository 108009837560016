import { Utils } from '@sigmail/common';
import { IEncryptor } from '@sigmail/crypto';
import { E_FAIL } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';

const crypto = window.crypto || (window as any).msCrypto;

export abstract class Encryptor implements IEncryptor {
  public readonly NAME: string;

  protected constructor(name: string) {
    if (
      window.isSecureContext !== true ||
      typeof crypto?.getRandomValues !== 'function' ||
      !(crypto?.subtle instanceof SubtleCrypto)
    ) {
      throw new SigmailCryptoException(E_FAIL, 'Error initializing crypto API.');
    }

    const trimmedName = (Utils.isString(name) && name.trim()) || '';
    if (trimmedName.length === 0) {
      throw new SigmailCryptoException(E_FAIL, 'Invalid name.');
    }

    this.NAME = name;
  }
}
