import { AsymmetricEncryptor, AsymmetricKey, EncryptAsymmetricKeyAlgorithm, SymmetricEncryptor } from '@sigmail/crypto';
import { Algorithm } from '.';
import { E_FAIL } from '../constants';
import * as Encoder from '../encoder';
import { Encryptor } from '../encryptor';
import { RSA_OAEP } from '../encryptor/asymmetric/RSA_OAEP';
import { AES_GCM } from '../encryptor/symmetric/AES_GCM';
import { MGFV } from '../key-derivation-function/MGFV';
import { SigmailCryptoException } from '../SigmailCryptoException';

/**
 * Define the algorithm EncryptAsymmetricKeyAlgorithm. This is used to encrypt
 * a private or public key (VK or PK) to be stored in the keys table, based on
 * another public key (UK).
 *
 * - An encapsulated key (880 bits) is created for each use (C)
 * - An AES key (AK, 256 bits), initialization vector (IV, 128 bits), and
 *   authentication value (AV, 128 bits) are derived from the encapsulated key
 * - The encapsulated key is encrypted using a public key
 * - The key to encrypt is encrypted using AES in GCM mode
 * - The encrypted encapsulated key and encrypted key are concatenated and
 *   stored
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 */
export class EncryptAsymmetricKeyAlgorithmImpl
  extends Algorithm<AsymmetricEncryptor, JsonWebKey>
  implements EncryptAsymmetricKeyAlgorithm {
  protected readonly encapsulatedKeyEncryptor: SymmetricEncryptor;

  public constructor(encapsulatedKeyEncryptor?: SymmetricEncryptor) {
    // create an instance of RSA_OAEP, with all default parameters
    super('EncryptAsymmetricKeyAlgorithm', new RSA_OAEP());

    this.encapsulatedKeyEncryptor =
      encapsulatedKeyEncryptor instanceof Encryptor
        ? encapsulatedKeyEncryptor
        : // create an instance of AES_GCM, with required parameters and key derivation primitives
          // create instances of MGFV for deriving AES key, IV, and AAD
          //  each uses a different initialCounter, so the derived values are unrelated
          //  each of these values must not overlap
          //  they are from the value of pi, starting at digit 14001 after the decimal point, 8153 7409 9615 4559879825989109371...
          new AES_GCM({
            encapsulatedKeyLength: 880,
            tagLength: 128,
            keyDf: new MGFV({ initialCounter: 8153, outLength: 256 }),
            ivDf: new MGFV({ initialCounter: 7409, outLength: 128 }),
            adDf: new MGFV({ initialCounter: 9615, outLength: 128 })
          });
  }

  public generateKey(): Promise<AsymmetricKey> {
    return this.encryptor.generateKey();
  }

  public importKey(key: JsonWebKey): Promise<CryptoKey> {
    return this.encryptor.importKey(key);
  }

  public async encrypt(key: AsymmetricKey, data: JsonWebKey, version: number): Promise<string> {
    // generate a new encapsulated key for each encryption
    const encapsulatedKey = await this.encapsulatedKeyEncryptor.generateKey();
    let encryptedValue = await this.encryptor.encrypt(key, encapsulatedKey);
    let b64Encoded = [Encoder.Base64.encode(encryptedValue)];

    // encrypt data using a key derived from the encapsulated key and version
    const symmetricKey = await this.encapsulatedKeyEncryptor.deriveKey(encapsulatedKey, version);
    const utf8EncodedData = Encoder.UTF8.encode(JSON.stringify(data));
    encryptedValue = await this.encapsulatedKeyEncryptor.encrypt(symmetricKey, utf8EncodedData);
    b64Encoded.push(Encoder.Base64.encode(encryptedValue));

    // concatenate the encrypted encapsulated key and the encrypted data
    return b64Encoded.join('.');
  }

  public async decrypt(key: AsymmetricKey, data: string, version: number): Promise<JsonWebKey> {
    // split given data to get the encrypted encapsulated key and the encrypted data
    const b64Encoded = data.split('.');
    if (b64Encoded.length !== 2 || b64Encoded[0].trim().length === 0 || b64Encoded[1].trim().length === 0) {
      throw new SigmailCryptoException(E_FAIL, 'Unexpected data format.');
    }

    // decrypt the encapsulated key
    let encryptedValue = Encoder.Base64.decode(b64Encoded[0]);
    const encapsulatedKey = await this.encryptor.decrypt(key, encryptedValue);

    // decrypt data using a key derived from the encapsulated key and version
    encryptedValue = Encoder.Base64.decode(b64Encoded[1]);
    const symmetricKey = await this.encapsulatedKeyEncryptor.deriveKey(encapsulatedKey, version);
    const utf8Encoded = await this.encapsulatedKeyEncryptor.decrypt(symmetricKey, encryptedValue);
    const decryptedValue = Encoder.UTF8.decode(utf8Encoded);
    return JSON.parse(decryptedValue);
  }
}
