import {
  AlgorithmCode,
  EncryptAsymmetricKeyAlgorithmCode,
  EncryptEncapsulatedKeyAlgorithmCode,
  EncryptObjectAlgorithmCode,
  EncryptWithParametersAlgorithmCode,
  NonEncryptedObjectAlgorithmCode
} from '@sigmail/common';
import { AsymmetricEncryptor, IAlgorithm, SymmetricEncryptor } from '@sigmail/crypto';
import { Encryptor } from '../encryptor';

const VALID_NON_ENCRYPTED_OBJECT_ALGORITHM_CODE_LIST: ReadonlyArray<NonEncryptedObjectAlgorithmCode> = [
  process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT
];

const VALID_ENCRYPT_ASYMMETRIC_KEY_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptAsymmetricKeyAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC,
  process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE
];

const VALID_ENCRYPT_WITH_PARAMS_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptWithParametersAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_WITH_PARAMETERS
];

const VALID_ENCRYPT_ENCAPSULATED_KEY_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptEncapsulatedKeyAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY
];

const VALID_ENCRYPT_OBJECT_ALGORITHM_CODE_LIST: ReadonlyArray<EncryptObjectAlgorithmCode> = [
  process.env.ALGORITHM_CODE_ENCRYPT_OBJECT
];

/** A list of known algorithm codes. */
const VALID_ALGORITHM_CODE_LIST: ReadonlyArray<AlgorithmCode> = [
  ...VALID_NON_ENCRYPTED_OBJECT_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_ASYMMETRIC_KEY_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_WITH_PARAMS_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_ENCAPSULATED_KEY_ALGORITHM_CODE_LIST,
  ...VALID_ENCRYPT_OBJECT_ALGORITHM_CODE_LIST
];

export abstract class Algorithm<
    E extends SymmetricEncryptor | AsymmetricEncryptor,
    D,
    K = ReturnType<E['generateKey']> extends Promise<infer U> ? U : ReturnType<E['generateKey']>
  >
  extends Encryptor
  implements IAlgorithm<K, D> {
  public static readonly isValidNonEncryptedObjectCode = (value: any): value is NonEncryptedObjectAlgorithmCode =>
    VALID_NON_ENCRYPTED_OBJECT_ALGORITHM_CODE_LIST.indexOf(value) !== -1;

  public static readonly isValidEncryptAsymmetricKeyCode = (value: any): value is EncryptAsymmetricKeyAlgorithmCode =>
    VALID_ENCRYPT_ASYMMETRIC_KEY_ALGORITHM_CODE_LIST.indexOf(value) !== -1;

  public static readonly isValidEncryptWithParametersCode = (value: any): value is EncryptWithParametersAlgorithmCode =>
    VALID_ENCRYPT_WITH_PARAMS_ALGORITHM_CODE_LIST.indexOf(value) !== -1;

  public static readonly isValidEncryptEncapsulatedKeyCode = (
    value: any
  ): value is EncryptEncapsulatedKeyAlgorithmCode =>
    VALID_ENCRYPT_ENCAPSULATED_KEY_ALGORITHM_CODE_LIST.indexOf(value) !== -1;

  public static readonly isValidEncryptObjectCode = (value: any): value is EncryptObjectAlgorithmCode =>
    VALID_ENCRYPT_OBJECT_ALGORITHM_CODE_LIST.indexOf(value) !== -1;

  public static readonly isValidCode = (value: any): value is AlgorithmCode =>
    VALID_ALGORITHM_CODE_LIST.indexOf(value) !== -1;

  protected constructor(name: string, protected readonly encryptor: E) {
    super(name);
  }

  public abstract generateKey(...args: any[]): Promise<K>;
  public abstract encrypt(key: K, data: D, version: number): Promise<string>;
  public abstract decrypt(key: K, data: string, version: number): Promise<D>;
}
