import { NotificationObjectRecallMessageValue as DV } from '@sigmail/objects';
import { NotificationObject } from '.';
import { DataObjectMsgBody } from '../data-object/msg-body';
import { DataObjectMsgMetadata } from '../data-object/msg-metadata';
import { DataObjectMsgReadReceipt } from '../data-object/msg-read-receipt';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE;

export class NotificationObjectRecallMessage extends NotificationObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      DataObjectMsgMetadata.isValidId(value.header) &&
      DataObjectMsgBody.isValidId(value.body) &&
      DataObjectMsgReadReceipt.isValidId(value.readReceipt)
    );
  }
}
