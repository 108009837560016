import { CaseReducerMfaEnableSuccess } from '@sigmail/app-state';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { UserCredentialsMfaLogin } from '@sigmail/objects';

const Logger = getLoggerWithPrefix('Reducer', 'mfaEnableSuccessReducer:');

export const mfaEnableSuccessReducer: CaseReducerMfaEnableSuccess = (state, action) => {
  try {
    Logger.info('== BEGIN ==');

    const { mfaAccountId, mfaMethod, mfaContact } = action.payload;
    if (
      !UserCredentialsMfaLogin.isValidMfaAccountId(mfaAccountId) ||
      !UserCredentialsMfaLogin.isValidMfaMethod(mfaMethod) ||
      !UserCredentialsMfaLogin.isValidMfaContact(mfaContact)
    ) {
      Logger.warn('Invalid payload.');
      return state;
    }

    return { ...state, mfaAccountId, mfaMethod, mfaContact };
  } finally {
    Logger.info('== END ==');
  }
};
