//
// IMPORTANT all URLs must end with a forward-slash
//
export const URL_SRP_EXCHANGE_CREDENTIALS = 'connect/exchange/';
export const URL_SRP_VERIFY_CREDENTIALS = 'connect/verification/';
export const URL_ENTER_STATE = 'api/enterState/';
export const URL_GET_IDS = 'api/getids/:count/';
export const URL_BATCH_QUERY_DATA = 'api/batch/query/';
export const URL_BATCH_UPDATE_DATA = 'api/batch/update/';

export const URL_SEND_EMAIL_MESSAGE = 'api/email/simple/';
export const URL_SEND_TEMPLATED_EMAIL_MESSAGE = 'api/email/template/';

export const URL_AUTHY_REGISTER_ACCOUNT = 'api/authy/registration/';
export const URL_AUTHY_UPDATE_EMAIL = 'api/authy/update/';
export const URL_AUTHY_UNREGISTER_ACCOUNT = 'api/authy/remove/:accountId/';
export const URL_AUTHY_SEND_MFA_CODE = 'api/authy/:mfaMethod/';
export const URL_AUTHY_VERIFY_MFA_CODE = 'api/authy/verify/';

export const URL_OSCAR_CHECK_AUTH_STATUS = 'api/oscar/checkIt/';
export const URL_OSCAR_GET_OAUTH_URL = 'api/oscar/url/';
export const URL_OSCAR_GET_PROVIDER_NUMBER = 'api/oscar/providerNo/';
export const URL_OSCAR_SEARCH_PATIENT = 'api/oscar/match/';
export const URL_OSCAR_ADD_DOCUMENT = 'api/oscar/addDoc/';

export const URL_HRM_SEND_REPORT = 'api/hrm/report/';

export const BATCH_UPDATE_ERROR_CODE_VERSION_CONFLICT = 30001;
