import { AddressLevel1ListI18n } from '@sigmail/i18n';
import { gettext } from '.';

const i18n: AddressLevel1ListI18n = {
  CAN: [
    {
      code: 'AB',
      // Translators: Full name of Canadian province (Alberta)
      name: gettext('addressLevel1List', 'name', 'CAN$AB'),
      // Translators: Abbreviation of Canadian province (Alberta)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$AB')
    },
    {
      code: 'BC',
      // Translators: Full name of Canadian province (British Columbia)
      name: gettext('addressLevel1List', 'name', 'CAN$BC'),
      // Translators: Abbreviation of Canadian province (British Columbia)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$BC')
    },
    {
      code: 'MB',
      // Translators: Full name of Canadian province (Manitoba)
      name: gettext('addressLevel1List', 'name', 'CAN$MB'),
      // Translators: Abbreviation of Canadian province (Manitoba)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$MB')
    },
    {
      code: 'NB',
      // Translators: Full name of Canadian province (New Brunswick)
      name: gettext('addressLevel1List', 'name', 'CAN$NB'),
      // Translators: Abbreviation of Canadian province (New Brunswick)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$NB')
    },
    {
      code: 'NL',
      // Translators: Full name of Canadian province (Newfoundland and Labrador)
      name: gettext('addressLevel1List', 'name', 'CAN$NL'),
      // Translators: Abbreviation of Canadian province (Newfoundland and Labrador)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$NL')
    },
    {
      code: 'NS',
      // Translators: Full name of Canadian province (Nova Scotia)
      name: gettext('addressLevel1List', 'name', 'CAN$NS'),
      // Translators: Abbreviation of Canadian province (Nova Scotia)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$NS')
    },
    {
      code: 'NT',
      // Translators: Full name of Canadian province (Northwest Territories)
      name: gettext('addressLevel1List', 'name', 'CAN$NT'),
      // Translators: Abbreviation of Canadian province (Northwest Territories)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$NT')
    },
    {
      code: 'NU',
      // Translators: Full name of Canadian province (Nunavut)
      name: gettext('addressLevel1List', 'name', 'CAN$NU'),
      // Translators: Abbreviation of Canadian province (Nunavut)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$NU')
    },
    {
      code: 'ON',
      // Translators: Full name of Canadian province (Ontario)
      name: gettext('addressLevel1List', 'name', 'CAN$ON'),
      // Translators: Abbreviation of Canadian province (Ontario)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$ON')
    },
    {
      code: 'PE',
      // Translators: Full name of Canadian province (Prince Edward Island)
      name: gettext('addressLevel1List', 'name', 'CAN$PE'),
      // Translators: Abbreviation of Canadian province (Prince Edward Island)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$PE')
    },
    {
      code: 'QC',
      // Translators: Full name of Canadian province (Quebec)
      name: gettext('addressLevel1List', 'name', 'CAN$QC'),
      // Translators: Abbreviation of Canadian province (Quebec)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$QC')
    },
    {
      code: 'SK',
      // Translators: Full name of Canadian province (Saskatchewan)
      name: gettext('addressLevel1List', 'name', 'CAN$SK'),
      // Translators: Abbreviation of Canadian province (Saskatchewan)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$SK')
    },
    {
      code: 'YT',
      // Translators: Full name of Canadian province (Yukon)
      name: gettext('addressLevel1List', 'name', 'CAN$YT'),
      // Translators: Abbreviation of Canadian province (Yukon)
      abbr: gettext('addressLevel1List', 'abbr', 'CAN$YT')
    }
  ]
};

export default i18n;
