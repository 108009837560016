import { createMuiTheme } from '@material-ui/core/styles';
import { BreakpointValues } from '@material-ui/core/styles/createBreakpoints';
import { Utils } from '@sigmail/common';
import * as tailwindConfig from './tailwind/tailwind.config';

const resolveTailwindConfig = require('tailwindcss/resolveConfig');
const { theme } = resolveTailwindConfig(tailwindConfig);

export const UITheme = createMuiTheme({
  palette: {
    primary: {
      light: theme.colors.primaryLight,
      main: theme.colors.primary,
      dark: theme.colors.primaryDark
    },
    secondary: {
      light: theme.colors.secondaryLight,
      main: theme.colors.secondary,
      dark: theme.colors.secondaryDark
    },
    success: {
      main: theme.colors.success
    },
    error: {
      main: theme.colors.danger
    },
    text: {
      primary: theme.colors.textPrimary,
      secondary: theme.colors.textSecondary,
      disabled: theme.colors.textDisabled
    }
  },
  breakpoints: {
    keys: ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'] as any[],
    values: Utils.transform(
      theme.screens,
      (values: any, unit: string, breakpoint) => {
        values[breakpoint] = parseFloat(unit);
      },
      {} as BreakpointValues
    )
  }
});
