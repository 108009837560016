// @ts-nocheck
import { ActionPayloadBatchQueryDataSuccess } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ApiFormattedDataObject, DataObjectMsgFolder, DataObjectMsgFolderValue } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';

export interface Payload {
  accessToken: string;
  msgFolderJsonList: ApiFormattedDataObject[];
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

class MigrationBumpMsgFolderValueToVersionThree extends BaseAction<Payload> {
  protected async onExecute(): Promise<void> {
    for (const { id: folderId } of this.payload.msgFolderJsonList) {
      await this.updateMessageFolderToVersionThree(folderId);
    }
  }

  private async updateMessageFolderToVersionThree(folderId: number): Promise<void> {
    const { accessToken, successPayload } = this.payload;

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        const query: FetchObjectsRequestData = {
          dataObjects: { ids: [folderId] },
          expectedCount: { dataObjects: null }
        };

        const { dataObjectList } = await this.fetchObjects(accessToken, query);

        const msgFolderJson = this.findDataObject(dataObjectList, { id: folderId, type: process.env.DATA_OBJECT_TYPE_MSG_FOLDER });
        if (Utils.isNil(msgFolderJson)) {
          throw new Api.MalformedResponseException(`Message folder object could not be fetched. (ID=${folderId})`);
        }

        const msgFolderObject = new DataObjectMsgFolder(msgFolderJson);
        const msgFolder: DataObjectMsgFolderValue<2 | 3> = await msgFolderObject.decryptedValue();

        if (msgFolder.$$formatver !== 2) {
          this.logger.info(`Call ignored; expected $$formatver to be 2, was ${msgFolder.$$formatver}.`);
          return;
        }

        const updatedValue: DataObjectMsgFolderValue = {
          ...msgFolder,
          $$formatver: 3,
          itemCount: {
            ...msgFolder.itemCount!,
            referral: { total: 0, unread: 0 }
          }
        };

        const updatedObject = await msgFolderObject.updateValue(updatedValue);

        const requestBody = new BatchUpdateRequestBuilder();
        requestBody.update(updatedObject);

        this.logger.info(`Adding an update operation to request body for message folder ${folderId}.`);
        await this.batchUpdateData(accessToken, requestBody.build());

        const { dataObjects } = successPayload.response;
        const index = this.findDataObjectIndex(dataObjects, { id: msgFolderObject.id });
        if (index !== -1) {
          dataObjects![index] = updatedObject.toApiFormatted();
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }
}

export const migration_bumpMsgFolderValueToVersionThree = (payload: Payload): AppThunk<Promise<void>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'bumpMsgFolderValueToVersionThree:');

    const migration = new MigrationBumpMsgFolderValueToVersionThree({ payload, apiService, logger: Logger });
    return await migration.execute();
  };
};
