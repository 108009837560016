import * as ErrorCode from './constants/error';
import { CODE, FACILITY, isString, SEVERITY } from './utils';

const DEFAULT_ERROR_MESSAGE_MAP: Readonly<Record<number, string>> = {
  [ErrorCode.E_NOT_IMPLEMENTED]: 'Not implemented yet.',

  [ErrorCode.E_INVALID_OBJECT_ID]: 'Invalid object ID.',
  [ErrorCode.E_UNKNOWN_OBJECT_TYPE]: 'Invalid/unknown object type.',
  [ErrorCode.E_INVALID_OBJECT_VERSION]: 'Invalid object version.',
  [ErrorCode.E_INVALID_OBJECT_VALUE]: 'Invalid object value.',
  [ErrorCode.E_INVALID_USER_OR_GROUP_ID]: 'Invalid user or user group ID.',
  [ErrorCode.E_INVALID_CREATION_DATE]: 'Invalid creation date.',
  [ErrorCode.E_INVALID_EXPIRY_DATE]: 'Invalid expiry date.',
  [ErrorCode.E_INVALID_GROUP_TYPE]: 'Invalid group type.',

  [ErrorCode.E_AUTH_FAIL]: 'Authentication failed.',
  [ErrorCode.E_AUTH_FAIL_SALT]: 'Salt value is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_DECODE_ID_TOKEN]: 'Failed to decode ID token.',
  [ErrorCode.E_AUTH_FAIL_CREDENTIAL_ID]: 'Credential ID is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_KEY_ID]: 'Key ID is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_USER_ID]: 'User ID is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_AUTH_STATE]: 'Auth state is either missing or invalid.',
  [ErrorCode.E_AUTH_FAIL_SHARED_PARAMS]: 'Shared parameters are either missing or invalid.',

  [ErrorCode.E_MESSAGING_FAIL]: 'Messaging error: Operation failed.',
  [ErrorCode.E_MESSAGING_FAIL_FOLDER_DATA_INVALID]: 'Message folder data is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_FOLDER_ID]: 'Message folder ID is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_OWNER_ID]: 'Owner ID is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_MSG_METADATA_ID]: 'Message metadata ID is either missing or invalid.',
  [ErrorCode.E_MESSAGING_FAIL_MSG_BODY_ID]: 'Message body ID is either missing or invalid.'
};

export class AppException extends Error {
  public readonly errorCode: number;

  public constructor(errorCode: number, message?: string) {
    const errorMessage = [
      `S${SEVERITY(errorCode)}-F${FACILITY(errorCode)}-C${CODE(errorCode)}`,
      isString(message) ? message : DEFAULT_ERROR_MESSAGE_MAP[errorCode]
    ]
      .filter(Boolean)
      .join(': ');

    super(errorMessage);

    this.errorCode = errorCode;
    this.name = 'AppException';
  }

  // prettier-ignore
  public get severity() { return SEVERITY(this.errorCode); }

  // prettier-ignore
  public get facility() { return FACILITY(this.errorCode); }

  // prettier-ignore
  public get code() { return CODE(this.errorCode); }
}
