import { EncapsulatedKey } from '@sigmail/common';
import { EncryptObjectAlgorithm, SymmetricKey } from '@sigmail/crypto';
import { Algorithm } from '.';
import { E_METHOD_NOT_IMPL } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';

export class NonEncryptedObjectAlgorithmImpl extends Algorithm<any, any> implements EncryptObjectAlgorithm {
  public constructor() {
    super('NonEncryptedObjectAlgorithm', null);
  }

  public generateKey(): Promise<EncapsulatedKey> {
    return Promise.resolve(new Uint8Array());
  }

  /** @throws {NotImplementedException} */
  public deriveKey(__UNUSED_key: EncapsulatedKey, __UNUSED_version: number): Promise<SymmetricKey> {
    throw new SigmailCryptoException(E_METHOD_NOT_IMPL, 'Method not implemented.');
  }

  public encrypt(__UNUSED_key: EncapsulatedKey, data: any, __UNUSED_version: number): Promise<string> {
    return Promise.resolve(JSON.stringify(data));
  }

  public decrypt(__UNUSED_key: EncapsulatedKey, data: string, __UNUSED_version: number): Promise<any> {
    return Promise.resolve(JSON.parse(data));
  }
}
