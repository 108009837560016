const BYTE_TO_HEX_MAP = new Array<string>(0xff + 1);
for (let n = 0; n <= 0xff; ++n) {
  const hexOctet = n.toString(16).padStart(2, '0');
  BYTE_TO_HEX_MAP[n] = hexOctet;
}

export function uint8ArrayToHex(bytes: Uint8Array): string {
  const len = bytes.length;
  const hexOctets = new Array<string>(len);
  for (let i = 0; i < len; ++i) {
    hexOctets[i] = BYTE_TO_HEX_MAP[bytes[i]];
  }
  return hexOctets.join('');
}
