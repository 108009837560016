import bcrypt from 'bcryptjs';
import { isString } from '.';

export const BCRYPT_DATA_MAX_LENGTH = 71;

export function bcryptHash(data: string, salt: string): Promise<string> {
  if (!isString(data)) {
    throw new TypeError('Argument <data> must be of type string.');
  }

  if (data.length > BCRYPT_DATA_MAX_LENGTH) {
    throw new Error(`Argument <data> may not be more than ${BCRYPT_DATA_MAX_LENGTH} characters in length.`);
  }

  if (!isString(salt)) {
    throw new TypeError('Argument <salt> must be of type string.');
  }

  return bcrypt.hash(data, salt);
}
