import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import { Utils } from '@sigmail/common';
import { FormInputI18n } from '@sigmail/i18n';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withTranslation } from '../../../i18n';
import { BaseFormInputDefinition, BaseFormPureComponent } from '../base-form-pure-component';
import { IconButtonTogglePasswordVisibility } from '../icon-button-toggle-password-visibility.component';
import * as Context from './signin-credentials-input.context';

interface FormInputDefinition {
  username: BaseFormInputDefinition[0];
  password: BaseFormInputDefinition[0];
}

const FORM_INPUT: FormInputDefinition = {
  username: {
    i18n: { label: 'Username' },
    autoComplete: 'off',
    type: 'text',
    required: true,
    endAdornment: <AccountCircleOutlinedIcon color="disabled" />
  },
  password: {
    i18n: { label: 'Password' },
    autoComplete: 'off',
    type: 'password',
    required: true
  }
};

const FORM_INPUT_LIST: ReadonlyArray<keyof FormInputDefinition> = ['username', 'password'];

export interface I18n {
  leadText?: string;
  formField: {
    username: FormInputI18n;
    password: FormInputI18n;
  };
  passwordVisibilityIcon: {
    labelShowPassword: string;
    labelHidePassword: string;
  };
}

export interface Props extends WithTranslation {
  I18N: I18n;
  styles?: {
    leadText?: string;
    formInput?: string;
  };
  usernameOnly?: boolean;
}

interface State extends Context.ContextValue {}

class SigninCredentialsInputComponent extends BaseFormPureComponent<Props, State> {
  public static contextType = Context.Context;
  context!: React.ContextType<typeof Context.Context>;

  public constructor(props: Props) {
    super(props, (FORM_INPUT as unknown) as BaseFormInputDefinition);

    this.onShowPasswordClick = this.onShowPasswordClick.bind(this);
  }

  public render(): React.ReactNode {
    const { I18N: i18n, t, styles, usernameOnly } = this.props;

    this.formInputDef.username.i18n = i18n.formField.username;

    this.formInputDef.password.i18n = i18n.formField.password;
    this.formInputDef.password.type = this.getState().showPassword ? 'text' : 'password';
    this.formInputDef.password.endAdornment = (
      <IconButtonTogglePasswordVisibility
        ariaLabel={t(
          this.getState().showPassword
            ? i18n.passwordVisibilityIcon.labelHidePassword
            : i18n.passwordVisibilityIcon.labelShowPassword
        )}
        visibility={this.getState().showPassword}
        onClick={this.onShowPasswordClick}
      />
    );

    return (
      <React.Fragment>
        {Utils.isString(i18n.leadText) && (
          <div className={styles?.leadText} dangerouslySetInnerHTML={{ __html: t(i18n.leadText) }} />
        )}

        {FORM_INPUT_LIST.map((inputId) =>
          usernameOnly === true && inputId === 'password' ? null : (
            <div key={inputId} className={styles?.formInput}>
              {this.renderFormInput(inputId)}
            </div>
          )
        )}
      </React.Fragment>
    );
  }

  protected getState(): React.ContextType<typeof Context.Context> {
    return this.context;
  }

  protected updateInputErrorMessage(inputId: string, errorMessage: string | null) {
    this.context.dispatch(Context.updateInputErrorMessage({ [inputId]: errorMessage }));
  }

  protected updateInputValue(inputId: string, value: string) {
    this.context.dispatch(Context.updateFormData({ [inputId]: value }));
  }

  private onShowPasswordClick(event: React.MouseEvent<HTMLButtonElement>): void {
    if (this.submitInProgress) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    this.context.dispatch(Context.togglePasswordVisibility());
  }
}

export const SigninCredentialsInput = withTranslation()(SigninCredentialsInputComponent);
SigninCredentialsInput.displayName = 'SigninCredentialsInput';
