import { SrpSession } from '@sigmail/common';
import SRP from 'secure-remote-password/client';

export function srpDeriveSession(
  clientSecretEphemeral: string,
  serverPublicEphemeral: string,
  salt: string,
  username: string,
  privateKey: string
): SrpSession {
  return SRP.deriveSession(clientSecretEphemeral, serverPublicEphemeral, salt, username, privateKey);
}
