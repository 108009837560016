import { Utils } from '@sigmail/common';
import { DataObjectMsgReadReceiptValue as DV } from '@sigmail/objects';
import { DataObject } from '.';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_READ_RECEIPT;

export class DataObjectMsgReadReceipt extends DataObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isArray(value.data);
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgReadReceipt> {
    const Class = this.constructor as typeof DataObjectMsgReadReceipt;
    return Class.updateValue(this, newValue);
  }
}
