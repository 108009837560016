import { withWidth, WithWidthProps } from '@material-ui/core';
import { Utils } from '@sigmail/common';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import * as ActionId from '../../constants/action-ids';
import { withTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import i18n from '../../i18n/global';
import { ActionNavList, NavAction } from '../shared/action-nav-list';
import { RouterNavLink } from '../shared/router-nav-link.component';
import { AppDrawer } from './app-drawer.component';
import * as Context from './layout.context';
import style from './site-header.module.css';

/** SiteHeader component properties. */
export interface Props extends WithWidthProps, WithTranslation {
  homeRoutePath: string;
  navAction: { [key: string]: NavAction };
  navClassName?: string;
}

/** A component which renders the site header. */
class SiteHeaderComponent extends React.PureComponent<Props> {
  public static contextType = Context.Context;
  context!: React.ContextType<typeof Context.Context>;

  public constructor(props: Props) {
    super(props);

    this.onA11yNavItemClick = this.onA11yNavItemClick.bind(this);
  }

  public render(): React.ReactNode {
    const { isScreenMediumAndUp, isScreenSmallAndUp } = this.context;

    return (
      <header className={style.header}>
        <div className="container-fluid">
          <div className="row">
            {!isScreenMediumAndUp && (
              <div styleName="style.col style.drawer">
                <AppDrawer />
              </div>
            )}
            <div styleName="style.col style.logo">
              <RouterNavLink to={this.props.homeRoutePath}>{this.props.t(i18n.logoText)}</RouterNavLink>
            </div>
            <div styleName="style.col style.a11y">{this.renderA11yOptionList()}</div>
            {isScreenSmallAndUp && <div styleName="style.col style.nav">{this.renderActionList()}</div>}
          </div>
        </div>
      </header>
    );
  }

  /** Render a list of actions (for screen readers) required for accessibility. */
  private renderA11yOptionList(): React.ReactNode {
    const a11yAction: { [key: string]: NavAction } = {};
    Utils.transform(
      Utils.pick(i18n.action, [ActionId.SkipToNavMenu, ActionId.SkipToMainContent]),
      (navAction, action, actionId) => {
        navAction[actionId] = { ...action, exact: true, onClick: this.onA11yNavItemClick };
      },
      a11yAction
    );
    return <ActionNavList t={this.props.t} action={a11yAction} />;
  }

  /** Renders a list of navigation links. */
  private renderActionList(): React.ReactNode {
    const { navAction, t, navClassName } = this.props;
    if (Utils.isNil(navAction)) {
      return null;
    }
    return <ActionNavList t={t} className={navClassName} action={navAction} />;
  }

  /** Event handler invoked when an accessibility navigation item is clicked. */
  private onA11yNavItemClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    event.preventDefault();
    event.stopPropagation();

    const element = document.querySelector(event.currentTarget.hash);
    if (element instanceof HTMLElement) {
      element.focus();
      element.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }
}

export const SiteHeader = withWidth({ noSSR: true })(withTranslation([I18N_NS_GLOBAL])(SiteHeaderComponent));
SiteHeader.displayName = 'SiteHeader';
