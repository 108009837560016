/*
 +------------------------------------------------------------------------------
 |
 | Action IDs mapping to routes
 |
 +------------------------------------------------------------------------------
*/
export const Home = 'home';

export const SignIn = 'signIn';
export const ForgotPassword = 'forgotPassword';
export const SignOut = 'signOut';

export const InviteInstitute = 'inviteInstitute';
export const RegisterInstitute = 'registerInstitute';

export const AccountManageSelf = 'accountManageSelf';
export const AccountManageMembers = 'accountManageMembers';

export const AccountSetupCredentials = 'accountSetupCredentials';
export const AccountSetupRecovery = 'accountSetupRecovery';
export const AccountSetupProfileInfo = 'accountSetupProfileInfo';
export const AccountSetupSuccess = 'accountSetupSuccess';
export const AccountSetupSecurity = 'accountSetupSecurity';

export const ArchivedMessages = 'archivedMessages';
export const ArchivedMessagesShared = 'archivedMessagesShared';
export const ComposeMessage = 'composeMessage';
export const DeletedMessages = 'deletedMessages';
export const DeletedMessagesShared = 'deletedMessagesShared';
export const DraftMessages = 'draftMessages';
export const ImportantMessages = 'importantMessages';
export const ImportantMessagesShared = 'importantMessagesShared';
export const ReferralMessages = 'referralMessages';
export const ReferralMessagesShared = 'referralMessagesShared';
export const Mailbox = 'mailbox';
export const ReceivedMessages = 'receivedMessages';
export const ReceivedMessagesShared = 'receivedMessagesShared';
export const ReminderMessages = 'reminderMessages';
export const SelectFilters = 'selectFilters';
export const SentMessages = 'sentMessages';

export const ContactList = 'contactList';
export const ContactListGlobalUsers = 'contactListGlobalUsers';
export const ContactListGlobalGroups = 'contactListGlobalGroups';
export const ContactListClient = 'contactListClient';
export const ContactListGuest = 'contactListGuest';

export const DocumentList = 'documentList';

export const LanguageToggle = 'toggleLanguage';
export const TermsAndConditions = 'termsAndConditions';
export const PrivacyPolicy = 'privacyPolicy';
export const FAQ = 'faq';
export const ContactUs = 'contactUs';

/*
 +------------------------------------------------------------------------------
 |
 | Action IDs mapping to UI elements
 |
 +------------------------------------------------------------------------------
*/
export const UserAccountMenu = 'userAccountMenu';
export const FormSubmit = 'formSubmit';

export const FormSubmitSecurityChallenge = 'formSubmitSecurityChallenge';
export const FormSubmitSignInCredentials = 'formSubmitSignInCredentials';
export const FormSubmitSelectMfaMethod = 'formSubmitSelectMfaMethod';
export const FormSubmitVerifyMfaCode = 'formSubmitVerifyMfaCode';
export const FormSubmitVerifyUsername = 'formSubmitVerifyUsername';
export const FormSubmitChoosePassword = 'formSubmitChoosePassword';

export const AcceptReferral = 'acceptReferral';
export const ActivateMember = 'activateMember';
export const AddMember = 'addMember';
export const BulkInviteGuests = 'bulkInviteGuests';
export const CreateGuestContactGroup = 'createGuestContactGroup';
export const DeactivateMember = 'deactivateMember';
export const DeclineReferral = 'declineReferral';
export const DialogAccept = 'dialogAccept';
export const DialogReject = 'dialogReject';
export const EditMessageSignature = 'editMessageSignature';
export const EditOscarEmrConfig = 'editOscarEmrConfig';
export const EditProfile = 'editProfile';
export const InviteGuest = 'inviteGuest';
export const InviteStaff = 'inviteStaff';
export const RemoveMemberInvitation = 'removeMemberInvitation';
export const ResendMemberInvitation = 'resendMemberInvitation';
export const RevokeMemberInvitation = 'revokeMemberInvitation';
export const SkipToMainContent = 'skipToMainContent';
export const SkipToNavMenu = 'skipToNavMenu';

// +----------------------------------------------------------------------------
// | value of these actions must be kept in sync with `MessagingI18n` type
// +----------------------------------------------------------------------------
export const CloseMessage = 'closeMessage';
export const ComposeHrmMessage = 'composeHrmMessage';
export const ComposeReferral = 'composeReferral';
export const DiscardDraft = 'discardDraft';
export const Forward = 'forward';
export const MarkAsRead = 'markRead';
export const MarkAsUnread = 'markUnread';
export const MoveToArchive = 'archive';
export const OpenDraftMessage = 'openDraftMessage';
export const Print = 'print';
export const Recall = 'recall';
export const Reminder = 'reminder';
export const Reply = 'reply';
export const ReplyAll = 'replyAll';
export const SendMessageToOscar = 'sendMessageToOscar';
