const BYTE_TO_HEX_MAP = new Array<string>(0xff + 1);
for (let n = 0; n <= 0xff; ++n) {
  const hexOctet = n.toString(16).padStart(2, '0');
  BYTE_TO_HEX_MAP[n] = hexOctet;
}

export function encode(bytes: Uint8Array): string {
  const len = bytes.length;
  const hexOctets = new Array<string>(len);
  for (let i = 0; i < len; ++i) {
    hexOctets[i] = BYTE_TO_HEX_MAP[bytes[i]];
  }
  return hexOctets.join('');
}

const PATTERN_HEX_CHAR = '[a-fA-F0-9]';
export function decode(value: string): Uint8Array {
  if (typeof value !== 'string') {
    throw new TypeError('Argument <value> must be of type string.');
  }

  const trimmedValue = value.trim();
  if (trimmedValue.length % 2 !== 0) {
    throw new Error("Value has an invalid length i.e. it's not divisible by 2");
  }

  if (!RegExp(`^${PATTERN_HEX_CHAR}+$`).test(value)) {
    throw new Error('Value must be encoded in hexadecimal format.');
  }

  const byteArray: string[] = trimmedValue.match(RegExp(`${PATTERN_HEX_CHAR}{2}`, 'g')) || [];
  return new Uint8Array(byteArray.map((byte) => parseInt(byte, 16)));
}
