import { MedicalInstituteInviteGuestFormDialogI18n } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MedicalInstituteInviteGuestFormDialogI18n = {
  title: gettext('medicalInstituteInviteGuestFormDialog', 'medicalInstituteInviteGuestFormDialog', 'title'),
  action: {
    submit: {
      // Translators: Label for the Submit action of Invite Guest Form dialog
      label: gettext('medicalInstituteInviteGuestFormDialog', 'medicalInstituteInviteGuestFormDialog', 'submitActionLabel')
    },
    dismiss: {
      // Translators: Label for the Dismiss action of Invite Guest Form dialog
      label: gettext('medicalInstituteInviteGuestFormDialog', 'medicalInstituteInviteGuestFormDialog', 'dismissActionLabel')
    }
  }
};

export default i18n;
