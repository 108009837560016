import { Utils } from '@sigmail/common';
import { ClientObjectConfigValue as DV } from '@sigmail/objects';
import { ClientObject } from '.';
import { DataObject } from '../data-object';

const TYPE = process.env.CLIENT_OBJECT_TYPE_CONFIGURATION;

export class ClientObjectConfiguration extends ClientObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      DataObject.isValidId(value.ownerId) &&
      Utils.isPlainObject(value.licenses) &&
      DataObject.isValidId(value.globalContactListId)
    );
  }

  public updateValue(newValue: DV): Promise<ClientObjectConfiguration> {
    const Class = this.constructor as typeof ClientObjectConfiguration;
    return Class.updateValue(this, newValue);
  }
}
