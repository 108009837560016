import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { PayloadAction } from '@reduxjs/toolkit';
import { MedicalInstitute } from '@sigmail/app-state';
import { Constants, Utils } from '@sigmail/common';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import { AppDispatch } from '../../../app-state';
import { sendMemberInvitationAction } from '../../../app-state/actions/medical-institute/send-member-invitation-action';
import { SubmitButton } from '../../../app/shared/submit-button.component';
import * as ActionContext from '../../../constants/action-context';
import * as ActionId from '../../../constants/action-ids';
import { withTranslation } from '../../../i18n';
import { I18N_NS_MEDICAL_INSTITUTE_INVITE_GUEST_FORM_DIALOG } from '../../../i18n/config/namespace-identifiers';
import inviteGuestFormI18n from '../../../i18n/medical-institute/invite-guest-form';
import i18n from '../../../i18n/medical-institute/invite-guest-form-dialog';
import { focusFirstInvalidInputElement } from '../../../utils/focus-first-invalid-input-element';
import { resolveActionLabel } from '../../../utils/resolve-action-label';
import style from './invite-guest-form-dialog.module.css';
import { InviteGuestForm } from './invite-guest-form.component';
import * as Context from './invite-guest-form.context';

let idCounter = 0;

interface OwnProps {
  open: boolean;
  onClose?: () => void;
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dispatchSendMemberInvitation(payload: MedicalInstitute.ActionPayloadSendMemberInvitation) {
    return dispatch(sendMemberInvitationAction(payload));
  }
});

const withConnect = connect(undefined, mapDispatchToProps);
type ConnectedProps = Omit<ReduxConnectedProps<typeof withConnect>, keyof OwnProps>;

export interface Props extends OwnProps, WithTranslation, ConnectedProps {}

interface State extends Context.ContextValue {}

class InviteGuestFormDialogComponent extends React.PureComponent<Props, State> {
  private readonly idCounter: number;
  private readonly refForm = React.createRef<HTMLFormElement>();

  public constructor(props: Props) {
    super(props);

    this.idCounter = ++idCounter;
    this.state = {
      ...Context.getDefaultValue(),
      accessCode: null
    };

    this.dispatchHandler = this.dispatchHandler.bind(this);
    this.onDialogEnter = this.onDialogEnter.bind(this);
    this.onDismissClick = this.onDismissClick.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  public componentDidUpdate(_: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.state.submitStatus === 'InProgress') {
      if (prevState.submitStatus !== 'InProgress') {
        let elInvalidInput = focusFirstInvalidInputElement(this.refForm.current);
        if (!(elInvalidInput instanceof HTMLElement)) {
          const invalidInputElementId = Utils.findKey(this.state.inputErrorMessage, (value) => value !== null);
          if (Utils.isString(invalidInputElementId)) {
            elInvalidInput = document.getElementById(invalidInputElementId);
          }
        }

        if (elInvalidInput instanceof HTMLElement) {
          this.setState({ submitStatus: 'ValidationErrors' }, () => {
            elInvalidInput!.focus();
            elInvalidInput!.scrollIntoView({ behavior: 'auto', block: 'start' });
          });
          return;
        }

        this.dispatchSendMemberInvitation();
      }
      return;
    }
  }

  public render(): React.ReactNode {
    return (
      <Dialog
        className={style.dialog}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        open={this.props.open}
        onEnter={this.onDialogEnter}
        aria-labelledby={`dialog-title-${this.idCounter}`}
      >
        {this.state.accessCode === null && (
          <DialogTitle id={`dialog-title-${this.idCounter}`}>
            <div dangerouslySetInnerHTML={{ __html: this.props.t(i18n.title) }} />
          </DialogTitle>
        )}
        <DialogContent>
          <Context.Context.Provider value={this.state}>
            <InviteGuestForm innerRef={this.refForm} onFormSubmit={this.onFormSubmit} />
          </Context.Context.Provider>
        </DialogContent>
        <DialogActions>
          {this.state.accessCode === null && this.renderDismissAction()}
          {this.renderSubmitAction()}
        </DialogActions>
      </Dialog>
    );
  }

  private renderSubmitAction(): React.ReactNode {
    const submitInProgress = this.state.submitStatus === 'InProgress';

    let { submit: submitAction } = i18n.action;
    if (this.state.accessCode !== null) {
      submitAction = inviteGuestFormI18n.submitSuccessDialog.action.dismiss;
    }

    return (
      <SubmitButton disabled={submitInProgress} progress={submitInProgress} onClick={this.onFormSubmit}>
        {this.props.t(resolveActionLabel(submitAction, ActionId.FormSubmit, ActionContext.FormInviteGuest))}
      </SubmitButton>
    );
  }

  private renderDismissAction(): React.ReactNode {
    let { label } = i18n.action.dismiss;
    label = this.props.t(Utils.isString(label) ? label : label('inviteGuestDismiss'));
    return (
      <a styleName="style.btn-dismiss" href="#dismiss" onClick={this.onDismissClick}>
        {label}
      </a>
    );
  }

  private onDialogEnter(): void {
    const initialState = Context.getDefaultValue();
    const state: State = {
      ...Context.rootReducer(initialState, Context.updateFormData(initialState)),
      dispatch: this.dispatchHandler
    };
    this.setState(state);
  }

  private onDismissClick(event: React.MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.state.submitStatus === 'InProgress') {
      return;
    }

    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  private onFormSubmit(event: React.SyntheticEvent<HTMLElement>): void {
    event.preventDefault();
    event.stopPropagation();

    this.dispatchHandler(Context.submitFormData());
  }

  private dispatchHandler(action: PayloadAction<any>): void {
    if (this.state.submitStatus === 'InProgress') {
      return;
    }

    if (action.type === Context.submitFormData.type) {
      if (Utils.isString(this.state.accessCode)) {
        if (typeof this.props.onClose === 'function') {
          this.props.onClose();
        }
        return;
      }
    }

    this.setState((state) => Context.rootReducer(state, action));
  }

  private async dispatchSendMemberInvitation(): Promise<void> {
    let maskedBirthDate: string | undefined = undefined;
    if (Utils.isString(this.state.birthDate)) {
      const maskedValue = Utils.maskBirthDate(this.state.birthDate, this.props.i18n.language);
      if (maskedValue.length > 0) maskedBirthDate = maskedValue;
    }

    const payload: MedicalInstitute.ActionPayloadSendMemberInvitation = {
      role: Constants.MedicalInstitute.ROLE_ID_GUEST,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      emailAddress: this.state.emailAddress,
      specialty: '',
      officeNumber: '',
      officeNumberExt: '',
      cellNumber: this.state.cellNumber,
      homeNumber: this.state.homeNumber,
      birthDate: this.state.birthDate?.toISOString() || '',
      maskedBirthDate,
      gender: this.state.gender,
      healthPlanJurisdiction: this.state.healthPlanJurisdiction,
      healthCardNumber: this.state.healthPlanNumber,
      groupName: ''
    };

    try {
      const accessCode = await this.props.dispatchSendMemberInvitation(payload);
      this.setState({ accessCode, submitStatus: 'NotSubmitted' });
    } catch {
      this.setState({ submitStatus: 'SubmitFailure' });
      /* ignore */
    }
  }
}

const ns = [I18N_NS_MEDICAL_INSTITUTE_INVITE_GUEST_FORM_DIALOG];
export const InviteGuestFormDialog = withConnect(withTranslation(ns)(InviteGuestFormDialogComponent));
InviteGuestFormDialog.displayName = 'InviteGuestFormDialog';
