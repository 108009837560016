import { AppException } from './app-exception';
import { E_NOT_IMPLEMENTED } from './constants/error';

export class NotImplementedException extends AppException {
  public constructor() {
    super(E_NOT_IMPLEMENTED);

    this.name = 'NotImplementedException';
  }
}
