import { Utils } from '@sigmail/common';
import { Api } from '@sigmail/services';
import { HttpService } from '../http/http-service';
import { apiAuthyRegisterAccount } from './authy-register-account';
import { apiAuthySendMfaCode } from './authy-send-mfa-code';
import { apiAuthyUnregisterAccount } from './authy-unregister-account';
import { apiAuthyUpdateEmail } from './authy-update-email';
import { apiAuthyVerifyMfaCode } from './authy-verify-mfa-code';
import { apiBatchQueryData } from './batch-query-data';
import { apiBatchUpdateData } from './batch-update-data';
import { apiEnterState } from './enter-state';
import { apiGetIDs } from './get-ids';
import { apiGetIDsByUsage } from './get-ids-by-usage';
import { apiHrmGetUserList } from './hrm/get-user-list';
import { apiHrmSendReport } from './hrm/send-report';
import { apiOscarAddDocument } from './oscar/add-document';
import { apiOscarCheckAuthStatus } from './oscar/check-auth-status';
import { apiOscarGetOAuthUrl } from './oscar/get-oauth-url';
import { apiOscarGetProviderNumber } from './oscar/get-provider-number';
import { apiOscarSearchPatient } from './oscar/search-patient';
import { apiSendEmailMessage } from './send-email-message';
import { apiSendTemplatedEmailMessage } from './send-templated-email-message';
import { apiSrpExchangeCredential } from './srp-exchange-credential';
import { apiSrpVerifyCredential } from './srp-verify-credential';

const API_KIND_LIST: ReadonlyArray<Api.Kind> = ['coreApi', 'emailApi', 'authyApi'];

export class ApiService implements Api.Service {
  public readonly httpService = new HttpService();
  public readonly baseUrl: Record<Api.Kind, string>;
  public readonly authKey: Record<Api.Kind, string>;

  public readonly srpExchangeCredential: Api.Service['srpExchangeCredential'];
  public readonly srpVerifyCredential: Api.Service['srpVerifyCredential'];

  public readonly enterState: Api.Service['enterState'];
  public readonly getIds: Api.Service['getIds'];
  public readonly getIdsByUsage: Api.Service['getIdsByUsage'];

  public readonly batchQueryData: Api.Service['batchQueryData'];
  public readonly batchUpdateData: Api.Service['batchUpdateData'];

  public readonly sendEmailMessage: Api.Service['sendEmailMessage'];
  public readonly sendTemplatedEmailMessage: Api.Service['sendTemplatedEmailMessage'];

  public readonly authyRegisterAccount: Api.Service['authyRegisterAccount'];
  public readonly authyUpdateEmail: Api.Service['authyUpdateEmail'];
  public readonly authyUnregisterAccount: Api.Service['authyUnregisterAccount'];
  public readonly authySendMfaCode: Api.Service['authySendMfaCode'];
  public readonly authyVerifyMfaCode: Api.Service['authyVerifyMfaCode'];

  public readonly oscarCheckAuthStatus: Api.Service['oscarCheckAuthStatus'];
  public readonly oscarGetOAuthUrl: Api.Service['oscarGetOAuthUrl'];
  public readonly oscarGetProviderNumber: Api.Service['oscarGetProviderNumber'];
  public readonly oscarSearchPatient: Api.Service['oscarSearchPatient'];
  public readonly oscarAddDocument: Api.Service['oscarAddDocument'];

  public readonly hrmGetUserList: Api.Service['hrmGetUserList'];
  public readonly hrmSendReport: Api.Service['hrmSendReport'];

  public constructor(baseUrl: Record<Api.Kind, string>, authKey?: Record<string, string>) {
    if (!Utils.isNonArrayObjectLike(baseUrl)) {
      throw new TypeError('Invalid argument value.');
    }

    const [[baseUrlCoreApi, authKeyCoreApi], [baseUrlEmailApi, authKeyEmailApi], [baseUrlAuthyApi, authKeyAuthyApi]] =
      API_KIND_LIST.map((key) => {
        const trimmedUrl = (Utils.isString(baseUrl[key]) && baseUrl[key].trim()) || '';
        const trimmedAuthKey =
          (Utils.isNonArrayObjectLike(authKey) && Utils.isString(authKey![key]) && authKey![key].trim()) || '';

        if (!trimmedUrl.endsWith('/')) {
          throw new TypeError('URL must end with a path separator.');
        }

        return [trimmedUrl, trimmedAuthKey];
      });

    this.baseUrl = { coreApi: baseUrlCoreApi, emailApi: baseUrlEmailApi, authyApi: baseUrlAuthyApi };
    this.authKey = { coreApi: authKeyCoreApi, emailApi: authKeyEmailApi, authyApi: authKeyAuthyApi };

    this.srpExchangeCredential = apiSrpExchangeCredential.bind(this);
    this.srpVerifyCredential = apiSrpVerifyCredential.bind(this);

    this.enterState = apiEnterState.bind(this);
    this.getIds = apiGetIDs.bind(this);
    this.getIdsByUsage = apiGetIDsByUsage.bind(this);

    this.batchQueryData = apiBatchQueryData.bind(this);
    this.batchUpdateData = apiBatchUpdateData.bind(this);

    this.sendEmailMessage = apiSendEmailMessage.bind(this);
    this.sendTemplatedEmailMessage = apiSendTemplatedEmailMessage.bind(this);

    this.authyRegisterAccount = apiAuthyRegisterAccount.bind(this);
    this.authyUpdateEmail = apiAuthyUpdateEmail.bind(this);
    this.authyUnregisterAccount = apiAuthyUnregisterAccount.bind(this);
    this.authySendMfaCode = apiAuthySendMfaCode.bind(this);
    this.authyVerifyMfaCode = apiAuthyVerifyMfaCode.bind(this);

    this.oscarCheckAuthStatus = apiOscarCheckAuthStatus.bind(this);
    this.oscarGetOAuthUrl = apiOscarGetOAuthUrl.bind(this);
    this.oscarGetProviderNumber = apiOscarGetProviderNumber.bind(this);
    this.oscarSearchPatient = apiOscarSearchPatient.bind(this);
    this.oscarAddDocument = apiOscarAddDocument.bind(this);

    this.hrmGetUserList = apiHrmGetUserList.bind(this);
    this.hrmSendReport = apiHrmSendReport.bind(this);
  }
}
