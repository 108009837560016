import { AppException, Constants, IAppUser } from '@sigmail/common';
import { AuthenticationData } from '../../core/authentication-data';
import * as AuthSelectors from '../selectors/auth';
import { BaseAction } from './base-action';

export interface AuthenticatedActionState {
  currentUser: IAppUser;
  roleAuthClaim: string;
}

export abstract class AuthenticatedAction<P, S extends AuthenticatedActionState = AuthenticatedActionState, R = void> extends BaseAction<
  P,
  S,
  R
> {
  protected preExecute() {
    const result = super.preExecute();
    this.state.currentUser = this.currentUser;

    this.state.roleAuthClaim = AuthSelectors.authClaimSelector(this.getRootState());
    if (!AuthenticationData.isValidAuthClaim(this.state.roleAuthClaim)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Role authorization claim is either missing or invalid.');
    }

    return result;
  }
}
