import { EncapsulatedKey, Utils } from '@sigmail/common';
import {
  EncryptWithParametersAlgorithm,
  EncryptWithParametersAlgorithmParams,
  SymmetricEncryptor,
  SymmetricKey
} from '@sigmail/crypto';
import { encodeBase64 as bcryptEncodeBase64, hash as bcryptHash } from 'bcryptjs';
import { Algorithm } from '.';
import { E_FAIL } from '../constants';
import * as Encoder from '../encoder';
import { AES_GCM } from '../encryptor/symmetric/AES_GCM';
import * as Hash from '../hash';
import { KeyDerivationFunction } from '../key-derivation-function';
import { MGFV } from '../key-derivation-function/MGFV';
import { SigmailCryptoException } from '../SigmailCryptoException';

/**
 * Define the algorithm EncryptWithParametersAlgorithm. This is used to encrypt
 * a private key (VK) to be stored in the keys table, based on an encapsulated
 * key derived from parameters.
 *
 * - An encapsulated key must be generated from the required parameters
 * - An AES key (AK, 256 bits), initialization vector (IV, 128 bits), and
 *   authentication value (AV, 128 bits) are derived from the encapsulated key
 * - The private key to encrypt is encrypted using AES in GCM mode
 * - The encrypted encapsulated key and encrypted key are concatenated and
 *   stored
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 */
export class EncryptWithParametersAlgorithmImpl
  extends Algorithm<SymmetricEncryptor, any>
  implements EncryptWithParametersAlgorithm {
  protected static readonly BCRYPT_PARAMS = '$2a$12$';
  protected readonly encapsulatedKeyDerivationFunction: KeyDerivationFunction;
  protected readonly envelopeName: string;

  public constructor(envelopeName?: string, encapsulatedKeyDerivationFunction?: KeyDerivationFunction) {
    super(
      'EncryptWithParametersAlgorithm',
      // create an instance of AES_GCM, with required parameters and key derivation primitives
      // create instances of MGFV for deriving AES key, IV, and AAD
      //  each uses a different initialCounter, so the derived values are unrelated
      //  each of these values must not overlap
      //  they are from the value of pi, starting at digit 23001 after the decimal point, 7969 9411 3973 8776 589986855417031...
      new AES_GCM({
        encapsulatedKeyLength: 848,
        tagLength: 128,
        keyDf: new MGFV({ initialCounter: 7969, outLength: 256 }),
        ivDf: new MGFV({ initialCounter: 9411, outLength: 128 }),
        adDf: new MGFV({ initialCounter: 3973, outLength: 128 })
      })
    );

    const trimmedEnvelopeName = Utils.isString(envelopeName)
      ? envelopeName.trim()
      : (this.constructor as Function).name;
    if (trimmedEnvelopeName.length === 0) {
      throw new SigmailCryptoException(E_FAIL, 'Invalid envelope name.');
    }
    this.envelopeName = trimmedEnvelopeName;

    // this instance of MGFV will be used to derive an encapsulated key from provided parameters
    //  the initialCounter is from the same sequence as above
    this.encapsulatedKeyDerivationFunction =
      encapsulatedKeyDerivationFunction instanceof KeyDerivationFunction
        ? encapsulatedKeyDerivationFunction
        : new MGFV({ initialCounter: 8776, outLength: 696 });
  }

  public async generateKey(params: EncryptWithParametersAlgorithmParams): Promise<EncapsulatedKey> {
    // generate an encapsulated key based on provided parameters
    //  these could be the username, password for example
    //  either hashed, or direct values can be used
    //  salt is a 128 bit hex encoded value, which must be the same for encryption and decryption to get the same key
    //  they must be consistently passed for the encryption and decryption
    // a distinct envelope will be used to wrap these and derive a key from the JSON version of this
    //  bcrypt will be used on the wrapped envelope if salt is provided
    // this is the distinct envelope, based on the name of this class, the typeCd, and the passed parameters
    const envelopeJson = JSON.stringify({
      envelope: this.envelopeName,
      type: params.type,
      parameter1: params.parameter1,
      parameter2: params.parameter2
    });

    // hash the json string, to make it fixed length
    let hashValue = await Hash.SHA256(Encoder.UTF8.encode(envelopeJson));
    if (Utils.isString(params.hexSalt)) {
      // salt was provided, so run bcrypt
      // decode the hex salt value into Uint8Array
      const hexSalt = Encoder.Hex.decode(params.hexSalt);

      // encode in bcrypt's unique version of Base64
      const b64Salt = bcryptEncodeBase64(hexSalt, hexSalt.length);

      // encode the hash value to Base64, to guarantee the length (43 bytes, < bcrypt's limit of 56)
      // prefix the Base64 salt value with the bcrypt parameters, controlling the number of loops for bcrypt
      const bcryptParams = (this.constructor as typeof EncryptWithParametersAlgorithmImpl).BCRYPT_PARAMS;
      const hash = await bcryptHash(Encoder.Base64.encode(hashValue), bcryptParams + b64Salt);

      // use the UTF-8 byte array from the 60 byte string returned by bcrypt
      hashValue = Encoder.UTF8.encode(hash);
    }

    // hash value is either the 32 byte hash value initially calculated
    // or a 60 byte value calculated by bcrypt
    return await this.encapsulatedKeyDerivationFunction.derive(hashValue);
  }

  public deriveKey(encapsulatedKey: EncapsulatedKey, version: number): Promise<SymmetricKey> {
    return this.encryptor.deriveKey(encapsulatedKey, version);
  }

  public async encrypt(encapsulatedKey: EncapsulatedKey, data: any, version: number): Promise<string> {
    const key = await this.deriveKey(encapsulatedKey, version);
    const decryptedValue = Encoder.UTF8.encode(JSON.stringify(data));
    const encryptedValue = await this.encryptor.encrypt(key, decryptedValue);
    return Encoder.Base64.encode(encryptedValue);
  }

  public async decrypt(encapsulatedKey: EncapsulatedKey, data: string, version: number): Promise<any> {
    const key = await this.deriveKey(encapsulatedKey, version);
    const encryptedValue = Encoder.Base64.decode(data);
    const decryptedValue = await this.encryptor.decrypt(key, encryptedValue);
    const json = JSON.parse(Encoder.UTF8.decode(decryptedValue));
    return json;
  }
}
