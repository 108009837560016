export const $$CryptographicKey = Symbol.for('$$CryptographicKey');

/**
 * Maximum safe integer value that can be used as a `SigmailObject` ID. The
 * value of `MAX_VALUE_OBJECT_ID` is 9,007,199,254,740,991.
 */
export const MAX_VALUE_OBJECT_ID = Number.MAX_SAFE_INTEGER;

/**
 * Maximum safe integer value that can be used as a `SigmailObject` version.
 * The value of `MAX_VALUE_OBJECT_VERSION` is 4,294,967,295.
 */
export const MAX_VALUE_OBJECT_VERSION = Number.MAX_SAFE_INTEGER >>> 0;
