import { HashFunction } from '@sigmail/crypto';
import { E_FAIL, E_FAIL_DIGEST } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';

async function hash(algorithm: 'SHA-256' | 'SHA-384' | 'SHA-512', data: Uint8Array): Promise<Uint8Array> {
  if (typeof window.crypto?.subtle?.digest !== 'function') {
    throw new SigmailCryptoException(E_FAIL, 'Not supported.');
  }

  try {
    return new Uint8Array(await window.crypto.subtle.digest(algorithm, data));
  } catch {
    throw new SigmailCryptoException(E_FAIL_DIGEST);
  }
}

export const SHA256: HashFunction = Object.assign(hash.bind(undefined, 'SHA-256'), {
  NAME: 'SHA-256',
  hashLength: 256
});

export const SHA384: HashFunction = Object.assign(hash.bind(undefined, 'SHA-384'), {
  NAME: 'SHA-384',
  hashLength: 384
});

export const SHA512: HashFunction = Object.assign(hash.bind(undefined, 'SHA-512'), {
  NAME: 'SHA-512',
  hashLength: 512
});
