import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_AUTHY_UPDATE_EMAIL } from './constants';

export async function apiAuthyUpdateEmail(this: Api.Service, accountId: number, emailAddress: string) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiAuthyUpdateEmail:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.authyApi.concat(URL_AUTHY_UPDATE_EMAIL);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    if (this.authKey.authyApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.authyApi);
    }
    const requestBody = { id: accountId, email: emailAddress };

    // Logger.info('Initiating HTTP request.');
    return await this.httpService.post<void>(requestUrl, JSON.stringify(requestBody), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          return;
        }
        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
