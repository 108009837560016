import { RandomNumberGenerator } from '@sigmail/crypto';
import { E_FAIL, E_FAIL_RNG } from '../constants';
import { SigmailCryptoException } from '../SigmailCryptoException';

type TypedArray =
  | Int8Array
  | Int16Array
  | Int32Array
  | Uint8Array
  | Uint8ClampedArray
  | Uint16Array
  | Uint32Array
  | Float32Array
  | Float64Array;

type TypedArrayConstructor<T extends TypedArray> = new (length: number) => T;

function getRandomValues<T extends TypedArray>(TypedArrayClass: TypedArrayConstructor<T>, length: number): T {
  if (typeof window.crypto?.getRandomValues !== 'function') {
    throw new SigmailCryptoException(E_FAIL, 'Not supported.');
  }

  try {
    return crypto.getRandomValues(new TypedArrayClass(length));
  } catch {
    throw new SigmailCryptoException(E_FAIL_RNG);
  }
}

function createRNG<T extends TypedArray>(
  name: string,
  TypedArrayClass: TypedArrayConstructor<T>
): RandomNumberGenerator<T> {
  return Object.assign(
    getRandomValues.bind<undefined, typeof TypedArrayClass, number[], T>(undefined, TypedArrayClass),
    { NAME: name }
  );
}

export const Int8 = createRNG('Int8', Int8Array);
export const Int16 = createRNG('Int16', Int16Array);
export const Int32 = createRNG('Int32', Int32Array);
export const Uint8 = createRNG('Uint8', Uint8Array);
export const Uint8Clamped = createRNG('Uint8Clamped', Uint8ClampedArray);
export const Uint16 = createRNG('Uint16', Uint16Array);
export const Uint32 = createRNG('Uint32', Uint32Array);
export const Float32 = createRNG('Float32', Float32Array);
export const Float64 = createRNG('Float64', Float64Array);
