import { IAppUser } from '@sigmail/common';
import { AppUserOrUserGroup } from './app-user-or-group';

export class AppUser extends AppUserOrUserGroup<'user'> implements IAppUser {
  /** @override */
  public static isValidType(value: any): value is 'user' {
    return value === 'user';
  }

  public constructor(id: number);
  public constructor(...args: any[]);
  public constructor(...args: any[]) {
    super(...(args.length === 1 && typeof args[0] !== 'number' ? args : ['user', ...args]));
  }
}
