import { encodeBase64 } from 'bcryptjs';
import { isString } from '.';
import { bcryptHash } from './bcrypt-hash';
import { generateSalt } from './generate-salt';
import { uint8ArrayFromHex } from './uint8-array-from-hex';

type GeneratePasswordHashReturnType = { hexSalt: string; passwordHash: string };

export async function generatePasswordHash(
  password: string,
  hexSalt?: string
): Promise<GeneratePasswordHashReturnType> {
  // generate salt (if required)
  const hexEncodedSalt = isString(hexSalt) ? hexSalt : generateSalt('hex');

  // generate password hash
  const b64Salt = encodeBase64(uint8ArrayFromHex(hexEncodedSalt), 16);
  const passwordHash = await bcryptHash(password, '$2a$10$'.concat(b64Salt));

  return { hexSalt: hexEncodedSalt, passwordHash };
}
