import { MenuItem, withWidth, WithWidthProps } from '@material-ui/core';
import { isWidthUp } from '@material-ui/core/withWidth';
import { CancelablePromise, Constants, Utils } from '@sigmail/common';
import { GlobalI18n } from '@sigmail/i18n';
import { getLogger } from '@sigmail/logging';
import { UserMessageFolderKey } from '@sigmail/objects';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { RouterAction } from 'sigmail';
import { AppDispatch } from '../../app-state';
import { signOutAction } from '../../app-state/actions';
import { processIncomingMessageNotificationsAction } from '../../app-state/actions/messaging/process-incoming-messages-action';
import { processRecallMessageNotificationsAction } from '../../app-state/actions/messaging/process-recall-message-notifications-action';
import { RootState } from '../../app-state/root-reducer';
import * as RootSelectors from '../../app-state/selectors';
import * as AuthSelectors from '../../app-state/selectors/auth';
import * as UserObjectSelectors from '../../app-state/selectors/user-object';
import { UserObjectCache } from '../../app-state/user-objects-slice/cache';
import * as ActionId from '../../constants/action-ids';
import * as ClientRightId from '../../constants/client-rights-identifiers';
import { withTranslation } from '../../i18n';
import { I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import i18n from '../../i18n/global';
import { InviteGuestFormDialog } from '../../template/medical-institute/invite-guest-form/invite-guest-form-dialog.component';
import { resolveActionLabel } from '../../utils/resolve-action-label';
import { NavAction } from '../shared/action-nav-list';
import { MemberAvatar } from '../shared/member-avatar.component';
import { MenuButton } from '../shared/menu-button.component';
import navStyle from '../shared/nav.module.css';
import * as Context from './layout.context';
import style from './layout.module.css';
import { SiteFooter } from './site-footer.component';
import { SiteHeader } from './site-header.component';

/** An instance of the logging module. */
const Logger = getLogger('LayoutComponent');

const VALID_LAYOUT_EVENT_TYPES: ReadonlyArray<string> = ['breakpointchange'];
const MESSAGE_FOLDER_KEY_INBOX: Extract<UserMessageFolderKey, 'inbox'> = 'inbox';

/**
 * Retrieves and returns data needed from the store which will be merged into
 * the Layout component's properties.
 */
const mapStateToProps = (state: RootState) => {
  const isUserLoggedIn = AuthSelectors.isUserLoggedInSelector(state);
  const currentUserId = AuthSelectors.currentUserIdSelector(state);
  const clientRights = AuthSelectors.clientRightsSelector(state);
  const homeRoutePath = RootSelectors.homeRoutePathSelector(state);

  const basicProfileObject = UserObjectSelectors.basicProfileObjectSelector(state)();
  const basicProfile = UserObjectCache.getValue(basicProfileObject);

  return { isUserLoggedIn, currentUserId, clientRights, homeRoutePath, basicProfile };
};

/**
 * Returns a list of action dispatcher(s) to be injected in Layout component's
 * properties.
 */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  async dispatchProcessInboxMessageNotifications() {
    await dispatch(processIncomingMessageNotificationsAction({ folderKey: MESSAGE_FOLDER_KEY_INBOX }));
    await dispatch(processRecallMessageNotificationsAction({ folderKey: MESSAGE_FOLDER_KEY_INBOX }));
  },

  dispatchSignOut() {
    return dispatch(signOutAction());
  }
});

/** HOC to create a LayoutComponent which is connected to the store. */
const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ConnectedProps = ReduxConnectedProps<typeof withConnect>;

/** Actions to be rendered in the drop-down menu of User Account element. */
const USER_ACCOUNT_MENU_ACTION_LIST: ReadonlyArray<string> = [
  ActionId.AccountManageSelf,
  ActionId.AccountManageMembers,
  ActionId.FAQ,
  ActionId.TermsAndConditions,
  ActionId.PrivacyPolicy,
  ActionId.ContactUs,
  ActionId.SignOut
];

/** Layout component properties. */
export interface Props extends WithWidthProps, RouteComponentProps, WithTranslation, ConnectedProps {}

/** Type definition for the {@link LayoutComponent#state} object. */
interface State extends Context.ContextValue {
  /** A cancelable promise to fetch notification objects. */
  fetchNotificationsPromise: CancelablePromise<any> | null;
  isUserAccountMenuOpen: boolean;
}

/** A component which renders the base page layout of the application. */
class LayoutComponent extends React.PureComponent<Props, State> {
  private readonly eventHandlerMap: Map<string, Array<Context.LayoutEventHandler>> = new Map();

  public static getDerivedStateFromProps(nextProps: Readonly<Props>, prevState: State): Partial<State> | null {
    if (nextProps.width !== prevState.breakpoint) {
      return {
        breakpoint: nextProps.width,
        isScreenSmallAndUp: isWidthUp('sm', nextProps.width!, /* inclusive := */ true),
        isScreenMediumAndUp: isWidthUp('md', nextProps.width!, /* inclusive := */ true)
      };
    }
    return null;
  }

  /**
   * Stores the timeout ID returned by setTimeout in activateNotificationFetch
   * method.
   */
  private timeoutId: ReturnType<Window['setTimeout']> = -1;

  public constructor(props: Props) {
    super(props);

    // initial state
    this.state = {
      isInviteGuestModalOpen: false,
      breakpoint: props.width,
      isScreenSmallAndUp: isWidthUp('sm', props.width!, /* inclusive := */ true),
      isScreenMediumAndUp: isWidthUp('md', props.width!, /* inclusive := */ true),

      dispatch: (action) => {
        if (action.type === Context.openInviteGuestModal.type) {
          this.setState({ isInviteGuestModalOpen: true });
        } else if (action.type === Context.closeInviteGuestModal.type) {
          this.setState({ isInviteGuestModalOpen: false });
        }
      },

      on: (eventType: string, handler: Context.LayoutEventHandler): void => {
        if (VALID_LAYOUT_EVENT_TYPES.indexOf(eventType) < 0) {
          return;
        }

        let handlers = this.eventHandlerMap.get(eventType);
        if (!Utils.isArray(handlers)) {
          this.eventHandlerMap.set(eventType, (handlers = []));
        }
        if (handlers.indexOf(handler) < 0) {
          handlers.push(handler);
        }
      },

      off: (eventType: string, handler: (...args: any[]) => any): void => {
        if (VALID_LAYOUT_EVENT_TYPES.indexOf(eventType) < 0) {
          return;
        }

        const handlers = this.eventHandlerMap.get(eventType) || [];
        const index = handlers.indexOf(handler);
        if (index >= 0) {
          if (handlers.length < 2) {
            this.eventHandlerMap.delete(eventType);
          } else {
            handlers.splice(index, 1);
          }
        }
      },

      fetchNotificationsPromise: null,
      isUserAccountMenuOpen: false
    };

    this.renderUserAccountMenuButton = this.renderUserAccountMenuButton.bind(this);
    this.onNavItemClick = this.onNavItemClick.bind(this);
    this.toggleUserAccountMenu = this.toggleUserAccountMenu.bind(this);
    this.onUserAccountMenuItemClick = this.onUserAccountMenuItemClick.bind(this);
    this.closeInviteGuestModal = this.closeInviteGuestModal.bind(this);
    this.activateNotificationFetch = this.activateNotificationFetch.bind(this);
  }

  /**
   * Called after the first render, this method will activate the
   * notification object fetching and processing provided that a user is
   * currently logged-in.
   */
  public componentDidMount(): void {
    if (this.props.isUserLoggedIn) {
      Logger.info('componentDidMount:', 'Activating notification fetch.');
      this.activateNotificationFetch();
    }
  }

  /**
   * When a change is detected in the:
   * - **login status:** this method will take care of activating or
   * deactivating the notification object fetching mechanism.
   * - **route location:** this method will toggle the visibility of
   * drawer button.
   *
   * @param prevProps
   */
  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.state.breakpoint !== prevState.breakpoint) {
      this.dispatchEvent('breakpointchange', { prevValue: prevState.breakpoint, newValue: this.state.breakpoint });
    }

    const { isUserLoggedIn } = this.props;
    if (isUserLoggedIn) {
      if (!prevProps.isUserLoggedIn) {
        this.activateNotificationFetch();
      }
    } else if (prevProps.isUserLoggedIn) {
      this.deactivateNotificationFetch();
    }
  }

  /** Cleans up by deactivating the notification object fetching. */
  public componentWillUnmount(): void {
    this.eventHandlerMap.clear();
    this.deactivateNotificationFetch();
  }

  /** Renders the master layout of the application. */
  public render() {
    const { isUserLoggedIn, homeRoutePath, children } = this.props;
    const className = isUserLoggedIn ? 'root' : style.root;

    return (
      <React.Fragment>
        <div className={className}>
          <Context.Context.Provider value={this.state}>
            <SiteHeader
              homeRoutePath={homeRoutePath}
              navAction={this.headerNavAction}
              navClassName={style['nav-header']}
            />
            {children}
            {this.renderSiteFooter()}
          </Context.Context.Provider>
        </div>
        <InviteGuestFormDialog open={this.state.isInviteGuestModalOpen} onClose={this.closeInviteGuestModal} />
      </React.Fragment>
    );
  }

  /**
   * Renders the User Account menu button in Site Header consisting of logged-in
   * user's avatar, full name, and profile badge.
   */
  private renderUserAccountMenuButton(): React.ReactChild {
    const { clientRights, t, basicProfile } = this.props;

    const menuItemList = USER_ACCOUNT_MENU_ACTION_LIST.map((actionId) => {
      if (
        (actionId === ActionId.AccountManageMembers && !clientRights.get(ClientRightId.CAN_ACCESS_MEMBER_MANAGEMENT)) ||
        (actionId === ActionId.AccountManageSelf && !clientRights.get(ClientRightId.CAN_ACCESS_OWN_ACCOUNT))
      ) {
        return null;
      }

      const menuItemLabel = resolveActionLabel(i18n.action[actionId], actionId);
      return (
        <MenuItem key={actionId} data-action-id={actionId} onClick={this.onUserAccountMenuItemClick}>
          {t(menuItemLabel)}
        </MenuItem>
      );
    });

    return (
      <li key={ActionId.UserAccountMenu} className={navStyle.item}>
        <MenuButton
          id="user-account-menu"
          anchorId="btn-user-account-menu"
          anchorChildren={
            <React.Fragment>
              {Utils.joinPersonName(basicProfile)}
              <MemberAvatar
                avatar={basicProfile?.avatar}
                firstName={basicProfile?.firstName}
                lastName={basicProfile?.lastName}
                license={Utils.MedicalInstitute.getLicenseTypeFromRole(basicProfile?.role)}
              />
            </React.Fragment>
          }
          open={this.state.isUserAccountMenuOpen}
          onClick={this.toggleUserAccountMenu}
          onClose={this.toggleUserAccountMenu}
        >
          {menuItemList}
        </MenuButton>
      </li>
    );
  }

  /**
   * Renders the Site Footer component; or an empty div if user isn't logged in.
   */
  private renderSiteFooter(): React.ReactNode {
    return this.props.isUserLoggedIn ? (
      <div styleName="style.empty-footer" />
    ) : (
      <SiteFooter navAction={this.footerNavAction} navClassName={style['nav-footer']} />
    );
  }

  /** Event handler invoked when a User Account menu item is clicked. */
  private onUserAccountMenuItemClick(event: React.SyntheticEvent<HTMLElement>): void {
    const actionId = event.currentTarget instanceof HTMLElement && event.currentTarget.getAttribute('data-action-id');

    // sign-out route doesn't actually exist; we catch the click here and
    // cancel the default browser action to handle sign out manually
    if (actionId === ActionId.SignOut) {
      event.preventDefault();
      event.stopPropagation();

      this.props.dispatchSignOut();
    } else if (
      actionId === ActionId.AccountManageSelf ||
      actionId === ActionId.AccountManageMembers ||
      actionId === ActionId.FAQ ||
      actionId === ActionId.ContactUs ||
      actionId === ActionId.TermsAndConditions ||
      actionId === ActionId.PrivacyPolicy
    ) {
      event.preventDefault();
      event.stopPropagation();

      const { to } = i18n.action[actionId] as RouterAction;
      if (Utils.isString(to)) {
        this.props.history.push(to);
      }
    }

    this.toggleUserAccountMenu(event);
  }

  /** Event handler invoked when User Account action is clicked. */
  private toggleUserAccountMenu(event: React.SyntheticEvent<HTMLElement>): void {
    event.preventDefault();
    event.stopPropagation();

    this.setState(({ isUserAccountMenuOpen }) => ({ isUserAccountMenuOpen: !isUserAccountMenuOpen }));
  }

  /** Event handler invoked when a navigation item is clicked. */
  private onNavItemClick(event: React.MouseEvent<HTMLElement>): void {
    const actionId = event.currentTarget instanceof HTMLElement && event.currentTarget.getAttribute('data-action-id');
    if (actionId === ActionId.InviteGuest) {
      event.preventDefault();
      event.stopPropagation();

      this.setState({ isInviteGuestModalOpen: true });
    }
  }

  private closeInviteGuestModal(): void {
    this.setState({ isInviteGuestModalOpen: false });
  }

  /**
   * Cancels the notification object fetch promise (if pending), and also clears
   * the associated timeout ID to deactivate further querying.
   */
  private deactivateNotificationFetch(): void {
    if (this.state.fetchNotificationsPromise?.isPending || this.timeoutId >= 0) {
      Logger.info('Deactivating notification fetch.');
      this.state.fetchNotificationsPromise?.cancel();
      clearTimeout(this.timeoutId);
      this.timeoutId = -1;
    }
  }

  /**
   * Activates the periodic notification object fetching process and stores the
   * timeout ID for cleanup later (if necessary).
   */
  private activateNotificationFetch(): void {
    // ------------------------------
    // shouldn't be necessary but wth
    clearTimeout(this.timeoutId);
    this.timeoutId = -1;
    // ------------------------------

    this.setState((state, props) => {
      // make sure a user is logged-in before attempting a fetch
      if (!props.isUserLoggedIn) {
        Logger.info('activateNotificationFetch:', 'Call ignored; user is not logged in.');
        return null;
      }

      // make sure a previous fetch is not already being awaited for
      if (state.fetchNotificationsPromise?.isPending) {
        Logger.info('activateNotificationFetch:', 'Call ignored; already activated.');
        return null;
      }

      Logger.info('Fetching new notifications (if any).');
      const fetchNotificationsPromise = Utils.makeCancelablePromise(props.dispatchProcessInboxMessageNotifications());
      fetchNotificationsPromise.promise
        .catch((error) => {
          if (!fetchNotificationsPromise.hasCanceled) {
            Logger.warn('Error processing notification objects:', error);
          }
        })
        .finally(() => {
          // schedule next check
          this.timeoutId = window.setTimeout(this.activateNotificationFetch, 15000);
        });

      return { fetchNotificationsPromise };
    });
  }

  /** Returns a map of navigation actions to be rendered in Site Header. */
  private get headerNavAction(): { [key: string]: NavAction } {
    const { isUserLoggedIn, clientRights } = this.props;
    const canInviteMember = clientRights.get(ClientRightId.CAN_INVITE_MEMBER) as ReadonlyArray<string>;
    const headerNavAction: { [key: string]: NavAction } = {};

    if (isUserLoggedIn) {
      const action: GlobalI18n['action'] = Utils.pick(
        i18n.action,
        [
          clientRights.get(ClientRightId.CAN_ACCESS_MAILBOX) && ActionId.Mailbox,
          (clientRights.get(ClientRightId.CAN_ACCESS_GLOBAL_CONTACTS) ||
            clientRights.get(ClientRightId.CAN_ACCESS_CLIENT_CONTACTS) ||
            clientRights.get(ClientRightId.CAN_ACCESS_CIRCLE_OF_CARE)) &&
            ActionId.ContactList,
          // ActionId.DocumentList,
          canInviteMember.includes(Constants.MedicalInstitute.ROLE_ID_GUEST) && ActionId.InviteGuest
        ].filter(Utils.isString)
      );

      Utils.transform(
        action,
        (navAction, action, actionId) => {
          navAction[actionId] = {
            ...action,
            exact: true,
            navLinkClassName: style.link,
            onClick: this.onNavItemClick
          };
        },
        headerNavAction
      );

      headerNavAction[ActionId.UserAccountMenu] = { label: '', render: this.renderUserAccountMenuButton };
    }

    return headerNavAction;
  }

  /** Returns a map of navigation actions to be rendered in Site Footer. */
  private get footerNavAction(): { [key: string]: NavAction } {
    const footerNavAction: { [key: string]: NavAction } = {};

    if (!this.props.isUserLoggedIn) {
      const action: GlobalI18n['action'] = Utils.pick(i18n.action, [
        // ActionId.LanguageToggle,
        ActionId.FAQ,
        ActionId.TermsAndConditions,
        ActionId.PrivacyPolicy
      ]);

      Utils.transform(
        action,
        (navAction, action, actionId) => {
          navAction[actionId] = {
            ...action,
            exact: true,
            navItemClassName: style.item,
            navLinkClassName: style.link,
            onClick: this.onNavItemClick
          };
        },
        footerNavAction
      );
    }

    return footerNavAction;
  }

  private dispatchEvent(eventType: string, eventArgs: any): void {
    if (VALID_LAYOUT_EVENT_TYPES.indexOf(eventType) < 0) {
      return;
    }

    const handlers = this.eventHandlerMap.get(eventType) || [];
    for (const handler of handlers) {
      handler.call(null, eventArgs);
    }
  }
}

export const Layout = withWidth({ noSSR: true })(
  withRouter(withTranslation([I18N_NS_GLOBAL])(withConnect(LayoutComponent)))
);

Layout.displayName = 'Layout';
