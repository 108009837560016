import { PersonName, PostalAddress } from '@sigmail/common';
import { isString } from '.';
import { joinPersonName } from './join-person-name';
import { joinPostalAddress } from './join-postal-address';

export function createMessageSignature(
  name?: Partial<PersonName> | null | undefined,
  address?: PostalAddress | null | undefined,
  phoneNumber?: string | null | undefined,
  phoneNumberExt?: string | null | undefined
): string {
  const result = [joinPersonName(name)];

  if (result[0].length > 0) {
    const { addressLine1, addressLine2, addressLevel2, addressLevel1, postalCode } = address || {};
    result.push(
      [
        joinPostalAddress({ addressLine1, addressLine2 }),
        joinPostalAddress({ addressLevel2, addressLevel1, postalCode })
      ]
        .filter(Boolean)
        .join('<br/>')
    );

    if (isString(phoneNumber)) {
      const trimmedPhoneNumber = phoneNumber.trim();
      result.push(trimmedPhoneNumber);
      if (trimmedPhoneNumber.length > 0 && isString(phoneNumberExt)) {
        const trimmedExt = phoneNumberExt.trim();
        if (trimmedExt.length > 0) {
          result[result.length - 1] += `\u00a0ext.\u00a0${trimmedExt}`;
        }
      }
    }
  }

  return `<p>${result.filter(Boolean).join('</p><p>')}</p>`;
}
