import { Json } from '@sigmail/common';
import jwtDecode from 'jwt-decode';
import { isNonArrayObjectLike, isString } from '.';
import { AppException } from '../app-exception';
import { E_AUTH_FAIL_DECODE_ID_TOKEN } from '../constants/error';

interface RequiredIdTokenProps {
  userId: number;
  keyId: number;
  credentialId: number;
  type: number; // credential type
  authState: string;
}

export interface JwtIdTokenValue extends RequiredIdTokenProps {
  [key: string]: Json;
}

const PROPS_NUMERIC: ReadonlyArray<keyof Pick<RequiredIdTokenProps, 'userId' | 'keyId' | 'credentialId' | 'type'>> = [
  'userId',
  'keyId',
  'credentialId',
  'type'
];

export function decodeIdToken(token: string): JwtIdTokenValue {
  let idToken: JwtIdTokenValue | undefined = undefined;

  // prettier-ignore
  try { idToken = jwtDecode<JwtIdTokenValue>(token); }
  catch (error) { /* ignore */ }

  if (!isNonArrayObjectLike(idToken)) {
    throw new AppException(E_AUTH_FAIL_DECODE_ID_TOKEN, 'ID Token is either missing or invalid.');
  }

  for (const prop of PROPS_NUMERIC) {
    const propValue = idToken[prop];
    if (isString(propValue)) {
      idToken[prop] = Number.parseInt(propValue, 10);
    }
  }

  return idToken;
}
