import { LoginFormI18n } from '@sigmail/i18n';
import { gettext } from '.';

const i18n: LoginFormI18n = {
  // Translators: (HTML) Page Heading
  heading1: gettext('loginForm', 'loginForm', 'h1', 'safe-html'),
  // Translators: (HTML) Lead Text
  leadText: gettext('loginForm', 'loginForm', 'lead', 'safe-html'),
  formField: {
    username: {
      // Translators: Label for the Username input
      label: gettext('loginForm', 'loginForm', 'inputLabelUsername'),
      error: {
        // Translators: Error message displayed when login form in submitted with providing a username
        valueMissing: gettext('loginForm', 'loginForm', 'errorMessageUsernameRequired')
      }
    },
    password: {
      // Translators: Label for the Password input
      label: gettext('loginForm', 'loginForm', 'inputLabelPassword'),
      error: {
        // Translators: Error message displayed when login form in submitted with providing a password
        valueMissing: gettext('loginForm', 'loginForm', 'errorMessagePasswordRequired')
      }
    },
    submitAction: {
      // Translators: Label for the Form Submit action
      label: gettext('loginForm', 'loginForm', 'actionLabelSubmit')
    }
  },
  passwordVisibilityIcon: {
    // Translators: Label for the Hide Password icon
    labelHidePassword: gettext('loginForm', 'loginForm', 'iconLabelHidePassword'),
    // Translators: Label for the Show Password icon
    labelShowPassword: gettext('loginForm', 'loginForm', 'iconLabelShowPassword')
  },
  action: {
    forgotPassword: {
      // Translators: Label for the Forgot Password action
      label: gettext('loginForm', 'loginForm', 'actionLabelForgotPassword')
    }
  },
  // Translators: Error message indicating username and/or password is incorrect
  errorMessageIncorrectCredentials: gettext('loginForm', 'loginForm', 'errorMessageIncorrectCredentials'),
  // Translators: Generic error message for any possible scenario of login failure
  errorMessageSubmitFailed: gettext('loginForm', 'loginForm', 'errorMessageSubmitFailed'),
  verifyMfaCode: {
    // Translators: (HTML) Page Heading
    heading1: gettext('loginForm', 'loginFormVerifyMfaCode', 'h1', 'safe-html'),
    submitAction: {
      // Translators: Label for the Form Submit action
      label: gettext('loginForm', 'loginFormVerifyMfaCode', 'actionLabelSubmit')
    }
  }
};

export default i18n;
