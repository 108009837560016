import { Utils } from '@sigmail/common';
import { DataObjectMsgMetadataValue as DV } from '@sigmail/objects';
import { DataObject } from '.';

const TYPE = process.env.DATA_OBJECT_TYPE_MSG_METADATA;

export class DataObjectMsgMetadata extends DataObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      Utils.isPlainObject(value.sender) &&
      Utils.isInteger(value.sentAtUtc) &&
      Utils.isString(value.subjectLine) &&
      Utils.isArray(value.recipientList) &&
      //
      // -----------------------------------------------------------------------
      // following check is disabled until we figure out a solution for draft
      // message metadata values which have the value 0 set as the read receipt
      // ID
      // -----------------------------------------------------------------------
      // this.isValidId(value.readReceiptId) &&
      // -----------------------------------------------------------------------
      //
      Utils.isString(value.importance) &&
      (Utils.isUndefined(value.documentList) || Utils.isArray(value.documentList))
    );
  }

  /** @override */
  public static isValidVersion(value: any): value is number {
    return value === 0;
  }

  public updateValue(newValue: DV): Promise<DataObjectMsgMetadata> {
    const Class = this.constructor as typeof DataObjectMsgMetadata;
    return Class.updateValue(this, newValue);
  }
}
