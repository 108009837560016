import { Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_SRP_VERIFY_CREDENTIALS } from './constants';
import { MalformedResponseException } from './malformed-response-exception';
import { ServiceException as ApiServiceException } from './service-exception';

const PROPS: ReadonlyArray<keyof Api.SrpVerifyCredentialResponseData> = [
  'scope',
  'token_type',
  'access_token',
  'expires_in',
  'refresh_token',
  'id_token'
];

function isValidApiResponse(json: any): json is Api.SrpVerifyCredentialResponseData {
  // make sure response JSON is an object and has at least all of the expected properties
  if (Utils.isNonArrayObjectLike(json) && Utils.every(PROPS, Utils.partial(Utils.has, json))) {
    // make sure all of the properties are of the expected data types
    return PROPS.every((p) => (p === 'expires_in' ? Utils.isInteger(json[p]) && json[p] > 0 : Utils.isString(json[p])));
  }
  return false;
}

export async function apiSrpVerifyCredential(
  this: Api.Service,
  sessionId: string,
  credentialHash: string,
  credentialProof: string
) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiSrpVerifyCredential:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(URL_SRP_VERIFY_CREDENTIALS);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }
    const formData = [
      `nonce=${encodeURIComponent(sessionId)}`,
      `username=${encodeURIComponent(credentialHash)}`,
      `password=${encodeURIComponent(credentialProof)}`,
      'grant_type=password'
    ].join('&');

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.SrpVerifyCredentialResponseData>(requestUrl, formData, {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<Api.SrpVerifyCredentialResponseData>(response);
          if (isValidApiResponse(responseJson)) return responseJson;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        if (response.status === 400) {
          throw new ApiServiceException(response, Constants.Error.E_AUTH_FAIL);
        }

        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
