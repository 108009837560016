import { MessagingI18n } from '@sigmail/i18n';
import { gettext } from '.';
import { getRoutePath } from '../app/routes';
import FolderIconArchivedPrimary from '../assets/images/folder-icon-archived-primary.min.svg';
import FolderIconArchivedSecondary from '../assets/images/folder-icon-archived-secondary.min.svg';
import FolderIconDeletedPrimary from '../assets/images/folder-icon-deleted-primary.min.svg';
import FolderIconDeletedSecondary from '../assets/images/folder-icon-deleted-secondary.min.svg';
import FolderIconDraftsPrimary from '../assets/images/folder-icon-drafts-primary.min.svg';
import FolderIconDraftsSecondary from '../assets/images/folder-icon-drafts-secondary.min.svg';
import FolderIconImportantPrimary from '../assets/images/folder-icon-important-primary.min.svg';
import FolderIconImportantSecondary from '../assets/images/folder-icon-important-secondary.min.svg';
import FolderIconInboxPrimary from '../assets/images/folder-icon-inbox-primary.min.svg';
import FolderIconInboxSecondary from '../assets/images/folder-icon-inbox-secondary.min.svg';
import FolderIconReferralsPrimary from '../assets/images/folder-icon-referrals-primary.min.svg';
import FolderIconReferralsSecondary from '../assets/images/folder-icon-referrals-secondary.min.svg';
import FolderIconReminderPrimary from '../assets/images/folder-icon-reminder-primary.min.svg';
import FolderIconReminderSecondary from '../assets/images/folder-icon-reminder-secondary.min.svg';
import FolderIconSentPrimary from '../assets/images/folder-icon-sent-primary.min.svg';
import FolderIconSentSecondary from '../assets/images/folder-icon-sent-secondary.min.svg';
import IconNewMessagePrimary from '../assets/images/icon-new-message-primary.min.svg';
import IconNewMessageSecondary from '../assets/images/icon-new-message-secondary.min.svg';
import * as ActionContext from '../constants/action-context';
import * as ActionId from '../constants/action-ids';
import { EnglishCanada, FrenchCanada } from '../constants/language-codes';
import * as RouteId from '../constants/route-identifiers';

const i18n: MessagingI18n = {
  navAction: {
    [ActionId.ComposeMessage]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: IconNewMessagePrimary,
          [FrenchCanada]: IconNewMessagePrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: IconNewMessageSecondary,
          [FrenchCanada]: IconNewMessageSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelComposeMessage'),
      to: getRoutePath(RouteId.ROUTE_MAIL_COMPOSE_MESSAGE)
    },
    [ActionId.ReceivedMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconInboxPrimary,
          [FrenchCanada]: FolderIconInboxPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconInboxSecondary,
          [FrenchCanada]: FolderIconInboxSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelReceivedMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_INBOX)
    },
    [ActionId.ReceivedMessagesShared]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconInboxPrimary,
          [FrenchCanada]: FolderIconInboxPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconInboxSecondary,
          [FrenchCanada]: FolderIconInboxSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelReceivedMessagesShared'),
      to: getRoutePath(RouteId.ROUTE_MAIL_INBOX_SHARED)
    },
    [ActionId.ReminderMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconReminderPrimary,
          [FrenchCanada]: FolderIconReminderPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconReminderSecondary,
          [FrenchCanada]: FolderIconReminderSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelReminderMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_REMINDER)
    },
    [ActionId.ImportantMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconImportantPrimary,
          [FrenchCanada]: FolderIconImportantPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconImportantSecondary,
          [FrenchCanada]: FolderIconImportantSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelImportantMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_IMPORTANT)
    },
    [ActionId.ImportantMessagesShared]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconImportantPrimary,
          [FrenchCanada]: FolderIconImportantPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconImportantSecondary,
          [FrenchCanada]: FolderIconImportantSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelImportantMessagesShared'),
      to: getRoutePath(RouteId.ROUTE_MAIL_IMPORTANT_SHARED)
    },
    [ActionId.ReferralMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconReferralsPrimary,
          [FrenchCanada]: FolderIconReferralsPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconReferralsSecondary,
          [FrenchCanada]: FolderIconReferralsSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelReferralMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_REFERRALS)
    },
    [ActionId.ReferralMessagesShared]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconReferralsPrimary,
          [FrenchCanada]: FolderIconReferralsPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconReferralsSecondary,
          [FrenchCanada]: FolderIconReferralsSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelReferralMessagesShared'),
      to: getRoutePath(RouteId.ROUTE_MAIL_REFERRALS_SHARED)
    },
    [ActionId.ArchivedMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconArchivedPrimary,
          [FrenchCanada]: FolderIconArchivedPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconArchivedSecondary,
          [FrenchCanada]: FolderIconArchivedSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelArchivedMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_ARCHIVED)
    },
    [ActionId.ArchivedMessagesShared]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconArchivedPrimary,
          [FrenchCanada]: FolderIconArchivedPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconArchivedSecondary,
          [FrenchCanada]: FolderIconArchivedSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelArchivedMessagesShared'),
      to: getRoutePath(RouteId.ROUTE_MAIL_ARCHIVED_SHARED)
    },
    [ActionId.DeletedMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconDeletedPrimary,
          [FrenchCanada]: FolderIconDeletedPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconDeletedSecondary,
          [FrenchCanada]: FolderIconDeletedSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelDeletedMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_TRASH)
    },
    [ActionId.DeletedMessagesShared]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconDeletedPrimary,
          [FrenchCanada]: FolderIconDeletedPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconDeletedSecondary,
          [FrenchCanada]: FolderIconDeletedSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelDeletedMessagesShared'),
      to: getRoutePath(RouteId.ROUTE_MAIL_TRASH_SHARED)
    },
    [ActionId.SentMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconSentPrimary,
          [FrenchCanada]: FolderIconSentPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconSentSecondary,
          [FrenchCanada]: FolderIconSentSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelSentMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_SENT)
    },
    [ActionId.DraftMessages]: {
      iconPrimary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconDraftsPrimary,
          [FrenchCanada]: FolderIconDraftsPrimary
        }
      },
      iconSecondary: {
        alt: '',
        src: {
          [EnglishCanada]: FolderIconDraftsSecondary,
          [FrenchCanada]: FolderIconDraftsSecondary
        }
      },
      label: gettext('messaging', 'messaging', 'actionLabelDraftMessages'),
      to: getRoutePath(RouteId.ROUTE_MAIL_DRAFTS)
    }
  },
  action: {
    [ActionId.Reply]: {
      // Translators: Label for the Reply action
      label: gettext('messaging', 'messaging', 'actionLabelReply')
    },
    [ActionId.ReplyAll]: {
      // Translators: Label for the Reply All action
      label: gettext('messaging', 'messaging', 'actionLabelReplyAll')
    },
    [ActionId.SendMessageToOscar]: {
      // Translators: Label for the Reply All action
      label: gettext('messaging', 'messaging', 'actionLabelSendMessageToOscar')
    },
    [ActionId.Forward]: {
      // Translators: Label for the Forward action
      label: gettext('messaging', 'messaging', 'actionLabelForward')
    },
    [ActionId.MarkAsRead]: {
      // Translators: Label for the Mark As Read action
      label: gettext('messaging', 'messaging', 'actionLabelMarkRead')
    },
    [ActionId.MarkAsUnread]: {
      // Translators: Label for the Mark As Unread action
      label: gettext('messaging', 'messaging', 'actionLabelMarkUnread')
    },
    // [ActionId.Reminder]: {
    //   // Translators: Label for the Reminder action
    //   label: gettext('messaging', 'messaging', 'actionLabelReminder')
    // },
    [ActionId.MoveToArchive]: {
      // Translators: Label for the Move To Archive action
      label: gettext('messaging', 'messaging', 'actionLabelArchive')
    },
    [ActionId.ComposeReferral]: {
      // Translators: Label for the Compose Referral action
      label: gettext('messaging', 'messaging', 'actionLabelComposeReferral')
    },
    [ActionId.Recall]: {
      // Translators: Label for the Recall action
      label: gettext('messaging', 'messaging', 'actionLabelRecall')
    },
    [ActionId.Print]: {
      // Translators: Label for the Print action
      label: gettext('messaging', 'messaging', 'actionLabelPrint')
    },
    [ActionId.ComposeHrmMessage]: {
      // Translators: Label for the Compose HRM Message action
      label: gettext('messaging', 'messaging', 'actionLabelComposeHrmMessage')
    },
    [ActionId.CloseMessage]: {
      // Translators: Label for the Close Message action
      label: gettext('messaging', 'messaging', 'actionLabelCloseMessage')
    },
    [ActionId.DiscardDraft]: {
      // Translators: Label for the Discard Draft action
      label: gettext('messaging', 'messaging', 'actionLabelDiscardDraft')
    }
  },
  ariaLabelFilter: gettext('messaging', 'messaging', 'dropdownLabelFilter'),
  filter: {
    search: gettext('messaging', 'messaging', 'filterLabelSearch'),
    all: gettext('messaging', 'messaging', 'filterLabelAll'),
    unread: gettext('messaging', 'messaging', 'filterLabelUnread'),
    read: gettext('messaging', 'messaging', 'filterLabelRead'),
    reminder: gettext('messaging', 'messaging', 'filterLabelReminders'),
    important: gettext('messaging', 'messaging', 'filterLabelImportant'),
    billable: gettext('messaging', 'messaging', 'filterLabelBillable'),
    referrals: gettext('messaging', 'messaging', 'filterLabelReferrals'),
    attachment: gettext('messaging', 'messaging', 'filterLabelAttachments')
  },
  subjectLineRecalledMessage: gettext('messaging', 'messaging', 'subjectLineRecalledMessage'),
  subjectLineAcceptedReferralResponse: gettext('messaging', 'messaging', 'subjectLineAcceptedReferralResponse'),
  subjectLineDeclinedReferralResponse: gettext('messaging', 'messaging', 'subjectLineDeclinedReferralResponse'),
  errorMessageRecallWindowClosed: gettext('messaging', 'messaging', 'errorMessageRecallWindowClosed'),

  dialog: {
    confirmMessageRecall: {
      title: gettext('messaging', 'dialogConfirmMessageRecall', 'title', 'safe-html'),
      bodyCurrentMessage: gettext('messaging', 'dialogConfirmMessageRecall', 'bodyCurrentMessage', 'safe-html'),
      bodySelectedMessage: gettext('messaging', 'dialogConfirmMessageRecall', 'bodySelectedMessage', 'safe-html'),
      noteMessageMayNotRecall: gettext('messaging', 'dialogConfirmMessageRecall', 'noteMessageMayNotRecall', 'safe-html'),
      action: {
        confirm: {
          label: gettext('messaging', 'dialogConfirmMessageRecall', 'actionLabelConfirm'),
          context: ActionContext.DialogConfirmRecallMessage
        },
        cancel: {
          label: gettext('messaging', 'dialogConfirmMessageRecall', 'actionLabelCancel'),
          context: ActionContext.DialogConfirmRecallMessage
        }
      }
    },

    confirmDiscardDraftMessage: {
      title: gettext('messaging', 'dialogConfirmDiscardDraft', 'title', 'safe-html'),
      bodyCurrentDraft: gettext('messaging', 'dialogConfirmDiscardDraft', 'bodyCurrentDraft', 'safe-html'),
      bodySelectedDraft: gettext('messaging', 'dialogConfirmDiscardDraft', 'bodySelectedDraft', 'safe-html'),
      action: {
        confirm: {
          label: gettext('messaging', 'dialogConfirmDiscardDraft', 'actionLabelConfirm'),
          context: ActionContext.DialogConfirmDiscardDraft
        },
        cancel: {
          label: gettext('messaging', 'dialogConfirmDiscardDraft', 'actionLabelCancel'),
          context: ActionContext.DialogConfirmDiscardDraft
        }
      }
    },

    confirmSendToOscar: {
      title: gettext('messaging', 'dialogConfirmSendToOscar', 'title', 'safe-html'),
      body: gettext('messaging', 'dialogConfirmSendToOscar', 'body', 'safe-html'),
      action: {
        confirm: {
          label: gettext('messaging', 'dialogConfirmSendToOscar', 'actionLabelConfirm'),
          context: ActionContext.DialogConfirmDiscardDraft
        },
        cancel: {
          label: gettext('messaging', 'dialogConfirmSendToOscar', 'actionLabelCancel'),
          context: ActionContext.DialogConfirmDiscardDraft
        }
      }
    },

    oscarOAuthParamsMissing: {
      title: gettext('messaging', 'dialogOscarOAuthParamsMissing', 'title', 'safe-html'),
      body: gettext('messaging', 'dialogOscarOAuthParamsMissing', 'body', 'safe-html'),
      action: {
        confirm: {
          label: gettext('messaging', 'dialogOscarOAuthParamsMissing', 'actionLabelClose'),
          context: ActionContext.DialogOscarOAuthParamsMissing
        }
      }
    },

    patientRecordNotFound: {
      title: gettext('messaging', 'dialogPatientRecordNotFound', 'title', 'safe-html'),
      body: gettext('messaging', 'dialogPatientRecordNotFound', 'body', 'safe-html'),
      action: {
        confirm: {
          label: gettext('messaging', 'dialogPatientRecordNotFound', 'actionLabelClose'),
          context: ActionContext.DialogPatientRecordNotFound
        }
      }
    },

    oscarSelectPatientRecord: {
      title: gettext('messaging', 'dialogOscarSelectPatientRecord', 'title', 'safe-html'),
      body: gettext('messaging', 'dialogOscarSelectPatientRecord', 'body', 'safe-html'),
      action: {
        confirm: {
          label: gettext('messaging', 'dialogOscarSelectPatientRecord', 'actionLabelContinue'),
          context: ActionContext.DialogOscarSelectPatientRecord
        },
        cancel: {
          label: gettext('messaging', 'dialogOscarSelectPatientRecord', 'actionLabelCancel'),
          context: ActionContext.DialogOscarSelectPatientRecord
        }
      }
    },

    uploadProgressDialog: {
      title: gettext('messaging', 'dialogOscarUploadProgress', 'title', 'safe-html'),
      progressMessage: {
        sendingBody: gettext('messaging', 'dialogOscarUploadProgress', 'progressMsgSendingBody', 'safe-html'),
        sendingDocument: gettext('messaging', 'dialogOscarUploadProgress', 'progressMsgSendingDoc', 'safe-html')
      },
      errorMessage: {
        failedToSendBody: gettext('messaging', 'dialogOscarUploadProgress', 'failedToSendBody', 'safe-html'),
        failedToSendDocuments: gettext('messaging', 'dialogOscarUploadProgress', 'failedToSendDocs', 'safe-html')
      },
      successMessage: gettext('messaging', 'dialogOscarUploadProgress', 'successMessage', 'safe-html'),
      action: {
        confirm: {
          label: gettext('messaging', 'dialogOscarUploadProgress', 'actionLabelClose'),
          context: ActionContext.DialogOscarUploadProgress
        }
      }
    }
  },

  gridOscarPatientRecord: {
    columnHeader: {
      firstName: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderFirstName'),
      lastName: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderLastName'),
      healthPlanNumber: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderHealthPlanNumber'),
      birthDate: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderBirthDate'),
      address: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderAddress'),
      phone: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderPhone'),
      email: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderEmail'),
      gender: gettext('messaging', 'gridOscarPatientRecord', 'columnHeaderGender')
    }
  },

  confirmCloseUnsentMessageDialog: {
    title: gettext('messaging', 'messaging', 'dialogTitleConfirmCloseUnsentMessage'),
    body: gettext('messaging', 'messaging', 'dialogBodyConfirmCloseUnsentMessage', 'safe-html'),
    action: {
      confirm: {
        label: gettext('messaging', 'messaging', 'actionLabelConfirmCloseUnsentMessage')
      },
      saveAsDraft: {
        label: gettext('messaging', 'messaging', 'actionLabelSaveUnsentMessageAsDraft')
      },
      cancel: {
        label: gettext('messaging', 'messaging', 'actionLabelCancelCloseUnsentMessage')
      }
    }
  },

  referralDeclineReasonDialog: {
    title: gettext('messaging', 'messaging', 'dialogTitleReferralDeclineReason', 'safe-html'),
    body: gettext('messaging', 'messaging', 'dialogBodyReferralDeclineReason', 'safe-html'),
    reason: {
      label: gettext('messaging', 'messaging', 'inputLabelDeclineReason'),
      error: {
        valueMissing: gettext('messaging', 'messaging', 'errorDeclineReasonRequired'),
        patternMismatch: gettext('messaging', 'messaging', 'errorDeclineReasonPatternMismatch')
      }
    },
    action: {
      dismiss: {
        label: gettext('messaging', 'messaging', 'actionLabelCancelDeclineReferral')
      },
      submit: {
        label: gettext('messaging', 'messaging', 'actionLabelSubmitDeclineReferral')
      }
    }
  }
};

export default i18n;
