type HtmlImageElementProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
export type HtmlImageElementPropsWithAlt = Omit<HtmlImageElementProps, 'alt'> & { alt: string };

/**
 * Returns a new {@link HtmlImageElementPropsWithAlt} with ALT and ROLE
 * attribute added if necessary. ALT attribute is added if either it's missing
 * or, it's value is not of data type string. ROLE attribute is added if ALT
 * attribute's value evaluates to an empty string.
 *
 * @param props
 */
export function processImgProps(props: HtmlImageElementProps): HtmlImageElementPropsWithAlt {
  if (typeof props !== 'object' || props === null || Array.isArray(props)) {
    return props as any;
  }

  const imgProps: HtmlImageElementPropsWithAlt = {
    alt: typeof props.alt === 'string' ? props.alt : '',
    role: props.role
  };

  // add ROLE attribute set to 'presentation' if ALT is blank
  // and no other role is explicitly assigned
  if (imgProps.alt.trim().length === 0 && !!!imgProps.role) {
    imgProps.role = 'presentation';
  }

  return { ...props, ...imgProps };
}
