import {
  Drawer,
  ExpansionPanel as Accordion,
  ExpansionPanelDetails as AccordionDetails,
  ExpansionPanelSummary as AccordionSummary,
  IconButton
} from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import { Constants, Utils } from '@sigmail/common';
import { UserObjectProfileBasicValue } from '@sigmail/objects';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { connect, ConnectedProps as ReduxConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Action, RouterAction } from 'sigmail';
import { AppDispatch } from '../../app-state';
import { signOutAction } from '../../app-state/actions';
import { RootState } from '../../app-state/root-reducer';
import * as RootSelectors from '../../app-state/selectors';
import * as AuthSelectors from '../../app-state/selectors/auth';
import * as UserObjectSelectors from '../../app-state/selectors/user-object';
import { UserObjectCache } from '../../app-state/user-objects-slice/cache';
import IconClose from '../../assets/images/icon-close-dialog.min.svg';
import NavIconContactUs from '../../assets/images/nav-icon-contact-us-secondary.min.svg';
import NavIconFAQ from '../../assets/images/nav-icon-faq-secondary.min.svg';
import NavIconLegalDocs from '../../assets/images/nav-icon-legal-docs-secondary.min.svg';
import NavIconManageMembers from '../../assets/images/nav-icon-manage-members-secondary.min.svg';
import NavIconMyAccount from '../../assets/images/nav-icon-my-account-secondary.min.svg';
import NavIconSignOut from '../../assets/images/nav-icon-sign-out-secondary.min.svg';
import * as ActionContext from '../../constants/action-context';
import * as ActionId from '../../constants/action-ids';
import * as ClientRightId from '../../constants/client-rights-identifiers';
import { withTranslation } from '../../i18n';
import { I18N_NS_CONTACT_LIST } from '../../i18n/config/namespace-identifiers';
import contactListI18n from '../../i18n/contact-list';
import globalI18n from '../../i18n/global';
import { processImgProps } from '../../utils/process-img-props';
import { resolveActionIcon } from '../../utils/resolve-action-icon';
import { resolveActionLabel } from '../../utils/resolve-action-label';
import { resolveActionPath } from '../../utils/resolve-action-path';
import { MessageFolderList } from '../messaging/message-folder-list/message-folder-list.component';
import { ActionNavList, NavAction } from '../shared/action-nav-list';
import { MemberAvatar } from '../shared/member-avatar.component';
import { RouterNavLink, RouterNavLinkProps } from '../shared/router-nav-link.component';
import * as Context from './layout.context';
import style from './site-header.module.css';

/**
 * Retrieves and returns data needed from the store which will be merged into
 * the AppDrawer component's properties.
 */
const mapStateToProps = (state: RootState) => {
  const isUserLoggedIn = AuthSelectors.isUserLoggedInSelector(state);
  const clientRights = AuthSelectors.clientRightsSelector(state);
  const homeRoutePath = RootSelectors.homeRoutePathSelector(state);

  const basicProfileObject = UserObjectSelectors.basicProfileObjectSelector(state)();
  const basicProfile = UserObjectCache.getValue(basicProfileObject, {} as UserObjectProfileBasicValue);

  return { isUserLoggedIn, clientRights, homeRoutePath, basicProfile };
};

/**
 * Returns a list of action dispatcher(s) to be injected in AppDrawer
 * component's properties.
 */
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  dispatchSignOut() {
    return dispatch(signOutAction());
  }
});

/** HOC to create a AppDrawerComponent which is connected to the store. */
const withConnect = connect(mapStateToProps, mapDispatchToProps);
type ConnectedProps = ReduxConnectedProps<typeof withConnect>;

/** Actions to be rendered in the extension panel of User Account element. */
const ACTION_ID_LIST_USER_ACCOUNT = [
  ActionId.AccountManageSelf,
  ActionId.AccountManageMembers,
  ActionId.FAQ,
  ActionId.TermsAndConditions,
  ActionId.PrivacyPolicy,
  ActionId.ContactUs,
  ActionId.SignOut
];

/** Mapping of an action ID to it's icon image. */
const NAV_ICON_IMAGE_MAP: { readonly [actionId: string]: string } = {
  [ActionId.AccountManageSelf]: NavIconMyAccount,
  [ActionId.AccountManageMembers]: NavIconManageMembers,
  [ActionId.FAQ]: NavIconFAQ,
  [ActionId.TermsAndConditions]: NavIconLegalDocs,
  [ActionId.PrivacyPolicy]: NavIconLegalDocs,
  [ActionId.ContactUs]: NavIconContactUs,
  [ActionId.SignOut]: NavIconSignOut
};

const UserAccountActionNavList = withConnect(
  withTranslation()(
    React.memo<WithTranslation & ConnectedProps & { onNavItemClick?: React.MouseEventHandler }>(
      ({ t, clientRights, onNavItemClick }) => {
        return (
          <ActionNavList
            t={t}
            className={style['nav-user-account']}
            action={Utils.transform(
              ACTION_ID_LIST_USER_ACCOUNT,
              (navAction, actionId) => {
                if (
                  (actionId === ActionId.AccountManageSelf &&
                    !clientRights.get(ClientRightId.CAN_ACCESS_OWN_ACCOUNT)) ||
                  (actionId === ActionId.AccountManageMembers &&
                    !clientRights.get(ClientRightId.CAN_ACCESS_MEMBER_MANAGEMENT))
                ) {
                  return;
                }

                navAction[actionId] = {
                  ...globalI18n.action[actionId],
                  exact: true,
                  image:
                    actionId in NAV_ICON_IMAGE_MAP ? processImgProps({ src: NAV_ICON_IMAGE_MAP[actionId] }) : undefined,
                  navItemClassName: style.item,
                  navLinkClassName: style.link,
                  onClick: onNavItemClick
                };
              },
              {} as { [actionId: string]: NavAction }
            )}
          />
        );
      }
    )
  )
);

UserAccountActionNavList.displayName = 'UserAccountActionNavList';

/** AppDrawer component properties. */
export interface Props extends RouteComponentProps, WithTranslation, ConnectedProps {}

/** Type definition for the {@link AppDrawerComponent#state} object. */
interface State {
  isOpen: boolean;
  expandedPanel: string;
}

class AppDrawerComponent extends React.PureComponent<Props, State> {
  public static contextType = Context.Context;
  context!: React.ContextType<typeof Context.Context>;

  public constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      expandedPanel: ''
    };

    this.toggleAppDrawer = this.toggleAppDrawer.bind(this);
    this.onAccordionExpandToggle = this.onAccordionExpandToggle.bind(this);
    this.onNavItemClick = this.onNavItemClick.bind(this);
  }

  public render(): React.ReactNode {
    const { isUserLoggedIn, t } = this.props;

    if (!isUserLoggedIn) {
      return null;
    }

    return (
      <React.Fragment>
        <IconButton
          id="btn-app-drawer"
          aria-haspopup={true}
          aria-controls="app-drawer"
          aria-label={t(this.state.isOpen ? globalI18n.ariaLabelCloseDrawer : globalI18n.ariaLabelOpenDrawer)}
          edge="start"
          color="inherit"
          onClick={this.toggleAppDrawer}
        >
          <MenuIcon />
        </IconButton>

        <Drawer id="app-drawer" open={this.state.isOpen} onClose={this.toggleAppDrawer}>
          <div styleName="style.drawer-title">
            <a href="#close" onClick={this.toggleAppDrawer}>
              <img {...processImgProps({ src: IconClose })} alt={this.props.t(globalI18n.ariaLabelCloseDrawer)} />
            </a>
            <RouterNavLink
              data-action-id={ActionId.Home}
              exact={true}
              to={this.props.homeRoutePath}
              onClick={this.onNavItemClick}
            >
              {this.props.t(globalI18n.logoText)}
            </RouterNavLink>
          </div>
          <div>
            {this.renderUserAccountAccordion()}
            {this.renderMailboxAccordion()}
            {this.renderContactListAccordion()}
            {this.renderInviteGuestAccordion()}
          </div>
        </Drawer>
      </React.Fragment>
    );
  }

  public toggleAppDrawer(event: React.MouseEvent<HTMLElement>): void {
    event.preventDefault();
    event.stopPropagation();

    this.setState(({ isOpen }) => ({ isOpen: !isOpen, expandedPanel: ActionId.Mailbox }));
  }

  public renderUserAccountAccordion(): React.ReactNode {
    const { UserAccountMenu: actionId } = ActionId;

    return (
      <Accordion
        elevation={0}
        square={true}
        expanded={this.state.expandedPanel === actionId}
        onChange={this.onAccordionExpandToggle}
      >
        <AccordionSummary
          data-action-id={actionId}
          id={`header-${actionId}`}
          aria-controls={`content-${actionId}`}
          expandIcon={<ExpandIcon color="inherit" />}
        >
          <React.Fragment>
            <MemberAvatar {...this.props.basicProfile} />
            {Utils.joinPersonName(this.props.basicProfile)}
          </React.Fragment>
        </AccordionSummary>
        <AccordionDetails>
          <UserAccountActionNavList onNavItemClick={this.onNavItemClick} />
        </AccordionDetails>
      </Accordion>
    );
  }

  public renderMailboxAccordion(): React.ReactNode {
    const { clientRights, t } = this.props;
    const { Mailbox: actionId } = ActionId;

    const render = [clientRights.get(ClientRightId.CAN_ACCESS_MAILBOX)].some(Boolean);
    if (!render) {
      return null;
    }

    return (
      <Accordion
        elevation={0}
        square={true}
        expanded={this.state.expandedPanel === actionId}
        onChange={this.onAccordionExpandToggle}
      >
        <AccordionSummary
          data-action-id={actionId}
          id={`header-${actionId}`}
          aria-controls={`content-${actionId}`}
          expandIcon={<ExpandIcon color="inherit" />}
        >
          {t(resolveActionLabel(globalI18n.action[actionId], actionId, ActionContext.AppDrawer))}
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <MessageFolderList
              classes={{
                root: style['nav-folder-list'],
                navItem: style.item,
                navLink: style.link,
                folderName: style['folder-name'],
                folderItemCount: style['folder-item-count']
              }}
              onNavItemClick={this.onNavItemClick}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  public renderContactListAccordion(): React.ReactNode {
    const { clientRights, location, t } = this.props;
    const { ContactList: actionId } = ActionId;

    const actionIdList = [
      clientRights.get(ClientRightId.CAN_ACCESS_GLOBAL_CONTACTS) && ActionId.ContactListGlobalUsers,
      clientRights.get(ClientRightId.CAN_ACCESS_GLOBAL_CONTACTS) && ActionId.ContactListGlobalGroups,
      clientRights.get(ClientRightId.CAN_ACCESS_CLIENT_CONTACTS) && ActionId.ContactListClient,
      clientRights.get(ClientRightId.CAN_ACCESS_CIRCLE_OF_CARE) && ActionId.ContactListGuest
    ].filter(Utils.isString);

    if (actionIdList.length === 0) {
      return null;
    }

    const action = Utils.transform(
      actionIdList,
      (navAction, actionId) => {
        const action = contactListI18n.navAction[actionId];

        const iconPrimary = resolveActionIcon({ icon: action.iconPrimary }, ActionContext.AppDrawer);
        const iconSecondary = resolveActionIcon({ icon: action.iconSecondary }, ActionContext.AppDrawer);
        const pathname = resolveActionPath(action, actionId, ActionContext.AppDrawer);
        const actionIcon = location.pathname === pathname ? iconPrimary : iconSecondary;

        navAction[actionId] = {
          ...Utils.omit(action, ['iconPrimary', 'iconSecondary']),
          exact: true,
          image: { ...actionIcon, src: actionIcon.src[this.props.i18n.language] },
          navItemClassName: style.item,
          navLinkClassName: style.link,
          onClick: this.onNavItemClick
        };
      },
      {} as { [actionId: string]: NavAction }
    );

    return (
      <Accordion
        elevation={0}
        square={true}
        expanded={this.state.expandedPanel === actionId}
        onChange={this.onAccordionExpandToggle}
      >
        <AccordionSummary
          data-action-id={actionId}
          id={`header-${actionId}`}
          aria-controls={`content-${actionId}`}
          expandIcon={<ExpandIcon color="inherit" />}
        >
          {t(resolveActionLabel(globalI18n.action[actionId], actionId, ActionContext.AppDrawer))}
        </AccordionSummary>
        <AccordionDetails>
          <ActionNavList className={style['nav-folder-list']} t={this.props.t} action={action} />
        </AccordionDetails>
      </Accordion>
    );
  }

  public renderInviteGuestAccordion(): React.ReactNode {
    const { InviteGuest: actionId } = ActionId;
    const { to, href, label } = globalI18n.action[actionId] as Action & RouterAction;
    const canInviteMember = this.props.clientRights.get(ClientRightId.CAN_INVITE_MEMBER) as ReadonlyArray<string>;

    if (!canInviteMember.includes(Constants.MedicalInstitute.ROLE_ID_GUEST)) {
      return null;
    }

    const linkProps: RouterNavLinkProps = {
      exact: true,
      className: style['action-link'],
      to: this.props.t(Utils.isString(to) ? to : typeof to === 'function' ? to(actionId) : ''),
      href: Utils.isString(href)
        ? this.props.t(href)
        : typeof href === 'function'
        ? this.props.t(href(actionId))
        : undefined,
      onClick: this.onNavItemClick
    };
    return (
      <RouterNavLink {...linkProps} data-action-id={actionId}>
        {this.props.t(resolveActionLabel({ label }, actionId))}
      </RouterNavLink>
    );
  }

  private onAccordionExpandToggle(event: React.ChangeEvent<any>, expanded: boolean): void {
    this.setState({ expandedPanel: expanded ? (event.currentTarget.getAttribute('data-action-id') as string) : '' });
  }

  private onNavItemClick(event: React.MouseEvent<HTMLElement>): void {
    const actionId = event.currentTarget.getAttribute('data-action-id');

    // sign-out route doesn't actually exist; we catch the click here and
    // cancel the default browser action to handle sign out manually
    if (actionId === ActionId.SignOut) {
      event.preventDefault();
      event.stopPropagation();

      this.resetState(() => this.props.dispatchSignOut());
      return;
    }

    if (
      actionId === ActionId.FAQ ||
      actionId === ActionId.ContactUs ||
      actionId === ActionId.TermsAndConditions ||
      actionId === ActionId.PrivacyPolicy
    ) {
      event.preventDefault();
      event.stopPropagation();

      const { to } = globalI18n.action[actionId] as RouterAction;
      if (Utils.isString(to)) {
        this.props.history.push(to);
      }

      this.resetState();
      return;
    }

    if (actionId === ActionId.InviteGuest) {
      event.preventDefault();
      event.stopPropagation();

      this.resetState(() => this.context.dispatch(Context.openInviteGuestModal()));
      return;
    }

    this.resetState();
  }

  private resetState(callback?: (() => void) | undefined): void {
    this.setState({ isOpen: false, expandedPanel: ActionId.Mailbox }, callback);
  }
}

const ns = [I18N_NS_CONTACT_LIST];
export const AppDrawer = withConnect(withRouter(withTranslation(ns)(AppDrawerComponent)));
AppDrawer.displayName = 'AppDrawer';
