import { CaseReducerAuthSuccess } from '@sigmail/app-state';
import { Constants } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AuthenticationData } from '../../core/authentication-data';

const Logger = getLoggerWithPrefix('Reducer', 'authSuccessReducer:');

export const authSuccessReducer: CaseReducerAuthSuccess = (state, action) => {
  try {
    Logger.info('== BEGIN ==');

    if (!AuthenticationData.isAssignableFrom(action.payload)) {
      Logger.warn('Invalid payload.');
      return state;
    }

    return { ...action.payload, lastAuthErrorCode: Constants.Error.S_OK };
  } finally {
    Logger.info('== END ==');
  }
};
