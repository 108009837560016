import { AlgorithmCode, AppException, Constants, Utils } from '@sigmail/common';
import {
  ApiFormattedSigmailObject,
  ApiFormattedVersionedSigmailObject,
  ISigmailObject,
  IVersionedSigmailObject
} from '@sigmail/objects';
import { SigmailObject } from './sigmail-object';

const PROPS: ReadonlyArray<keyof Omit<IVersionedSigmailObject<any>, keyof ISigmailObject<any>>> = ['version'];

const API_FORMATTED_PROPS: ReadonlyArray<
  keyof Omit<ApiFormattedVersionedSigmailObject, keyof ApiFormattedSigmailObject>
> = ['version'];

/**
 * TODO document
 *
 * @template DV Type of decrypted value
 */
export abstract class VersionedSigmailObject<DV> extends SigmailObject<DV> implements IVersionedSigmailObject<DV> {
  /** Determines if the given value is a valid version. */
  public static isValidVersion(value: any): value is number {
    return Utils.isInteger(value) && value >= 0 && value <= Constants.MAX_VALUE_OBJECT_VERSION;
  }

  /** @override */
  public static isAssignableFrom(obj: any): obj is IVersionedSigmailObject<any> {
    return (
      super.isAssignableFrom(obj) === true &&
      Utils.every(PROPS, Utils.partial(Utils.has, obj)) &&
      this.isValidVersion(obj.version)
    );
  }

  /** @override */
  public static isApiFormatted(obj: any): obj is ApiFormattedVersionedSigmailObject {
    return (
      super.isApiFormatted(obj) === true &&
      Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, obj)) &&
      Utils.isNumber(obj.version)
    );
  }

  public readonly version: number;

  /**
   * Initializes a new instance of the `SigmailObject` class.
   *
   * @param type Type code of this object, or `undefined` to use the default.
   * @param id ID of this object.
   * @param code Code identifying the algorithm used to encrypt the value of
   * this object, or `undefined` to use the default code defined for this type.
   * @param version Version of this object.
   *
   * @throws {AppException} if one or more of the arguments are invalid.
   */
  protected constructor(type: number | undefined, id: number, code: AlgorithmCode | undefined, version: number);

  protected constructor(obj: ApiFormattedVersionedSigmailObject);

  protected constructor(...args: any[]);

  protected constructor(...args: any[]) {
    super(...args);

    const Class = this.constructor as typeof VersionedSigmailObject;
    if (Class === VersionedSigmailObject) throw new TypeError('Initialization error.');

    const version = args.length === 1 ? args[0].version : args[3];
    if (!Class.isValidVersion(version)) throw new AppException(Constants.Error.E_INVALID_OBJECT_VERSION);

    this.version = version;
  }

  /** @override */
  public equals(other: any): other is IVersionedSigmailObject<DV> {
    return super.equals(other) === true && this.version === other.version;
  }

  /** @override */
  public hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.version)) | 0;
    return hashed;
  }

  /** @override */
  public toApiFormatted(): ApiFormattedVersionedSigmailObject {
    const apiFormatted = super.toApiFormatted();
    return { ...apiFormatted, version: this.version };
  }
}
