export const AccountSetupSuccess = 'accountSetupSuccess';
export const AccountSignIn = 'accountSignIn';
export const AppDrawer = 'appDrawer';

export const ContactListClient = 'contactListClient';
export const ContactListGlobalGroups = 'contactListGlobalGroups';
export const ContactListGlobalUsers = 'contactListGlobalUsers';
export const ContactListGuest = 'contactListGuest';

export const DialogChangePassword = 'dialogChangePassword';
export const DialogConfirmDiscardDraft = 'dialogConfirmDiscardDraft';
export const DialogConfirmOperation = 'dialogConfirmOperation';
export const DialogConfirmRecallMessage = 'dialogConfirmRecallMessage';
export const DialogConfirmSendToOscar = 'dialogConfirmSendToOscar';
export const DialogDisableMfa = 'dialogDisableMfa';
export const DialogEditMessageSignature = 'dialogEditMessageSignature';
export const DialogEditProfile = 'dialogEditProfile';
export const DialogEnableMfa = 'dialogEnableMfa';
export const DialogOscarOAuthParamsMissing = 'dialogOscarOAuthParamsMissing';
export const DialogOscarSelectPatientRecord = 'dialogOscarSelectPatientRecord';
export const DialogOscarUploadProgress = 'dialogOscarUploadProgress';
export const DialogPatientRecordNotFound = 'dialogPatientRecordNotFound';

export const FormAuthCredentialsSetup = 'formAuthCredentialsSetup';
export const FormComposeMessage = 'formComposeMessage';
export const FormComposeReferral = 'formComposeReferral';
export const FormConfirmSecuritySetup = 'formConfirmSecuritySetup';
export const FormContactUs = 'formContactUs';
export const FormInviteGuest = 'formInviteGuest';
export const FormInviteInstitution = 'formInviteInstitution';
export const FormInviteStaff = 'formInviteStaff';
export const FormPasswordResetSetup = 'formPasswordResetSetup';
export const FormSelectMfaMethod = 'formSelectMfaMethod';
export const FormSendPasswordResetLink = 'formSendPasswordResetLink';
export const FormSubmitDocuments = 'formSubmitDocuments';
export const FormVerifyMfaCode = 'formVerifyMfaCode';

export const ManageMembers = 'manageMembers';
export const PasswordResetSuccess = 'passwordResetSuccess';
export const SendPasswordResetLinkSuccess = 'sendPasswordResetLinkSuccess';

export const TabPanelProfile = 'tabPanelProfile';
export const TabPanelPreferences = 'tabPanelPreferences';
export const TabPanelIntegrations = 'tabPanelIntegrations';

export const WizardForgotPassword = 'wizardForgotPassword';
