import { AlgorithmCode, AppException, Constants, EncapsulatedKey, Utils, ValueObject } from '@sigmail/common';
import { Algorithm, Constants as CryptoConstants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import { ApiFormattedSigmailObject, EncryptObjectValueParams, ISigmailObject } from '@sigmail/objects';

const ErrorConstants = { ...Constants.Error, ...CryptoConstants.Error };

interface EncryptObjectValueAlgorithm {
  encrypt(data: any): Promise<string>;
  decrypt(data: string): Promise<any>;
}

const PROPS: ReadonlyArray<keyof Omit<ISigmailObject<any>, keyof ValueObject | 'toApiFormatted'>> = [
  'type',
  'id',
  'code'
];

const API_FORMATTED_PROPS: ReadonlyArray<keyof ApiFormattedSigmailObject> = ['type', 'id', 'code'];

const DEFAULT_ENCRYPT_DECRYPT_OBJECT_CODE = process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT;

async function getEncryptObjectValueAlgorithm(params: EncryptObjectValueParams): Promise<EncryptObjectValueAlgorithm> {
  let encapsulatedKey: EncapsulatedKey;

  const objectCode = Utils.isNil(params.objectCode) ? DEFAULT_ENCRYPT_DECRYPT_OBJECT_CODE : params.objectCode;
  if (Algorithm.isValidEncryptObjectCode(objectCode)) {
    if (params.key?.type !== process.env.CRYPTOGRAPHIC_KEY_TYPE_ENCAPSULATED) {
      throw new SigmailCryptoException(ErrorConstants.E_INVALID_KEY);
    }
    encapsulatedKey = await params.key!.decryptedValue();
  } else if (!Algorithm.isValidNonEncryptedObjectCode(objectCode)) {
    throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
  } else {
    encapsulatedKey = new Uint8Array();
  }

  const objectVersion = Utils.isNil(params.objectVersion) ? 0 : params.objectVersion;
  const algorithm = getAlgorithm(objectCode);
  return {
    encrypt: (data: any) => algorithm.encrypt(encapsulatedKey, data, objectVersion),
    decrypt: (data: string) => algorithm.decrypt(encapsulatedKey, data, objectVersion)
  };
}

/**
 * TODO document
 *
 * @template DV Type of decrypted value
 */
export abstract class SigmailObject<DV> implements ISigmailObject<DV> {
  /** Default algorithm code to use if one is not explicitly provided. */
  protected static get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT;
  }

  /**
   * A numeric code identifying the type of this object.
   *
   * Default implementation throws an error. Derived classes must override this
   * getter to return a valid type code.
   */
  public static get TYPE(): number {
    throw new Error('Not implemented.');
  }

  /** Determines if the given value is a valid type code for this object. */
  public static isValidType(value: any): value is number {
    return Utils.isInteger(value) && value > 0 && value === this.TYPE;
  }

  /** Determines if the given value is a valid ID. */
  public static isValidId(value: any): value is number {
    return Utils.isInteger(value) && value > 0 && value <= Constants.MAX_VALUE_OBJECT_ID;
  }

  /** Determines if the given value is a valid algorithm code. */
  public static isValidAlgorithmCode(value: any): value is AlgorithmCode {
    return Algorithm.isValidCode(value);
  }

  /**
   * Determines if the given object can be used to instantiate a new instance
   * of this type.
   */
  public static isAssignableFrom(obj: any): obj is ISigmailObject<any> {
    if (obj instanceof SigmailObject && this === obj.constructor) return true;

    return (
      Utils.isNonArrayObjectLike(obj) &&
      Utils.every(PROPS, Utils.partial(Utils.has, obj)) &&
      this.isValidType(obj.type) &&
      this.isValidId(obj.id) &&
      this.isValidAlgorithmCode(obj.code) &&
      typeof obj.equals === 'function' &&
      typeof obj.hashCode === 'function' &&
      typeof obj.toApiFormatted === 'function'
    );
  }

  /**
   * Determines if the given object looks like the API-formatted equivalent of
   * this type.
   */
  public static isApiFormatted(obj: any): obj is ApiFormattedSigmailObject {
    return (
      Utils.isNonArrayObjectLike(obj) &&
      Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, obj)) &&
      API_FORMATTED_PROPS.every((prop) => Utils.isNumber(obj[prop]))
    );
  }

  protected static encryptObjectValue<DV>(value: DV, params: EncryptObjectValueParams) {
    return getEncryptObjectValueAlgorithm(params).then((algorithm) => algorithm.encrypt(value));
  }

  protected static decryptObjectValue<DV>(value: string, params: EncryptObjectValueParams) {
    return getEncryptObjectValueAlgorithm(params).then((algorithm) => algorithm.decrypt(value) as Promise<DV>);
  }

  public readonly type: number;
  public readonly id: number;
  public readonly code: AlgorithmCode;

  /**
   * Initializes a new instance of the `SigmailObject` class.
   *
   * @param type Type code of this object, or `undefined` to use the default.
   * @param id ID of this object.
   * @param code Code identifying the algorithm used to encrypt the value of
   * this object, or `undefined` to use the default code defined for this type.
   *
   * @throws {AppException} if one or more of the arguments are invalid.
   */
  protected constructor(type: number | undefined, id: number, code?: AlgorithmCode);

  protected constructor(obj: ApiFormattedSigmailObject);

  protected constructor(...args: any[]);

  protected constructor(...args: any[]) {
    const Class = this.constructor as typeof SigmailObject;
    if (Class === SigmailObject) throw new TypeError('Initialization error.');

    let type: number | undefined;
    let id: number;
    let code: AlgorithmCode | undefined;

    if (args.length === 1) {
      const obj = args[0];
      if (!Class.isApiFormatted(obj)) {
        throw new AppException(ErrorConstants.S_ERROR, 'Invalid argument value.');
      }

      type = obj.type;
      id = obj.id;
      code = obj.code;
    } else if (args.length >= 3) {
      type = args[0];
      id = args[1];
      code = args[2];
    } else {
      throw new AppException(ErrorConstants.S_ERROR, 'Invalid argument value.');
    }

    const typeCode = Utils.isUndefined(type) ? Class.TYPE : type;
    const algorithmCode = Utils.isUndefined(code) ? Class.DEFAULT_CODE : code;

    if (!Class.isValidType(typeCode)) throw new AppException(ErrorConstants.E_UNKNOWN_OBJECT_TYPE);
    if (!Class.isValidId(id)) throw new AppException(ErrorConstants.E_INVALID_OBJECT_ID);
    if (!Class.isValidAlgorithmCode(algorithmCode)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }

    this.type = typeCode;
    this.id = id;
    this.code = algorithmCode;
  }

  public equals(other: any): other is ISigmailObject<DV> {
    const Class = this.constructor as typeof SigmailObject;
    if (!Class.isAssignableFrom(other)) return false;

    return this.type === other.type && this.id === other.id && this.code === other.code;
  }

  public hashCode(): number {
    let hashed = 0;
    hashed = (31 * hashed + Utils.hashNumber(this.type)) | 0;
    hashed = (31 * hashed + Utils.hashNumber(this.id)) | 0;
    hashed = (31 * hashed + Utils.hashNumber(this.code)) | 0;
    return hashed;
  }

  /** Returns a new API-formatted equivalent instance of this type. */
  public toApiFormatted(): ApiFormattedSigmailObject {
    return { type: this.type, id: this.id, code: this.code };
  }
}
