import { ActionPayloadBatchQueryData, ActionPayloadBatchQueryDataSuccess, ActionPayloadBatchUpdateData } from '@sigmail/app-state';
import { AppException, Constants, IAppUser, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedCryptographicKey,
  ApiFormattedDataObject,
  ApiFormattedNotificationObject,
  ApiFormattedUserCredentials,
  ApiFormattedUserObject
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppDispatch, getInitialRootState } from '..';
import { RootState } from '../root-reducer';
import * as AuthSelectors from '../selectors/auth';
import { batchQueryDataAction } from './batch-query-data-action';
import { batchQuerySuccessAction } from './batch-query-success-action';
import { batchUpdateDataAction } from './batch-update-data-action';
import { getIdGeneratorAction } from './get-id-generator-action';

export interface FetchObjectsRequestData extends Api.BatchQueryRequestData {
  expectedCount?: {
    claims?: number | null;
    dataObjects?: number | null;
    userObjects?: number | null;
    userObjectsByType?: number | null;
    userCredentialsObjects?: number | null;
    userCredentialsByType?: number | null;
    notificationObjects?: number | null;
    notificationObjectsByType?: number | null;
    keysByType?: number | null;
  };
}

export interface FetchObjectsResponseData extends Pick<Required<Api.BatchQueryResponseData>, 'claims' | 'serverDateTime'> {
  credentialList: Array<ApiFormattedUserCredentials>;
  dataObjectList: Array<ApiFormattedDataObject>;
  keyList: Array<ApiFormattedCryptographicKey>;
  notificationObjectList: Array<ApiFormattedNotificationObject>;
  userObjectList: Array<ApiFormattedUserObject>;
}

export interface ActionInitParams<P> {
  payload: P;
  dispatch?: AppDispatch;
  getState?: () => RootState;
  apiService?: Api.Service;
  logger: ReturnType<typeof getLoggerWithPrefix>;
}

export abstract class BaseAction<P, S extends object = {}, R = void> {
  protected readonly state = {} as S;

  protected readonly payload: P;
  protected readonly dispatch: AppDispatch;
  protected readonly getRootState: () => RootState;
  protected readonly apiService: Api.Service;
  protected readonly logger: ReturnType<typeof getLoggerWithPrefix>;

  public constructor({ payload, dispatch, getState, apiService, logger }: ActionInitParams<P>) {
    this.payload = payload;
    this.dispatch = dispatch!;
    this.getRootState = typeof getState === 'function' ? getState : getInitialRootState;
    this.apiService = apiService!;
    this.logger = logger;

    this.onExecute = this.onExecute.bind(this);
    this.postExecute = this.postExecute.bind(this);
  }

  protected preExecute(): Promise<any> {
    this.logger.info('== BEGIN ==');
    return Promise.resolve();
  }

  protected abstract onExecute(...args: any[]): Promise<R>;

  protected postExecute(): Promise<void> {
    this.logger.info('== END ==');
    return Promise.resolve();
  }

  public execute(): Promise<R> {
    return this.preExecute()
      .then(this.onExecute)
      .catch((error) => {
        this.logger.warn('Error while executing action:', error);
        throw error;
      })
      .finally(this.postExecute);
  }

  protected get isUserLoggedIn(): boolean {
    return AuthSelectors.isUserLoggedInSelector(this.getRootState());
  }

  protected get currentUser(): IAppUser {
    const currentUser = AuthSelectors.currentUserSelector(this.getRootState());
    if (Utils.isNil(currentUser)) {
      throw new AppException(Constants.Error.E_AUTH_FAIL, 'No user is currently logged-in; or user data is either missing or invalid.');
    }
    return currentUser;
  }

  protected enterState(query: Api.EnterStateRequestData): Promise<Api.EnterStateResponseData>;
  protected enterState(apiAccessToken: string, query: Api.EnterStateRequestData): Promise<Api.EnterStateResponseData>;
  protected enterState(...args: any[]): Promise<Api.EnterStateResponseData> {
    const [apiAccessToken, requestBody] =
      args.length === 1 ? [AuthSelectors.accessTokenSelector(this.getRootState()), args[0]] : args.length >= 2 ? args : [];
    return this.apiService.enterState(apiAccessToken, requestBody);
  }

  protected batchQueryData(apiAccessToken: string, query: Api.BatchQueryRequestData): Promise<Api.BatchQueryResponseData> {
    return this.apiService.batchQueryData(apiAccessToken, query);
  }

  // protected batchQueryData(query: ActionPayloadBatchQueryData['query']): Promise<Api.BatchQueryResponseData>;

  // protected batchQueryData(
  //   query: ActionPayloadBatchQueryData['query'],
  //   cache: ActionPayloadBatchQueryData['cache']
  // ): Promise<Api.BatchQueryResponseData>;

  // protected batchQueryData(
  //   apiAccessToken: string,
  //   query: ActionPayloadBatchQueryData['query']
  // ): Promise<Api.BatchQueryResponseData>;

  // protected batchQueryData(
  //   apiAccessToken: string,
  //   query: ActionPayloadBatchQueryData['query'],
  //   cache: ActionPayloadBatchQueryData['cache']
  // ): Promise<Api.BatchQueryResponseData>;

  // protected batchQueryData(...args: any[]): Promise<Api.BatchQueryResponseData> {
  //   let accessToken: ActionPayloadBatchQueryData['accessToken'] = undefined;
  //   let query = {} as ActionPayloadBatchQueryData['query'];
  //   let cache: ActionPayloadBatchQueryData['cache'] = undefined;

  //   if (args.length === 1) {
  //     query = args[0];
  //   } else if (args.length >= 2) {
  //     if (Utils.isString(args[0])) {
  //       [accessToken, query, cache] = args;
  //     } else {
  //       [query, cache] = args;
  //     }
  //   }

  //   return this.dispatch(batchQueryDataAction({ accessToken, query, cache }));
  // }

  protected dispatchBatchQueryData(query: ActionPayloadBatchQueryData['query']): Promise<Api.BatchQueryResponseData>;

  protected dispatchBatchQueryData(
    query: ActionPayloadBatchQueryData['query'],
    cache: ActionPayloadBatchQueryData['cache']
  ): Promise<Api.BatchQueryResponseData>;

  protected dispatchBatchQueryData(
    apiAccessToken: string,
    query: ActionPayloadBatchQueryData['query']
  ): Promise<Api.BatchQueryResponseData>;

  protected dispatchBatchQueryData(
    apiAccessToken: string,
    query: ActionPayloadBatchQueryData['query'],
    cache: ActionPayloadBatchQueryData['cache']
  ): Promise<Api.BatchQueryResponseData>;

  protected dispatchBatchQueryData(...args: any[]): Promise<Api.BatchQueryResponseData> {
    let accessToken: ActionPayloadBatchQueryData['accessToken'] = undefined;
    let query = {} as ActionPayloadBatchQueryData['query'];
    let cache: ActionPayloadBatchQueryData['cache'] = undefined;

    if (args.length === 1) {
      query = args[0];
    } else if (args.length >= 2) {
      if (Utils.isString(args[0])) {
        [accessToken, query, cache] = args;
      } else {
        [query, cache] = args;
      }
    }

    return this.dispatch(batchQueryDataAction({ accessToken, query, cache }));
  }

  protected fetchObjects(apiAccessToken: string, request: FetchObjectsRequestData): Promise<FetchObjectsResponseData> {
    return this.dispatchFetchObjects(apiAccessToken, request, /* cache := */ null);
  }

  protected dispatchFetchObjects(request: FetchObjectsRequestData): Promise<FetchObjectsResponseData>;

  protected dispatchFetchObjects(
    request: FetchObjectsRequestData,
    cache: ActionPayloadBatchQueryData['cache']
  ): Promise<FetchObjectsResponseData>;

  protected dispatchFetchObjects(apiAccessToken: string, request: FetchObjectsRequestData): Promise<FetchObjectsResponseData>;

  protected dispatchFetchObjects(
    apiAccessToken: string,
    request: FetchObjectsRequestData,
    cache: ActionPayloadBatchQueryData['cache']
  ): Promise<FetchObjectsResponseData>;

  protected async dispatchFetchObjects(...args: any[]): Promise<FetchObjectsResponseData> {
    let apiAccessToken: ActionPayloadBatchQueryData['accessToken'] = undefined;
    let request = {} as FetchObjectsRequestData;
    let cache: ActionPayloadBatchQueryData['cache'] = undefined;

    if (args.length === 1) {
      request = args[0];
    } else if (args.length >= 2) {
      if (Utils.isString(args[0])) {
        [apiAccessToken, request, cache] = args;
      } else {
        [request, cache] = args;
      }
    }

    const response: FetchObjectsResponseData = {
      claims: [],
      serverDateTime: '',
      credentialList: [],
      dataObjectList: [],
      keyList: [],
      notificationObjectList: [],
      userObjectList: []
    };

    let { expectedCount = {}, ...query } = request;

    let responseJson: Api.BatchQueryResponseData;
    if (Utils.isString(apiAccessToken)) {
      responseJson = await this.dispatchBatchQueryData(apiAccessToken, query, cache);
    } else {
      responseJson = await this.dispatchBatchQueryData(query, cache);
    }

    if (this.logger.getLevel() <= 3 /* log.levels.WARN */) {
      const arrayLength = (array?: any): number => (Utils.isNonEmptyArray(array) ? array.length : 0);

      expectedCount = {
        claims: Utils.isUndefined(expectedCount.claims) ? 0 : expectedCount.claims,
        dataObjects: Utils.isUndefined(expectedCount.dataObjects) ? arrayLength(query.dataObjects?.ids) : expectedCount.dataObjects,
        userObjects: Utils.isUndefined(expectedCount.userObjects) ? arrayLength(query.userObjects?.ids) : expectedCount.userObjects,
        userObjectsByType: Utils.isUndefined(expectedCount.userObjectsByType)
          ? arrayLength(query.userObjectsByType)
          : expectedCount.userObjectsByType,
        userCredentialsObjects: Utils.isUndefined(expectedCount.userCredentialsObjects)
          ? arrayLength(query.userCredentialsObjects?.ids)
          : expectedCount.userCredentialsObjects,
        userCredentialsByType: Utils.isUndefined(expectedCount.userCredentialsByType)
          ? arrayLength(query.userCredentialsByType)
          : expectedCount.userCredentialsByType,
        notificationObjects: Utils.isUndefined(expectedCount.notificationObjects)
          ? arrayLength(query.notificationObjects?.ids)
          : expectedCount.notificationObjects,
        notificationObjectsByType: Utils.isUndefined(expectedCount.notificationObjectsByType)
          ? arrayLength(query.notificationObjectsByType)
          : expectedCount.notificationObjectsByType,
        keysByType: Utils.isUndefined(expectedCount.keysByType) ? arrayLength(query.keysByType) : expectedCount.keysByType
      };

      for (const key of Object.keys(expectedCount) as Array<keyof typeof expectedCount>) {
        const expected = expectedCount[key];
        if (expected !== null) {
          const actual = arrayLength(responseJson[key]);
          if (actual !== expected) {
            // throw new Api.MalformedResponseException(`Expected <${key}> to be of size ${expected}, was ${actual}`);
            this.logger.warn(`Expected <${key}> to be of size ${expected}, was ${actual}`);
          }
        }
      }
    }

    response.serverDateTime = this.deserializeServerDateTime(responseJson.serverDateTime).toISOString();
    if (Utils.isNonEmptyArray(responseJson.claims)) response.claims.push(...responseJson.claims);
    if (Utils.isNonEmptyArray(responseJson.dataObjects)) response.dataObjectList.push(...responseJson.dataObjects);
    if (Utils.isNonEmptyArray(responseJson.keysByType)) response.keyList.push(...responseJson.keysByType);
    if (Utils.isNonEmptyArray(responseJson.notificationObjects)) response.notificationObjectList.push(...responseJson.notificationObjects);
    if (Utils.isNonEmptyArray(responseJson.notificationObjectsByType))
      response.notificationObjectList.push(...responseJson.notificationObjectsByType);
    if (Utils.isNonEmptyArray(responseJson.userCredentialsObjects)) response.credentialList.push(...responseJson.userCredentialsObjects);
    if (Utils.isNonEmptyArray(responseJson.userCredentialsByType)) response.credentialList.push(...responseJson.userCredentialsByType);
    if (Utils.isNonEmptyArray(responseJson.userObjects)) response.userObjectList.push(...responseJson.userObjects);
    if (Utils.isNonEmptyArray(responseJson.userObjectsByType)) response.userObjectList.push(...responseJson.userObjectsByType);

    return response;
  }

  protected findDataObjectIndex(
    list: ReadonlyArray<ApiFormattedDataObject> | undefined,
    criteria: Partial<Pick<ApiFormattedDataObject, 'type' | 'id' | 'ownerId'>>
  ): number {
    if (!Utils.isNonEmptyArray(list)) return -1;
    return list.findIndex(
      ({ type, id, ownerId }) =>
        (Utils.isUndefined(criteria.type) || type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || id === criteria.id) &&
        (Utils.isUndefined(criteria.ownerId) || ownerId === criteria.ownerId)
    );
  }

  protected findDataObject(
    list: ReadonlyArray<ApiFormattedDataObject> | undefined,
    criteria: Partial<Pick<ApiFormattedDataObject, 'type' | 'id' | 'ownerId'>>
  ): ApiFormattedDataObject | undefined {
    const index = this.findDataObjectIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findKeyIndex(
    list: ReadonlyArray<ApiFormattedCryptographicKey> | undefined,
    criteria: Partial<Pick<ApiFormattedCryptographicKey, 'type' | 'id'>>
  ): number {
    if (!Utils.isNonEmptyArray(list)) return -1;
    return list.findIndex(
      ({ type, id }) =>
        (Utils.isUndefined(criteria.type) || type === criteria.type) && (Utils.isUndefined(criteria.id) || id === criteria.id)
    );
  }

  protected findKey(
    list: ReadonlyArray<ApiFormattedCryptographicKey> | undefined,
    criteria: Partial<Pick<ApiFormattedCryptographicKey, 'type' | 'id'>>
  ): ApiFormattedCryptographicKey | undefined {
    const index = this.findKeyIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findNotificationObjectIndex(
    list: ReadonlyArray<ApiFormattedNotificationObject> | undefined,
    criteria: Partial<Pick<ApiFormattedNotificationObject, 'type' | 'id' | 'userId'>>
  ): number {
    if (!Utils.isNonEmptyArray(list)) return -1;
    return list.findIndex(
      ({ type, id, userId }) =>
        (Utils.isUndefined(criteria.type) || type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || id === criteria.id) &&
        (Utils.isUndefined(criteria.userId) || userId === criteria.userId)
    );
  }

  protected findNotificationObject(
    list: ReadonlyArray<ApiFormattedNotificationObject> | undefined,
    criteria: Partial<Pick<ApiFormattedNotificationObject, 'type' | 'id' | 'userId'>>
  ): ApiFormattedNotificationObject | undefined {
    const index = this.findNotificationObjectIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findCredentialsIndex(
    list: ReadonlyArray<ApiFormattedUserCredentials> | undefined,
    criteria: Partial<Pick<ApiFormattedUserCredentials, 'type' | 'id' | 'userId'>>
  ): number {
    if (!Utils.isNonEmptyArray(list)) return -1;
    return list.findIndex(
      ({ type, id, userId }) =>
        (Utils.isUndefined(criteria.type) || type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || id === criteria.id) &&
        (Utils.isUndefined(criteria.userId) || userId === criteria.userId)
    );
  }

  protected findCredentials(
    list: ReadonlyArray<ApiFormattedUserCredentials> | undefined,
    criteria: Partial<Pick<ApiFormattedUserCredentials, 'type' | 'id' | 'userId'>>
  ): ApiFormattedUserCredentials | undefined {
    const index = this.findCredentialsIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected findUserObjectIndex(
    list: ReadonlyArray<ApiFormattedUserObject> | undefined,
    criteria: Partial<Pick<ApiFormattedUserObject, 'type' | 'id' | 'userId'>>
  ): number {
    if (!Utils.isNonEmptyArray(list)) return -1;
    return list.findIndex(
      ({ type, id, userId }) =>
        (Utils.isUndefined(criteria.type) || type === criteria.type) &&
        (Utils.isUndefined(criteria.id) || id === criteria.id) &&
        (Utils.isUndefined(criteria.userId) || userId === criteria.userId)
    );
  }

  protected findUserObject(
    list: ReadonlyArray<ApiFormattedUserObject> | undefined,
    criteria: Partial<Pick<ApiFormattedUserObject, 'type' | 'id' | 'userId'>>
  ): ApiFormattedUserObject | undefined {
    const index = this.findUserObjectIndex(list, criteria);
    return index !== -1 ? list![index] : undefined;
  }

  protected dispatchBatchQueryDataSuccess(payload: ActionPayloadBatchQueryDataSuccess): Promise<void> {
    return this.dispatch(batchQuerySuccessAction(payload));
  }

  protected batchUpdateData(
    apiAccessToken: Required<ActionPayloadBatchUpdateData>['accessToken'],
    mutations: ActionPayloadBatchUpdateData['mutations']
  ): Promise<Api.BatchUpdateResponseData> {
    return this.dispatchBatchUpdateData(apiAccessToken, mutations);
  }

  protected dispatchBatchUpdateData(mutations: ActionPayloadBatchUpdateData['mutations']): Promise<Api.BatchUpdateResponseData>;

  protected dispatchBatchUpdateData(
    apiAccessToken: Required<ActionPayloadBatchUpdateData>['accessToken'],
    mutations: ActionPayloadBatchUpdateData['mutations']
  ): Promise<Api.BatchUpdateResponseData>;

  protected dispatchBatchUpdateData(...args: any[]): Promise<Api.BatchUpdateResponseData> {
    const [apiAccessToken, mutations] = args.length === 1 ? [undefined, args[0]] : args.length >= 2 ? args : [];
    return this.dispatch(batchUpdateDataAction({ accessToken: apiAccessToken, mutations }));
  }

  protected deserializeServerDateTime(serverDateTime: any): Date {
    if (Utils.isString(serverDateTime)) {
      const dtServer = new Date(serverDateTime);
      if (Utils.isValidDate(dtServer)) {
        return dtServer;
      }
    }
    this.logger.warn('Server date-time was either missing or invalid.');
    return new Date();
  }

  protected async fetchServerDateAndTime(apiAccessToken: string): Promise<Date> {
    return this.dispatchFetchServerDateAndTime(apiAccessToken);
  }

  protected async dispatchFetchServerDateAndTime(apiAccessToken?: string): Promise<Date> {
    this.logger.info("Fetching API server's current date and time.");

    const authState = AuthSelectors.authClaimSelector(this.getRootState());
    const query: Api.BatchQueryRequestData = { authState };

    let responseJson: Api.BatchQueryResponseData;
    if (Utils.isString(apiAccessToken)) {
      responseJson = await this.dispatchBatchQueryData(apiAccessToken, query, /* cache := */ null);
    } else {
      responseJson = await this.dispatchBatchQueryData(query, /* cache := */ null);
    }

    return this.deserializeServerDateTime(responseJson.serverDateTime);
  }

  protected fetchIdsByUsage(apiAccessToken: string, requestBody: Api.GetIdsRequestData): Promise<Api.GetIdsResponseData> {
    const count =
      Utils.isNonArrayObjectLike(requestBody) &&
      Utils.isNonArrayObjectLike(requestBody.ids) &&
      (Utils.isArray(requestBody.ids.ids) ? requestBody.ids.ids : []).reduce(
        (count, id) => count + (Utils.isNonArrayObjectLike(id) && Utils.isInteger(id.count) && id.count > 0 ? id.count : 1),
        Utils.isArray(requestBody.ids.usages) ? requestBody.ids.usages.length : 0
      );

    this.logger.info(`Fetching a list of IDs. (Count = ${count})`);
    return this.apiService.getIdsByUsage(apiAccessToken, requestBody);
  }

  protected dispatchFetchIdsByUsage(requestBody: Api.GetIdsRequestData): Promise<Api.GetIdsResponseData>;
  protected dispatchFetchIdsByUsage(apiAccessToken: string, requestBody: Api.GetIdsRequestData): Promise<Api.GetIdsResponseData>;
  protected dispatchFetchIdsByUsage(...args: any[]): Promise<Api.GetIdsResponseData> {
    const [apiAccessToken, requestBody] =
      args.length === 1 ? [AuthSelectors.accessTokenSelector(this.getRootState()), args[0]] : args.length >= 2 ? args : [];

    return this.fetchIdsByUsage(apiAccessToken, requestBody);
  }

  protected fetchIds(apiAccessToken: string, count: number): Promise<number[]> {
    return this.dispatchFetchIds(apiAccessToken, count).then((generator) => Array.from(generator));
  }

  protected dispatchFetchIds(count: number): Promise<Generator<number, number>>;
  protected dispatchFetchIds(apiAccessToken: string, count: number): Promise<Generator<number, number>>;
  protected dispatchFetchIds(...args: any[]): Promise<Generator<number, number>> {
    const [apiAccessToken, count] = args.length === 1 ? [undefined, args[0]] : args.length >= 2 ? args : [];

    this.logger.info(`Fetching a list of IDs. (count = ${count})`);
    return this.dispatch(getIdGeneratorAction({ count, accessToken: apiAccessToken }));
  }
}
