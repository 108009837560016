import { AppException, AppUser, Constants, IAppUser, JsonObject, MfaMethod, Utils } from '@sigmail/common';
import {
  IUserObject,
  UserCredentialsMfaLogin,
  UserObjectAccessRights,
  UserObjectAccessRightsValue
} from '@sigmail/objects';
import { IAuthenticationData } from 'sigmail';

// IMPORTANT sequence must match the argument sequence in AuthenticationData constructor
const PROPS: ReadonlyArray<keyof IAuthenticationData> = [
  'scope',
  'tokenType',
  'accessToken',
  'expiresIn',
  'refreshToken',
  'idToken',
  'salt',
  'user',
  'accessRights',
  'mfaAccountId',
  'mfaMethod',
  'mfaContact',
  'authClaim'
];

const REGEX_VALID_SALT = /^[A-Fa-f0-9]+$/;

export class AuthenticationData implements IAuthenticationData {
  public static isValidScope(value: any): value is string {
    return Utils.isString(value);
  }

  public static isValidTokenType(value: any): value is string {
    return Utils.isString(value) && value.toLowerCase() === 'bearer';
  }

  public static isValidAccessToken(value: any): value is string {
    return Utils.isValidJwtToken(value, 'bearer');
  }

  public static isValidExpiry(value: any): value is number {
    return Utils.isInteger(value) && value > 0;
  }

  public static isValidRefreshToken(value: any): value is string {
    return Utils.isValidJwtToken(value, 'bearer');
  }

  public static isValidIdToken(value: any): value is string {
    return Utils.isValidJwtToken(value, 'id');
  }

  public static isValidSalt(value: any): value is string {
    return REGEX_VALID_SALT.test(value);
  }

  public static isValidAuthClaim(value: any): value is string {
    return Utils.isValidJwtToken(value, 'id');
  }

  public static isAssignableFrom(obj: any): obj is IAuthenticationData {
    return (
      Utils.isNonArrayObjectLike(obj) &&
      Utils.every(PROPS, Utils.partial(Utils.has, obj)) &&
      this.isValidScope(obj.scope) &&
      this.isValidTokenType(obj.tokenType) &&
      this.isValidAccessToken(obj.accessToken) &&
      this.isValidExpiry(obj.expiresIn) &&
      this.isValidRefreshToken(obj.refreshToken) &&
      this.isValidIdToken(obj.idToken) &&
      this.isValidSalt(obj.salt) &&
      AppUser.isAssignableFrom(obj.user) &&
      UserObjectAccessRights.isAssignableFrom(obj.accessRights) &&
      (obj.mfaAccountId === null || UserCredentialsMfaLogin.isValidMfaAccountId(obj.mfaAccountId)) &&
      (obj.mfaMethod === null || UserCredentialsMfaLogin.isValidMfaMethod(obj.mfaMethod)) &&
      (obj.mfaContact === null || UserCredentialsMfaLogin.isValidMfaContact(obj.mfaContact)) &&
      this.isValidAuthClaim(obj.authClaim)
    );
  }

  public readonly scope: string;
  public readonly tokenType: string;
  public readonly accessToken: string;
  public readonly expiresIn: number;
  public readonly refreshToken: string;
  public readonly idToken: string;
  public readonly salt: string;
  public readonly user: IAppUser;
  public readonly accessRights: IUserObject<UserObjectAccessRightsValue>;
  public readonly mfaAccountId: number | null;
  public readonly mfaMethod: MfaMethod | null;
  public readonly mfaContact: string | null;
  public readonly authClaim: string;

  public constructor(
    scope: string,
    tokenType: string,
    accessToken: string,
    expiresIn: number,
    refreshToken: string,
    idToken: string,
    salt: string,
    user: IAppUser,
    accessRights: IUserObject<UserObjectAccessRightsValue>,
    mfaAccountId: number | null,
    mfaMethod: MfaMethod | null,
    mfaContact: string | null,
    authClaim: string
  );

  public constructor(other: JsonObject);
  public constructor(...args: any[]);
  public constructor(...args: any[]) {
    const Class = this.constructor as typeof AuthenticationData;

    let other: JsonObject = {};
    if (args.length === 1) {
      other = args[0];
    } else if (args.length === PROPS.length) {
      PROPS.reduce((authData, prop, index) => {
        authData[prop] = args[index];
        return authData;
      }, other);
    }

    if (!Class.isAssignableFrom(other)) {
      throw new AppException(Constants.Error.S_ERROR, 'Invalid argument value.');
    }

    this.scope = other.scope;
    this.tokenType = other.tokenType;
    this.accessToken = other.accessToken;
    this.expiresIn = other.expiresIn;
    this.refreshToken = other.refreshToken;
    this.idToken = other.idToken;
    this.salt = other.salt;
    this.user = new AppUser(other.user.id);
    this.accessRights = other.accessRights;
    this.mfaAccountId = other.mfaAccountId;
    this.mfaMethod = other.mfaMethod;
    this.mfaContact = other.mfaContact;
    this.authClaim = other.authClaim;
  }
}
