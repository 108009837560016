import { NotificationObjectClientRegistrationValue as DV } from '@sigmail/objects';
import { NotificationObject } from '.';
import { ClientObject } from '../client-object';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_CLIENT_REGISTRATION;

export class NotificationObjectClientRegistration extends NotificationObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && ClientObject.isValidId(value.clientId);
  }
}
