import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import { URL_OSCAR_SEARCH_PATIENT } from '../constants';
import { MalformedResponseException } from '../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../service-exception';

function isValidOscarPatientRecord(record?: any): record is Api.OscarPatientRecord {
  return (
    Utils.isNonArrayObjectLike<Api.OscarPatientRecord>(record) &&
    Utils.isInteger(record.demographicNo) &&
    Utils.isString(record.firstName) &&
    Utils.isString(record.lastName) &&
    Utils.isString(record.dobYear) &&
    Utils.isString(record.dobMonth) &&
    Utils.isString(record.dobDay)
  );
}

function isValidResponseData(responseJson?: any): responseJson is Api.OscarSearchPatientResponseData {
  return Utils.isArray(responseJson) && responseJson.every(isValidOscarPatientRecord);
}

export async function apiOscarSearchPatient(
  this: Api.Service,
  accessToken: string,
  query: Api.OscarSearchPatientRequestData
) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiOscarSearchPatient:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(URL_OSCAR_SEARCH_PATIENT);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.OscarSearchPatientResponseData>(requestUrl, JSON.stringify(query), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<Api.OscarSearchPatientResponseData>(response);
          if (isValidResponseData(responseJson)) return responseJson;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        if (response.status === 400) {
          throw new ApiServiceException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
