import { AppUser } from '@sigmail/common';
import { UserObjectProfilePrivateValue as DV } from '@sigmail/objects';
import { UserObject } from '.';
import { DataObject } from '../data-object';

const TYPE = process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE;

export class UserObjectProfilePrivate extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      AppUser.isValidId(value.adminId) &&
      this.isValidId(value.clientId) &&
      DataObject.isValidId(value.ownerId) &&
      DataObject.isValidId(value.auditId) &&
      DataObject.isValidId(value.globalContactListId)
    );
  }

  public updateValue(newValue: DV): Promise<UserObjectProfilePrivate> {
    const Class = this.constructor as typeof UserObjectProfilePrivate;
    return Class.updateValue(this, newValue);
  }
}
