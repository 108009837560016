import { Utils } from '@sigmail/common';
import { Encoder } from '@sigmail/crypto';

export type Params = Encoder.Base64Params;

const DEFAULT_PARAMS: Params = {
  // accept either '+/' or url safe '-_' as the last two characters
  ALPHA: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/-_',
  // pad characters are ignored on decode, never added on encode
  PAD_CHAR: '='
};

export function encode(value: Uint8Array, params?: Partial<Params>): string {
  const { ALPHA } = Utils.defaults({}, params, DEFAULT_PARAMS);

  let result = '';
  let bits = 0;
  let nb = 0;
  for (let i = 0; i < value.length; ++i) {
    let by = value[i] & 0xff;
    bits = ((bits & 0xff) << 8) | by;
    nb += 8;
    while (nb >= 6) {
      nb -= 6;
      result += ALPHA.charAt((bits >> nb) & 0x3f);
    }
  }
  // encode last character, if any (no padding)
  if (nb > 0) {
    result += ALPHA.charAt((bits << (6 - nb)) & 0x3f);
  }
  return result;
}

export function decode(value: string, params?: Partial<Params>): Uint8Array {
  const { ALPHA, PAD_CHAR } = Utils.defaults({}, params, DEFAULT_PARAMS);

  let imax = value.length;
  if (imax === 0) {
    return new Uint8Array(0);
  }

  while (imax > 0 && value.charAt(imax - 1) === PAD_CHAR) {
    --imax;
  }

  // this is the exact length of the result
  let result = new Uint8Array(Math.trunc((imax * 6) / 8));
  let bits = 0;
  let nb = 0;
  let j = 0;
  for (let i = 0; i < imax; ++i) {
    let ch = value.charAt(i);
    let by = ALPHA.indexOf(ch);
    if (by > 0x3f) {
      by = 0x3e + (by & 0x01); // treat all char pairs after end as same as +/
    }
    bits = ((bits & 0xff) << 6) | by;
    nb += 6;
    if (nb >= 8) {
      result[j++] = (bits >> (nb - 8)) & 0xff;
      nb -= 8;
    }
  }
  return result;
}
