import { Utils } from '@sigmail/common';
import { ClientObjectUserListValue as DV } from '@sigmail/objects';
import { ClientObject } from '.';

const TYPE = process.env.CLIENT_OBJECT_TYPE_USER_LIST;

export class ClientObjectUserList extends ClientObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return (
      super.isValidDecryptedValue(value) &&
      Utils.isArray(value.active) &&
      Utils.isArray(value.inactive) &&
      Utils.isArray(value.pending)
    );
  }

  public updateValue(newValue: DV): Promise<ClientObjectUserList> {
    const Class = this.constructor as typeof ClientObjectUserList;
    return Class.updateValue(this, newValue);
  }
}
