import { Nullable, PostalAddress } from '@sigmail/common';
import { isNil, isString, transform } from '.';

const KEYS: Array<keyof PostalAddress> = [
  'addressLine1',
  'addressLine2',
  'addressLevel2',
  'addressLevel1',
  'postalCode'
];

export function joinPostalAddress(props: Nullable<PostalAddress> | null | undefined): string {
  if (isNil(props)) {
    return '';
  }

  const address = transform(
    KEYS,
    (result, prop, index) => {
      result[index] = isString(props[prop]) && props[prop]!.trim();
    },
    Array<string | false>(KEYS.length)
  );

  return address.filter(Boolean).join(', ');
}
