import { IAppUserOrUserGroup, ValueObject } from '@sigmail/common';
import { AppException } from './app-exception';
import { MAX_VALUE_OBJECT_ID } from './constants';
import { E_INVALID_OBJECT_ID, E_UNKNOWN_OBJECT_TYPE, S_ERROR } from './constants/error';
import { every, has, hashNumber, hashString, isInteger, isNonArrayObjectLike, partial } from './utils';

const PROPS: ReadonlyArray<keyof Omit<IAppUserOrUserGroup, keyof ValueObject>> = ['type', 'id'];

export abstract class AppUserOrUserGroup<T extends 'user' | 'group' = 'user' | 'group'>
  implements IAppUserOrUserGroup<T> {
  public static isValidType(value: any): value is 'user' | 'group' {
    return value === 'user' || value === 'group';
  }

  public static isValidId(value: any): value is number {
    return isInteger(value) && value > 0 && value <= MAX_VALUE_OBJECT_ID;
  }

  public static isAssignableFrom(obj: any): obj is IAppUserOrUserGroup {
    if (obj instanceof AppUserOrUserGroup && this === obj.constructor) return true;

    return (
      isNonArrayObjectLike(obj) &&
      every(PROPS, partial(has, obj)) &&
      this.isValidType(obj.type) &&
      this.isValidId(obj.id) &&
      typeof obj.equals === 'function' &&
      typeof obj.hashCode === 'function'
    );
  }

  public readonly type: T;
  public readonly id: number;

  public constructor(type: T, id: number);
  public constructor(...args: any[]);
  public constructor(...args: any[]) {
    const Class = this.constructor as typeof AppUserOrUserGroup;
    if (Class === AppUserOrUserGroup) throw new TypeError('Initialization error.');

    let type: T | undefined;
    let id: number;

    if (args.length === 1) {
      const obj = args[0];
      if (!every(PROPS, partial(has, obj))) {
        throw new AppException(S_ERROR, 'Invalid argument value.');
      }
      type = obj.type;
      id = obj.id;
    } else if (args.length >= 2) {
      type = args[0];
      id = args[1];
    } else {
      throw new AppException(S_ERROR, 'Invalid argument value.');
    }

    if (!Class.isValidType(type)) throw new AppException(E_UNKNOWN_OBJECT_TYPE);
    if (!Class.isValidId(id)) throw new AppException(E_INVALID_OBJECT_ID);

    this.type = type;
    this.id = id;
  }

  public equals(other: any): other is IAppUserOrUserGroup {
    const Class = this.constructor as typeof AppUserOrUserGroup;
    if (!Class.isAssignableFrom(other)) return false;

    return this.type === other.type && this.id === other.id;
  }

  public hashCode(): number {
    let hashed = 0;
    hashed = (31 * hashed + hashString(this.type)) | 0;
    hashed = (31 * hashed + hashNumber(this.id)) | 0;
    return hashed;
  }
}
