import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../../http/service-exception';
import { URL_OSCAR_ADD_DOCUMENT } from '../constants';
import { MalformedResponseException } from '../malformed-response-exception';
import { ServiceException as ApiServiceException } from '../service-exception';

function isValidResponseData(responseJson?: any): responseJson is Api.OscarAddDocumentResponseData {
  // TODO validate type structure
  return true;
}

export async function apiOscarAddDocument(
  this: Api.Service,
  accessToken: string,
  query: Api.OscarAddDocumentRequestData
) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiOscarAddDocument:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.coreApi.concat(URL_OSCAR_ADD_DOCUMENT);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<Api.OscarAddDocumentResponseData>(requestUrl, JSON.stringify(query), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<Api.OscarAddDocumentResponseData>(response);
          if (isValidResponseData(responseJson)) return responseJson;

          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        if (response.status === 400) {
          throw new ApiServiceException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
