import { GlobalI18n } from '@sigmail/i18n';
import { gettext } from '.';
import { getRoutePath } from '../app/routes';
import * as ActionId from '../constants/action-ids';
import * as RouteId from '../constants/route-identifiers';

const i18n: GlobalI18n = {
  // Translators: Product name (currently SigMail)
  productName: gettext('global', 'global', 'productName'),
  // Translators: Logo text
  logoText: gettext('global', 'global', 'logoText'),
  contactUsNumber: {
    // Translators: Phone Number to be displayed for Contact Us information (e.g. 1-888-555-1234)
    label: gettext('global', 'global', 'contactUsNumberLabel'),
    // Translators: Phone Number URL (typically a telephone link, e.g. tel:+1-888-555-1234)
    href: gettext('global', 'global', 'contactUsNumberUrl', 'url')
  },
  // Translators: (HTML) Copyright Notice
  copyrightNotice: gettext('global', 'global', 'copyrightNotice', 'safe-html'),
  // Translators: Generic message to be displayed if an operation fails with an error
  errorOccurredMessageGeneric: gettext('global', 'global', 'errorOccurredMessageGeneric'),
  // Translators: Generic message to be displayed if an operation (which can be retried) fails with an error
  errorOccurredTryAgainMessageGeneric: gettext('global', 'global', 'errorOccurredTryAgainMessageGeneric'),
  // Translators: Generic message to be displayed if a data load operation fails with an error
  errorOccurredLoadingDataMessageGeneric: gettext('global', 'global', 'errorOccurredLoadingDataMessageGeneric'),
  // Translators: Generic message to be displayed if a data load operation (which can be retried) fails with an error
  errorOccurredLoadingDataTryAgainMessageGeneric: gettext('global', 'global', 'errorOccurredLoadingDataTryAgainMessageGeneric'),
  // Translators: Label for the Open Drawer Menu button (available to screen readers only)
  ariaLabelOpenDrawer: gettext('global', 'global', 'ariaLabelOpenDrawer'),
  // Translators: Label for the Close Drawer Menu button (available to screen readers only)
  ariaLabelCloseDrawer: gettext('global', 'global', 'ariaLabelCloseDrawer'),
  // Translators: Label for the Close button of a popup (dialog, alert, etc.) (available to screen readers only)
  ariaLabelClosePopup: gettext('global', 'global', 'ariaLabelClosePopup'),
  // Translators: Message displayed when user does not have sufficient rights to perform an operation
  insufficientRightsMessage: gettext('global', 'global', 'insufficientRightsMessage'),
  invalidEmailLinkAlert: {
    // Translators: Title of the alert displayed when an email link is no longer valid
    title: gettext('global', 'global', 'alertTitleInvalidEmailLink'),
    // Translators: (HTML) Body of the alert displayed when an email link is no longer valid
    body: gettext('global', 'global', 'alertBodyInvalidEmailLink', 'safe-html')
  },
  action: {
    [ActionId.SkipToNavMenu]: {
      // Translators: Label for the Skip To Navigation Menu action (available to screen readers only)
      label: gettext('global', 'global', 'actionLabelSkipToNavMenu'),
      href: '#sr-nav'
    },
    [ActionId.SkipToMainContent]: {
      // Translators: Label for the Skip To Main Content action (available to screen readers only)
      label: gettext('global', 'global', 'actionLabelSkipToMainContent'),
      href: '#sr-content'
    },
    [ActionId.LanguageToggle]: {
      // Translators: Label for the Language Toggle action
      label: gettext('global', 'global', 'actionLabelLanguageToggle'),
      href: () => window.location.href
    },
    [ActionId.AccountManageSelf]: {
      // Translators: Label for the My Account action
      label: gettext('global', 'global', 'actionLabelMyAccount'),
      to: getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_SELF)
    },
    [ActionId.AccountManageMembers]: {
      // Translators: Label for the Manage Members action
      label: gettext('global', 'global', 'actionLabelManageMembers'),
      to: getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_MEMBERS)
    },
    [ActionId.InviteGuest]: {
      // Translators: Label for the Invite Guest action
      label: gettext('global', 'global', 'actionLabelInviteGuest'),
      to: ''
    },
    [ActionId.Mailbox]: {
      // Translators: Label for the Mailbox action
      label: gettext('global', 'global', 'actionLabelMailbox'),
      to: getRoutePath(RouteId.ROUTE_MAIL)
    },
    [ActionId.ContactList]: {
      // Translators: Label for the Contact List action
      label: gettext('global', 'global', 'actionLabelContactList'),
      to: getRoutePath(RouteId.ROUTE_CONTACT_LIST)
    },
    [ActionId.DocumentList]: {
      // Translators: Label for the Document List action
      label: gettext('global', 'global', 'actionLabelDocumentList'),
      to: getRoutePath(RouteId.ROUTE_DOCUMENT_LIST)
    },
    [ActionId.FAQ]: {
      // Translators: Label for the FAQ action
      label: gettext('global', 'global', 'actionLabelFAQ'),
      to: getRoutePath(RouteId.ROUTE_FAQ),
      href: 'https://www.sigmahealthtech.com/#faq'
    },
    [ActionId.TermsAndConditions]: {
      // Translators: Label for the Terms and Conditions action
      label: gettext('global', 'global', 'actionLabelTermsAndConditions'),
      to: getRoutePath(RouteId.ROUTE_TERMS_AND_CONDITIONS),
      href: 'https://www.sigmahealthtech.com/terms-of-use'
    },
    [ActionId.PrivacyPolicy]: {
      // Translators: Label for the Privacy Policy action
      label: gettext('global', 'global', 'actionLabelPrivacyPolicy'),
      to: getRoutePath(RouteId.ROUTE_PRIVACY_POLICY),
      href: 'https://www.sigmahealthtech.com/privacy-policy'
    },
    [ActionId.ContactUs]: {
      // Translators: Label for the Contact Us action
      label: gettext('global', 'global', 'actionLabelContactUs'),
      to: getRoutePath(RouteId.ROUTE_CONTACT_US)
    },
    [ActionId.SignOut]: {
      // Translators: Label for the Sign Out action
      label: gettext('global', 'global', 'actionLabelSignOut'),
      to: getRoutePath(RouteId.ROUTE_SIGN_OUT)
    }
  },
  sharedFormField: {
    gender: {
      options: [
        {
          code: 'unknown',
          // Translators: Label for the Unknown Gender option
          label: gettext('global', 'sharedFormField', 'optionLabelGenderUnknown')
        },
        {
          code: 'male',
          // Translators: Label for the Male Gender option
          label: gettext('global', 'sharedFormField', 'optionLabelGenderMale')
        },
        {
          code: 'female',
          // Translators: Label for the Female Gender option
          label: gettext('global', 'sharedFormField', 'optionLabelGenderFemale')
        },
        {
          code: 'other',
          // Translators: Label for the Other Gender option
          label: gettext('global', 'sharedFormField', 'optionLabelGenderOther')
        }
      ]
    }
  }
};

export default i18n;
