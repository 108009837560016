import { Avatar, Badge } from '@material-ui/core';
import { Utils } from '@sigmail/common';
import React from 'react';
import ProMemberBadge from '../../assets/images/badge-pro-member.min.svg';
import StandardMemberBadge from '../../assets/images/badge-std-member.min.svg';
import { PersonNameAvatar } from './person-name-avatar.component';

export interface Props {
  avatar?: string | undefined;
  firstName?: string | undefined;
  lastName?: string | undefined;
  license?: string | null | undefined;
  backgroundColor?: string;
}

const LicenseBadgeAvatar = React.memo((({ license }) => (
  <Avatar
    alt=""
    role="presentation"
    aria-hidden={true}
    src={Utils.MedicalInstitute.isProLicense(license) ? ProMemberBadge : StandardMemberBadge}
  />
)) as React.FC<{ license: string }>);

const MemberAvatarComponent: React.FC<Props> = ({ avatar, firstName, lastName, license, backgroundColor }) => {
  const trimmedAvatar = (Utils.isString(avatar) && avatar.trim()) || '';

  let memberAvatar: React.ReactElement;
  if (trimmedAvatar.length > 0) {
    memberAvatar = <Avatar alt="" role="presentation" aria-hidden={true} src={trimmedAvatar} />;
  } else {
    memberAvatar = (
      <PersonNameAvatar
        firstName={Utils.MedicalInstitute.isGuestLicense(license) ? '' : firstName}
        lastName={Utils.MedicalInstitute.isGuestLicense(license) ? '' : lastName}
        backgroundColor={backgroundColor}
      />
    );
  }

  if (Utils.MedicalInstitute.isProLicense(license) || Utils.MedicalInstitute.isStandardLicense(license)) {
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        badgeContent={<LicenseBadgeAvatar license={license} />}
      >
        {memberAvatar}
      </Badge>
    );
  }

  return memberAvatar;
};

export const MemberAvatar = React.memo(MemberAvatarComponent);
MemberAvatar.displayName = 'MemberAvatar';
