import { CircularProgress as MuiCircularProgress, WithStyles } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

const styles = createStyles({
  root: {
    display: 'flex',
    flex: 1,
    flexFlow: 'row nowrap',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export interface Props extends WithStyles<typeof styles> {
  style?: React.CSSProperties;
}

function CircularProgressComponent({ classes, style }: Props) {
  return (
    <div className={classes.root} style={style}>
      <MuiCircularProgress />
    </div>
  );
}

export const CircularProgress = withStyles(styles)(CircularProgressComponent);
CircularProgress.displayName = 'CircularProgress';
