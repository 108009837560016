import { Utils } from '@sigmail/common';
import { ClientObjectContactListValue as DV } from '@sigmail/objects';
import { ClientObject } from '.';

const TYPE = process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST;

export class ClientObjectContactList extends ClientObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isArray(value.list);
  }

  public updateValue(newValue: DV): Promise<ClientObjectContactList> {
    const Class = this.constructor as typeof ClientObjectContactList;
    return Class.updateValue(this, newValue);
  }
}
