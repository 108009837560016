(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@sigmail/common"), require("@sigmail/logging"), require("@sigmail/objects"));
	else if(typeof define === 'function' && define.amd)
		define("SigmailServices", ["@sigmail/common", "@sigmail/logging", "@sigmail/objects"], factory);
	else if(typeof exports === 'object')
		exports["SigmailServices"] = factory(require("@sigmail/common"), require("@sigmail/logging"), require("@sigmail/objects"));
	else
		root["SigmailServices"] = factory(root["@sigmail/common"], root["@sigmail/logging"], root["@sigmail/objects"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__774__, __WEBPACK_EXTERNAL_MODULE__484__, __WEBPACK_EXTERNAL_MODULE__873__) {
return 