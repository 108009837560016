import { CancelablePromise, NonArrayObjectLike } from '@sigmail/common';
import _ from 'lodash';
import { SEVERITY_ERROR, SEVERITY_SUCCESS } from '../constants/error/severity';
import { DATE_FORMAT_FULL_NO_TIME } from './format-timestamp';

function smi(i32: number) {
  // https://github.com/immutable-js/immutable-js/blob/master/src/Math.js

  // v8 has an optimization for storing 31-bit signed numbers.
  // Values which have either 00 or 11 as the high order bits qualify.
  // This function drops the highest order bit in a signed number, maintaining
  // the sign bit.
  return ((i32 >>> 1) & 0x40000000) | (i32 & 0xbfffffff);
}

export const SEVERITY = (code: number): number => ((code & (1 << 31)) === 0 ? SEVERITY_SUCCESS : SEVERITY_ERROR);
export const FACILITY = (code: number) => (code >> 16) & 0x000007ff;
export const CODE = (code: number) => code & 0x0000ffff;

//
// Util
//
export const noop = _.noop;

//
// Lang
//
export const cloneDeep = _.cloneDeep;
export const isArray = _.isArray;
export const isDate = _.isDate;
export const isFinite = (value?: any): value is number => _.isFinite(value);
export const isInteger = (value?: any): value is number => _.isInteger(value);
export const isNaN = _.isNaN;
export const isNil = _.isNil;
export const isNonArrayObjectLike = <T>(value?: T): value is NonArrayObjectLike<T> =>
  _.isObjectLike(value) && !_.isArray(value);
export const isNonEmptyArray = (value?: any): value is any[] => _.isArray(value) && value.length > 0;
export const isNotNil = <T>(value?: T): value is NonNullable<T> => !_.isNil(value);
export const isNumber = _.isNumber;
export const isObject = _.isObject;
export const isObjectLike = _.isObjectLike;
export const isPlainObject = _.isPlainObject;
export const isString = _.isString;
export const isUndefined = _.isUndefined;
export const isValidDate = (value?: any): value is Date => _.isDate(value) && !_.isNaN(value.getTime());

//
// Collection
//
export const every = _.every;
export const flatten = _.flatten;
export const invokeMap = _.invokeMap;

export function* makeSequence<T extends ReadonlyArray<any>>(values: T): Generator<T[0], T[0], unknown> {
  let iterationCount = 0;
  for (let i = 0; i < values.length; i++) {
    iterationCount++;
    yield values[i];
  }
  return iterationCount;
}

export const shuffle = <T extends any[]>(array: T): T => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

//
// Function
//
export const debounce = _.debounce;
export const partial = _.partial;
export const rearg = _.rearg;
export const memoize = _.memoize;

//
// Object
//
export const at = _.at;
export const defaults = _.defaults;
export const defaultsDeep = _.defaultsDeep;
export const findKey = _.findKey;
export const invoke = _.invoke;
export const has = _.has;
export const mapKeys = _.mapKeys;
export const mapValues = _.mapValues;
export const omit = _.omit;
export const pick = _.pick;
export const transform = _.transform;

//
// Number
//
// Compress arbitrarily large numbers into smi hashes.
export const hashNumber = (n: number): number => {
  if (n !== n || n === Infinity) {
    return 0;
  }
  let hash = n | 0;
  if (hash !== n) {
    hash ^= n * 0xffffffff;
  }
  while (n > 0xffffffff) {
    n /= 0xffffffff;
    hash ^= n;
  }
  return smi(hash);
};

//
// String
//
export const hashString = (value: string): number => {
  // https://github.com/immutable-js/immutable-js/blob/master/src/Hash.js

  // This is the hash from JVM
  // The hash code for a string is computed as
  // s[0] * 31 ^ (n - 1) + s[1] * 31 ^ (n - 2) + ... + s[n - 1],
  // where s[i] is the ith character of the string and n is the length of
  // the string. We "mod" the result to make it between 0 (inclusive) and 2^31
  // (exclusive) by dropping high bits.
  let hashed = 0;
  for (let index = 0; index < value.length; index++) {
    hashed = (31 * hashed + value.charCodeAt(index)) | 0;
  }
  return smi(hashed);
};

export const camelCase = _.camelCase;

export const maskEmailAddress = (emailAddress: string): string => {
  return emailAddress.replace(
    /^(.)(.*)(.@)([^.]*)(.*)$/,
    (_, a, b, c, d, e) => a + b.replace(/./g, '*') + c + d.replace(/^(.).*(.)$$/g, '$1*****$2') + e
  );
};

export const maskPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber.replace(/\d(?=\d{2})/g, '*');
};

export const maskBirthDate = (birthDate: string | number | Date, locale: string): string => {
  const dtBirth = new Date(birthDate);
  if (isValidDate(dtBirth)) {
    const dtFormatter = DATE_FORMAT_FULL_NO_TIME(locale);
    return dtFormatter
      .formatToParts(dtBirth)
      .map((part) => (part.type === 'month' ? Array<string>(part.value.length).fill('X').join('') : part.value))
      .join('');
  }
  return '';
};

//
// Promise
//
export const makeCancelablePromise = <T = void>(promise: Promise<T>): CancelablePromise<T> => {
  let isPending_ = true;
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (value) => {
        isPending_ = false;
        if (hasCanceled_) {
          reject((result = { isCanceled: true }));
        } else {
          resolve((result = value));
        }
      },
      (error) => {
        isPending_ = false;
        if (hasCanceled_) {
          reject((result = { isCanceled: true }));
        } else {
          reject((result = error));
        }
      }
    );
  });

  let result: any = wrappedPromise;
  return {
    promise: wrappedPromise,

    get isPending() {
      return isPending_;
    },

    get hasCanceled() {
      return hasCanceled_;
    },

    value() {
      if (!isPending_ && !hasCanceled_) {
        return result;
      }
      throw result;
    },

    cancel() {
      if (isPending_) {
        hasCanceled_ = true;
        isPending_ = false;
      }
    }
  };
};
