import { MfaMethod, Utils } from '@sigmail/common';
import { ServerParamsMfaLogin, SharedParamsMfaLogin } from '@sigmail/objects';
import { UserCredentials } from '.';

const TYPE = process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN;

const SHARED_PARAMS_PROPS: ReadonlyArray<keyof SharedParamsMfaLogin> = [
  'salt',
  'mfaAccountId',
  'mfaMethod',
  'mfaContact'
];

const SERVER_PARAMS_PROPS: ReadonlyArray<keyof ServerParamsMfaLogin> = ['verifier'];

export class UserCredentialsMfaLogin extends UserCredentials<SharedParamsMfaLogin, ServerParamsMfaLogin> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  public static isValidMfaAccountId(value: any): value is number {
    return Utils.isInteger(value) && value > 0;
  }

  public static isValidMfaMethod(value: any): value is MfaMethod {
    return value === 'call' || value === 'sms' || value === 'email';
  }

  public static isValidMfaContact(value: any): value is string {
    return Utils.isString(value);
  }

  /** @override */
  public static isValidSharedParameters(value: any): value is SharedParamsMfaLogin {
    if (!super.isValidSharedParameters(value)) return false;

    return (
      Utils.every(SHARED_PARAMS_PROPS, Utils.partial(Utils.has, value)) &&
      Utils.isString(value.salt) &&
      Utils.isNumber(value.mfaAccountId) &&
      Utils.isString(value.mfaMethod) &&
      Utils.isString(value.mfaContact)
    );
  }

  /** @override */
  public static isValidServerParameters(value: any): value is ServerParamsMfaLogin {
    if (!super.isValidServerParameters(value)) return false;

    return Utils.every(SERVER_PARAMS_PROPS, Utils.partial(Utils.has, value)) && Utils.isString(value.verifier);
  }
}
