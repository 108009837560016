import { Constants } from '@sigmail/common';

export const AUTH_STATE_ACCOUNT_SETUP = 'accountSetup';
export const AUTH_STATE_CHANGE_PASSWORD = 'changePassword';
export const AUTH_STATE_CREATE_CIRCLE_OF_CARE_GROUP = 'createCircleOfCareGroup';
export const AUTH_STATE_CREATE_GUEST_CONTACT_GROUP = 'createGuestContactGroup';
export const AUTH_STATE_CREATE_MESSAGE_FOLDER_EXT = 'sendMessage';
export const AUTH_STATE_DELETE_MESSAGE = 'sendMessage';
export const AUTH_STATE_DISABLE_MFA = 'processMfa';
export const AUTH_STATE_ENABLE_MFA = 'processMfa';
export const AUTH_STATE_MANAGE_MEMBERS = 'manageMembers';
export const AUTH_STATE_MOVE_MESSAGE = 'sendMessage';
export const AUTH_STATE_PASSWORD_RESET = 'passwordReset';
export const AUTH_STATE_PROCESS_INCOMING_MESSAGES = 'incomingMessage';
export const AUTH_STATE_PROCESS_READ_RECEIPTS = 'incomingMessage';
export const AUTH_STATE_PROCESS_RECALL_MESSAGES = 'incomingMessage';
export const AUTH_STATE_RECALL_MESSAGE = 'sendMessage';
export const AUTH_STATE_REMOVE_INVITE = 'updateMemberInvitation';
export const AUTH_STATE_RESEND_INVITE = 'updateMemberInvitation';
export const AUTH_STATE_REVOKE_INVITE = 'updateMemberInvitation';
export const AUTH_STATE_SAVE_MESSAGE_AS_DRAFT = 'sendMessage';
export const AUTH_STATE_SEND_CLIENT_INVITATION = 'sendClientInvitation';
export const AUTH_STATE_SEND_MESSAGE = 'sendMessage';
export const AUTH_STATE_SEND_PASSWORD_RESET_LINK = 'forgotPasswordLink';
export const AUTH_STATE_SEND_REFERRAL = 'sendMessage';
export const AUTH_STATE_SEND_REFERRAL_RESPONSE = 'sendMessage';
export const AUTH_STATE_SET_LAST_SENT_TO_OSCAR_FLAG = 'sendMessage';
export const AUTH_STATE_UPDATE_GROUP_PREFERENCES = 'updateGroup';
export const AUTH_STATE_UPDATE_MESSAGE_READ_STATE = 'sendMessage';
export const AUTH_STATE_UPDATE_PROFILE_INFO = 'updateUser';
export const AUTH_STATE_UPDATE_USER_PREFERENCES = 'updateUser';

type ServerRightsRoleIdentifier = 'AdminPhysician' | 'Physician+' | 'Physician' | 'AdminStaff' | 'Staff' | 'Patient';

type PrefixedServerRightsRoleIdentifier<Str extends string> = `${Str}${ServerRightsRoleIdentifier}`;

export const AUTH_STATE_AUTHORIZED_AS_ROLE: Readonly<Record<string, PrefixedServerRightsRoleIdentifier<'authorizedAs'> | undefined>> = {
  [Constants.MedicalInstitute.ROLE_ID_ADMIN_PHYSICIAN]: 'authorizedAsAdminPhysician',
  [Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN]: 'authorizedAsPhysician+',
  [Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN]: 'authorizedAsPhysician',
  [Constants.MedicalInstitute.ROLE_ID_ADMIN_STAFF]: 'authorizedAsAdminStaff',
  [Constants.MedicalInstitute.ROLE_ID_STAFF]: 'authorizedAsStaff',
  [Constants.MedicalInstitute.ROLE_ID_GUEST]: 'authorizedAsPatient'
};

export const AUTH_STATE_CREATE_AS_ROLE: Readonly<Record<string, PrefixedServerRightsRoleIdentifier<'createAs'> | undefined>> = {
  [Constants.MedicalInstitute.ROLE_ID_ADMIN_PHYSICIAN]: 'createAsAdminPhysician',
  [Constants.MedicalInstitute.ROLE_ID_SENIOR_PHYSICIAN]: 'createAsPhysician+',
  [Constants.MedicalInstitute.ROLE_ID_JUNIOR_PHYSICIAN]: 'createAsPhysician',
  [Constants.MedicalInstitute.ROLE_ID_ADMIN_STAFF]: 'createAsAdminStaff',
  [Constants.MedicalInstitute.ROLE_ID_STAFF]: 'createAsStaff',
  [Constants.MedicalInstitute.ROLE_ID_GUEST]: 'createAsPatient'
};
