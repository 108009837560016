import { createSelector } from '@reduxjs/toolkit';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { getRoutePath } from '../../app/routes';
import * as ClientRightId from '../../constants/client-rights-identifiers';
import * as RouteId from '../../constants/route-identifiers';
import { clientRightsSelector, isUserLoggedInSelector } from './auth';

const LOGGER = 'RootSelectors';

export const homeRoutePathSelector = createSelector(
  isUserLoggedInSelector,
  clientRightsSelector,
  (isUserLoggedIn, clientRights) => {
    const Logger = getLoggerWithPrefix(LOGGER, 'homeRoutePathSelector:');

    let path = getRoutePath(RouteId.ROUTE_SIGN_IN);
    if (isUserLoggedIn) {
      path = getRoutePath(RouteId.ROUTE_APP_ROOT);
      if (clientRights.get(ClientRightId.CAN_ACCESS_MAILBOX)) {
        path = getRoutePath(RouteId.ROUTE_MAIL);
      } else if (clientRights.get(ClientRightId.CAN_INVITE_INSTITUTE)) {
        path = getRoutePath(RouteId.ROUTE_INVITE_INSTITUTE);
      } else if (clientRights.get(ClientRightId.CAN_ACCESS_MEMBER_MANAGEMENT)) {
        path = getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_MEMBERS);
      } else if (clientRights.get(ClientRightId.CAN_ACCESS_OWN_ACCOUNT)) {
        path = getRoutePath(RouteId.ROUTE_ACCOUNT_MANAGE_SELF);
      }
    }

    Logger.info('homeRoutePath =', JSON.stringify(path));
    return path;
  }
);
