import { Utils } from '@sigmail/common';
import { GroupObjectServerRightsValue as DV } from '@sigmail/objects';
import { GroupObject } from '.';

const TYPE = process.env.GROUP_OBJECT_TYPE_SERVER_RIGHTS;

export class GroupObjectServerRights extends GroupObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isString(value.groupClaim);
  }

  public updateValue(newValue: DV): Promise<GroupObjectServerRights> {
    const Class = this.constructor as typeof GroupObjectServerRights;
    return Class.updateValue(this, newValue);
  }
}
