import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MfaMethod, Utils } from '@sigmail/common';
import React from 'react';
import { BaseFormComponentState, BaseFormInputErrorMessage } from '../base-form-pure-component';

export interface FormData {
  verificationCode: string;
}

export interface ContextValue extends BaseFormComponentState, FormData {
  mfaAccountId: number;
  mfaMethod: MfaMethod;
  mfaContact: string;
  dispatch(action: PayloadAction<any>): void;
}

type CaseReducer<TPayload = void> = (state: ContextValue, action: PayloadAction<TPayload>) => ContextValue;

const inputValidityStateReducer: CaseReducer<BaseFormInputErrorMessage> = (state, action) => {
  const inputErrorMessage: BaseFormInputErrorMessage = {};

  Utils.transform(
    action.payload,
    (errorMessageState, newErrorMessage, inputId) => {
      const prevErrorMessage = state.inputErrorMessage[inputId];
      if (prevErrorMessage !== newErrorMessage) {
        errorMessageState[inputId] = newErrorMessage;
      }
    },
    inputErrorMessage
  );

  const inputErrorMessageState: Pick<ContextValue, 'inputErrorMessage'> = {
    inputErrorMessage: Utils.defaults(inputErrorMessage, state.inputErrorMessage)
  };

  return { ...state, ...inputErrorMessageState };
};

const FORM_DATA_STATE: ReadonlyArray<keyof FormData> = ['verificationCode'];
const formDataStateReducer: CaseReducer<{ [key: string]: any }> = (state, action) => ({
  ...state,
  ...Utils.pick(action.payload, FORM_DATA_STATE)
});

export const getDefaultValue = (): ContextValue => ({
  verificationCode: '',
  mfaAccountId: NaN,
  mfaMethod: 'sms',
  mfaContact: '',
  inputErrorMessage: {},
  submitStatus: 'NotSubmitted',
  dispatch() {}
});

const verifyMfaCodeSlice = createSlice({
  name: 'verifyMfaCode',
  initialState: getDefaultValue(),
  reducers: {
    updateInputErrorMessage: inputValidityStateReducer,
    updateFormData: formDataStateReducer,
    resetSubmitStatus: (state) => ({ ...state, submitStatus: 'NotSubmitted' })
  }
});

export const {
  reducer: rootReducer,
  actions: { updateInputErrorMessage, updateFormData, resetSubmitStatus }
} = verifyMfaCodeSlice;

export const Context = React.createContext(getDefaultValue());
