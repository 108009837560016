import { Constants, Utils } from '@sigmail/common';
import { UserObjectProfileBasicValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_PROFILE_BASIC;

export class UserObjectProfileBasic extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    const { firstName, lastName, emailAddress, role: roleId, $$formatver } = value as DV;
    let result =
      Utils.isString(firstName) &&
      Utils.isString(lastName) &&
      Utils.isString(emailAddress) &&
      Constants.MedicalInstitute.ROLE_ID_LIST.includes(roleId);

    if (result && !Utils.isUndefined($$formatver) && $$formatver >= 4) {
      if (Utils.MedicalInstitute.isPhysicianRole(roleId) || Utils.MedicalInstitute.isStaffRole(roleId)) {
        return Utils.isString(value.officeNumber);
      }
    }

    return result;
  }

  public updateValue(newValue: DV): Promise<UserObjectProfileBasic> {
    const Class = this.constructor as typeof UserObjectProfileBasic;
    return Class.updateValue(this, newValue);
  }
}
