// ============================================================================
// IMPORTANT - .json/.md files under %PUBLIC_URL%/locale/{{lng}}/ directory
// must have the same names as the namespace names defined below
//
export const I18N_NS_GLOBAL: string = 'global';
export const I18N_NS_DOCUMENT_TITLE: string = 'documentTitle';
export const I18N_NS_ADDRESS_LEVEL1_LIST: string = 'addressLevel1List';
export const I18N_NS_MEMBERSHIP_STATUS: string = 'membershipStatus';
export const I18N_NS_EMAIL_MESSAGE_TEMPLATE: string = 'emailMessageTemplate';
export const I18N_NS_LOGIN_FORM: string = 'loginForm';
export const I18N_NS_IDENTIFICATION_DOCUMENTS: string = 'identificationDocuments';
export const I18N_NS_EDITOR_TOOLBAR: string = 'editorToolbar';

export const I18N_NS_INVITE_INSTITUTION_FORM: string = 'inviteInstitutionForm';
export const I18N_NS_FAQ: string = 'faq';
export const I18N_NS_CONTACT_US_FORM: string = 'contactUsForm';
export const I18N_NS_TERMS_AND_CONDITIONS: string = 'termsAndConditions';
export const I18N_NS_PRIVACY_POLICY: string = 'privacyPolicy';
export const I18N_NS_AUTH_CREDENTIALS_SETUP_FORM: string = 'authCredentialsSetupForm';
export const I18N_NS_SECURITY_QUESTION_LIST: string = 'securityQuestionList';
export const I18N_NS_PASSWORD_RESET_SETUP_FORM: string = 'passwordResetSetupForm';

export const I18N_NS_MEDICAL_INSTITUTE_LICENSE_TYPE: string = 'medicalInstituteLicenseType';
export const I18N_NS_MEDICAL_INSTITUTE_MEMBER_ROLE: string = 'medicalInstituteMemberRole';
export const I18N_NS_MEDICAL_INSTITUTE_SPECIALTY_LIST: string = 'medicalInstituteSpecialtyList';
export const I18N_NS_MEDICAL_INSTITUTE_HEALTH_PLAN_JURISDICTION: string = 'medicalInstituteHealthPlanJurisdiction';
export const I18N_NS_MEDICAL_INSTITUTE_REGISTRATION_FORM: string = 'medicalInstituteRegistrationForm';
export const I18N_NS_MEDICAL_INSTITUTE_PROFILE_INFO_SETUP_FORM: string = 'medicalInstituteProfileInfoSetupForm';
export const I18N_NS_MEDICAL_INSTITUTE_MANAGE_MEMBERS: string = 'medicalInstituteManageMembers';
export const I18N_NS_MEDICAL_INSTITUTE_BULK_INVITE_GUESTS: string = 'medicalInstituteBulkInviteGuests';
export const I18N_NS_MEDICAL_INSTITUTE_INVITE_GUEST_FORM: string = 'medicalInstituteInviteGuestForm';
export const I18N_NS_MEDICAL_INSTITUTE_INVITE_GUEST_FORM_DIALOG: string = 'medicalInstituteInviteGuestFormDialog';
export const I18N_NS_MEDICAL_INSTITUTE_INVITE_STAFF_FORM: string = 'medicalInstituteInviteStaffForm';
export const I18N_NS_MEDICAL_INSTITUTE_INVITE_STAFF_FORM_DIALOG: string = 'medicalInstituteInviteStaffFormDialog';
export const I18N_NS_MEDICAL_INSTITUTE_EDIT_PROFILE_FORM: string = 'medicalInstituteEditProfileForm';
export const I18N_NS_MEDICAL_INSTITUTE_CONTACT_LIST_GLOBAL: string = 'medicalInstituteContactListGlobal';
export const I18N_NS_MEDICAL_INSTITUTE_PATIENT_REFERRAL: string = 'medicalInstitutePatientReferral';
export const I18N_NS_MEDICAL_INSTITUTE_PATIENT_REFERRAL_FORM: string = 'medicalInstitutePatientReferralForm';
export const I18N_NS_MEDICAL_INSTITUTE_HRM_FORM: string = 'medicalInstituteHrmForm';
export const I18N_NS_MEDICAL_INSTITUTE_HRM_DROP_DOWN_LIST: string = 'medicalInstituteHrmDropDownList';

export const I18N_NS_MESSAGING: string = 'messaging';
export const I18N_NS_CONTACT_LIST: string = 'contactList';
export const I18N_NS_CONTACT_LIST_CLIENT: string = 'contactListClient';
export const I18N_NS_CONTACT_LIST_GUEST: string = 'contactListGuest';

export const I18N_NS_SELECT_MFA_METHOD: string = 'selectMfaMethod';
export const I18N_NS_VERIFY_MFA_CODE: string = 'verifyMfaCode';

export const I18N_NS_NEW_PASSWORD_INPUT: string = 'newPasswordInput';
//
// ============================================================================
