import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';

export const sendTemplatedEmailMessageAction = (payload: Api.TemplatedEmailMessage): AppThunk<Promise<void>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'sendTemplatedEmailMessageAction:');

    Logger.info('== BEGIN ==');
    try {
      await apiService.sendTemplatedEmailMessage(payload);
      Logger.debug(JSON.stringify(payload));
    } catch (error) {
      Logger.error(error);
      throw error;
    } finally {
      Logger.info('== END ==');
    }
  };
};
