import { VerifyMfaCodeI18n } from '@sigmail/i18n';
import { gettext } from '.';
import * as MfaMethodId from '../constants/mfa-method-identifier';

const i18n: VerifyMfaCodeI18n = {
  // Translators: (HTML) Lead text
  leadText: {
    [MfaMethodId.TextMessage]: gettext('verifyMfaCode', 'verifyMfaCode', 'leadTextMfaMethodTextMessage', 'safe-html'),
    [MfaMethodId.PhoneCall]: gettext('verifyMfaCode', 'verifyMfaCode', 'leadTextMfaMethodPhoneCall', 'safe-html'),
    [MfaMethodId.EmailAddress]: gettext('verifyMfaCode', 'verifyMfaCode', 'leadTextMfaMethodEmail', 'safe-html')
  },
  formField: {
    verificationCode: {
      label: gettext('verifyMfaCode', 'verifyMfaCode', 'inputLabelVerificationCode'),
      error: {
        valueMissing: gettext('verifyMfaCode', 'verifyMfaCode', 'errorVerificationCodeRequired'),
        badInput: gettext('verifyMfaCode', 'verifyMfaCode', 'errorVerificationCodeBadInput')
      }
    }
  },
  notReceivedCodeQuestion: gettext('verifyMfaCode', 'verifyMfaCode', 'questionNotReceivedCode'),
  resendCodeAction: {
    label: gettext('verifyMfaCode', 'verifyMfaCode', 'actionLabelResendCode')
  },
  errorMessageSubmitFailed: gettext('verifyMfaCode', 'verifyMfaCode', 'errorMessageSubmitFailed')
};

export default i18n;
