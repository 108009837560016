// @ts-nocheck
import { ActionPayloadBatchQueryDataSuccess } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  GroupGuestUserContactListItem,
  GroupObjectGuestList,
  GroupObjectGuestListValue,
  IUserObject,
  UserObjectContactInfo,
  UserObjectContactInfoValue,
  UserObjectProfileProtected
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { BaseAction, FetchObjectsRequestData } from '../base-action';

export interface Payload {
  accessToken: string;
  groupId: number;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

interface State {
  groupGuestListObject: IUserObject<GroupObjectGuestListValue>;
  groupGuestList: GroupObjectGuestListValue;
}

class MigrationMaskHealthCardNumberInContactInfo extends BaseAction<Payload, State> {
  protected async onExecute() {
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchGroupGuestList();

        const { accessToken, successPayload } = this.payload;
        const { groupGuestList, groupGuestListObject } = this.state;

        const { $$formatver } = groupGuestList;
        if (!Utils.isNil($$formatver) && $$formatver >= 2) {
          this.logger.info(`Call ignored; migration is not required. ($$formatver = ${$$formatver})`);
          return;
        }

        const userObjectsByType: Array<Required<FetchObjectsRequestData>['userObjectsByType'][0]> = [];
        const userIdToGroupContactListItemMap = new Map<number, GroupGuestUserContactListItem>(
          groupGuestList.list
            .filter((contact): contact is GroupGuestUserContactListItem => {
              return contact.type === 'user' && Utils.isUndefined(contact.userData.memberType);
            })
            .map((contact) => {
              userObjectsByType.push(
                { userId: contact.id, type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED },
                { userId: contact.id, type: process.env.USER_OBJECT_TYPE_CONTACT_INFO }
              );
              return [contact.id, contact];
            })
        );

        const query: FetchObjectsRequestData = { userObjectsByType };
        const { userObjectList } = await this.fetchObjects(accessToken, query);

        const userIdToMaskedHealthPlanNumberMap = new Map<number, string>();
        const userIdToContactInfoMap = new Map<number, IUserObject<UserObjectContactInfoValue>>();
        for (const apiFormattedObject of userObjectList!) {
          if (apiFormattedObject.type === process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED) {
            const protectedProfileObject = new UserObjectProfileProtected(apiFormattedObject);
            const protectedProfile = await protectedProfileObject.decryptedValue();
            const maskedHealthPlanNumber = Utils.MedicalInstitute.maskHealthPlanNumber(protectedProfile.healthCardNumber!);
            userIdToMaskedHealthPlanNumberMap.set(apiFormattedObject.userId, maskedHealthPlanNumber);
          } else if (apiFormattedObject.type === process.env.USER_OBJECT_TYPE_CONTACT_INFO) {
            const contactInfoObject = new UserObjectContactInfo(apiFormattedObject);
            userIdToContactInfoMap.set(apiFormattedObject.userId, contactInfoObject);
          }
        }

        for (const [contactId, contact] of userIdToGroupContactListItemMap) {
          const maskedHealthPlanNumber = userIdToMaskedHealthPlanNumberMap.get(contactId);
          if (!Utils.isString(maskedHealthPlanNumber)) continue;

          const contactInfoObject = userIdToContactInfoMap.get(contactId);
          if (!Utils.isNil(contactInfoObject)) {
            const contactInfo = await contactInfoObject.decryptedValue();
            const updatedValue: UserObjectContactInfoValue = { ...contactInfo, healthCardNumber: maskedHealthPlanNumber };
            const updatedObject = await contactInfoObject.updateValue(updatedValue);
            userIdToContactInfoMap.set(contactId, updatedObject);
          }

          const updatedListItem: GroupGuestUserContactListItem = {
            ...contact,
            userData: { ...contact.userData, healthCardNumber: maskedHealthPlanNumber }
          };
          userIdToGroupContactListItemMap.set(contactId, updatedListItem);
        }

        const requestBody = new BatchUpdateRequestBuilder();
        const updatedGroupGuestListValue: GroupObjectGuestListValue = {
          ...groupGuestList,
          $$formatver: 2,
          list: Array.from(userIdToGroupContactListItemMap.values())
        };
        const updatedGroupGuestListObject = await groupGuestListObject.updateValue(updatedGroupGuestListValue);
        requestBody.update(Array.from<IUserObject<any>>(userIdToContactInfoMap.values()).concat(updatedGroupGuestListObject));

        await this.batchUpdateData(this.payload.accessToken, requestBody.build());

        const index = successPayload.response.userObjectsByType!.findIndex(({ id }) => id === groupGuestListObject.id);
        if (index !== -1) {
          successPayload.response.userObjectsByType![index] = updatedGroupGuestListObject.toApiFormatted();
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }
      }
    }
  }

  private async fetchGroupGuestList(): Promise<void> {
    this.logger.info('Fetching the latest contact info object.');

    const { groupId, accessToken } = this.payload;

    const query: FetchObjectsRequestData = {
      userObjectsByType: [{ userId: groupId, type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST }]
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    const groupGuestListJson = this.findUserObject(userObjectList, { userId: groupId, type: process.env.GROUP_OBJECT_TYPE_GUEST_LIST });
    if (Utils.isNil(groupGuestListJson)) {
      throw new Api.MalformedResponseException('Group guest list object could not be fetched.');
    }

    this.state.groupGuestListObject = new GroupObjectGuestList(groupGuestListJson);
    this.state.groupGuestList = await this.state.groupGuestListObject.decryptedValue();
  }
}

export const migration_maskHealthCardNumberInContactInfo = (payload: Payload): AppThunk<Promise<void>> => {
  return async (_D, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Migration', 'maskHealthCardNumberInContactInfo');

    const migration = new MigrationMaskHealthCardNumberInContactInfo({ payload, apiService, logger: Logger });
    return await migration.execute();
  };
};
