import { Utils } from '@sigmail/common';
import { VerifyMfaCodeI18n } from '@sigmail/i18n';
import { UserCredentialsMfaLogin } from '@sigmail/objects';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withTranslation } from '../../../i18n';
import globalI18n from '../../../i18n/global';
import { BaseFormInputDefinition, BaseFormPureComponent } from '../base-form-pure-component';
import { ErrorMessageSnackbar } from '../error-message-snackbar.component';
import * as Context from './verify-mfa-code.context';

interface FormInputDefinition {
  verificationCode: BaseFormInputDefinition[0];
}

const FORM_INPUT: FormInputDefinition = {
  verificationCode: {
    i18n: { label: 'Verification code' },
    autoComplete: 'off',
    type: 'text',
    required: true
  }
};

const FORM_INPUT_LIST: ReadonlyArray<keyof FormInputDefinition> = ['verificationCode'];

export interface Props extends WithTranslation {
  I18N: VerifyMfaCodeI18n;
  styles?: {
    root?: string;
    leadText?: string;
    formInput?: string;
    notReceivedCodeQuestion?: string;
    resentCodeAction?: string;
  };
  onResendCodeActionClick: () => any;
  onFormSubmit?: React.FormEventHandler<HTMLFormElement>;
}

interface State extends Context.ContextValue {}

class VerifyMfaCodeComponent extends BaseFormPureComponent<Props, State> {
  public static contextType = Context.Context;
  context!: React.ContextType<typeof Context.Context>;

  public constructor(props: Props) {
    super(props, (FORM_INPUT as unknown) as BaseFormInputDefinition);

    this.onResendCodeActionClick = this.onResendCodeActionClick.bind(this);
    this.resetSubmitStatus = this.resetSubmitStatus.bind(this);
  }

  public render(): React.ReactNode {
    this.formInputDef.verificationCode.i18n = this.props.I18N.formField.verificationCode;

    const formClassName = [
      this.props.styles?.root,
      this.getState().submitStatus === 'ValidationErrors' && 'was-validated'
    ];

    return (
      <form className={formClassName.filter(Boolean).join(' ')} onSubmit={this.props.onFormSubmit}>
        {Utils.isString(this.props.I18N.errorMessageSubmitFailed) && (
          <ErrorMessageSnackbar
            message={this.props.t(this.props.I18N.errorMessageSubmitFailed)}
            open={this.getState().submitStatus === 'SubmitFailure'}
            onClose={this.resetSubmitStatus}
            closeText={this.props.t(globalI18n.ariaLabelClosePopup)}
          />
        )}

        <div
          className={this.props.styles?.leadText}
          dangerouslySetInnerHTML={{
            __html: this.props.t(this.props.I18N.leadText[this.getState().mfaMethod] || '', {
              MFA_CONTACT: this.getState().mfaContact
            })
          }}
        />

        {FORM_INPUT_LIST.map((inputId) => (
          <div key={inputId} className={this.props.styles?.formInput}>
            {this.renderFormInput(inputId)}
          </div>
        ))}

        <div className={this.props.styles?.notReceivedCodeQuestion}>
          {this.props.t(this.props.I18N.notReceivedCodeQuestion)}
        </div>

        <div className={this.props.styles?.resentCodeAction}>
          <a href="/" onClick={this.onResendCodeActionClick}>
            {this.props.t(this.props.I18N.resendCodeAction.label as string)}
          </a>
        </div>

        <input type="submit" style={{ display: 'none' }} />
      </form>
    );
  }

  protected getState(): React.ContextType<typeof Context.Context> {
    return this.context;
  }

  protected updateInputErrorMessage(inputId: string, errorMessage: string | null) {
    this.getState().dispatch(Context.updateInputErrorMessage({ [inputId]: errorMessage }));
  }

  protected updateInputValue(inputId: string, value: string) {
    this.getState().dispatch(Context.updateFormData({ [inputId]: value }));
  }

  private async onResendCodeActionClick(event: React.MouseEvent<HTMLAnchorElement>): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    if (UserCredentialsMfaLogin.isValidMfaAccountId(this.getState().mfaAccountId)) {
      await Promise.resolve(this.props.onResendCodeActionClick());
    }
  }

  private resetSubmitStatus(): void {
    this.getState().dispatch(Context.resetSubmitStatus());
  }
}

export const VerifyMfaCode = withTranslation()(VerifyMfaCodeComponent);
VerifyMfaCode.displayName = 'VerifyMfaCode';
