import { NotificationObjectReadReceiptValue as DV } from '@sigmail/objects';
import { NotificationObject } from '.';
import { DataObjectMsgReadReceipt } from '../data-object/msg-read-receipt';

const TYPE = process.env.NOTIFICATION_OBJECT_TYPE_READ_RECEIPT;

export class NotificationObjectReadReceipt extends NotificationObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && DataObjectMsgReadReceipt.isValidId(value.header);
  }
}
