import { ActionPayloadBatchQueryDataSuccess, Messaging } from '@sigmail/app-state';
import { AppException, Constants, JsonObject, ReadonlyMessageBodyReferral, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedDataObject,
  ApiFormattedNotificationObject,
  DataObjectMsgBody,
  DataObjectMsgBodyValue,
  DataObjectMsgFolderItem,
  DataObjectMsgFolderValue,
  DataObjectMsgMetadata,
  DataObjectMsgMetadataValue,
  MessageFolderItemCount,
  NotificationObjectIncomingMessage,
  NotificationObjectIncomingMessageValue,
  UserMessageFolderKey
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { Set as ImmutableSet } from 'immutable';
import { AppThunk } from '../..';
import { MessagingException } from '../../../core/messaging-exception';
import { MessageFlags } from '../../../utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { DataObjectCache } from '../../data-objects-slice/cache';
import * as AuthSelectors from '../../selectors/auth';
import * as DataObjectSelectors from '../../selectors/data-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_PROCESS_INCOMING_MESSAGES } from '../constants/auth-state-identifier';
import { BaseMessagingAction } from './base-messaging-action';
import { createMessageFolderExtensionAction } from './create-message-folder-extension-action';
import { ApplyMessageFolderUpdateMeta, ApplyMessageFolderUpdateResult, updateMessageFolderAction } from './update-msg-folder-action';

const MESSAGE_FOLDER_KEY_GROUP_INBOX: Extract<Messaging.ParentMessageFolderKey, '$group_inbox'> = '$group_inbox';
const MESSAGE_FOLDER_KEY_INBOX: Extract<Messaging.ParentMessageFolderKey, 'inbox'> = 'inbox';
const MESSAGE_FOLDER_KEY_SENT: Extract<UserMessageFolderKey, 'sent'> = 'sent';

interface Payload extends Messaging.ActionPayloadProcessIncomingMessageNotifications {}

interface State extends AuthenticatedActionState {
  apiAccessToken: string;
  notificationObjectList: ReadonlyArray<ApiFormattedNotificationObject>;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ActionPayloadBatchQueryDataSuccess;
  referralResponseMsgSet: ImmutableSet<DataObjectMsgFolderItem>;
}

class ProcessIncomingMessageNotificationssAction extends BaseMessagingAction<Payload, State> {
  protected preExecute() {
    return super
      .preExecute()
      .then(() => {
        if (this.payload.folderKey !== MESSAGE_FOLDER_KEY_INBOX && this.payload.folderKey !== MESSAGE_FOLDER_KEY_GROUP_INBOX) {
          throw new MessagingException(`Expected <folderKey> to be one of: ${MESSAGE_FOLDER_KEY_INBOX}, ${MESSAGE_FOLDER_KEY_GROUP_INBOX}`);
        }

        this.state.apiAccessToken = AuthSelectors.accessTokenSelector(this.getRootState());

        const query: Api.EnterStateRequestData = {
          authState: this.state.roleAuthClaim,
          state: AUTH_STATE_PROCESS_INCOMING_MESSAGES
        };

        return this.enterState(this.state.apiAccessToken, query);
      })
      .then(({ authState }) => {
        this.state.roleAuthClaim = authState;
      });
  }

  protected async onExecute() {
    const { folderKey } = this.payload;
    const { roleAuthClaim: authState, apiAccessToken } = this.state;

    const folderId = (await this.findMessageFolder(folderKey)).id;
    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      // NOTE that we intentionally issue a message folder data fetch before
      // checking to see if there's any incoming message notifications available
      // to process; this way even if there's no new notifications, we will at
      // least have the latest version of the object available
      this.logger.info(`Fetching latest message folder data. (folderKey=${folderKey})`);
      await this.dispatchFetchObjects({ authState, dataObjects: { ids: [folderId] } });

      await this.fetchNotificationObjectList();

      const incomingMessageNotificationList = this.state.notificationObjectList
        .filter(({ type }) => type === process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE)
        .map((notificationJson) => new NotificationObjectIncomingMessage(notificationJson));

      const incomingMessageList: Array<NotificationObjectIncomingMessageValue> = [];
      for (const notificationObject of incomingMessageNotificationList) {
        const decryptedValue = await notificationObject.decryptedValue();
        incomingMessageList.push(decryptedValue);
      }

      if (incomingMessageList.length === 0) {
        this.logger.info('Incoming message notification list is empty; call ignored');
        return;
      }

      this.logger.info('Fetching all message metadata and body IDs extracted from notification objects.');
      const query: FetchObjectsRequestData = {
        authState,
        dataObjects: { ids: Utils.flatten(incomingMessageList.map(({ header, body }) => [header, body])) },
        expectedCount: { dataObjects: null }
      };

      const { dataObjectList, serverDateTime } = await this.fetchObjects(apiAccessToken, query);

      const dtExpiry = this.deserializeServerDateTime(serverDateTime);
      const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
      const msgFolderObject = dataObjectByIdSelector<DataObjectMsgFolderValue>(folderId);
      const msgFolder = DataObjectCache.getValue(msgFolderObject);
      if (Utils.isNil(msgFolderObject) || Utils.isNil(msgFolder)) {
        throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_DATA_INVALID);
      }

      const notificationObjectsToExpire: Array<NotificationObjectIncomingMessage> = [];
      const newMessageList: Array<DataObjectMsgFolderItem> = [];
      const idsFound: Array<number> = [];
      const itemCount = Utils.cloneDeep<MessageFolderItemCount>(msgFolder.itemCount);

      this.state.referralResponseMsgSet = ImmutableSet().asMutable();

      let index = -1;
      for (const { header: metadataId, body: bodyId } of incomingMessageList) {
        ++index;

        if (idsFound.indexOf(metadataId) >= 0 || idsFound.indexOf(bodyId) >= 0) {
          this.logger.warn('Duplicate notification record found with metadataId', metadataId, 'bodyId', bodyId);
          continue;
        }

        const data = {} as { header: ApiFormattedDataObject; body: ApiFormattedDataObject };
        for (const obj of dataObjectList) {
          if (Utils.isNotNil(data.header) && Utils.isNotNil(data.body)) {
            break;
          }

          if (Utils.isNil(data.header) && obj.id === metadataId && obj.type === process.env.DATA_OBJECT_TYPE_MSG_METADATA) {
            data.header = obj;
            idsFound.push(metadataId);
          } else if (Utils.isNil(data.body) && obj.id === bodyId && obj.type === process.env.DATA_OBJECT_TYPE_MSG_BODY) {
            data.body = obj;
            idsFound.push(bodyId);
          }
        }

        if (Utils.isNil(data.header) || Utils.isNil(data.body)) {
          this.logger.warn('Response JSON does not contain data for metadataId', metadataId, 'bodyId', bodyId);
          continue;
        }

        const msgMetadataObject = new DataObjectMsgMetadata(data.header);
        let msgMetadata: DataObjectMsgMetadataValue | undefined = undefined;
        const msgBodyObject = new DataObjectMsgBody(data.body);
        let msgBody: DataObjectMsgBodyValue | undefined = undefined;

        try {
          msgMetadata = await msgMetadataObject.decryptedValue();
        } catch (error) {
          this.logger.warn(`Error decrypting message metadata (ID=${metadataId}):`, error);
          /* ignore */
        }

        try {
          msgBody = await msgBodyObject.decryptedValue();
        } catch (error) {
          this.logger.warn(`Error decrypting message body (ID=${bodyId}):`, error);
          /* ignore */
        }

        if (Utils.isNil(msgMetadata) || Utils.isNil(msgBody)) {
          this.logger.warn(`Message metadata (ID=${metadataId}) and/or body (ID=${bodyId}) is either missing or invalid.`);
          continue;
        }

        const { isImportant, isBillable, isReferral } = MessageFlags({
          messageForm: msgMetadata.messageForm,
          flags: Utils.pick(msgMetadata, ['importance', 'billable'])
        });

        let flags: DataObjectMsgFolderItem['flags'] = undefined;
        if (isImportant || isBillable) {
          flags = {
            importance: isImportant ? 'high' : undefined,
            billable: isBillable || undefined
          };
        }

        let isReferralResponse = false;
        let extraData: DataObjectMsgFolderItem['extraData'] = null;
        if (isReferral) {
          const { value: msgBodyValue } = (msgBody as ReadonlyMessageBodyReferral).messageForm;
          if (Utils.isNonArrayObjectLike(msgBodyValue.response)) {
            isReferralResponse = true;
            extraData = { referralResponse: (msgBodyValue.response! as unknown) as JsonObject };
          }
        }

        const receivedMessage: DataObjectMsgFolderItem = {
          messageForm: { name: isReferral ? 'referral' : 'default' },
          header: metadataId,
          body: bodyId,
          entity: [msgMetadata.sender.type === 'group' ? msgMetadata.sender.groupName : Utils.joinPersonName(msgMetadata.sender)],
          subject: msgMetadata.subjectLine,
          timestamp: msgMetadata.sentAtUtc,
          documentList: msgMetadata.documentList,
          flags,
          extraData
        };

        ++itemCount.all.total;
        ++itemCount.all.unread;
        itemCount.important.total += +isImportant;
        itemCount.important.unread += +isImportant;
        itemCount.billable.total += +isBillable;
        itemCount.billable.unread += +isBillable;
        itemCount.referral.total += +isReferral;
        itemCount.referral.unread += +isReferral;

        newMessageList.push(receivedMessage);

        if (isReferralResponse) {
          this.state.referralResponseMsgSet.add(receivedMessage);
        }

        const notificationObject = incomingMessageNotificationList[index];
        notificationObjectsToExpire.push(notificationObject);
      }

      if (newMessageList.length === 0) {
        this.logger.info('Nothing to update.');
        return;
      }

      this.logger.info('Sorting the newly received messages in descending order.');
      newMessageList.sort(({ timestamp: value1 }, { timestamp: value2 }) => (value1 === value2 ? 0 : value1 < value2 ? 1 : -1));

      this.logger.info('Appending previous list of messages into the new message list.');
      newMessageList.push(...msgFolder.data);

      const updatedValue: DataObjectMsgFolderValue = { ...msgFolder, data: newMessageList, itemCount };
      const updatedObject = await msgFolderObject.updateValue(updatedValue);

      try {
        this.state.requestBody = new BatchUpdateRequestBuilder();

        this.state.successPayload = {
          request: { dataObjects: { ids: [] } },
          response: { dataObjects: [], serverDateTime: '' }
        };

        this.state.requestBody.update(updatedObject).expire(notificationObjectsToExpire, dtExpiry);
        this.state.successPayload.request.dataObjects!.ids.push(msgFolderObject.id);
        this.state.successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());

        if (!this.state.referralResponseMsgSet.isEmpty()) {
          await this.processReferralResponseMsgList();
        }

        const mutations = this.state.requestBody.build();
        await this.dispatchBatchUpdateData({ authState, ...mutations });

        try {
          await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
        } catch (error) {
          this.logger.warn('Error manually updating app state:', error);
          /* ignore */
        }

        try {
          await this.dispatch(createMessageFolderExtensionAction({ folderKey }));
        } catch (error) {
          this.logger.warn('Error creating message folder extension:', error);
          /* ignore */
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }
  }

  private async fetchNotificationObjectList(): Promise<void> {
    this.logger.info('Fetching incoming message notifications (if any).');

    const notificationObjectsByType: Array<Required<FetchObjectsRequestData>['notificationObjectsByType'][0]> = [];
    if (this.payload.folderKey === MESSAGE_FOLDER_KEY_INBOX) {
      const { id: userId } = this.state.currentUser;
      notificationObjectsByType.push({ userId, type: process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE });
    } else if (this.payload.folderKey === MESSAGE_FOLDER_KEY_GROUP_INBOX) {
      const circleOfCareGroup = UserObjectSelectors.circleOfCareGroupSelector(this.getRootState());
      if (Utils.isNil(circleOfCareGroup)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Circle of care group is either missing or invalid.');
      }
      notificationObjectsByType.push({ userId: circleOfCareGroup.id, type: process.env.NOTIFICATION_OBJECT_TYPE_INCOMING_MESSAGE });
    }

    let notificationObjectList: ReadonlyArray<ApiFormattedNotificationObject> = [];
    if (notificationObjectsByType.length > 0) {
      const query: FetchObjectsRequestData = {
        authState: this.state.roleAuthClaim,
        notificationObjectsByType,
        expectedCount: { notificationObjectsByType: null }
      };

      const responseJson = await this.dispatchFetchObjects(query);
      notificationObjectList = responseJson.notificationObjectList;
    }

    this.state.notificationObjectList = notificationObjectList;
  }

  private async processReferralResponseMsgList(): Promise<void> {
    this.logger.info('Processing referral response message list.');

    try {
      const { requestBody, successPayload } = this.state;

      await this.dispatch(
        updateMessageFolderAction({
          roleAuthClaim: this.state.roleAuthClaim,
          folderKey: MESSAGE_FOLDER_KEY_SENT,
          requestBody,
          successPayload,
          applyUpdate: this.applySentMessageFolderUpdate.bind(this)
        })
      );
    } catch (error) {
      this.logger.warn('processReferralResponseMsgList:', error);
      /* ignore */
    }
  }

  private applySentMessageFolderUpdate(
    folderData: Array<DataObjectMsgFolderItem>,
    _: MessageFolderItemCount,
    meta: ApplyMessageFolderUpdateMeta
  ): ApplyMessageFolderUpdateResult {
    const { referralResponseMsgSet } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: ApplyMessageFolderUpdateResult = { updated: false, done: false };

    const found: Array<DataObjectMsgFolderItem> = [];
    for (const receivedMessage of referralResponseMsgSet) {
      let metadataId: number;
      let bodyId: number;

      try {
        const msg = (receivedMessage.extraData!.referralResponse as JsonObject).msg as JsonObject;
        metadataId = msg.header as number;
        bodyId = msg.body as number;
      } catch {
        continue;
      }

      this.logger.info(`Locating item with metadata ID <${metadataId}> and body ID <${bodyId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);
      const index = folderData.findIndex(({ header, body }) => header === metadataId && body === bodyId);
      if (index === -1) continue;
      found.push(receivedMessage);

      const message = folderData[index];
      let extraData = { ...receivedMessage.extraData! };
      if (Utils.isNonArrayObjectLike(message.extraData)) {
        extraData = { ...extraData, ...message.extraData! };
      }

      folderData[index] = { ...message, extraData };
      result.updated = true;
    }

    // ASSUMPTION: set was initialized using asMutable()
    referralResponseMsgSet.subtract(found);

    result.done = referralResponseMsgSet.isEmpty();
    return result;
  }
}

export const processIncomingMessageNotificationsAction = (payload: Payload): AppThunk<Promise<void>> => {
  return async (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'processIncomingMessageNotificationsAction:');

    try {
      const action = new ProcessIncomingMessageNotificationssAction({ payload, dispatch, getState, apiService, logger: Logger });
      return await action.execute();
    } catch (error) {
      Logger.warn('Incoming message notification processing failed with an error:', error);
      /* ignore */
    }
  };
};
