import { Utils } from '@sigmail/common';
import React from 'react';
import ReactDOM from 'react-dom';
import { Cache as DataObjectCacheImpl, DataObjectCache } from './app-state/data-objects-slice/cache';
import { Cache as UserObjectCacheImpl, UserObjectCache } from './app-state/user-objects-slice/cache';
import './i18n';
import './index.css';
import * as serviceWorker from './serviceWorker';

declare const window: any;
declare const module: any;

//
// Disable React Devtools in Production
//
if (process.env.REACT_APP_ENV === 'production') {
  const REACT_DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;
  if (typeof REACT_DEV_TOOLS === 'object') {
    for (const [key, value] of Object.entries(REACT_DEV_TOOLS)) {
      REACT_DEV_TOOLS[key] = typeof value === 'function' ? Utils.noop : null;
    }
  }
}

//
// Enable some debugging helper methods in non-production
//
if (process.env.REACT_APP_ENV !== 'production') {
  window.__DEV_SIGMAIL__ = {
    dumpUserObjectCache: (UserObjectCache as UserObjectCacheImpl).dumpToConsoleLog.bind(UserObjectCache),
    dumpDataObjectCache: (DataObjectCache as DataObjectCacheImpl).dumpToConsoleLog.bind(DataObjectCache)
  };
}

function renderApp() {
  const { App } = require('./app/app.component');
  ReactDOM.render(
    <React.Suspense fallback={null}>
      <App />
    </React.Suspense>,
    document.getElementById('root')
  );
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./app/app.component', renderApp);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
