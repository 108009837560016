import { Utils } from '@sigmail/common';
import { UserObjectCalendarValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_CALENDAR;

export class UserObjectCalendar extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isNonArrayObjectLike(value.workSchedule);
  }

  public updateValue(newValue: DV): Promise<UserObjectCalendar> {
    const Class = this.constructor as typeof UserObjectCalendar;
    return Class.updateValue(this, newValue);
  }
}
