import { IUserObjectCache, IUserObjectValueType } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { IUserObject, UserObject } from '@sigmail/objects';

const isUserObjectLike = (obj: any) => Utils.isNonArrayObjectLike(obj) && UserObject.isValidId(obj.id);

function equals(obj1: any, obj2: any): boolean {
  if (!isUserObjectLike(obj1)) return false;
  if (!isUserObjectLike(obj2)) return false;

  // consider two entries to be equals if their IDs are equal
  return obj1 === obj2 || obj1.id === obj2.id;
}

export class Cache implements IUserObjectCache {
  // private readonly INDEX = new WeakMap<IUserObject<any>, number>();
  private readonly DATA: Array<[IUserObject<any>, any]> = [];

  public dumpToConsoleLog(): void {
    if (process.env.REACT_APP_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(this.DATA);
    }
  }

  public add<T extends IUserObject<any>>(object: T, decryptedValue: IUserObjectValueType<T>): void {
    const index = this.findIndex(object);
    if (typeof index !== 'undefined') {
      // this.INDEX.delete(this.DATA[index][0]);
      this.DATA[index][0] = object;
      this.DATA[index][1] = decryptedValue;
    } else {
      // const length = this.DATA.push([object, decryptedValue]);
      // this.INDEX.set(object, length - 1);
      this.DATA.push([object, decryptedValue]);
    }
  }

  public clear(): void {
    // this.DATA.forEach(([obj]) => this.INDEX.delete(obj));
    this.DATA.length = 0;
  }

  public delete(object: number | IUserObject<any> | undefined): void {
    const index = this.findIndex(object);
    if (typeof index !== 'undefined') {
      // this.INDEX.delete(this.DATA[index][0]);
      this.DATA.splice(index, 1);
    }
  }

  public find<T extends IUserObject<any>>(object: number | T | undefined): [T, IUserObjectValueType<T>] | undefined {
    const index = this.findIndex(object);
    if (typeof index !== 'undefined') {
      const entry = this.DATA[index];
      // NOTE: create a new tuple and return it
      return [entry[0], entry[1]] as [T, IUserObjectValueType<T>];
    }
    return undefined;
  }

  public findIndex(object: number | IUserObject<any> | undefined): number | undefined {
    let index: number | undefined = undefined;
    // let addToIndex: boolean = false;

    if (UserObject.isValidId(object)) {
      index = this.DATA.findIndex(([obj]) => obj.id === object);
    } else {
      // index = this.INDEX.get(object);
      // if (typeof index === 'undefined') {
      index = this.DATA.findIndex(([obj]) => equals(obj, object));
      //   addToIndex = true;
      // }
    }

    if (typeof index === 'number' && index >= 0 && index < this.DATA.length) {
      // if (addToIndex) {
      //   this.INDEX.set(this.DATA[index][0], index);
      // }
      return index;
    }

    return undefined;
  }

  public getValue<T extends IUserObject<any>>(object: number | T | undefined): IUserObjectValueType<T> | undefined;
  public getValue<T extends IUserObject<any>>(
    object: number | T | undefined,
    notSetValue: IUserObjectValueType<T>
  ): IUserObjectValueType<T>;
  public getValue<T extends IUserObject<any>>(
    object: number | T | undefined,
    notSetValue?: IUserObjectValueType<T>
  ): IUserObjectValueType<T> | undefined {
    const entry = this.find(object);
    return entry?.[1] || notSetValue;
  }

  public has(object: number | IUserObject<any> | undefined): boolean {
    const index = this.findIndex(object);
    return typeof index !== 'undefined';
  }
}

export const UserObjectCache: IUserObjectCache = new Cache();
