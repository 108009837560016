import { AlgorithmCode, AppException, Constants, EncapsulatedKey, Utils } from '@sigmail/common';
import { Algorithm, Constants as CryptoConstants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import { ICryptographicKeyEncapsulated, ISigmailObject, ValueFormatVersion } from '@sigmail/objects';
import { CryptographicKey } from '.';

const ErrorConstants = { ...Constants.Error, ...CryptoConstants.Error };

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_ENCAPSULATED;

export class CryptographicKeyEncapsulated
  extends CryptographicKey<EncapsulatedKey>
  implements ICryptographicKeyEncapsulated {
  /** @override */
  protected static get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_ENCAPSULATED_KEY;
  }

  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  public static isAssignableFrom(obj: any): obj is ICryptographicKeyEncapsulated {
    return super.isAssignableFrom(obj) === true && typeof obj.encryptFor === 'function';
  }

  public static async createForObject<DV extends ValueFormatVersion>(
    obj: ISigmailObject<DV>,
    encryptedFor: number,
    createdAtUtc: Date
  ): Promise<CryptographicKeyEncapsulated | null> {
    const { id: keyId, code: objectCode } = obj;

    if (Algorithm.isValidNonEncryptedObjectCode(objectCode)) return null;
    if (!Algorithm.isValidEncryptObjectCode(objectCode))
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    if (!this.isValidId(encryptedFor))
      throw new AppException(ErrorConstants.S_ERROR, 'Invalid ID. (Parameter: encryptedFor)');

    const algorithm = getAlgorithm(objectCode);
    const key = await algorithm.generateKey();
    return this.create(keyId, undefined, 0, key, encryptedFor, createdAtUtc);
  }

  public static async create(
    id: number,
    code: AlgorithmCode | undefined,
    version: number,
    key: EncapsulatedKey,
    encryptedFor: number,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  ): Promise<CryptographicKeyEncapsulated> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    const value = await this.encryptEncapsulatedKey(key, { encryptedFor, keyCode, keyVersion: version });
    const args = [id, keyCode, version, value, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args);
  }

  public static async encryptFor(key: null, id: number): Promise<null>;
  // prettier-ignore
  public static async encryptFor(key: ICryptographicKeyEncapsulated, id: number): Promise<ICryptographicKeyEncapsulated>;
  public static async encryptFor(key: any, id: number): Promise<ICryptographicKeyEncapsulated | null> {
    if (key === null) return null;

    const { id: keyId, createdAtUtc } = key;
    const encapsulatedKey = await key.decryptedValue();
    return this.create(keyId, undefined, 0, encapsulatedKey, id, createdAtUtc);
  }

  public encryptFor(id: number): Promise<ICryptographicKeyEncapsulated> {
    const Class = this.constructor as typeof CryptographicKeyEncapsulated;
    return Class.encryptFor(this, id);
  }

  public decryptedValue(): Promise<EncapsulatedKey> {
    if (!Algorithm.isValidEncryptEncapsulatedKeyCode(this.code)) {
      throw new SigmailCryptoException(ErrorConstants.E_UNKNOWN_ALGORITHM_CODE);
    }
    const algorithm = getAlgorithm(this.code);
    const Class = this.constructor as typeof CryptographicKeyEncapsulated;
    const cryptoKey = Class.getPrivateKey(this.encryptedForId);
    return algorithm.decrypt({ privateKey: cryptoKey }, this.value, this.version);
  }
}
