import { IAppUserGroup, IAppUserOrUserGroup } from '@sigmail/common';
import { AppException } from './app-exception';
import { AppUserOrUserGroup } from './app-user-or-group';
import { E_INVALID_GROUP_TYPE } from './constants/error';
import { every, has, hashString, isString, partial } from './utils';

const OWN_PROPS: ReadonlyArray<keyof Omit<IAppUserGroup, keyof IAppUserOrUserGroup>> = ['groupType'];

export class AppUserGroup extends AppUserOrUserGroup<'group'> implements IAppUserGroup {
  /** @override */
  public static isValidType(value: any): value is 'group' {
    return value === 'group';
  }

  public static isValidGroupType(value: any): value is string {
    return isString(value) && value.trim().length > 0;
  }

  public static isAssignableFrom(obj: any): obj is IAppUserGroup {
    return (
      super.isAssignableFrom(obj) === true &&
      every(OWN_PROPS, partial(has, obj)) &&
      this.isValidGroupType(obj.groupType)
    );
  }

  public readonly groupType: string;

  public constructor(id: number, groupType: string);
  public constructor(...args: any[]);
  public constructor(...args: any[]) {
    super(...(args.length === 1 ? args : ['group', ...args]));

    const Class = this.constructor as typeof AppUserGroup;

    const groupType = args.length === 1 ? args[0].groupType : args[1];
    if (!Class.isValidGroupType(groupType)) throw new AppException(E_INVALID_GROUP_TYPE);

    this.groupType = groupType.trim();
  }

  /** @override */
  public equals(other: any): other is IAppUserGroup {
    return super.equals(other) === true && this.groupType === other.groupType;
  }

  /** @override */
  public hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + hashString(this.groupType)) | 0;
    return hashed;
  }
}
