import { AlgorithmCode, AppException, AppUserOrUserGroup, Constants, Utils } from '@sigmail/common';
import { Algorithm } from '@sigmail/crypto';
import {
  ApiFormattedCryptographicKey,
  ApiFormattedNotificationObject,
  ApiFormattedVersionedSigmailObject,
  ICryptographicKeyEncapsulated,
  INotificationObject,
  IVersionedSigmailObject,
  NotificationObjectValueType,
  ValueFormatVersion
} from '@sigmail/objects';
import { CryptographicKeyEncapsulated } from '../cryptographic-key/encapsulated-key';
import { VersionedSigmailObject } from '../versioned-sigmail-object';

const PROPS: ReadonlyArray<
  keyof Omit<
    INotificationObject<any>,
    keyof IVersionedSigmailObject<any> | 'generateKeysEncryptedFor' | 'decryptedValue'
  >
> = ['value', 'userId', 'sendingUserId', 'createdAtUtc', 'expiredAtUtc'];

const API_FORMATTED_PROPS: ReadonlyArray<
  keyof Omit<ApiFormattedNotificationObject, keyof ApiFormattedVersionedSigmailObject>
> = ['userId', 'sendingUserId', 'key', 'value', 'createdAtUtc', 'expiredAtUtc'];

/**
 * @TODO document
 *
 * @template DV Type of decrypted value.
 */
export abstract class NotificationObject<DV extends ValueFormatVersion>
  extends VersionedSigmailObject<DV>
  implements INotificationObject<DV> {
  /** @override */
  protected static get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT;
  }

  /** @override */
  public static isValidAlgorithmCode(value: any): value is AlgorithmCode {
    return Algorithm.isValidNonEncryptedObjectCode(value);
  }

  /** @override */
  public static isValidVersion(value: any): value is number {
    return value === 0;
  }

  /** Determines if the given value is a valid encrypted value. */
  public static isValidEncryptedValue(value: any): value is string {
    return Utils.isString(value);
  }

  /**
   * Override this method in derived classes to provide a correct/more specific
   * implementation of validation checks for the decrypted value of an object.
   */
  protected static isValidDecryptedValue(value: any): boolean {
    if (!Utils.isPlainObject(value)) return false;

    const { $$formatver } = value;
    return Utils.isUndefined($$formatver) || (Utils.isInteger($$formatver) && $$formatver > 0);
  }

  /** Determines if the given value is a valid creation date. */
  public static isValidCreationDate(value: any): value is Date {
    return Utils.isValidDate(value);
  }

  /**
   * Determines if the given value is a valid expiry date. `null` is considered
   * a valid value.
   */
  public static isValidExpiryDate(value: any): value is Date | null {
    return value === null || Utils.isValidDate(value);
  }

  protected static isKeyAssignableFrom(key: any): key is ICryptographicKeyEncapsulated {
    return CryptographicKeyEncapsulated.isAssignableFrom(key);
  }

  protected static isKeyApiFormatted(key: any): key is ApiFormattedCryptographicKey {
    return CryptographicKeyEncapsulated.isApiFormatted(key);
  }

  protected static keyFromApiFormatted(key: any): ICryptographicKeyEncapsulated {
    return new CryptographicKeyEncapsulated(key);
  }

  /** @override */
  public static isAssignableFrom(obj: any): obj is INotificationObject<any> {
    return (
      super.isAssignableFrom(obj) === true &&
      Utils.every(PROPS, Utils.partial(Utils.has, obj)) &&
      this.isValidEncryptedValue(obj.value) &&
      AppUserOrUserGroup.isValidId(obj.userId) &&
      AppUserOrUserGroup.isValidId(obj.sendingUserId) &&
      this.isValidCreationDate(obj.createdAtUtc) &&
      this.isValidExpiryDate(obj.expiredAtUtc) &&
      (obj[Constants.$$CryptographicKey] === null || this.isKeyAssignableFrom(obj[Constants.$$CryptographicKey])) &&
      typeof obj.generateKeysEncryptedFor === 'function' &&
      typeof obj.decryptedValue === 'function'
    );
  }

  /** @override */
  public static isApiFormatted(obj: any): obj is ApiFormattedNotificationObject {
    return (
      super.isApiFormatted(obj) === true &&
      Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, obj)) &&
      Utils.isString(obj.value) &&
      Utils.isNumber(obj.userId) &&
      Utils.isNumber(obj.sendingUserId) &&
      Utils.isString(obj.createdAtUtc) &&
      (obj.expiredAtUtc === null || Utils.isString(obj.expiredAtUtc)) &&
      (obj.key === null || this.isKeyApiFormatted(obj.key))
    );
  }

  public static async create<T extends NotificationObject<ValueFormatVersion>>(
    this: new (...args: any[]) => T,
    id: number,
    code: AlgorithmCode | undefined,
    version: number,
    value: NotificationObjectValueType<T>,
    userId: number,
    sendingUserId: number,
    encryptedFor: number,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  ): Promise<T> {
    const Class = (this as unknown) as typeof NotificationObject;
    if (Class === NotificationObject) throw new TypeError('Illegal method invocation.');

    const objectCode = Utils.isUndefined(code) ? Class.DEFAULT_CODE : code;
    const key = await CryptographicKeyEncapsulated.createForObject(
      { type: Class.TYPE, id, code: objectCode, version } as NotificationObject<ValueFormatVersion>,
      encryptedFor,
      createdAtUtc
    );
    const encryptedValue = await Class.encryptObjectValue(value, { key, objectCode, objectVersion: version });
    const args = [id, objectCode, version, encryptedValue, userId, sendingUserId, key, createdAtUtc, expiredAtUtc];
    return Reflect.construct(Class, args);
  }

  public readonly value: string;
  public readonly userId: number;
  public readonly sendingUserId: number;
  public readonly [Constants.$$CryptographicKey]: ICryptographicKeyEncapsulated | null;
  public readonly createdAtUtc: Date;
  public readonly expiredAtUtc: Date | null;

  public constructor(
    id: number,
    code: AlgorithmCode | undefined,
    version: number,
    value: string,
    userId: number,
    sendingUserId: number,
    key: ICryptographicKeyEncapsulated | null,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  );

  public constructor(obj: ApiFormattedNotificationObject);

  public constructor(...args: any[]) {
    super(...(args.length === 1 ? args : [undefined, ...args]));

    const Class = this.constructor as typeof NotificationObject;
    if (Class === NotificationObject) throw new TypeError('Initialization error.');

    const value = args.length === 1 ? args[0].value : args[3];
    const userId = args.length === 1 ? args[0].userId : args[4];
    const sendingUserId = args.length === 1 ? args[0].sendingUserId : args[5];
    const apiFormattedKey = args.length === 1 ? args[0].key : args[6];
    const createdAtUtc = args.length === 1 ? new Date(args[0].createdAtUtc) : args[7];
    const expiredAtUtc = args.length === 1 ? args[0].expiredAtUtc : args[8];
    const expiryDate = Utils.isNil(expiredAtUtc) ? null : new Date(expiredAtUtc);

    const key =
      apiFormattedKey === null
        ? null
        : Class.isKeyAssignableFrom(apiFormattedKey)
        ? apiFormattedKey
        : Class.keyFromApiFormatted(apiFormattedKey);

    if (!Class.isValidEncryptedValue(value)) throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE);
    if (!AppUserOrUserGroup.isValidId(userId)) throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
    if (!AppUserOrUserGroup.isValidId(sendingUserId))
      throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
    if (!Class.isValidCreationDate(createdAtUtc)) throw new AppException(Constants.Error.E_INVALID_CREATION_DATE);
    if (!Class.isValidExpiryDate(expiryDate)) throw new AppException(Constants.Error.E_INVALID_EXPIRY_DATE);

    this.value = value;
    this.userId = userId;
    this.sendingUserId = sendingUserId;
    this[Constants.$$CryptographicKey] = key;
    this.createdAtUtc = createdAtUtc;
    this.expiredAtUtc = expiryDate;
  }

  /** @override */
  public equals(other: any): other is INotificationObject<DV> {
    return super.equals(other) === true && this.userId === other.userId && this.sendingUserId === other.sendingUserId;
  }

  /** @override */
  public hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.userId)) | 0;
    hashed = (31 * hashed + Utils.hashNumber(this.sendingUserId)) | 0;
    return hashed;
  }

  public async generateKeysEncryptedFor(...ids: number[]): Promise<Array<ICryptographicKeyEncapsulated | null>> {
    const keys = new Array<ICryptographicKeyEncapsulated | null>(ids.length);
    if (ids.length > 0) {
      keys.fill(null);
      if (!Algorithm.isValidNonEncryptedObjectCode(this.code)) {
        for (let index = 0; index < ids.length; index++) {
          const key = await this[Constants.$$CryptographicKey]!.encryptFor(ids[index]);
          keys[index] = key;
        }
      }
    }
    return keys;
  }

  public async decryptedValue(): Promise<DV> {
    const Class = this.constructor as typeof NotificationObject;

    const value = await Class.decryptObjectValue<DV>(this.value, {
      key: this[Constants.$$CryptographicKey],
      objectCode: this.code,
      objectVersion: this.version
    });

    if (!Class.isValidDecryptedValue(value)) {
      const errorMessage = [
        'Decrypted value failed the validation check.',
        `type=${this.type}`,
        `id=${this.id}`,
        `code=${this.code}`,
        `version=${this.version}`,
        `key.type=${this[Constants.$$CryptographicKey]?.type}`,
        `key.code=${this[Constants.$$CryptographicKey]?.code}`
      ];
      throw new AppException(
        Constants.Error.E_INVALID_OBJECT_VALUE,
        `${errorMessage[0]} (${errorMessage.slice(1).join(', ')})`
      );
    }

    return value;
  }

  /** @override */
  public toApiFormatted(): ApiFormattedNotificationObject {
    const apiFormatted = super.toApiFormatted();

    const key = this[Constants.$$CryptographicKey];
    let apiFormattedKey: ApiFormattedCryptographicKey | null = null;
    if (key !== null) {
      apiFormattedKey = key.toApiFormatted();
    }

    return {
      ...apiFormatted,
      value: this.value,
      userId: this.userId,
      sendingUserId: this.sendingUserId,
      key: apiFormattedKey,
      createdAtUtc: this.createdAtUtc.toISOString(),
      expiredAtUtc: this.expiredAtUtc === null ? null : this.expiredAtUtc.toISOString()
    };
  }
}
