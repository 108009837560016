import { Utils } from '@sigmail/common';
import { GroupObjectProfileBasicValue as DV } from '@sigmail/objects';
import { GroupObject } from '.';

const TYPE = process.env.GROUP_OBJECT_TYPE_PROFILE_BASIC;

export class GroupObjectProfileBasic extends GroupObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isString(value.name) && Utils.isArray(value.memberList);
  }

  public updateValue(newValue: DV): Promise<GroupObjectProfileBasic> {
    const Class = this.constructor as typeof GroupObjectProfileBasic;
    return Class.updateValue(this, newValue);
  }
}
