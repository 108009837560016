import DateFnsUtils from '@date-io/date-fns';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import { KeyboardDatePicker as DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Utils } from '@sigmail/common';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { maskArray } from 'react-text-mask';
import { BaseFormInputDefinition, BaseFormPureComponent } from '../../../app/shared/base-form-pure-component';
import * as FormInputConstraint from '../../../constants/form-input-constraint';
import * as FormInputMask from '../../../constants/form-input-mask';
import * as FormInputPattern from '../../../constants/form-input-pattern';
import { withTranslation } from '../../../i18n';
import addressLevel1ListI18n from '../../../i18n/address-level1-list';
import {
  I18N_NS_ADDRESS_LEVEL1_LIST,
  I18N_NS_MEDICAL_INSTITUTE_INVITE_GUEST_FORM
} from '../../../i18n/config/namespace-identifiers';
import i18n from '../../../i18n/medical-institute/invite-guest-form';
import * as Context from './invite-guest-form.context';
import style from './invite-guest-form.module.css';

const FORM_INPUT: BaseFormInputDefinition = {
  firstName: {
    i18n: i18n.fieldset.default.field.firstName,
    autoComplete: 'off',
    type: 'text',
    required: true,
    minLength: FormInputConstraint.FirstName.minLength,
    maxLength: FormInputConstraint.FirstName.maxLength,
    pattern: FormInputPattern.FirstName
  },
  lastName: {
    i18n: i18n.fieldset.default.field.lastName,
    autoComplete: 'off',
    type: 'text',
    required: true,
    minLength: FormInputConstraint.LastName.minLength,
    maxLength: FormInputConstraint.LastName.maxLength,
    pattern: FormInputPattern.LastName
  },
  birthDate: {
    i18n: i18n.fieldset.default.field.birthDate,
    autoComplete: 'off',
    type: 'text',
    required: true,
    minLength: 10,
    maxLength: 10
  },
  gender: {
    i18n: i18n.fieldset.default.field.gender
  },
  cellNumber: {
    i18n: i18n.fieldset.default.field.cellNumber,
    autoComplete: 'off',
    type: 'text',
    pattern: FormInputPattern.PhoneNumber,
    mask: FormInputMask.PhoneNumber
  },
  homeNumber: {
    i18n: i18n.fieldset.default.field.homeNumber,
    autoComplete: 'off',
    type: 'text',
    pattern: FormInputPattern.PhoneNumber,
    mask: FormInputMask.PhoneNumber
  },
  emailAddress: {
    i18n: i18n.fieldset.default.field.emailAddress,
    autoComplete: 'off',
    type: 'text',
    required: true,
    minLength: FormInputConstraint.EmailAddress.minLength,
    maxLength: FormInputConstraint.EmailAddress.maxLength,
    pattern: FormInputPattern.EmailAddress
  }
};

const FORM_INPUT_HEALTH_PLAN: BaseFormInputDefinition = {
  healthPlanJurisdiction: {
    i18n: i18n.fieldset.healthPlan.field.jurisdiction,
    required: true
  },
  healthPlanNumber: {
    i18n: i18n.fieldset.healthPlan.field.planNumber,
    autoComplete: 'off',
    type: 'text',
    required: true
  }
};

const HEALTH_PLAN_NUMBER_PLACEHOLDER: Readonly<Record<string, Readonly<Record<string, string | undefined>>>> = {
  CAN: {
    AB: '111-111-111-11',
    BC: '9111-111-111',
    MB: '111-111-111',
    NB: '111-111-111',
    NL: '111-111-111-111',
    NS: '1111-111-111',
    NT: 'X1111111',
    NU: '111111111',
    ON: '1111-111-111-XX',
    PE: '111111111',
    QC: 'XXXX-1111-1111',
    SK: '111-111-111',
    YT: '111-111-111'
  }
};

export interface Props extends WithTranslation {
  innerRef?: React.Ref<HTMLFormElement>;
  onFormSubmit?: React.FormEventHandler<HTMLFormElement>;
}

class InviteGuestFormComponent extends BaseFormPureComponent<Props> {
  public static contextType = Context.Context;
  context!: React.ContextType<typeof Context.Context>;

  public constructor(props: Props) {
    super(props, FORM_INPUT);

    this.formInputDef = {
      ...this.formInputDef,
      birthDate: {
        ...this.formInputDef.birthDate,
        render: this.renderBirthDatePicker.bind(this)
      },
      gender: {
        ...FORM_INPUT.gender,
        render: this.renderGenderInput.bind(this)
      }
    };

    this.renderHealthPlanJurisdictionInput = this.renderHealthPlanJurisdictionInput.bind(this);
    this.onBirthDateInputChange = this.onBirthDateInputChange.bind(this);
    this.onGenderChange = this.onGenderChange.bind(this);
    this.onHealthPlanJurisdictionChange = this.onHealthPlanJurisdictionChange.bind(this);
    this.getHealthPlanNumberMask = this.getHealthPlanNumberMask.bind(this);
  }

  protected getState(): Context.ContextValue {
    return this.context;
  }

  public render(): React.ReactNode {
    const classNameForm = [style.form, this.formHasErrors && 'was-validated'];

    if (Utils.isString(this.getState().accessCode)) {
      return (
        <React.Fragment>
          <div styleName="style.heading-success">
            <CheckOutlinedIcon />
            {this.props.t(i18n.submitSuccessDialog.title, {
              FIRST_NAME: this.getState().firstName,
              LAST_NAME: this.getState().lastName
            })}
          </div>
          <div styleName="style.access-code-label">{this.props.t(i18n.submitSuccessDialog.accessCodeLabel)}</div>
          <div styleName="style.access-code">{this.getState().accessCode}</div>
        </React.Fragment>
      );
    }

    const healthPlanNumberPattern = this.getHealthPlanNumberPattern();
    const formInputHealthPlan: typeof FORM_INPUT_HEALTH_PLAN = {
      ...FORM_INPUT_HEALTH_PLAN,
      healthPlanJurisdiction: {
        ...FORM_INPUT_HEALTH_PLAN.healthPlanJurisdiction,
        render: this.renderHealthPlanJurisdictionInput.bind(this)
      },
      healthPlanNumber: {
        ...FORM_INPUT_HEALTH_PLAN.healthPlanNumber,
        placeholder: this.getHealthPlanNumberPlaceholder(),
        required: Utils.isString(healthPlanNumberPattern),
        pattern: Utils.isString(healthPlanNumberPattern)
          ? healthPlanNumberPattern
          : FormInputPattern.NothingOrNonWhitespaceLoose,
        mask: this.getHealthPlanNumberMask
      }
    };

    return (
      <form
        ref={this.props.innerRef}
        className={classNameForm.filter(Boolean).join(' ')}
        onSubmit={this.props.onFormSubmit}
      >
        <div className="container">
          <fieldset className="row">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {Object.keys(this.formInputDef).map((inputId) => (
                <div
                  key={inputId}
                  styleName="style.form-input-group"
                  className={inputId === 'emailAddress' ? 'email' : undefined}
                >
                  {this.renderFormInput(inputId)}
                </div>
              ))}
            </MuiPickersUtilsProvider>
          </fieldset>

          <fieldset styleName="style.fieldset-health-plan">
            <legend>{this.props.t(i18n.fieldset.healthPlan.legend)}</legend>
            <div>
              {Object.keys(formInputHealthPlan).map((inputId) => (
                <div key={inputId} styleName="style.form-input-group">
                  {this.renderFormInput(inputId, formInputHealthPlan)}
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <input type="submit" style={{ display: 'none' }} />
      </form>
    );
  }

  private renderBirthDatePicker(): React.ReactNode {
    const { birthDate, inputErrorMessage } = this.getState();

    let errorMessage: Context.ContextValue['inputErrorMessage']['birthDate'] = undefined;
    let hasError = false;
    if (this.formHasErrors) {
      errorMessage = inputErrorMessage.birthDate;
      if (Utils.isString(errorMessage)) {
        errorMessage = this.props.t(errorMessage);
        hasError = true;
      }
    }

    return (
      <DatePicker
        id="birthDate"
        variant="inline"
        inputVariant="outlined"
        autoOk={true}
        format="yyyy-MM-dd"
        margin="none"
        openTo="year"
        disabled={this.submitInProgress}
        disableFuture={true}
        disableToolbar={true}
        label={this.props.t(i18n.fieldset.default.field.birthDate.label)}
        value={birthDate}
        onChange={this.onBirthDateInputChange}
        error={hasError}
        helperText={hasError ? errorMessage : undefined}
        // KeyboardButtonProps={{ 'aria-label': 'change date' }}
        InputLabelProps={{ shrink: birthDate !== null }}
      />
    );
  }

  private renderGenderInput(): React.ReactNode {
    const label = this.props.t(i18n.fieldset.default.field.gender.label);

    let errorMessage = this.getState().inputErrorMessage.gender;
    if (Utils.isString(errorMessage)) {
      errorMessage = this.props.t(errorMessage);
    }

    const hasError = this.formHasErrors && Utils.isString(errorMessage);

    return (
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel id="label-gender">{label}</InputLabel>
        <Select
          labelId="label-gender"
          id="gender"
          disabled={this.submitInProgress}
          value={this.getState().gender}
          onChange={this.onGenderChange}
          label={label}
          error={hasError}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            transformOrigin: { horizontal: 'right', vertical: 'top' },
            style: { maxHeight: '320px' }
          }}
        >
          {i18n.fieldset.default.field.gender.options.map(({ code, label }) => (
            <MenuItem key={code} value={code}>
              {this.props.t(label)}
            </MenuItem>
          ))}
        </Select>

        {hasError && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  }

  private renderHealthPlanJurisdictionInput(): React.ReactNode {
    const label = this.props.t(i18n.fieldset.healthPlan.field.jurisdiction.label);

    let errorMessage = this.getState().inputErrorMessage.healthPlanJurisdiction;
    if (Utils.isString(errorMessage)) {
      errorMessage = this.props.t(errorMessage);
    }

    const hasError = this.formHasErrors && Utils.isString(errorMessage);

    return (
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel id="label-health-plan-jurisdiction">{label}</InputLabel>
        <Select
          labelId="label-health-plan-jurisdiction"
          id="health-plan-jurisdiction"
          disabled={this.submitInProgress}
          value={this.getState().healthPlanJurisdiction}
          onChange={this.onHealthPlanJurisdictionChange}
          label={label}
          error={hasError}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            transformOrigin: { horizontal: 'right', vertical: 'top' },
            style: { maxHeight: '320px' }
          }}
        >
          {addressLevel1ListI18n.CAN.map(({ code, name }) => (
            <MenuItem key={`CAN$${code}`} value={`CAN$${code}`}>
              {this.props.t(name)}
            </MenuItem>
          ))}
          {i18n.fieldset.healthPlan.field.jurisdiction.options.map(({ code, label }) => (
            <MenuItem key={code} value={code}>
              {this.props.t(label)}
            </MenuItem>
          ))}
        </Select>

        {hasError && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  }

  protected updateInputErrorMessage(inputId: string, errorMessage: string | null | undefined) {
    if (Utils.isString(errorMessage)) {
      switch (inputId) {
        case 'firstName':
        case 'lastName':
        case 'emailAddress':
          errorMessage = this.props.t(errorMessage, {
            MIN_LENGTH: FORM_INPUT[inputId].minLength,
            MAX_LENGTH: FORM_INPUT[inputId].maxLength
          });
          break;
        default:
          break;
      }
    }

    this.getState().dispatch(Context.updateInputErrorMessage({ [inputId]: errorMessage }));
  }

  protected updateInputValue(inputId: string, value: string) {
    if (inputId === 'healthPlanNumber') {
      value = value.toUpperCase();
    }

    this.getState().dispatch(Context.updateFormData({ [inputId]: value }));
  }

  private onBirthDateInputChange(value: Date | null): void {
    this.getState().dispatch(Context.updateFormData({ birthDate: value }));
  }

  private onGenderChange(event: any): void {
    this.updateInputValue('gender', event.target.value);
  }

  private onHealthPlanJurisdictionChange(event: any): void {
    this.updateInputValue('healthPlanJurisdiction', event.target.value);
  }

  private getHealthPlanNumberPattern(): string | undefined {
    const { healthPlanJurisdiction: jurisdiction } = this.getState();
    if (Object.keys(FormInputPattern.HealthPlanNumber).some((key) => jurisdiction.startsWith(key))) {
      const [countryCode, stateCode] = jurisdiction.split('$', 2);
      const pattern = FormInputPattern.HealthPlanNumber[countryCode][stateCode];
      if (Utils.isString(pattern)) return pattern;
    }
    return undefined;
  }

  private getHealthPlanNumberPlaceholder(): string | undefined {
    const { healthPlanJurisdiction: jurisdiction } = this.getState();
    if (Object.keys(HEALTH_PLAN_NUMBER_PLACEHOLDER).some((key) => jurisdiction.startsWith(key))) {
      const [countryCode, stateCode] = jurisdiction.split('$', 2);
      const placeholder = HEALTH_PLAN_NUMBER_PLACEHOLDER[countryCode][stateCode];
      if (Utils.isString(placeholder)) return placeholder;
    }
    return undefined;
  }

  private getHealthPlanNumberMask(): maskArray {
    const { healthPlanJurisdiction: jurisdiction } = this.getState();
    if (Object.keys(FormInputMask.HealthPlanNumber).some((key) => jurisdiction.startsWith(key))) {
      const [countryCode, stateCode] = jurisdiction.split('$', 2);
      const mask = FormInputMask.HealthPlanNumber[countryCode][stateCode];
      if (Utils.isArray(mask)) return mask;
    }
    return false;
  }
}

const ns = [I18N_NS_MEDICAL_INSTITUTE_INVITE_GUEST_FORM, I18N_NS_ADDRESS_LEVEL1_LIST];
export const InviteGuestForm = withTranslation(ns)(InviteGuestFormComponent);
InviteGuestForm.displayName = 'InviteGuestForm';
