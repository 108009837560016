export const InstituteName = { minLength: 4, maxLength: 40 };

export const FirstName = { minLength: 2, maxLength: 40 };
export const LastName = { minLength: 2, maxLength: 40 };
export const EmailAddress = { minLength: 5, maxLength: 60 };

export const Username = { minLength: 8, maxLength: 64 };

// ==
// IMPORTANT:
// any change in min and max length here must be kept in sync with the
// regular expression defined in `./form-input-pattern.ts`.
// ==
export const Password = { minLength: 12, maxLength: 64 };
// ==

export const GroupName = { minLength: 1, maxLength: 64 };

export const GuestContactGroupName = { minLength: 2, maxLength: 128 };
