import { ActionPayloadGetIdGenerator } from '@sigmail/app-state';
import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { AppThunk } from '..';

export const getIdGeneratorAction = ({ accessToken, count }: ActionPayloadGetIdGenerator): AppThunk<Promise<Generator<number, number>>> => {
  return async (_D, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'getIdGeneratorAction:');

    Logger.info('== BEGIN ==');
    try {
      const apiAccessToken = Utils.isString(accessToken) ? accessToken : getState().auth.accessToken;
      const idList = await apiService.getIds(apiAccessToken, count);
      return Utils.makeSequence(idList);
    } finally {
      Logger.info('== END ==');
    }
  };
};
