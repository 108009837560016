const PATTERN_HEX_CHAR = '[a-fA-F0-9]';

export function uint8ArrayFromHex(value: string): Uint8Array {
  if (typeof value !== 'string') {
    throw new TypeError('Argument <value> must be of type string.');
  }

  const trimmedValue = value.trim();
  if (trimmedValue.length % 2 !== 0) {
    throw new Error("Value has an invalid length i.e. it's not divisible by 2");
  }

  if (!RegExp(`^${PATTERN_HEX_CHAR}+$`).test(value)) {
    throw new Error('Value must be encoded in hexadecimal format.');
  }

  const byteArray: string[] = value.match(RegExp(`${PATTERN_HEX_CHAR}{2}`, 'g')) || [];
  return new Uint8Array(byteArray.map((byte) => parseInt(byte, 16)));
}
