import { Utils } from '@sigmail/common';
import React from 'react';
import { WithTranslation } from 'react-i18next';
import { Route as ReactRouterRoute, RouteProps as ReactRouterRouteProps } from 'react-router';
import { RouteProps } from 'sigmail';
import { withTranslation } from '../../i18n';
import { I18N_NS_DOCUMENT_TITLE, I18N_NS_GLOBAL } from '../../i18n/config/namespace-identifiers';
import i18n from '../../i18n/document-title';
import globalI18n from '../../i18n/global';

export interface Props extends RouteProps, WithTranslation {}

const PROPS_TO_OMIT: ReadonlyArray<keyof Omit<Props, keyof ReactRouterRouteProps>> = [
  // RouteProps
  'routeId',
  'actionId',
  'childRoutes',

  // WithTranslation
  't',
  'tReady',
  'i18n'
];

class RouteComponent extends React.Component<Props, any> {
  public componentDidMount(): void {
    const { t, routeId } = this.props;

    const title = t(i18n[routeId] || '').trim();
    if (title.length === 0) {
      document.title = t(globalI18n.productName);
      return;
    }

    const prefix = t(i18n.prefix).trim();
    const suffix = t(i18n.suffix).trim();
    const docTitle = [prefix, title, suffix].filter(Boolean).join(' ');
    document.title = docTitle;
  }

  public render(): React.ReactNode {
    const routeProps = Utils.omit(this.props, PROPS_TO_OMIT);
    return <ReactRouterRoute {...routeProps} />;
  }
}

export const Route = withTranslation([I18N_NS_GLOBAL, I18N_NS_DOCUMENT_TITLE])(RouteComponent);
Route.displayName = 'Route';
