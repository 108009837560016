import { Utils } from '@sigmail/common';
import { UserObjectServerRightsValue as DV } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_SERVER_RIGHTS;

export class UserObjectServerRights extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    return super.isValidDecryptedValue(value) && Utils.isValidJwtToken(value.userClaim, 'id');
  }

  public updateValue(newValue: DV): Promise<UserObjectServerRights> {
    const Class = this.constructor as typeof UserObjectServerRights;
    return Class.updateValue(this, newValue);
  }
}
