import { Utils } from '@sigmail/common';
import cryptoJs from 'crypto-js';

/**
 * @param count Number of 8-bit unsigned integer values to generate
 */
export function getRandomBytes(count: number): Uint8Array;
export function getRandomBytes(count: number, encoding: 'hex' | 'base64'): string;
export function getRandomBytes(count: number, encoding?: 'hex' | 'base64'): Uint8Array | string {
  const crypto = window.crypto || (window as any).msCrypto;
  if (
    !Utils.isNil(crypto) &&
    Utils.isObject(crypto) &&
    !Utils.isArray(crypto) &&
    typeof crypto.getRandomValues === 'function'
  ) {
    try {
      const byteArray = crypto.getRandomValues(new Uint8Array(count));
      const enc = (encoding === 'hex' || encoding === 'base64') && encoding;
      if (enc) {
        const encoder = enc === 'hex' ? cryptoJs.enc.Hex : cryptoJs.enc.Base64;
        return encoder.stringify(cryptoJs.lib.WordArray.create(byteArray));
      }
      return byteArray;
    } catch {
      /** ignore */
    }
  }
  throw new Error('Native crypto module could not be used to get secure random bytes.');
}
