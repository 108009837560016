import { Utils } from '@sigmail/common';
import { UserObjectContactInfoValue as DV, UserObjectContactInfoValue_v2 } from '@sigmail/objects';
import { UserObject } from '.';

const TYPE = process.env.USER_OBJECT_TYPE_CONTACT_INFO;

export class UserObjectContactInfo extends UserObject<DV> {
  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  /** @override */
  protected static isValidDecryptedValue(value: any): value is DV {
    if (!super.isValidDecryptedValue(value)) return false;

    let result =
      Utils.isString(value.firstName) && Utils.isString(value.lastName) && Utils.isString(value.emailAddress);
    if (result && !Utils.isUndefined(value.$$formatver) && value.$$formatver >= 2) {
      const { role: roleId } = value as UserObjectContactInfoValue_v2;
      if (Utils.MedicalInstitute.isGuestRole(roleId)) {
        result = Utils.isString(value.healthCardNumber);
      } else if (Utils.MedicalInstitute.isPhysicianRole(roleId) || Utils.MedicalInstitute.isStaffRole(roleId)) {
        result = Utils.isString(value.specialty) && Utils.isString(value.groupName);
      } else {
        result = false;
      }
    }

    return result;
  }

  public updateValue(newValue: DV): Promise<UserObjectContactInfo> {
    const Class = this.constructor as typeof UserObjectContactInfo;
    return Class.updateValue(this, newValue);
  }
}
