export * from './base-http-service';
export * from './http-service';
export * from './service-exception';
import * as ErrorCode from './constants/error';

export const Constants = {
  Error: {
    ...ErrorCode
  }
};
