import {
  LICENSE_TYPE_GUEST,
  LICENSE_TYPE_PRO,
  LICENSE_TYPE_STANDARD
} from '../../constants/medical-institute/license-type-identifier';
import {
  ROLE_ID_ADMIN_PHYSICIAN,
  ROLE_ID_ADMIN_STAFF,
  ROLE_ID_GUEST,
  ROLE_ID_JUNIOR_PHYSICIAN,
  ROLE_ID_SENIOR_PHYSICIAN,
  ROLE_ID_STAFF
} from '../../constants/medical-institute/role-identifier';

export const isAdminPhysicianRole = (roleId: any): roleId is string => roleId === ROLE_ID_ADMIN_PHYSICIAN;
export const isSeniorPhysicianRole = (roleId: any): roleId is string => roleId === ROLE_ID_SENIOR_PHYSICIAN;
export const isJuniorPhysicianRole = (roleId: any): roleId is string => roleId === ROLE_ID_JUNIOR_PHYSICIAN;
export const isPhysicianRole = (roleId: any): roleId is string =>
  isAdminPhysicianRole(roleId) || isSeniorPhysicianRole(roleId) || isJuniorPhysicianRole(roleId);

export const isAdminStaffRole = (roleId: any): roleId is string => roleId === ROLE_ID_ADMIN_STAFF;
export const isBasicStaffRole = (roleId: any): roleId is string => roleId === ROLE_ID_STAFF;
export const isStaffRole = (roleId: any): roleId is string => isAdminStaffRole(roleId) || isBasicStaffRole(roleId);

export const isGuestRole = (roleId: any): roleId is string => roleId === ROLE_ID_GUEST;
export const isNonGuestRole = (roleId: any): roleId is string => isPhysicianRole(roleId) || isStaffRole(roleId);

export const isProLicense = (licenseType: any): licenseType is string => licenseType === LICENSE_TYPE_PRO;
export const isStandardLicense = (licenseType: any): licenseType is string => licenseType === LICENSE_TYPE_STANDARD;
export const isGuestLicense = (licenseType: any): licenseType is string => licenseType === LICENSE_TYPE_GUEST;

export function getLicenseTypeFromRole(roleId: any): string {
  let licenseType = '';
  if (isGuestRole(roleId)) {
    licenseType = LICENSE_TYPE_GUEST;
  } else if (isPhysicianRole(roleId) || isStaffRole(roleId)) {
    licenseType = LICENSE_TYPE_PRO;
    if (isJuniorPhysicianRole(roleId)) {
      licenseType = LICENSE_TYPE_STANDARD;
    }
  }
  return licenseType;
}

export function maskHealthPlanNumber(planNumber: string): string {
  if (typeof planNumber !== 'string') return '';

  const len = planNumber.length;
  const limit = Math.floor(len / 2);
  const start = Math.ceil(limit / 2);

  // const masked = [...planNumber]
  const masked = planNumber.split('')
    .map((c, index) => (index < start || index > start + limit - 1 ? c : /[\d-]/.test(c) ? 'x' : c))
    .join('');

  return masked;
}
