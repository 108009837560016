import { Utils } from '@sigmail/common';
import { AsymmetricEncryptor, AsymmetricKey } from '@sigmail/crypto';
import { Encryptor } from '..';
import {
  E_FAIL_DECRYPT,
  E_FAIL_ENCRYPT,
  E_FAIL_EXPORT_KEY,
  E_FAIL_GENERATE_KEY,
  E_FAIL_IMPORT_KEY,
  E_INVALID_KEY
} from '../../constants';
import { SigmailCryptoException } from '../../SigmailCryptoException';

export interface Params {
  /** Length, in bits, of the key. */
  keyLength: number;

  /** The public exponent. */
  publicExponent: Uint8Array;

  /** Name of the digest function to use. */
  hash: 'SHA-256' | 'SHA-384' | 'SHA-512';
}

const DEFAULT_PARAMS: Params = {
  keyLength: 2048,
  publicExponent: Uint8Array.from([0x01, 0x00, 0x01]),
  hash: 'SHA-256'
};

/**
 * Define the primitive RSA_OAEP to perform crypto operations related to
 * RSA_OAEP with any size modulo.
 *
 * - Operations are generateKey, encrypt, decrypt
 *
 * @author Kim Birchard <kbirchard@sigmahealthtech.com>
 */
export class RSA_OAEP extends Encryptor implements AsymmetricEncryptor {
  private readonly params: Params;

  public constructor(params?: Partial<Params>) {
    super('RSA-OAEP');

    this.params = Utils.defaults({}, params, DEFAULT_PARAMS);
  }

  public async generateKey(): Promise<AsymmetricKey> {
    try {
      const algo: RsaHashedKeyGenParams = {
        name: this.NAME,
        modulusLength: this.params.keyLength,
        publicExponent: this.params.publicExponent,
        hash: this.params.hash
      };
      const keyPair = (await crypto.subtle.generateKey(algo, /* extractable := */ true, [
        'decrypt',
        'encrypt'
      ])) as CryptoKeyPair;
      const exportedPrivateKey = await this.exportKey(keyPair.privateKey);
      const privateKey = await this.importKey(exportedPrivateKey);
      const exportedPublicKey = await this.exportKey(keyPair.publicKey);
      const publicKey = await this.importKey(exportedPublicKey);
      return { privateKey, publicKey, exportedPrivateKey, exportedPublicKey };
    } catch (error) {
      const exception =
        error instanceof SigmailCryptoException ? error : new SigmailCryptoException(E_FAIL_GENERATE_KEY);
      throw exception;
    }
  }

  public async importKey(key: JsonWebKey): Promise<CryptoKey> {
    try {
      const algo: RsaHashedImportParams = { name: this.NAME, hash: this.params.hash };
      return await crypto.subtle.importKey('jwk', key, algo, /* extractable := */ false, key.key_ops as KeyUsage[]);
    } catch {
      throw new SigmailCryptoException(E_FAIL_IMPORT_KEY);
    }
  }

  public async exportKey(key: CryptoKey): Promise<JsonWebKey> {
    try {
      const exportedKey = await crypto.subtle.exportKey('jwk', key);
      return exportedKey;
    } catch {
      throw new SigmailCryptoException(E_FAIL_EXPORT_KEY);
    }
  }

  public async encrypt(key: AsymmetricKey, data: Uint8Array): Promise<Uint8Array> {
    const { publicKey } = key;
    if (!(publicKey instanceof CryptoKey)) {
      throw new SigmailCryptoException(E_INVALID_KEY, 'Invalid public key.');
    }

    try {
      const algo: RsaOaepParams = { name: this.NAME };
      const encrypted = await crypto.subtle.encrypt(algo, publicKey, data);
      return new Uint8Array(encrypted);
    } catch {
      throw new SigmailCryptoException(E_FAIL_ENCRYPT);
    }
  }

  public async decrypt(key: AsymmetricKey, data: Uint8Array): Promise<Uint8Array> {
    const { privateKey } = key;
    if (!(privateKey instanceof CryptoKey)) {
      throw new SigmailCryptoException(E_INVALID_KEY, 'Invalid private key.');
    }

    try {
      const algo: RsaOaepParams = { name: this.NAME };
      const decrypted = await crypto.subtle.decrypt(algo, privateKey, data);
      return new Uint8Array(decrypted);
    } catch {
      throw new SigmailCryptoException(E_FAIL_DECRYPT);
    }
  }
}
