import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { Api } from '@sigmail/services';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { URL_GET_IDS } from './constants';
import { MalformedResponseException } from './malformed-response-exception';

export async function apiGetIDs(this: Api.Service, accessToken: string, count: number) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiGetIDs:');

  Logger.info('== BEGIN ==');
  try {
    if (!Utils.isInteger(count) || count < 1) {
      return Promise.resolve<number[]>([]);
    }

    const requestUrl = this.baseUrl.coreApi.concat(URL_GET_IDS.replace(/:count\/$/, count.toString(10)));
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    requestHeaders.append('Authorization', `Bearer ${accessToken}`);
    if (this.authKey.coreApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.coreApi);
    }

    Logger.info('Initiating HTTP request.');
    return await this.httpService.get<number[]>(requestUrl, {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<number[]>(response, undefined);
          if (
            Utils.isArray(responseJson) &&
            responseJson.length === count &&
            responseJson.every((value) => Utils.isInteger(value) && value > 0)
          ) {
            return Utils.shuffle(responseJson);
          }
          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
