import { ActionPayloadBatchQueryDataSuccess, Messaging } from '@sigmail/app-state';
import { AppException, Constants, IAppUserGroup, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  DataObject,
  DataObjectMsgFolder,
  DataObjectMsgFolderExt,
  DataObjectMsgFolderExtValue,
  DataObjectMsgFolderValue,
  GroupMessageFolderKey,
  IDataObject,
  UserMessageFolderKey
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { AppThunk } from '../..';
import { MessagingException } from '../../../core/messaging-exception';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import { DataObjectCache } from '../../data-objects-slice/cache';
import * as DataObjectSelectors from '../../selectors/data-object';
import * as GroupObjectSelectors from '../../selectors/group-object';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedAction, AuthenticatedActionState } from '../authenticated-action';
import { GROUP_MESSAGE_FOLDER_PREFIX, MAX_MESSAGE_FOLDER_ITEM_COUNT } from '../constants';
import { AUTH_STATE_CREATE_MESSAGE_FOLDER_EXT } from '../constants/auth-state-identifier';

// Unique collection of all top-level message folder keys
const MESSAGE_FOLDER_KEY_SET: ReadonlySet<string> = new Set<UserMessageFolderKey | GroupMessageFolderKey>([
  'inbox',
  'sent',
  'drafts',
  '$group_inbox'
]);

interface Payload extends Messaging.ActionPayloadCreateMessageFolderExtension {}

interface State extends AuthenticatedActionState {
  dtServer: Date;
  circleOfCareGroup: IAppUserGroup;
  folderId: number;
  ownerId: number;
  auditId: number;
  folderObject: IDataObject<DataObjectMsgFolderValue>;
  folderContents: DataObjectMsgFolderValue;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ActionPayloadBatchQueryDataSuccess;
  batchUpdateAuthState: string;
  idsClaim: string;
  folderExtId: number;
  excessData: DataObjectMsgFolderValue['data'];
}

class CreateMessageFolderExtensionAction extends AuthenticatedAction<Payload, State> {
  protected async onExecute() {
    await this.fetchMessageFolderList();

    this.extractMessageFolderContents();
    if (this.state.folderContents.data.length < MAX_MESSAGE_FOLDER_ITEM_COUNT) {
      this.logger.info('Message folder is not full; no action required.');
      return;
    }

    await this.generateIdSequence();
    await this.generateRequestBody();

    try {
      const { batchUpdateAuthState: authState, idsClaim, requestBody, successPayload } = this.state;
      await this.dispatchBatchUpdateData({ authState, claims: [idsClaim], ...requestBody.build() });

      try {
        await this.dispatchBatchQueryDataSuccess(successPayload);
      } catch (error) {
        this.logger.warn('Error while manually updating app state:', error);
        /* ignore */
      }
    } catch (error) {
      this.logger.warn('Error while creating message folder extension:', error);
      /* ignore */
    }
  }

  private async fetchMessageFolderList(): Promise<void> {
    const { folderKey, parentFolderKey } = this.payload;
    this.logger.info(`Fetching user folder list to find the ID of a message folder <${folderKey}>.`);

    const {
      roleAuthClaim: authState,
      currentUser: { id: userId }
    } = this.state;

    const { serverDateTime } = await this.dispatchFetchObjects({
      authState,
      userObjectsByType: [
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE },
        { userId, type: process.env.USER_OBJECT_TYPE_FOLDER_LIST }
      ]
    });
    this.state.dtServer = this.deserializeServerDateTime(serverDateTime);

    const userMsgFolderMap = UserObjectSelectors.messageFolderMapSelector(this.getRootState())();
    const groupMsgFolderMap = GroupObjectSelectors.messageFolderMapSelector(this.getRootState())();

    let folderId: number | undefined = undefined;
    let isGroupFolder = false;
    if (MESSAGE_FOLDER_KEY_SET.has(folderKey)) {
      isGroupFolder = folderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX);
      const messageFolderMap = isGroupFolder ? groupMsgFolderMap : userMsgFolderMap;
      folderId = messageFolderMap[folderKey]?.id;
    } else if (Utils.isString(parentFolderKey) && MESSAGE_FOLDER_KEY_SET.has(parentFolderKey)) {
      isGroupFolder = parentFolderKey.startsWith(GROUP_MESSAGE_FOLDER_PREFIX);
      const messageFolderMap = isGroupFolder ? groupMsgFolderMap : userMsgFolderMap;
      const msgFolderChildMap = messageFolderMap[parentFolderKey]?.children;
      if (!Utils.isNil(msgFolderChildMap)) {
        folderId = msgFolderChildMap[folderKey]?.id;
      }
    }

    if (isGroupFolder) {
      const group = UserObjectSelectors.circleOfCareGroupSelector(this.getRootState());
      if (Utils.isNil(group)) {
        throw new MessagingException('Circle of care group is either missing or invalid.');
      }
      this.state.circleOfCareGroup = group;
    }

    if (!DataObjectMsgFolder.isValidId(folderId)) {
      throw new MessagingException(
        Constants.Error.E_MESSAGING_FAIL_FOLDER_ID,
        `Folder ID is either missing or invalid. (Folder key = ${folderKey}, Parent folder key = ${parentFolderKey})`
      );
    } else {
      this.state.folderId = folderId;
      await this.dispatchFetchObjects({ authState, dataObjects: { ids: [folderId] } });
    }

    const ownerId = UserObjectSelectors.ownerIdSelector(this.getRootState());
    const auditId = UserObjectSelectors.auditIdSelector(this.getRootState());
    if (!DataObject.isValidId(ownerId) || !DataObject.isValidId(auditId)) {
      throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Owner/audit ID is either missing or invalid.');
    } else {
      this.state.ownerId = ownerId;
      this.state.auditId = auditId;
    }
  }

  private extractMessageFolderContents(): void {
    this.logger.info('Extracting message folder contents.');

    const dataObjectByIdSelector = DataObjectSelectors.dataObjectByIdSelector(this.getRootState());
    const folderObject = dataObjectByIdSelector<DataObjectMsgFolderValue>(this.state.folderId);
    const folderContents = DataObjectCache.getValue(folderObject);
    if (Utils.isNil(folderObject) || Utils.isNil(folderContents)) {
      throw new MessagingException(Constants.Error.E_MESSAGING_FAIL_FOLDER_DATA_INVALID);
    } else {
      this.state.folderObject = folderObject;
      this.state.folderContents = folderContents;
    }
  }

  private async generateIdSequence(): Promise<void> {
    this.logger.info('Generating an ID sequence.');

    const query: Api.GetIdsRequestData = {
      authState: this.state.roleAuthClaim,
      state: AUTH_STATE_CREATE_MESSAGE_FOLDER_EXT,
      ids: {
        ids: [{ type: process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT }]
      }
    };

    const { authState, idsClaim, ids: idRecord } = await this.dispatchFetchIdsByUsage(query);
    this.state.batchUpdateAuthState = authState;
    this.state.idsClaim = idsClaim;
    [this.state.folderExtId] = idRecord[process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT];
  }

  private async generateRequestBody(): Promise<void> {
    this.state.requestBody = new BatchUpdateRequestBuilder();

    this.state.successPayload = {
      request: { dataObjects: { ids: [] } },
      response: { dataObjects: [], serverDateTime: '' }
    };

    await this.addInsertOperationForMessageFolderExt();
    await this.addUpdateOperationForMessageFolder();
  }

  private async addInsertOperationForMessageFolderExt(): Promise<void> {
    this.logger.info('Adding an insert operation to request body for message folder extension.');

    const {
      folderContents,
      circleOfCareGroup,
      currentUser,
      requestBody,
      folderExtId: id,
      ownerId,
      auditId,
      dtServer,
      successPayload
    } = this.state;

    const folderData: Array<DataObjectMsgFolderValue['data'][0]> = [...folderContents.data];
    const value: DataObjectMsgFolderExtValue = { $$formatver: 1, data: folderData, next: folderContents.next };
    this.state.excessData = folderData.splice(0, value.data.length - MAX_MESSAGE_FOLDER_ITEM_COUNT);

    const encryptedFor = Utils.isNotNil(circleOfCareGroup) ? circleOfCareGroup.id : currentUser.id;
    const folderExtObject = await DataObjectMsgFolderExt.create(id, undefined, 1, value, ownerId, encryptedFor, dtServer);
    const keyList = await folderExtObject.generateKeysEncryptedFor(auditId);
    keyList.push(folderExtObject[Constants.$$CryptographicKey]);
    requestBody.insert(folderExtObject);
    requestBody.insert(keyList.filter(Utils.isNotNil));

    successPayload.request.dataObjects!.ids.push(id);
    successPayload.response.dataObjects!.push(folderExtObject.toApiFormatted());
  }

  private async addUpdateOperationForMessageFolder(): Promise<void> {
    this.logger.info('Adding an update operation to request body for message folder.');

    const { folderContents, excessData, folderExtId, folderObject, requestBody, successPayload } = this.state;

    const updatedValue: DataObjectMsgFolderValue = { ...folderContents, data: excessData, next: folderExtId };
    const updatedObject = await folderObject.updateValue(updatedValue);
    requestBody.update(updatedObject);

    successPayload.request.dataObjects!.ids.push(folderObject.id);
    successPayload.response.dataObjects!.push(updatedObject.toApiFormatted());
  }
}

export const createMessageFolderExtensionAction = (payload: Payload): AppThunk<Promise<void>> => {
  return (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'createMessageFolderExtensionAction:');

    const action = new CreateMessageFolderExtensionAction({ payload, dispatch, getState, apiService, logger: Logger });
    return action.execute();
  };
};
