import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { ServiceException as HttpServiceException } from '../http/service-exception';
import { ApiService } from './api-service';
import { URL_AUTHY_REGISTER_ACCOUNT } from './constants';
import { MalformedResponseException } from './malformed-response-exception';

export async function apiAuthyRegisterAccount(
  this: ApiService,
  emailAddress: string,
  phoneNumber: string,
  countryCallingCode: number
) {
  const Logger = getLoggerWithPrefix('ApiService', 'apiAuthyRegisterAccount:');

  Logger.info('== BEGIN ==');
  try {
    const requestUrl = this.baseUrl.authyApi.concat(URL_AUTHY_REGISTER_ACCOUNT);
    const requestHeaders = new Headers();
    requestHeaders.append('Content-Type', 'application/json');
    if (this.authKey.authyApi.length > 0) {
      requestHeaders.append('X-ApiKey', this.authKey.authyApi);
    }
    const requestBody = { email: emailAddress, phoneNumber, countryCode: countryCallingCode };

    Logger.info('Initiating HTTP request.');
    return await this.httpService.post<number>(requestUrl, JSON.stringify(requestBody), {
      headers: requestHeaders,
      cache: 'no-store',

      async responseParser(response) {
        if (response.status === 200) {
          const responseJson = await Utils.tryGetResponseJson<{ [key: string]: number }>(response, undefined);
          if (Utils.isPlainObject(responseJson) && Utils.isFinite(responseJson!.Id) && responseJson!.Id > 0) {
            return responseJson!.Id;
          }
          Logger.warn('Operation failed. (Malformed/unexpected response data)');
          throw new MalformedResponseException(response);
        }

        Logger.warn(`Operation failed. (HTTP ${response.status} ${response.statusText})`);
        throw new HttpServiceException(response);
      }
    });
  } finally {
    Logger.info('== END ==');
  }
}
