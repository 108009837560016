import { AlgorithmCode, AppException, AppUserOrUserGroup, Constants, JsonObject, Utils } from '@sigmail/common';
import { Algorithm } from '@sigmail/crypto';
import {
  ApiFormattedSigmailObject,
  ApiFormattedUserCredentials,
  ISigmailObject,
  IUserCredentials,
  ServerParamsValueType,
  SharedParamsValueType
} from '@sigmail/objects';
import { SigmailObject } from '../sigmail-object';

const PROPS: ReadonlyArray<keyof Omit<IUserCredentials, keyof ISigmailObject<any> | 'decryptedValue'>> = [
  'userId',
  'keyId',
  'credentialHash',
  'sharedParameters',
  'serverParameters',
  'createdAtUtc',
  'expiredAtUtc'
];

const API_FORMATTED_PROPS: ReadonlyArray<keyof Omit<ApiFormattedUserCredentials, keyof ApiFormattedSigmailObject>> = [
  'userId',
  'keyId',
  'credentialHash',
  'sharedParameters',
  'serverParameters',
  'createdAtUtc',
  'expiredAtUtc'
];

/**
 * TODO document
 *
 * @template DVShared Type of shared parameters.
 * @template DVServer Type of server parameters
 */
export abstract class UserCredentials<DVShared = JsonObject, DVServer = JsonObject>
  extends SigmailObject<string>
  implements IUserCredentials<DVShared, DVServer> {
  /** @override */
  protected static get DEFAULT_CODE(): AlgorithmCode {
    return process.env.ALGORITHM_CODE_NON_ENCRYPTED_OBJECT;
  }

  /** @override */
  public static isValidAlgorithmCode(value: any): value is AlgorithmCode {
    return Algorithm.isValidNonEncryptedObjectCode(value);
  }

  /** Determines if the given value is a valid key ID. */
  public static isValidKeyId(value: any): value is number {
    return Utils.isInteger(value) && value >= 0;
  }

  /** Determines if the given value is a valid credential hash. */
  public static isValidCredentialHash(value: any): value is string {
    return Utils.isString(value);
  }

  /** Determines if the given value is a valid encrypted value. */
  public static isValidEncryptedValue(value: any): value is string {
    return Utils.isString(value);
  }

  /** Determines if the given value is a valid shared parameters value. */
  public static isValidSharedParameters(value: any): NonNullable<any> {
    return Utils.isNonArrayObjectLike(value);
  }

  /** Determines if the given value is a valid server parameters value. */
  public static isValidServerParameters(value: any): NonNullable<any> {
    return Utils.isNonArrayObjectLike(value);
  }

  /** Determines if the given value is a valid creation date. */
  public static isValidCreationDate(value: any): value is Date {
    return Utils.isValidDate(value);
  }

  /**
   * Determines if the given value is a valid expiry date. `null` is considered
   * a valid value.
   */
  public static isValidExpiryDate(value: any): value is Date | null {
    return value === null || Utils.isValidDate(value);
  }

  /** @override */
  public static isAssignableFrom(obj: any): obj is IUserCredentials {
    return (
      super.isAssignableFrom(obj) === true &&
      Utils.every(PROPS, Utils.partial(Utils.has, obj)) &&
      AppUserOrUserGroup.isValidId(obj.userId) &&
      this.isValidKeyId(obj.keyId) &&
      this.isValidCredentialHash(obj.credentialHash) &&
      this.isValidSharedParameters(obj.sharedParameters) &&
      this.isValidServerParameters(obj.serverParameters) &&
      this.isValidCreationDate(obj.createdAtUtc) &&
      this.isValidExpiryDate(obj.expiredAtUtc) &&
      typeof obj.decryptedValue === 'function'
    );
  }

  /** @override */
  public static isApiFormatted(obj: any): obj is ApiFormattedUserCredentials {
    return (
      super.isApiFormatted(obj) === true &&
      Utils.every(API_FORMATTED_PROPS, Utils.partial(Utils.has, obj)) &&
      Utils.isNumber(obj.userId) &&
      Utils.isNumber(obj.keyId) &&
      Utils.isString(obj.credentialHash) &&
      Utils.isString(obj.sharedParameters) &&
      Utils.isString(obj.serverParameters) &&
      Utils.isString(obj.createdAtUtc) &&
      (obj.expiredAtUtc === null || Utils.isString(obj.expiredAtUtc))
    );
  }

  public static async create<T extends UserCredentials<any, any>>(
    this: new (...args: any[]) => T,
    id: number,
    code: AlgorithmCode | undefined,
    userId: number,
    keyId: number,
    credentialHash: string,
    sharedParameters: SharedParamsValueType<T>,
    serverParameters: ServerParamsValueType<T>,
    encryptedFor: number,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  ): Promise<T> {
    const Class = (this as unknown) as typeof UserCredentials;
    if (Class === UserCredentials) throw new TypeError('Illegal method invocation.');

    const objectCode = Utils.isUndefined(code) ? Class.DEFAULT_CODE : code;
    const sharedParams = await Class.encryptObjectValue(sharedParameters, { objectCode });
    const serverParams = await Class.encryptObjectValue(serverParameters, { objectCode });
    // prettier-ignore
    const args = [id, objectCode, userId, keyId, credentialHash, sharedParams, serverParams, createdAtUtc, expiredAtUtc];
    return Reflect.construct(Class, args);
  }

  public readonly userId: number;
  public readonly keyId: number;
  public readonly credentialHash: string;
  public readonly sharedParameters: string;
  public readonly serverParameters: string;
  public readonly createdAtUtc: Date;
  public readonly expiredAtUtc: Date | null;

  public constructor(
    id: number,
    code: AlgorithmCode | undefined,
    userId: number,
    keyId: number,
    credentialHash: string,
    sharedParameters: string,
    serverParameters: string,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  );

  public constructor(obj: ApiFormattedUserCredentials);

  public constructor(...args: any[]) {
    super(...(args.length === 1 ? args : [undefined, ...args]));

    const Class = this.constructor as typeof UserCredentials;
    if (Class === UserCredentials) throw new TypeError('Initialization error.');

    const userId = args.length === 1 ? args[0].userId : args[2];
    const keyId = args.length === 1 ? args[0].keyId : args[3];
    const credentialHash = args.length === 1 ? args[0].credentialHash : args[4];
    const sharedParameters = args.length === 1 ? args[0].sharedParameters : args[5];
    const serverParameters = args.length === 1 ? args[0].serverParameters : args[6];
    const createdAtUtc = args.length === 1 ? new Date(args[0].createdAtUtc) : args[7];
    const expiredAtUtc = args.length === 1 ? args[0].expiredAtUtc : args[8];
    const expiryDate = Utils.isNil(expiredAtUtc) ? null : new Date(expiredAtUtc);

    if (!AppUserOrUserGroup.isValidId(userId)) throw new AppException(Constants.Error.E_INVALID_USER_OR_GROUP_ID);
    if (!Class.isValidKeyId(keyId)) throw new AppException(Constants.Error.E_INVALID_OBJECT_ID, 'Invalid key ID.');
    if (!Class.isValidCredentialHash(credentialHash))
      throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE, 'Invalid credential hash.');
    if (!Class.isValidEncryptedValue(sharedParameters))
      throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE, 'Invalid shared parameters.');
    if (!Class.isValidEncryptedValue(serverParameters))
      throw new AppException(Constants.Error.E_INVALID_OBJECT_VALUE, 'Invalid server parameters.');
    if (!Class.isValidCreationDate(createdAtUtc)) throw new AppException(Constants.Error.E_INVALID_CREATION_DATE);
    if (!Class.isValidExpiryDate(expiryDate)) throw new AppException(Constants.Error.E_INVALID_EXPIRY_DATE);

    this.userId = userId;
    this.keyId = keyId;
    this.credentialHash = credentialHash;
    this.sharedParameters = sharedParameters;
    this.serverParameters = serverParameters;
    this.createdAtUtc = createdAtUtc;
    this.expiredAtUtc = expiryDate;
  }

  /** @override */
  public equals(other: any): other is IUserCredentials {
    return (
      super.equals(other) === true &&
      this.userId === other.userId &&
      this.keyId === other.keyId &&
      this.credentialHash === other.credentialHash &&
      this.sharedParameters === other.sharedParameters &&
      this.serverParameters === other.serverParameters
    );
  }

  /** @override */
  public hashCode(): number {
    let hashed = super.hashCode();
    hashed = (31 * hashed + Utils.hashNumber(this.userId)) | 0;
    hashed = (31 * hashed + Utils.hashNumber(this.keyId)) | 0;
    hashed = (31 * hashed + Utils.hashString(this.credentialHash)) | 0;
    hashed = (31 * hashed + Utils.hashString(this.sharedParameters)) | 0;
    hashed = (31 * hashed + Utils.hashString(this.serverParameters)) | 0;
    return hashed;
  }

  public async decryptedValue(which: 'shared'): Promise<DVShared>;
  public async decryptedValue(which: 'server'): Promise<DVServer>;
  public async decryptedValue(...args: any[]): Promise<DVShared | DVServer> {
    const which = args.length > 0 ? (args[0] as string) : '';
    if (which !== 'shared' && which !== 'server') {
      throw new AppException(Constants.Error.S_ERROR, 'Missing or invalid argument(s).');
    }

    const Class = this.constructor as typeof UserCredentials;
    const value = await Class.decryptObjectValue<any>(
      which === 'shared' ? this.sharedParameters : this.serverParameters,
      { objectCode: this.code }
    );

    const isValid =
      (which === 'shared' && Class.isValidSharedParameters(value)) ||
      (which === 'server' && Class.isValidServerParameters(value));

    if (!isValid) {
      const errorMessage = [
        'Decrypted value failed the validation check.',
        `type=${this.type}`,
        `id=${this.id}`,
        `code=${this.code}`
      ];
      throw new AppException(
        Constants.Error.E_INVALID_OBJECT_VALUE,
        `${errorMessage[0]} (${errorMessage.slice(1).join(', ')})`
      );
    }

    return value;
  }

  /** @override */
  public toApiFormatted(): ApiFormattedUserCredentials {
    const apiFormatted = super.toApiFormatted();
    return {
      ...apiFormatted,
      userId: this.userId,
      keyId: this.keyId,
      credentialHash: this.credentialHash,
      sharedParameters: this.sharedParameters,
      serverParameters: this.serverParameters,
      createdAtUtc: this.createdAtUtc.toISOString(),
      expiredAtUtc: this.expiredAtUtc === null ? null : this.expiredAtUtc.toISOString()
    };
  }
}
