import { Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import { sha256Hash } from './sha256-hash';

const VALID_CREDENTIAL_TYPE_LIST: number[] = [
  process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN,
  process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN,
  process.env.USER_CREDENTIALS_TYPE_SECURITY_QUESTION,
  process.env.USER_CREDENTIALS_TYPE_PASSWORD_RESET,
  process.env.USER_CREDENTIALS_TYPE_EMAIL_TOKEN
];

export function generateCredentialHash(credentialType: number, credentialData: string | { [key: string]: string }) {
  const Logger = getLoggerWithPrefix('Util', 'generateCredentialHash:');

  if (!Utils.isFinite(credentialType)) {
    throw new TypeError('Argument <credentialType> must be a finite numeric value.');
  }

  if (VALID_CREDENTIAL_TYPE_LIST.indexOf(credentialType) === -1) {
    throw new TypeError('Argument <credentialType> must be one of the known credential types.');
  }

  let payload = credentialData;
  if (!Utils.isString(credentialData)) {
    if (Utils.isPlainObject(credentialData)) {
      if ('username' in credentialData && Utils.isString(credentialData.username)) {
        payload = { ...credentialData, username: credentialData.username.trim().toLowerCase() };
      }
    } else {
      throw new TypeError('Argument <credentialData> must be of type string or plain object.');
    }
  }

  const data = JSON.stringify({ type: credentialType, payload });
  Logger.debug('data =', data);
  return sha256Hash(data, 'hex');
}
