import { AlgorithmCode, EncryptAsymmetricKeyAlgorithmCode, Utils } from '@sigmail/common';
import { Algorithm, Constants, getAlgorithm, SigmailCryptoException } from '@sigmail/crypto';
import { ICryptographicKeyPublic } from '@sigmail/objects';
import { CryptographicKey } from '.';

const TYPE = process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC;

export class CryptographicKeyPublic extends CryptographicKey<JsonWebKey> implements ICryptographicKeyPublic {
  /** @override */
  protected static get DEFAULT_CODE(): EncryptAsymmetricKeyAlgorithmCode {
    return process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC;
  }

  /** @override */
  public static get TYPE(): number {
    return TYPE;
  }

  public static async create(
    id: number,
    code: AlgorithmCode | undefined,
    version: number,
    value: JsonWebKey,
    encryptedFor: number,
    createdAtUtc: Date,
    expiredAtUtc?: Date | null
  ): Promise<CryptographicKeyPublic> {
    const keyCode = Utils.isUndefined(code) ? this.DEFAULT_CODE : code;
    const encryptedValue = await this.encryptAsymmetricKey(value, { encryptedFor, keyCode, keyVersion: version });
    const args = [id, keyCode, version, encryptedValue, encryptedFor, createdAtUtc, expiredAtUtc];
    return Reflect.construct(this, args);
  }

  public static async encryptFor(key: ICryptographicKeyPublic, id: number): Promise<ICryptographicKeyPublic> {
    const { id: keyId, createdAtUtc } = key;
    const jsonWebKey = await key.decryptedValue();
    return this.create(keyId, undefined, 0, jsonWebKey, id, createdAtUtc);
  }

  public encryptFor(id: number): Promise<ICryptographicKeyPublic> {
    const Class = this.constructor as typeof CryptographicKeyPublic;
    return Class.encryptFor(this, id);
  }

  public decryptedValue(): Promise<JsonWebKey> {
    if (!Algorithm.isValidEncryptAsymmetricKeyCode(this.code)) {
      throw new SigmailCryptoException(Constants.Error.E_UNKNOWN_ALGORITHM_CODE);
    }
    const algorithm = getAlgorithm(this.code);
    const Class = this.constructor as typeof CryptographicKeyPublic;
    const cryptoKey = Class.getPrivateKey(this.encryptedForId);
    return algorithm.decrypt({ privateKey: cryptoKey }, this.value, this.version);
  }
}
