import { MedicalInstitute } from '@sigmail/i18n';
import { gettext } from '..';

const i18n: MedicalInstitute.InviteGuestFormI18n = {
  fieldset: {
    default: {
      field: {
        firstName: {
          // Translators: Label for the First Name input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelFirstName'),
          error: {
            // Translators: Error message displayed when First Name is left blank
            valueMissing: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorFirstNameRequired'),
            // Translators: Error message displayed when First Name doesn't satisfy minimum length requirement
            tooShort: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorFirstNameTooShort'),
            // Translators: Error message displayed when First Name doesn't satisfy maximum length requirement
            tooLong: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorFirstNameTooLong'),
            // Translators: Error message displayed when First Name consists of nothing but whitespace characters
            patternMismatch: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorFirstNameBadInput')
          }
        },
        lastName: {
          // Translators: Label for the Last Name input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelLastName'),
          error: {
            // Translators: Error message displayed when Last Name is left blank
            valueMissing: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorLastNameRequired'),
            // Translators: Error message displayed when Last Name doesn't satisfy minimum length requirement
            tooShort: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorLastNameTooShort'),
            // Translators: Error message displayed when Last Name doesn't satisfy maximum length requirement
            tooLong: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorLastNameTooLong'),
            // Translators: Error message displayed when Last Name consists of nothing but whitespace characters
            patternMismatch: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorLastNameBadInput')
          }
        },
        birthDate: {
          // Translators: Label for the Birth Date input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelBirthDate'),
          error: {
            // Translators: Error message displayed when Birth Date is left blank
            valueMissing: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorBirthDateRequired'),
            // Translators: Error message displayed when Birth Date is not valid
            badInput: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorBirthDateBadInput')
          }
        },
        gender: {
          // Translators: Label for the Gender input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelGender'),
          options: [
            {
              code: 'unknown',
              // Translators: Label for the Unknown Gender option
              label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'optionLabelGenderUnknown')
            },
            {
              code: 'male',
              // Translators: Label for the Male Gender option
              label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'optionLabelGenderMale')
            },
            {
              code: 'female',
              // Translators: Label for the Female Gender option
              label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'optionLabelGenderFemale')
            },
            {
              code: 'other',
              // Translators: Label for the Other Gender option
              label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'optionLabelGenderOther')
            }
          ]
        },
        cellNumber: {
          // Translators: Label for the Cell Number input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelCellNumber'),
          error: {
            // Translators: Error message displayed when Cell Number doesn't match the expected format
            patternMismatch: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorCellNumberBadInput')
          }
        },
        homeNumber: {
          // Translators: Label for the Home Number input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelHomeNumber'),
          error: {
            // Translators: Error message displayed when Home Number doesn't match the expected format
            patternMismatch: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorHomeNumberBadInput')
          }
        },
        emailAddress: {
          // Translators: Label for the Email Address input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelEmailAddress'),
          error: {
            // Translators: Error message displayed when Email Address is left blank
            valueMissing: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorEmailAddressRequired'),
            // Translators: Error message displayed when Email Address doesn't satisfy minimum length requirement
            tooShort: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorEmailAddressTooShort'),
            // Translators: Error message displayed when Email Address doesn't satisfy maximum length requirement
            tooLong: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorEmailAddressTooLong'),
            // Translators: Error message displayed when Email Address contains invalid characters
            patternMismatch: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorEmailAddressBadInput')
          }
        }
      }
    },
    healthPlan: {
      legend: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'legendHealthPlanFieldset'),
      field: {
        jurisdiction: {
          // Translators: Label for the Health Plan Jurisdiction input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelHealthPlanJurisdiction'),
          options: [
            {
              code: 'self-paid',
              // Translators: Label for the Health Plan Jurisdiction option (Self Paid)
              label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'optionLabelHealthPlanSelfPaid')
            },
            {
              code: 'insurance',
              // Translators: Label for the Health Plan Jurisdiction option (Other Insurance)
              label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'optionLabelHealthPlanOther')
            },
            {
              code: 'foreign',
              // Translators: Label for the Health Plan Jurisdiction option (Foreign)
              label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'optionLabelHealthPlanForeign')
            }
          ],
          error: {
            // Translators: Error message displayed when Health Plan Jurisdiction is not selected
            valueMissing: gettext(
              'medicalInstituteInviteGuestForm',
              'medicalInstituteInviteGuestForm',
              'errorHealthPlanJurisdictionRequired'
            )
          }
        },
        planNumber: {
          // Translators: Label for the Health Plan Number input
          label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'inputLabelHealthPlanNumber'),
          error: {
            // Translators: Error message displayed when Health Plan Number is left blank
            valueMissing: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorHealthPlanNumberRequired'),
            // Translators: Error message displayed when Health Plan Number doesn't match the expected format
            patternMismatch: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'errorHealthPlanNumberBadInput')
          }
        }
      }
    }
  },
  submitSuccessDialog: {
    title: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'submitSuccessDialogTitle'),
    accessCodeLabel: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'submitSuccessDialogAccessCodeLabel'),
    action: {
      dismiss: {
        label: gettext('medicalInstituteInviteGuestForm', 'medicalInstituteInviteGuestForm', 'submitSuccessDialogActionLabelDismiss')
      }
    }
  }
};

export default i18n;
