import { Utils } from '@sigmail/common';
import { SigmailObjectType, VersionedSigmailObject } from '@sigmail/objects';
import { Api } from '@sigmail/services';
import {
  CLIENT_OBJECT_TYPE_CODE_LIST,
  CRYPTOGRAPHIC_KEY_TYPE_CODE_LIST,
  DATA_OBJECT_TYPE_CODE_LIST,
  GROUP_OBJECT_TYPE_CODE_LIST,
  NOTIFICATION_OBJECT_TYPE_CODE_LIST,
  USER_CREDENTIALS_TYPE_CODE_LIST,
  USER_OBJECT_TYPE_CODE_LIST
} from '../app-state/constants';

type BatchUpdateRequestBody = Pick<
  Api.BatchUpdateRequestData,
  'keys' | 'dataObjects' | 'notificationObjects' | 'userCredentialsObjects' | 'userObjects'
>;

function getKeyNameForObject<T extends SigmailObjectType>(obj: T): keyof BatchUpdateRequestBody | undefined {
  let key: ReturnType<typeof getKeyNameForObject> = undefined;

  if (Utils.isNonArrayObjectLike(obj) && Utils.isNumber(obj.type)) {
    if (CRYPTOGRAPHIC_KEY_TYPE_CODE_LIST.includes(obj.type)) {
      key = 'keys';
    } else if (DATA_OBJECT_TYPE_CODE_LIST.includes(obj.type)) {
      key = 'dataObjects';
    } else if (NOTIFICATION_OBJECT_TYPE_CODE_LIST.includes(obj.type)) {
      key = 'notificationObjects';
    } else if (USER_CREDENTIALS_TYPE_CODE_LIST.includes(obj.type)) {
      key = 'userCredentialsObjects';
    } else if (
      USER_OBJECT_TYPE_CODE_LIST.includes(obj.type) ||
      CLIENT_OBJECT_TYPE_CODE_LIST.includes(obj.type) ||
      GROUP_OBJECT_TYPE_CODE_LIST.includes(obj.type)
    ) {
      key = 'userObjects';
    }
  }

  return key;
}

export class BatchUpdateRequestBuilder {
  private readonly requestBody: BatchUpdateRequestBody = {};

  public build(): BatchUpdateRequestBody {
    return this.requestBody;
  }

  public insert<T extends SigmailObjectType>(data: T | T[]): BatchUpdateRequestBuilder {
    const list = Utils.isArray(data) ? data : [data];

    list.forEach((data) => {
      const key = getKeyNameForObject(data)!;
      if (Utils.isString(key) !== true) throw new TypeError('Unknown data type.');

      (this.requestBody[key] || (this.requestBody[key] = [] as Array<Api.BatchUpdateOperation<any>>)).push({
        operation: 'insert',
        data
      });
    });

    return this;
  }

  public update<T extends SigmailObjectType>(data: T | T[], dataUpdater?: any): BatchUpdateRequestBuilder {
    const pushToRequestBody = (data: T, dataUpdater?: Api.DataUpdater<any>) => {
      const key = getKeyNameForObject(data)!;
      if (Utils.isString(key) !== true) throw new TypeError('Unknown data type.');

      (this.requestBody[key] || (this.requestBody[key] = [] as Array<Api.BatchUpdateOperation<any>>)).push({
        operation: 'update',
        data,
        dataUpdater
      });
    };

    if (Utils.isArray(data)) {
      data.forEach((obj) => pushToRequestBody(obj, dataUpdater?.[obj.id]));
    } else {
      pushToRequestBody(data, dataUpdater);
    }

    return this;
  }

  public expire<T extends SigmailObjectType>(data: T | T[], expiredAtUtc?: Date): BatchUpdateRequestBuilder {
    const list = Utils.isArray(data) ? data : [data];
    const expiredAt = expiredAtUtc instanceof Date ? expiredAtUtc : new Date(0);

    list.forEach((obj) => {
      const key = getKeyNameForObject(obj)!;
      if (Utils.isString(key) !== true) throw new TypeError('Unknown data type.');

      const props: Array<string> = ['type', 'id'];
      if (key === 'userCredentialsObjects') {
        props.push('userId');
      } else {
        props.push('version');

        if (key === 'keys') props.push('encryptedForId');
        else if (key === 'dataObjects') props.push('ownerId');
        else if (key === 'notificationObjects' || key === 'userObjects') props.push('userId');
      }

      const data: any = { ...Utils.pick(obj, props), expiredAtUtc: expiredAt };
      if (VersionedSigmailObject.isValidVersion(data.version) && data.version > 0) {
        ++data.version;
      }

      (this.requestBody[key] || (this.requestBody[key] = [] as Array<Api.BatchUpdateOperation<any>>)).push({
        operation: 'expire',
        data
      });
    });

    return this;
  }
}
