import { ActionPayloadBatchQueryDataSuccess, Messaging } from '@sigmail/app-state';
import { AppException, Constants, Utils } from '@sigmail/common';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedNotificationObject,
  DataObjectMsgFolderItem,
  MessageFolderItemCount,
  NotificationObjectRecallMessage,
  NotificationObjectRecallMessageValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { Set as ImmutableSet } from 'immutable';
import { AppThunk } from '../..';
import { MessagingException } from '../../../core/messaging-exception';
import { MessageFlags } from '../../../utils';
import { BatchUpdateRequestBuilder } from '../../../utils/batch-update-request-builder';
import * as AuthSelectors from '../../selectors/auth';
import * as UserObjectSelectors from '../../selectors/user-object';
import { AuthenticatedActionState } from '../authenticated-action';
import { FetchObjectsRequestData } from '../base-action';
import { AUTH_STATE_PROCESS_RECALL_MESSAGES } from '../constants/auth-state-identifier';
import { BaseMessagingAction } from './base-messaging-action';
import { ApplyMessageFolderUpdateMeta, ApplyMessageFolderUpdateResult, updateMessageFolderAction } from './update-msg-folder-action';

const MESSAGE_FOLDER_KEY_INBOX: Extract<Messaging.ParentMessageFolderKey, 'inbox'> = 'inbox';
const MESSAGE_FOLDER_KEY_GROUP_INBOX: Extract<Messaging.ParentMessageFolderKey, '$group_inbox'> = '$group_inbox';

interface Payload extends Messaging.ActionPayloadProcessRecallMessageNotifications {}

interface State extends AuthenticatedActionState {
  apiAccessToken: string;
  notificationObjectList: ReadonlyArray<ApiFormattedNotificationObject>;
  dtServer: Date;
  msgMetadataIdMap: Record<number, [NotificationObjectRecallMessage, NotificationObjectRecallMessageValue]>;
  msgMetadataIdSet: ImmutableSet<number>;
  requestBody: BatchUpdateRequestBuilder;
  successPayload: ActionPayloadBatchQueryDataSuccess;
}

class ProcessRecallMessageNotificationsAction extends BaseMessagingAction<Payload, State> {
  protected preExecute() {
    const { folderKey, parentFolderKey } = this.payload;

    return super
      .preExecute()
      .then(() => {
        if (
          folderKey !== MESSAGE_FOLDER_KEY_INBOX &&
          folderKey !== MESSAGE_FOLDER_KEY_GROUP_INBOX &&
          parentFolderKey !== MESSAGE_FOLDER_KEY_INBOX &&
          parentFolderKey !== MESSAGE_FOLDER_KEY_GROUP_INBOX
        ) {
          throw new MessagingException(
            `Operation is not supported for this folder. (folderKey=${folderKey}, parentFolderKey=${String(parentFolderKey)})`
          );
        }

        this.state.apiAccessToken = AuthSelectors.accessTokenSelector(this.getRootState());

        const query: Api.EnterStateRequestData = {
          authState: this.state.roleAuthClaim,
          state: AUTH_STATE_PROCESS_RECALL_MESSAGES
        };

        return this.enterState(this.state.apiAccessToken, query);
      })
      .then(({ authState }) => {
        this.state.roleAuthClaim = authState;
      });
  }

  protected async onExecute() {
    const { folderKey, parentFolderKey } = this.payload;
    const { roleAuthClaim: authState } = this.state;

    for (let MAX_ATTEMPTS = 2, attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        await this.fetchNotificationObjectList();

        const recallMessageNotificationList = this.state.notificationObjectList
          .filter(({ type }) => type === process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE)
          .map((apiFormattedObject) => new NotificationObjectRecallMessage(apiFormattedObject));

        if (recallMessageNotificationList.length === 0) {
          this.logger.info('Recall message notification list is empty; call ignored');
          return;
        }

        this.state.dtServer = await this.dispatchFetchServerDateAndTime();
        this.state.msgMetadataIdMap = {};
        this.state.msgMetadataIdSet = ImmutableSet<number>().asMutable();
        this.state.requestBody = new BatchUpdateRequestBuilder();
        this.state.successPayload = {
          request: { dataObjects: { ids: [] } },
          response: { dataObjects: [], serverDateTime: '' }
        };

        for (const notificationObject of recallMessageNotificationList) {
          const decryptedValue = await notificationObject.decryptedValue();
          this.state.msgMetadataIdMap[decryptedValue.header] = [notificationObject, decryptedValue];
          this.state.msgMetadataIdSet.add(decryptedValue.header);
        }

        await this.dispatch(
          updateMessageFolderAction({
            roleAuthClaim: authState,
            folderKey,
            parentFolderKey,
            requestBody: this.state.requestBody,
            successPayload: this.state.successPayload,
            applyUpdate: this.applyMessageFolderUpdate.bind(this)
          })
        );

        const { dataObjects, notificationObjects } = this.state.requestBody.build();
        if (Utils.isNonEmptyArray(dataObjects) || Utils.isNonEmptyArray(notificationObjects)) {
          await this.dispatchBatchUpdateData({ authState, dataObjects, notificationObjects });

          try {
            await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
          } catch (error) {
            this.logger.warn('Error manually updating app state:', error);
            /* ignore */
          }
        }

        break;
      } catch (error) {
        if (attempt === MAX_ATTEMPTS || !(error instanceof Api.VersionConflictException)) {
          throw error;
        }

        this.logger.info('Version conflict error; operation will be retried.');
      }
    }
  }

  private async fetchNotificationObjectList(): Promise<void> {
    this.logger.info('Fetching recall message notifications (if any).');

    const { folderKey, parentFolderKey } = this.payload;
    const { roleAuthClaim: authState } = this.state;

    const notificationObjectsByType: Array<Required<FetchObjectsRequestData>['notificationObjectsByType'][0]> = [];
    if (folderKey === MESSAGE_FOLDER_KEY_INBOX || parentFolderKey === MESSAGE_FOLDER_KEY_INBOX) {
      const { id: userId } = this.state.currentUser;
      notificationObjectsByType.push({ userId, type: process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE });
    } else if (folderKey === MESSAGE_FOLDER_KEY_GROUP_INBOX || parentFolderKey === MESSAGE_FOLDER_KEY_GROUP_INBOX) {
      const circleOfCareGroup = UserObjectSelectors.circleOfCareGroupSelector(this.getRootState());
      if (Utils.isNil(circleOfCareGroup)) {
        throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'Circle of care group is either missing or invalid.');
      }
      notificationObjectsByType.push({ userId: circleOfCareGroup.id, type: process.env.NOTIFICATION_OBJECT_TYPE_RECALL_MESSAGE });
    }

    let notificationObjectList: ReadonlyArray<ApiFormattedNotificationObject> = [];
    if (notificationObjectsByType.length > 0) {
      const query: FetchObjectsRequestData = {
        authState,
        notificationObjectsByType,
        expectedCount: {
          notificationObjectsByType: null
        }
      };

      const responseJson = await this.dispatchFetchObjects(query);
      notificationObjectList = responseJson.notificationObjectList;
    }

    this.state.notificationObjectList = notificationObjectList;
  }

  private async applyMessageFolderUpdate(
    folderData: Array<DataObjectMsgFolderItem>,
    itemCount: MessageFolderItemCount,
    meta: ApplyMessageFolderUpdateMeta
  ): Promise<ApplyMessageFolderUpdateResult> {
    const { msgMetadataIdSet, msgMetadataIdMap, requestBody, dtServer, successPayload } = this.state;

    const folderOrExt = `message folder${meta.folderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT ? ' extension' : ''}`;
    const result: ApplyMessageFolderUpdateResult = { updated: false, done: false };

    let idsFound: Array<number> = [];
    for (const msgMetadataId of msgMetadataIdSet) {
      this.logger.info(`Locating item with metadata ID <${msgMetadataId}> in ${folderOrExt} <${meta.folderOrExtId}>.`);

      const index = folderData.findIndex(({ header }) => header === msgMetadataId);
      if (index === -1) continue;
      idsFound.push(msgMetadataId);

      const notificationObject = msgMetadataIdMap[msgMetadataId][0];
      const message = folderData[index];
      const { isRecalled, isMarkedAsRead, isImportant, hasReminder, isBillable, isReferral } = MessageFlags(message);

      // mark message recalled if it's not already
      if (!isRecalled) {
        const { all, important, reminder, billable, referral } = itemCount;
        all.unread -= +!isMarkedAsRead;
        important.unread -= +(isImportant && !isMarkedAsRead);
        reminder.unread -= +(hasReminder && !isMarkedAsRead);
        billable.unread -= +(isBillable && !isMarkedAsRead);
        referral.unread -= +(isReferral && !isMarkedAsRead);

        folderData[index] = {
          ...message,
          flags: {
            ...(message.flags || {}),
            recalled: notificationObject.createdAtUtc.getTime(),
            markedAsRead: true // a recalled message cannot be in an unread state
          }
        };

        result.updated = true;
      } else {
        this.logger.warn('Message is already marked as recalled; skipped.');
      }

      requestBody.expire(notificationObject, dtServer);

      const { body: msgBodyId, readReceipt: readReceiptId } = msgMetadataIdMap[msgMetadataId][1];
      successPayload.request.dataObjects!.ids.push(msgMetadataId, msgBodyId, readReceiptId);
      // XXX: because we are not pushing the corresponding data objects in
      // successPayload's response, they will be removed from app state if present
    }

    // ASSUMPTION: set was initialized using asMutable()
    msgMetadataIdSet.subtract(idsFound);

    result.done = msgMetadataIdSet.isEmpty();
    return result;
  }
}

export const processRecallMessageNotificationsAction = (payload: Payload): AppThunk<Promise<void>> => {
  return async (dispatch, getState, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'processRecallMessageNotificationsAction:');

    try {
      const action = new ProcessRecallMessageNotificationsAction({ payload, dispatch, getState, apiService, logger: Logger });
      return await action.execute();
    } catch (error) {
      Logger.warn('Recall message notification processing failed with an error:', error);
      /* ignore */
    }
  };
};
