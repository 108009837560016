export const CAN_INVITE_INSTITUTE = Symbol.for('urn:sigmail:client_rights:invite-institute');

export const CAN_INVITE_MEMBER = Symbol.for('urn:sigmail:client_rights:invite-member');
export const CAN_ACTIVATE_MEMBER = Symbol.for('urn:sigmail:client_rights:activate-member');
export const CAN_DEACTIVATE_MEMBER = Symbol.for('urn:sigmail:client_rights:deactivate-member');

export const CAN_RESEND_INVITATION = Symbol.for('urn:sigmail:client_rights:resend-invitation');
export const CAN_REVOKE_INVITATION = Symbol.for('urn:sigmail:client_rights:revoke-invitation');
export const CAN_REMOVE_INVITATION = Symbol.for('urn:sigmail:client_rights:remove-invitation');

export const CAN_ACCESS_MAILBOX = Symbol.for('urn:sigmail:client_rights:access-mailbox');
export const CAN_ACCESS_GLOBAL_CONTACTS = Symbol.for('urn:sigmail:client_rights:access-global-contacts');
export const CAN_ACCESS_MEMBER_MANAGEMENT = Symbol.for('urn:sigmail:client_rights:access-member-management');
export const CAN_ACCESS_OWN_ACCOUNT = Symbol.for('urn:sigmail:client_rights:access-own-account');
export const CAN_ACCESS_CLIENT_CONTACTS = Symbol.for('urn:sigmail:client_rights:access-client-contacts');
export const CAN_ACCESS_CIRCLE_OF_CARE = Symbol.for('urn:sigmail:client_rights:access-circle-of-care');

export const CAN_COMPOSE_HRM_MESSAGE = Symbol.for('urn:sigmail:client_rights:compose-hrm-message');
export const CAN_COMPOSE_MESSAGE = Symbol.for('urn:sigmail:client_rights:compose-message');
export const CAN_COMPOSE_REFERRAL = Symbol.for('urn:sigmail:client_rights:compose-referral');
export const CAN_SEND_MESSAGE = Symbol.for('urn:sigmail:client_rights:send-message');
export const CAN_SEND_MESSAGE_TO_OSCAR_EMR = Symbol.for('urn:sigmail:client_rights:send-message-to-oscar-emr');
export const CAN_REPLY_TO_MESSAGE = Symbol.for('urn:sigmail:client_rights:reply-to-message');
export const CAN_FORWARD_MESSAGE = Symbol.for('urn:sigmail:client_rights:forward-message');
export const CAN_ARCHIVE_MESSAGE = Symbol.for('urn:sigmail:client_rights:archive-message');
export const CAN_DELETE_MESSAGE = Symbol.for('urn:sigmail:client_rights:delete-message');
export const CAN_SAVE_MESSAGE_DRAFT = Symbol.for('urn:sigmail:client_rights:save-message-draft');
export const ATTACH_DOCUMENTS_TO_MESSAGE = Symbol.for('urn:sigmail:client_rights:attach-documents-to-message');
export const CAN_MARK_MESSAGE_IMPORTANT = Symbol.for('urn:sigmail:client_rights:mark-message-important');
export const CAN_MARK_MESSAGE_BILLABLE = Symbol.for('urn:sigmail:client_rights:mark-message-billable');
export const CAN_SET_MESSAGE_REMINDER = Symbol.for('urn:sigmail:client_rights:set-message-reminder');
export const RECALL_MESSAGE_WINDOW = Symbol.for('urn:sigmail:client_rights:recall-message-window');

export const MAX_TOTAL_MSG_ATTACHMENT_SIZE = Symbol.for('urn:sigmail:client_rights:max-total-msg-attachment-size');
export const MAX_PER_FILE_MSG_ATTACHMENT_SIZE = Symbol.for(
  'urn:sigmail:client_rights:max-per-file-msg-attachment-size'
);

export const CAN_DEMOTE_ROLE = Symbol.for('urn:sigmail:client_rights:demote-role');
export const CAN_PROMOTE_ROLE = Symbol.for('urn:sigmail:client_rights:promote-role');
export const CAN_PROMOTE_ROLE_TO_ADMIN = Symbol.for('urn:sigmail:client_rights:promote-role-to-admin');

export const CAN_UPDATE_GROUP_PREFS = Symbol.for('urn:sigmail:client_rights:update-group-prefs');
