import {
  ActionPayloadAuthSuccess,
  ActionPayloadBatchQueryDataSuccess,
  ActionPayloadSignIn,
  SignInSuccess,
  SignInSuccessAuthOnly,
  SrpVerifyCredentialResponseData
} from '@sigmail/app-state';
import { AppException, AppUser, AppUserGroup, Constants, IAppUserGroup, SrpEphemeral, Utils } from '@sigmail/common';
import { EncryptWithParametersAlgorithmParams, getAlgorithm } from '@sigmail/crypto';
import { getLoggerWithPrefix } from '@sigmail/logging';
import {
  ApiFormattedDataObject,
  ApiFormattedUserCredentials,
  ClientObjectConfiguration,
  CryptographicKey,
  CryptographicKeyMaster,
  CryptographicKeyPrivate,
  CryptographicKeyPublic,
  DataObjectMsgFolder,
  GroupObjectFolderList,
  GroupObjectFolderListValue,
  IUserObject,
  UserCredentials,
  UserCredentialsMfaLogin,
  UserObjectAccessRights,
  UserObjectAccessRightsValue,
  UserObjectContactList,
  UserObjectFolderList,
  UserObjectFolderListValue,
  UserObjectProfileBasic,
  UserObjectProfileBasicValue,
  UserObjectProfilePrivate,
  UserObjectProfilePrivateValue,
  UserObjectServerRights,
  UserObjectServerRightsValue
} from '@sigmail/objects';
import { Api } from '@sigmail/services';
import { IAuthenticationData } from 'sigmail';
import { authSuccess } from '.';
import { AppThunk } from '..';
import { DEFAULT_CLIENT_RIGHTS_BY_ROLE } from '../../constants/medical-institute-default-client-rights';
import { CIRCLE_OF_CARE } from '../../constants/medical-institute-user-group-type-identifier';
import { AuthenticationData } from '../../core/authentication-data';
import { BaseAction, FetchObjectsRequestData } from '../actions/base-action';
import { AUTH_STATE_AUTHORIZED_AS_ROLE } from '../actions/constants/auth-state-identifier';
import { migration_addGroupsAndInstituteInfoToGlobalContactList } from '../actions/migrations/add-groups-and-institute-info-to-global-contact-list';
import { migration_bumpBasicProfileValueToVersionFour } from '../actions/migrations/bump-basic-profile-value-to-version-four';
import { migration_bumpContactInfoValueToVersionTwo } from '../actions/migrations/bump-contact-info-value-to-version-two';
import { migration_bumpMsgFolderValueToVersionThree } from '../actions/migrations/bump-msg-folder-value-to-version-three';
import { migration_bumpUserAndGroupFolderListToVersionTwo } from '../actions/migrations/bump-user-and-group-folder-list-to-version-two';
import { migration_createPreferencesObject } from '../actions/migrations/create-preferences-object';
import { migration_maskHealthCardNumberInContactInfo } from '../actions/migrations/mask-health-card-number-in-contact-info';
import { srpExchangeCredentialAction } from '../actions/SRP/srp-exchange-credential-action';
import { srpVerifyCredentialAction } from '../actions/SRP/srp-verify-credential-action';
import { authFailure } from '../auth-slice';

interface State {
  sessionId: string;
  clientEphemeral: SrpEphemeral;
  serverEphemeralPublic: string;
  sharedParameters: SignInSuccess['sharedParameters'];
  verifyCredentialsResponseData: SrpVerifyCredentialResponseData;
  userId: number;
  keyId: number;
  credentialId: number;
  credentialType: number;
  authState: string;
  mfaData: Readonly<
    Pick<IAuthenticationData, 'mfaAccountId' | 'mfaMethod' | 'mfaContact'> & {
      mfaCredentialId: number | null;
    }
  >;
  currentVersionClaim: string;
  userKeyPublic: CryptographicKeyPublic;
  basicProfile: UserObjectProfileBasicValue;
  privateProfile: UserObjectProfilePrivateValue;
  accessRightsObject: IUserObject<UserObjectAccessRightsValue>;
  serverRights: UserObjectServerRightsValue;
  successPayload: ActionPayloadBatchQueryDataSuccess;
  circleOfCareGroup: IAppUserGroup;
  globalContactListId: number;
  msgFolderJsonList: Array<ApiFormattedDataObject>;
}

class SignInAction extends BaseAction<ActionPayloadSignIn, State, (SignInSuccess | SignInSuccessAuthOnly) | undefined> {
  private readonly privateKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PRIVATE);
  private readonly publicKeyAlgo = getAlgorithm(process.env.ALGORITHM_CODE_ENCRYPT_ASYMMETRIC_KEY_PUBLIC);

  protected async onExecute() {
    let authSuccessPayload: ActionPayloadAuthSuccess | null = null;

    try {
      await this.srpExchangeCredentials();
      await this.srpVerifyCredentials();

      this.extractIdTokenData();
      await this.fetchMfaData();

      if (this.payload.authOnly === true) {
        this.logger.info('Authentication succeeded.');

        const authOnlySuccessPayload: SignInSuccessAuthOnly = {
          ...this.state.verifyCredentialsResponseData,
          ...this.state.mfaData,
          loggedInClaim: this.state.authState,
          user: new AppUser(this.state.userId),
          sharedParameters: this.state.sharedParameters
        };

        return authOnlySuccessPayload;
      }

      if (!CryptographicKey.isValidId(this.state.keyId)) throw new AppException(Constants.Error.E_AUTH_FAIL_KEY_ID);
      if (!UserCredentials.isValidId(this.state.credentialId)) throw new AppException(Constants.Error.E_AUTH_FAIL_CREDENTIAL_ID);
      if (!AuthenticationData.isValidAuthClaim(this.state.authState)) throw new AppException(Constants.Error.E_AUTH_FAIL_AUTH_STATE);

      await this.preloadUserObjects();
      await this.fetchRoleAuthorizationClaim();
      await this.fetchClientAndAuditKeys();
      await this.fetchGroupKeys();
      if (Utils.MedicalInstitute.isNonGuestRole(this.state.basicProfile.role)) {
        await this.preloadGroupObjects();
        await this.preloadGroupMessageFolders();
        await this.preloadClientObjects();
        await this.fetchGlobalContactListKeys();
      }
      await this.preloadUserMessageFolders();

      // await this.applyMigrations();

      const { mfaCredentialId, ...mfaData } = this.state.mfaData;
      authSuccessPayload = {
        ...this.state.verifyCredentialsResponseData,
        ...mfaData,
        user: new AppUser(this.state.userId),
        accessRights: this.state.accessRightsObject,
        authClaim: this.state.authState
      };
    } catch (error) {
      this.logger.error(error, error.message);

      if (this.payload.authOnly === true) {
        this.logger.info('Authentication failed.');
        return undefined;
      }

      let errorCode = Constants.Error.E_AUTH_FAIL;
      if (error instanceof Error && error.name === 'InvalidTokenError') {
        errorCode = Constants.Error.E_AUTH_FAIL_DECODE_ID_TOKEN;
      } else if (error instanceof AppException) {
        errorCode = error.errorCode;
      }

      this.dispatch(authFailure({ errorCode }));
      return undefined;
    }

    this.logger.info('Authentication succeeded.');
    try {
      if (typeof this.payload.beforeInitializeSession === 'function') {
        try {
          await Promise.resolve(this.payload.beforeInitializeSession(authSuccessPayload));
        } catch (error) {
          this.logger.warn('beforeInitializeSession failed with an error', error);
          throw error;
        }
      }

      try {
        await this.dispatchBatchQueryDataSuccess(this.state.successPayload);
      } catch (error) {
        this.logger.warn('batchQuerySuccessAction failed with an error', error);
        throw error;
      }

      this.dispatch(authSuccess(authSuccessPayload));

      const signInSuccessPayload: SignInSuccess = {
        ...authSuccessPayload,
        mfaCredentialId: this.state.mfaData.mfaCredentialId,
        sharedParameters: this.state.sharedParameters
      };

      return signInSuccessPayload;
    } catch (error) {
      this.logger.error(error);

      let errorCode = Constants.Error.S_ERROR;
      if (error instanceof AppException) {
        errorCode = error.errorCode;
      }

      this.dispatch(authFailure({ errorCode }));
      return undefined;
    }
  }

  private async srpExchangeCredentials(): Promise<void> {
    this.logger.info('Initiating the sign-in process with exchange of credentials.');

    const { credentialHash } = this.payload;
    const { nonce, clientEphemeral, counterpartB, shared } = await this.dispatch(srpExchangeCredentialAction({ credentialHash }));
    this.logger.debug('serverEphemeralPublic =', counterpartB);

    this.state.sessionId = nonce;
    this.state.clientEphemeral = clientEphemeral;
    this.state.serverEphemeralPublic = counterpartB;

    const sharedParams = JSON.parse(shared) as State['sharedParameters'];
    if (!Utils.isNonArrayObjectLike(sharedParams) || !AuthenticationData.isValidSalt(sharedParams.salt)) {
      throw new AppException(Constants.Error.E_AUTH_FAIL_SALT);
    }

    this.state.sharedParameters = sharedParams;
    this.logger.debug('salt =', sharedParams.salt);
  }

  private async srpVerifyCredentials(): Promise<void> {
    this.logger.info("Credential exchange succeeded; continuing with client's session proof verification.");

    const { credentialHash, password } = this.payload;
    const { sessionId, clientEphemeral, serverEphemeralPublic, sharedParameters } = this.state;
    const { salt: hexSalt } = sharedParameters;

    const trimmedPassword = (Utils.isString(password) && password.trim()) || '';
    const { passwordHash } = await Utils.generatePasswordHash(trimmedPassword, hexSalt);

    const verifyCredentialsResponseData = await this.dispatch(
      srpVerifyCredentialAction({
        sessionId,
        salt: hexSalt,
        credentialHash,
        passwordHash,
        clientEphemeralSecret: clientEphemeral.secret,
        serverEphemeralPublic
      })
    );

    this.state.verifyCredentialsResponseData = verifyCredentialsResponseData;
  }

  private extractIdTokenData(): void {
    this.logger.info('Extracting user ID, key ID, and credential ID out of the JWT ID token.');

    const { idToken } = this.state.verifyCredentialsResponseData;

    const decodedIdToken = Utils.decodeIdToken(idToken);
    if (!Utils.isValidJwtToken(decodedIdToken.authState, 'id')) {
      throw new AppException(Constants.Error.E_AUTH_FAIL_DECODE_ID_TOKEN);
    }
    this.logger.debug('decodedIdToken =', decodedIdToken);

    const decodedAuthState = Utils.decodeIdToken(decodedIdToken.authState);
    if (!AppUser.isValidId(decodedAuthState.userId)) {
      throw new AppException(Constants.Error.E_AUTH_FAIL_USER_ID);
    }
    this.logger.debug('decodedAuthState =', decodedAuthState);

    const { userId, keyId, credentialId, type: credentialType } = decodedAuthState;
    // =========================================================================
    // leave the following commented code intact as a reminder that
    // we do NOT perform these validations here intentionally
    // =========================================================================
    // if (!CryptographicKey.isValidId(keyId)) throw new AppException(Constants.Error.E_AUTH_FAIL_KEY_ID);
    // if (!UserCredentials.isValidId(credentialId)) throw new AppException(Constants.Error.E_AUTH_FAIL_CREDENTIAL_ID);

    this.state.userId = userId;
    this.state.keyId = keyId;
    this.state.credentialId = credentialId;
    this.state.credentialType = credentialType;
    this.state.authState = decodedIdToken.authState;
  }

  private async fetchMfaData(): Promise<void> {
    this.logger.info('Fetching any MFA data associated with the credential.');

    const {
      authState,
      userId,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    let credentialList: Array<ApiFormattedUserCredentials> = [];
    if (this.state.credentialType === process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN) {
      const query: FetchObjectsRequestData = {
        authState,
        userCredentialsByType: [{ userId, type: process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN }],
        expectedCount: { userCredentialsByType: null }
      };

      const responseJson = await this.fetchObjects(accessToken, query);
      credentialList = responseJson.credentialList;
    }

    if (credentialList.length === 0) {
      this.state.mfaData = { mfaCredentialId: null, mfaAccountId: null, mfaMethod: null, mfaContact: null };
      return;
    }

    const mfaJson = this.findCredentials(credentialList, { type: process.env.USER_CREDENTIALS_TYPE_MFA_LOGIN, userId });
    if (Utils.isNil(mfaJson)) throw new Api.MalformedResponseException();

    const mfaSharedParams = JSON.parse(mfaJson.sharedParameters);
    if (!UserCredentialsMfaLogin.isValidSharedParameters(mfaSharedParams)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, 'MFA data is either missing or invalid.');
    }

    this.state.mfaData = {
      mfaCredentialId: mfaJson.id,
      mfaAccountId: mfaSharedParams.mfaAccountId,
      mfaMethod: mfaSharedParams.mfaMethod,
      mfaContact: mfaSharedParams.mfaContact
    };

    this.logger.info('MFA record found. Credential ID =', mfaJson.id);
    this.logger.info('mfaAccountId =', mfaSharedParams.mfaAccountId);
    this.logger.info('mfaMethod =', mfaSharedParams.mfaMethod);
    this.logger.info('mfaContact =', mfaSharedParams.mfaContact);
  }

  private async preloadUserObjects(): Promise<void> {
    this.logger.info('Preloading some of the user objects which may be required immediately after sign-in.');

    const {
      userId,
      keyId,
      credentialId,
      authState,
      verifyCredentialsResponseData: { accessToken },
      sharedParameters: { salt: hexSalt }
    } = this.state;

    const query: Api.BatchQueryRequestData = {
      authState,
      encryptedFor: { ids: [userId] },
      keysByType: [
        { id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER, encryptedForId: credentialId },
        { id: userId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, encryptedForId: keyId },
        { id: userId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC }
      ],
      userObjectsByType: [
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC },
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PROTECTED },
        { userId, type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE },
        { userId, type: process.env.USER_OBJECT_TYPE_FOLDER_LIST },
        { userId, type: process.env.USER_OBJECT_TYPE_CONTACT_INFO },
        { userId, type: process.env.USER_OBJECT_TYPE_CONTACT_LIST },
        { userId, type: process.env.USER_OBJECT_TYPE_GUEST_DATA },
        { userId, type: process.env.USER_OBJECT_TYPE_PREFERENCES },
        { userId, type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS },
        { userId, type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS }
      ]
    };

    const { keysByType: keyList, userObjectsByType: userObjectList, claims } = await this.batchQueryData(accessToken, query);
    if (
      !Utils.isArray(keyList) ||
      !Utils.isArray(userObjectList) ||
      !Utils.isArray(claims) ||
      claims.length !== 1 ||
      !AuthenticationData.isValidAuthClaim(claims[0])
    ) {
      throw new Api.MalformedResponseException();
    }

    this.state.currentVersionClaim = claims[0];

    //
    // decrypt and cache master private key
    //
    const masterKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_MASTER, id: keyId });
    if (Utils.isNil(masterKeyJson)) {
      throw new Api.MalformedResponseException('Master key could not be fetched.');
    } else {
      const masterKeyPrivate = new CryptographicKeyMaster(masterKeyJson);
      const trimmedPassword = (Utils.isString(this.payload.password) && this.payload.password.trim()) || '';
      const params: EncryptWithParametersAlgorithmParams = {
        type: process.env.USER_CREDENTIALS_TYPE_PRIMARY_USER_LOGIN,
        parameter1: this.payload.username, // IMPORTANT: use username as-is, do NOT trim() or toLowerCase() etc
        parameter2: trimmedPassword,
        hexSalt
      };
      this.logger.debug('Decryption params', JSON.stringify(params));
      const jsonWebKey = await masterKeyPrivate.decryptedValue(params);
      this.logger.debug('User master key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.privateKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPrivateKey(masterKeyPrivate.id, cryptoKey);
    }

    //
    // decrypt and cache user private key
    //
    const userPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: userId });
    if (Utils.isNil(userPrivateKeyJson)) {
      throw new Api.MalformedResponseException('User private key could not be fetched.');
    } else {
      const userKeyPrivate = new CryptographicKeyPrivate(userPrivateKeyJson);
      const jsonWebKey = await userKeyPrivate.decryptedValue();
      this.logger.debug('User private key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.privateKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPrivateKey(userKeyPrivate.id, cryptoKey);
      CryptographicKey.clearPrivateKey(masterKeyJson.id);
    }

    //
    // decrypt and cache user public key
    //
    const userPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: userId });
    if (Utils.isNil(userPublicKeyJson)) {
      throw new Api.MalformedResponseException('User public key could not be fetched.');
    } else {
      const userKeyPublic = new CryptographicKeyPublic(userPublicKeyJson);
      const jsonWebKey = await userKeyPublic.decryptedValue();
      this.logger.debug('User public key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.publicKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPublicKey(userKeyPublic.id, cryptoKey);

      this.state.userKeyPublic = userKeyPublic;
    }

    //
    // decrypt and save basic profile for later use
    //
    const basicProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_BASIC, userId });
    if (Utils.isNil(basicProfileJson)) {
      throw new Api.MalformedResponseException('Basic profile object could not be fetched.');
    } else {
      const basicProfileObject = new UserObjectProfileBasic(basicProfileJson);
      this.state.basicProfile = await basicProfileObject.decryptedValue();
      this.logger.debug('basicProfile', JSON.stringify(this.state.basicProfile));
    }

    //
    // decrypt and save private profile for later use
    //
    const privateProfileJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_PROFILE_PRIVATE, userId });
    if (Utils.isNil(privateProfileJson)) {
      throw new Api.MalformedResponseException('Private profile object could not be fetched.');
    } else {
      const privateProfileObject = new UserObjectProfilePrivate(privateProfileJson);
      this.state.privateProfile = await privateProfileObject.decryptedValue();
      this.logger.debug('privateProfile', JSON.stringify(this.state.privateProfile));
    }

    //
    // save access rights object as is (i.e. encrypted) for later use
    //
    const index = this.findUserObjectIndex(userObjectList, { type: process.env.USER_OBJECT_TYPE_ACCESS_RIGHTS, userId });
    const accessRightsJson = index === -1 ? undefined : userObjectList[index];
    if (Utils.isNil(accessRightsJson)) {
      throw new Api.MalformedResponseException('Access rights object could not be fetched.');
    } else {
      const accessRightsObject = new UserObjectAccessRights(accessRightsJson);
      const defaultRights = DEFAULT_CLIENT_RIGHTS_BY_ROLE[this.state.basicProfile.role];
      this.state.accessRightsObject = await accessRightsObject.updateValue(defaultRights);
      userObjectList[index] = this.state.accessRightsObject.toApiFormatted();
    }

    //
    // decrypt and save server rights for later use
    //
    const serverRightsJson = this.findUserObject(userObjectList, { type: process.env.USER_OBJECT_TYPE_SERVER_RIGHTS, userId });
    if (Utils.isNil(serverRightsJson)) {
      throw new Api.MalformedResponseException('Server rights object could not be fetched.');
    } else {
      const serverRightsObject = new UserObjectServerRights(serverRightsJson);
      this.state.serverRights = await serverRightsObject.decryptedValue();
      this.logger.debug('serverRights', JSON.stringify(this.state.serverRights));
    }

    this.state.successPayload = {
      request: { userObjectsByType: query.userObjectsByType!, dataObjects: { ids: [] } },
      response: { userObjectsByType: userObjectList, dataObjects: [], serverDateTime: '' }
    };
  }

  private async fetchRoleAuthorizationClaim(): Promise<void> {
    this.logger.info('Fetching the role authorization claim.');

    const {
      basicProfile: { role: roleId },
      authState: loggedInClaim,
      currentVersionClaim,
      serverRights: { userClaim },
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    const authorizedAsRoleState = AUTH_STATE_AUTHORIZED_AS_ROLE[roleId];
    if (!Utils.isString(authorizedAsRoleState)) {
      throw new AppException(Constants.Error.S_ERROR, 'Role authorization state name could not be determined.');
    }

    const query: Api.EnterStateRequestData = {
      authState: loggedInClaim,
      claims: [currentVersionClaim, userClaim],
      state: authorizedAsRoleState
    };

    const { authState } = await this.enterState(accessToken, query);
    if (!AuthenticationData.isValidAuthClaim(authState)) {
      throw new Api.MalformedResponseException();
    }

    this.state.authState = authState; // overwrite previously saved claim
  }

  private async fetchClientAndAuditKeys(): Promise<void> {
    this.logger.info('Fetching audit public key, and client public and private keys.');

    const {
      basicProfile: { role: roleId },
      authState,
      verifyCredentialsResponseData: { accessToken },
      privateProfile: { clientId, auditId }
    } = this.state;

    const encryptedForId = Utils.MedicalInstitute.isNonGuestRole(roleId) ? clientId : undefined;
    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [
        Utils.MedicalInstitute.isNonGuestRole(roleId) ? { id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE } : undefined,
        { id: clientId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId },
        { id: auditId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId }
      ].filter(Utils.isNotNil)
    };

    const { keyList } = await this.fetchObjects(accessToken, query);

    if (Utils.MedicalInstitute.isNonGuestRole(roleId)) {
      //
      // decrypt and cache client private key
      //
      const clientPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: clientId });
      if (Utils.isNil(clientPrivateKeyJson)) {
        throw new Api.MalformedResponseException('Client private key could not be fetched.');
      } else {
        const clientKeyPrivate = new CryptographicKeyPrivate(clientPrivateKeyJson);
        const jsonWebKey = await clientKeyPrivate.decryptedValue();
        this.logger.debug('Client private key:', JSON.stringify(jsonWebKey));
        const cryptoKey = await this.privateKeyAlgo.importKey(jsonWebKey);
        CryptographicKey.setPrivateKey(clientKeyPrivate.id, cryptoKey);
      }
    }

    //
    // decrypt and cache client public key
    //
    const clientPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: clientId });
    if (Utils.isNil(clientPublicKeyJson)) {
      throw new Api.MalformedResponseException('Client public key could not be fetched.');
    } else {
      const clientKeyPublic = new CryptographicKeyPublic(clientPublicKeyJson);
      const jsonWebKey = await clientKeyPublic.decryptedValue();
      this.logger.debug('Client public key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.publicKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPublicKey(clientKeyPublic.id, cryptoKey);
    }

    //
    // decrypt and cache audit public key
    //
    const auditPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: auditId });
    if (Utils.isNil(auditPublicKeyJson)) {
      throw new Api.MalformedResponseException('Audit public key could not be fetched.');
    } else {
      const auditKeyPublic = new CryptographicKeyPublic(auditPublicKeyJson);
      const jsonWebKey = await auditKeyPublic.decryptedValue();
      this.logger.debug('Audit public key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.publicKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPublicKey(auditKeyPublic.id, cryptoKey);
    }
  }

  private async fetchGroupKeys(): Promise<void> {
    this.logger.info('Fetching group private and public key.');

    const {
      basicProfile,
      successPayload: { response },
      userId,
      authState,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    let group: IAppUserGroup | undefined = undefined;
    if (Utils.MedicalInstitute.isGuestRole(basicProfile.role)) {
      const contactListJson = this.findUserObject(response.userObjectsByType!, { type: process.env.USER_OBJECT_TYPE_CONTACT_LIST, userId });
      if (Utils.isNil(contactListJson)) {
        throw new Api.MalformedResponseException('User contact list is either missing or invalid.');
      }
      const contactListObject = new UserObjectContactList(contactListJson);
      const { contacts: contactList } = await contactListObject.decryptedValue();
      const circleOfCareGroup = contactList.find((entry) => entry.type === 'group' && entry.groupType === CIRCLE_OF_CARE);
      if (Utils.isNotNil(circleOfCareGroup)) {
        group = new AppUserGroup(circleOfCareGroup.id, CIRCLE_OF_CARE);
      }
    } else if (Utils.MedicalInstitute.isNonGuestRole(basicProfile.role)) {
      const circleOfCareGroup = basicProfile.memberOf?.find((group) => group.groupType === CIRCLE_OF_CARE);
      if (Utils.isNotNil(circleOfCareGroup)) {
        group = new AppUserGroup(circleOfCareGroup.id, circleOfCareGroup.groupType);
      }
    }

    if (Utils.isNil(group)) {
      throw new AppException(Constants.Error.E_DATA_MISSING_OR_INVALID, "User's circle of care group could not be determined.");
    }

    this.state.circleOfCareGroup = group;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [
        { id: group.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE },
        { id: group.id, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId: group.id }
      ]
    };

    const { keyList } = await this.fetchObjects(accessToken, query);

    //
    // decrypt and cache the group's private key
    //
    const groupPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: group!.id });
    if (Utils.isNil(groupPrivateKeyJson)) {
      throw new Api.MalformedResponseException('Group private key could not be fetched.');
    } else {
      const groupKeyPrivate = new CryptographicKeyPrivate(groupPrivateKeyJson);
      const jsonWebKey = await groupKeyPrivate.decryptedValue();
      this.logger.debug('Group private key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.privateKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPrivateKey(groupKeyPrivate.id, cryptoKey);
    }

    //
    // decrypt and cache the group's public key
    //
    const groupPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: group!.id });
    if (Utils.isNil(groupPublicKeyJson)) {
      throw new Api.MalformedResponseException('Group public key could not be fetched.');
    } else {
      const groupKeyPublic = new CryptographicKeyPublic(groupPublicKeyJson);
      const jsonWebKey = await groupKeyPublic.decryptedValue();
      this.logger.debug('Group public key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.publicKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPublicKey(groupKeyPublic.id, cryptoKey);
    }
  }

  private async preloadGroupObjects(): Promise<void> {
    this.logger.info('Preloading some of the group objects which may be required immediately after sign-in.');

    const {
      authState,
      circleOfCareGroup: group,
      verifyCredentialsResponseData: { accessToken },
      successPayload: { request, response }
    } = this.state;

    // fetch group folder list
    const query: FetchObjectsRequestData = {
      authState,
      userObjectsByType: [{ userId: group.id, type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST }],
      expectedCount: { userObjectsByType: null }
    };

    const { userObjectList } = await this.fetchObjects(accessToken, query);

    request.userObjectsByType!.push(...query.userObjectsByType!);
    response.userObjectsByType!.push(userObjectList[0]);
  }

  private async preloadGroupMessageFolders(): Promise<void> {
    this.logger.info('Trying to preload group message folder data.');

    const {
      authState,
      verifyCredentialsResponseData: { accessToken },
      successPayload: { request, response }
    } = this.state;

    try {
      const folderListJson = this.findUserObject(response.userObjectsByType, { type: process.env.GROUP_OBJECT_TYPE_FOLDER_LIST });
      if (Utils.isNil(folderListJson)) {
        throw new Api.MalformedResponseException('Group folder list object is either missing or invalid.');
      }

      const folderListObject = new GroupObjectFolderList(folderListJson);
      const folderList: GroupObjectFolderListValue<1 | 2> = await folderListObject.decryptedValue();
      let messageFolderIds: Array<number> = [];
      if (Utils.isUndefined(folderList.$$formatver) || folderList.$$formatver === 1) {
        messageFolderIds = Object.values(folderList)
          .map((folder) => folder?.id)
          .filter(DataObjectMsgFolder.isValidId);
      } else if (folderList.$$formatver >= 2) {
        messageFolderIds = Utils.flatten(
          Object.values(folderList.msg).map(({ id, children }) => {
            const ids: Array<number | undefined> = [id];
            if (!Utils.isUndefined(children)) {
              ids.push(...Object.values(children).map((folder) => folder?.id));
            }
            return ids.filter(DataObjectMsgFolder.isValidId);
          })
        );
      }

      const query: FetchObjectsRequestData = {
        authState,
        dataObjects: { ids: messageFolderIds },
        expectedCount: { dataObjects: null }
      };

      const { dataObjectList } = await this.fetchObjects(accessToken, query);

      // for (let index = 0; index < dataObjectList.length; index++) {
      //   const msgFolderJson = dataObjectList[index];

      //   const msgFolderObject = new DataObjectMsgFolder(msgFolderJson);
      //   const updatedObject = await msgFolderObject.updateValue({
      //     $$formatver: 3,
      //     data: [],
      //     next: null,
      //     itemCount: {
      //       all: { total: 0, unread: 0 },
      //       important: { total: 0, unread: 0 },
      //       reminder: { total: 0, unread: 0 },
      //       billable: { total: 0, unread: 0 },
      //       referral: { total: 0, unread: 0 }
      //     }
      //   });

      //   await this.batchUpdateData(accessToken, { dataObjects: [{ operation: 'update', data: updatedObject }] });

      //   dataObjectList[index] = updatedObject.toApiFormatted();
      // }

      (this.state.msgFolderJsonList || (this.state.msgFolderJsonList = [])).push(...dataObjectList);

      request.dataObjects!.ids.push(...messageFolderIds);
      response.dataObjects!.push(...dataObjectList);
    } catch (error) {
      this.logger.warn(error);
      /* ignore */
    }
  }

  private async preloadClientObjects(): Promise<void> {
    this.logger.info('Trying to preload client user and contact list objects.');

    const {
      authState,
      verifyCredentialsResponseData: { accessToken },
      privateProfile: { clientId },
      successPayload: { request, response }
    } = this.state;

    try {
      const query: FetchObjectsRequestData = {
        authState,
        userObjectsByType: [
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONTACT_LIST },
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_CONFIGURATION },
          { userId: clientId, type: process.env.CLIENT_OBJECT_TYPE_USER_LIST }
        ],
        expectedCount: { userObjectsByType: null }
      };

      const { userObjectList } = await this.fetchObjects(accessToken, query);

      const clientConfigJson = this.findUserObject(userObjectList, {
        type: process.env.CLIENT_OBJECT_TYPE_CONFIGURATION,
        userId: clientId
      });

      if (Utils.isNil(clientConfigJson)) {
        throw new Api.MalformedResponseException('Client configuration could not be fetched.');
      } else {
        const clientConfigObject = new ClientObjectConfiguration(clientConfigJson);
        const clientConfig = await clientConfigObject.decryptedValue();
        this.state.globalContactListId = clientConfig.globalContactListId;
      }

      request.userObjectsByType!.push(...query.userObjectsByType!);
      response.userObjectsByType!.push(...userObjectList);
    } catch (error) {
      this.logger.warn(error);
      /* ignore */
    }
  }

  private async fetchGlobalContactListKeys(): Promise<void> {
    this.logger.info('Fetching global contact list public and private keys.');

    const {
      authState,
      globalContactListId: keyId,
      verifyCredentialsResponseData: { accessToken }
    } = this.state;

    const query: FetchObjectsRequestData = {
      authState,
      keysByType: [
        { id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE },
        { id: keyId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId: keyId }
      ]
    };

    const { keyList } = await this.fetchObjects(accessToken, query);

    //
    // decrypt and cache global contact list private key
    //
    const contactListPrivateKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PRIVATE, id: keyId });
    if (Utils.isNil(contactListPrivateKeyJson)) {
      throw new Api.MalformedResponseException('Global contact list private key could not be fetched.');
    } else {
      const contactListKeyPrivate = new CryptographicKeyPrivate(contactListPrivateKeyJson);
      const jsonWebKey = await contactListKeyPrivate.decryptedValue();
      this.logger.debug('Global contact list private key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.privateKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPrivateKey(contactListKeyPrivate.id, cryptoKey);
    }

    //
    // decrypt and cache global contact list public key
    //
    const contactListPublicKeyJson = this.findKey(keyList, { type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, id: keyId });
    if (Utils.isNil(contactListPublicKeyJson)) {
      throw new Api.MalformedResponseException('Global contact list public key could not be fetched.');
    } else {
      const contactListKeyPublic = new CryptographicKeyPublic(contactListPublicKeyJson);
      const jsonWebKey = await contactListKeyPublic.decryptedValue();
      this.logger.debug('Global contact list public key:', JSON.stringify(jsonWebKey));
      const cryptoKey = await this.publicKeyAlgo.importKey(jsonWebKey);
      CryptographicKey.setPublicKey(contactListKeyPublic.id, cryptoKey);
    }
  }

  private async preloadUserMessageFolders(): Promise<void> {
    this.logger.info('Trying to preload user message folder data.');

    const {
      authState,
      verifyCredentialsResponseData: { accessToken },
      successPayload: { request, response }
    } = this.state;

    try {
      const folderListJson = this.findUserObject(response.userObjectsByType, { type: process.env.USER_OBJECT_TYPE_FOLDER_LIST });
      if (Utils.isNil(folderListJson)) {
        throw new Api.MalformedResponseException('User folder list object is either missing or invalid.');
      }

      const folderListObject = new UserObjectFolderList(folderListJson);
      const folderList: UserObjectFolderListValue<1 | 2> = await folderListObject.decryptedValue();
      let messageFolderIds: Array<number> = [];
      if (Utils.isUndefined(folderList.$$formatver) || folderList.$$formatver === 1) {
        messageFolderIds = Object.values(folderList)
          .map((folder) => folder?.id)
          .filter(DataObjectMsgFolder.isValidId);
      } else if (folderList.$$formatver >= 2) {
        messageFolderIds = Utils.flatten(
          Object.values(folderList.msg).map(({ id, children }) => {
            const ids: Array<number | undefined> = [id];
            if (!Utils.isUndefined(children)) {
              ids.push(...Object.values(children).map((folder) => folder?.id));
            }
            return ids.filter(DataObjectMsgFolder.isValidId);
          })
        );
      }

      this.logger.warn('messageFolderIds', messageFolderIds);

      const query: FetchObjectsRequestData = {
        authState,
        dataObjects: { ids: messageFolderIds },
        expectedCount: { dataObjects: null }
      };

      const { dataObjectList } = await this.fetchObjects(accessToken, query);

      (this.state.msgFolderJsonList || (this.state.msgFolderJsonList = [])).push(...dataObjectList);

      request.dataObjects!.ids.push(...messageFolderIds);
      response.dataObjects!.push(...dataObjectList);
    } catch (error) {
      this.logger.warn(error);
      /* ignore */
    }
  }

  private async applyMigrations(): Promise<void> {
    // =========================================================================
    // ............................... IMPORTANT ...............................
    // =========================================================================
    //
    // Changing the order of migrations may possibly cause data corruption; be
    // absolutely sure of what you're doing before making any changes
    //
    // =========================================================================

    const {
      verifyCredentialsResponseData: { accessToken },
      basicProfile: { role: roleId },
      privateProfile: { clientId, auditId, ownerId },
      globalContactListId,
      userId,
      successPayload,
      circleOfCareGroup: group,
      msgFolderJsonList
    } = this.state;

    if (Utils.MedicalInstitute.isPhysicianRole(roleId)) {
      await this.migration_encryptUserKeyPublicForGlobalContactList();
    }
    // await this.migration_bumpMsgFolderValueToVersionTwo();
    await this.dispatch(migration_bumpContactInfoValueToVersionTwo({ accessToken, userId, clientId, globalContactListId, successPayload }));
    await this.dispatch(migration_createPreferencesObject({ accessToken, userId, clientId, auditId, globalContactListId, successPayload }));
    if (Utils.MedicalInstitute.isPhysicianRole(roleId)) {
      // prettier-ignore
      await this.dispatch(migration_addGroupsAndInstituteInfoToGlobalContactList({ accessToken, clientId, globalContactListId, successPayload }));
    }
    // prettier-ignore
    await this.dispatch(migration_bumpUserAndGroupFolderListToVersionTwo({ accessToken, userId, roleId, groupId: group.id, ownerId, auditId, successPayload }));
    await this.dispatch(migration_bumpBasicProfileValueToVersionFour({ accessToken, userId, successPayload }));
    await this.dispatch(migration_bumpMsgFolderValueToVersionThree({ accessToken, msgFolderJsonList, successPayload }));
    if (Utils.MedicalInstitute.isNonGuestRole(roleId)) {
      await this.dispatch(migration_maskHealthCardNumberInContactInfo({ accessToken, groupId: group.id, successPayload }));
    }
  }

  private async migration_encryptUserKeyPublicForGlobalContactList(): Promise<void> {
    const Logger = getLoggerWithPrefix('Migration', 'encryptUserKeyPublicForGlobalContactList:');

    const {
      authState,
      userId,
      globalContactListId,
      verifyCredentialsResponseData: { accessToken },
      userKeyPublic
    } = this.state;

    const query: Api.BatchQueryRequestData = {
      authState,
      keysByType: [{ id: userId, type: process.env.CRYPTOGRAPHIC_KEY_TYPE_PUBLIC, encryptedForId: globalContactListId }]
    };

    const { keysByType: keyList } = await this.batchQueryData(accessToken, query);
    if (!Utils.isArray(keyList)) throw new Api.MalformedResponseException();

    if (keyList.length === 0) {
      try {
        Logger.info('User public key (encrypted for global contact list) could not be found; creating one.');

        const key = await userKeyPublic.encryptFor(globalContactListId);

        const mutations: Api.BatchUpdateRequestData = { authState, keys: [{ operation: 'insert', data: key }] };
        await this.batchUpdateData(accessToken, mutations);
      } catch (error) {
        Logger.warn(error);
        /* ignore */
      }
    } else {
      Logger.debug('User public key (encrypted for global contact list) is already present; call ignored.');
    }
  }

  // private async migration_bumpMsgFolderValueToVersionTwo(): Promise<void> {
  //   const Logger = getLoggerWithPrefix('Migration', 'bumpMsgFolderValueToVersionTwo:');

  //   const {
  //     msgFolderJsonList,
  //     authState,
  //     verifyCredentialsResponseData: { accessToken },
  //     successPayload: { response }
  //   } = this.state;

  //   if (!Utils.isArray(msgFolderJsonList)) {
  //     return;
  //   }

  //   const calculateItemCounts = (count: DataObjectMsgFolderValue_v2['itemCount'], message: DataObjectMsgFolderItem) => {
  //     const { isMarkedAsRead, isImportant, hasReminder, isBillable } = MessageFlags(message);

  //     ++count.all.total;
  //     count.all.unread += +!isMarkedAsRead;

  //     count.important.total += +isImportant;
  //     count.important.unread += +(isImportant && !isMarkedAsRead);

  //     count.reminder.total += +hasReminder;
  //     count.reminder.unread += +(hasReminder && !isMarkedAsRead);

  //     count.billable.total += +isBillable;
  //     count.billable.unread += +(isBillable && !isMarkedAsRead);
  //   };

  //   for (const msgFolderJson of this.state.msgFolderJsonList) {
  //     let folderId = msgFolderJson.id;
  //     try {
  //       const itemCount: DataObjectMsgFolderValue_v2['itemCount'] = {
  //         all: { total: 0, unread: 0 },
  //         important: { total: 0, unread: 0 },
  //         reminder: { total: 0, unread: 0 },
  //         billable: { total: 0, unread: 0 }
  //       };

  //       let msgFolderOrExtId: number | null = folderId;
  //       let msgFolderOrExtType = msgFolderJson.type;
  //       let dataObjectList: ApiFormattedDataObject[] = [msgFolderJson];
  //       let baseMsgFolderObject: IDataObject<DataObjectMsgFolderValue> | undefined = undefined;
  //       let baseMsgFolderValue: DataObjectMsgFolderValue | undefined = undefined;

  //       while (DataObjectMsgFolder.isValidId(msgFolderOrExtId)) {
  //         if (msgFolderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT) {
  //           const { dataObjects } = await this.batchQueryData({ authState, dataObjects: { ids: [msgFolderOrExtId] } }, accessToken);

  //           if (!Utils.isArray(dataObjects) || dataObjects.length !== 1) {
  //             throw new Api.MalformedResponseException('Message folder extension could not be fetched.');
  //           }

  //           dataObjectList = dataObjects;
  //         }

  //         const msgFolderObject = new DataObjectMsgFolder(dataObjectList[0]);
  //         const msgFolder = await msgFolderObject.decryptedValue();

  //         if (msgFolderOrExtType === process.env.DATA_OBJECT_TYPE_MSG_FOLDER) {
  //           if (Utils.isNotNil(msgFolder.$$formatver) && msgFolder.$$formatver > 1) {
  //             break;
  //           }
  //           baseMsgFolderObject = msgFolderObject;
  //           baseMsgFolderValue = { ...Utils.pick(msgFolder, ['data', 'next']), $$formatver: 2 };
  //         }

  //         Utils.transform(msgFolder.data, calculateItemCounts, itemCount);

  //         msgFolderOrExtId = msgFolder.next;
  //         msgFolderOrExtType = process.env.DATA_OBJECT_TYPE_MSG_FOLDER_EXT;
  //       }

  //       if (Utils.isNotNil(baseMsgFolderObject) && Utils.isNotNil(baseMsgFolderValue)) {
  //         const updatedMsgFolderObject = await baseMsgFolderObject.updateValue({ ...baseMsgFolderValue, itemCount });
  //         const mutations: Api.BatchUpdateRequestData = {
  //           authState,
  //           dataObjects: [{ operation: 'update', data: updatedMsgFolderObject }]
  //         };
  //         await this.batchUpdateData(accessToken, mutations);

  //         // eslint-disable-next-line no-loop-func
  //         const index = response.dataObjects!.findIndex((dataObject) => dataObject.id === folderId);
  //         if (index >= 0) response.dataObjects![index] = updatedMsgFolderObject.toApiFormatted();
  //       }
  //     } catch (error) {
  //       Logger.warn(`Error applying migration to folder (ID=${folderId}):`, error);
  //       /* ignore */
  //     }
  //   }
  // }
}

export const signInAction = (payload: ActionPayloadSignIn): AppThunk<Promise<(SignInSuccess | SignInSuccessAuthOnly) | undefined>> => {
  return async (dispatch, _S, { apiService }) => {
    const Logger = getLoggerWithPrefix('Action', 'signInAction:');

    const action = new SignInAction({ payload, dispatch, apiService, logger: Logger });
    return await action.execute();
  };
};
